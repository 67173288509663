import { cx } from '@emotion/css';
import type { ApexOptions } from 'apexcharts';
import type { FC } from 'react';
import Chart from 'react-apexcharts';

const defaultOptions: ApexOptions = {
  chart: {
    sparkline: {
      enabled: true,
    },
    toolbar: {
      show: false,
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'light',
      shadeIntensity: 1,
    },
  },
  plotOptions: {
    area: {
      fillTo: 'end',
    },
  },
  theme: {
    monochrome: {
      enabled: true,
      color: '#873CFF',
    },
  },
  tooltip: {
    style: {
      fontSize: '14px',
    },
  },
};

const defaultHeight = 60;

type Props = {
  series: ApexOptions['series'];
  labels: ApexOptions['labels'];
  height?: number;
  title: string;
  total?: number;
  percent?: number;
};

const formatNumber = (num: number) => {
  const formatter = new Intl.NumberFormat('en-AU');
  return formatter.format(num);
};

const Area: FC<Props> = ({ series, labels, height, title, total, percent }) => {
  const dataPresent = (series || []).length > 0 && (labels || []).length > 0;
  const showPercent = percent !== undefined && dataPresent;

  return (
    <div>
      <div className="flex justify-between">
        <div>
          <div className="font-poppins text-gray-700 mb-1">{title}</div>
          {total !== undefined && (
            <div className="font-poppins text-gray-700 font-semibold text-2xl mb-4">
              {formatNumber(total)}
            </div>
          )}
        </div>
        {showPercent && (
          <div>
            <div
              className={cx(
                'px-2.5 py-0.5 font-medium text-xs rounded-md',
                percent > 0
                  ? 'bg-green-100 text-green-800'
                  : 'bg-red-100 text-red-450',
              )}
            >
              {percent.toFixed(2)}%
            </div>
          </div>
        )}
      </div>
      {dataPresent ? (
        <Chart
          height={height || defaultHeight}
          options={{ ...defaultOptions, labels }}
          series={series}
          type="area"
        />
      ) : (
        <div className="text-center text-sm">No data yet</div>
      )}
    </div>
  );
};

export default Area;
