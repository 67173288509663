import { Box } from '@material-ui/core';
import InfoPanelDivider from '../../InfoPanelDivider';
import IDesign from '@/types/IDesign';
import { InformationProps } from '../../DetailsForm';
import InfoPanelContainer from '@/components/InfoPanelContainer';
import CardDesigns from './CardDesign';

interface IMoreOrderShippingInformationProps {
  information: Partial<InformationProps>[];
  totalPrice: number;
  selectedDesign: IDesign | undefined;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  units: number;
}

const MoreOrderShippingInformation = (
  props: IMoreOrderShippingInformationProps,
) => {
  const { information, totalPrice, selectedDesign, setStep, units } = props;

  return (
    <>
      <InfoPanelContainer
        title={
          <div>
            <p className="text-2xl font-medium leading-6 text-gray-900 mb-2">
              Order summary
            </p>
          </div>
        }
        padding="py-5"
        information="Add details to better idenitify your group"
      >
        <Box className="px-6">
          <Box className="flex items-center text-gray-900 font-poppins font-medium text-sm">
            <p>Product type</p>
            <p className=" flex flex-1 justify-end items-center">
              Tapt custom cards
            </p>
          </Box>
          <InfoPanelDivider />
          <Box className="flex items-center text-gray-900 font-poppins font-medium text-sm">
            <Box className="">
              <p>Number of card </p>
              <p className="text-gray-500 font-normal">
                All cards are of the same design{' '}
              </p>
            </Box>
            <p className="flex flex-1 justify-end item-center">{units}</p>
          </Box>
          <InfoPanelDivider />
          <Box className="grid gap-6 text-gray-900 font-poppins font-medium text-sm">
            <Box className="grid grid-cols-2 ">
              <p className="col-span-1">Subtotal</p>
              <p className="col-span-1 flex justify-end items-center">
                AUD ${totalPrice}
              </p>
            </Box>
            <Box className="grid grid-cols-2 font-normal">
              <p className="col-span-1">GST (%10 included)</p>
              <p className="col-span-1 flex justify-end items-center">
                AUD $
                {(totalPrice / 10).toString().length > 6
                  ? (totalPrice / 10).toFixed(3)
                  : totalPrice / 10}
              </p>
            </Box>
          </Box>
        </Box>
      </InfoPanelContainer>
      <InfoPanelDivider />
      <InfoPanelContainer
        title={
          <div>
            <p className="text-2xl font-medium leading-6 text-gray-900 mb-2">
              Card design
            </p>
          </div>
        }
        padding="py-5"
        information="Select a design template for you card/s. "
        additionalContents={
          <p>
            You can select a previously uploaded design, or upload a new design
            for a fresh, new look!
          </p>
        }
      >
        <Box className="px-6">
          <CardDesigns selectedDesign={selectedDesign} setStep={setStep} />
        </Box>
      </InfoPanelContainer>
      <InfoPanelDivider />
      <InfoPanelContainer
        title={
          <div>
            <p className="text-2xl font-medium leading-6 text-gray-900 mb-2">
              Card details
            </p>
          </div>
        }
        padding="py-5"
        information="Add details to better idenitify your group"
      >
        <Box className="px-6">
          {information.map((item, index) => (
            <>
              <Box className=" text-sm font-poppins font-normal text-gray-500 grid gap-2.5">
                <p className="text-gray-900 font-medium">Card {index + 1} </p>
                <p className={item?.companyName ? '' : 'hidden'}>
                  {item?.companyName}
                </p>
                <p className={item?.jobTitle ? '' : 'hidden'}>
                  {item.jobTitle}
                </p>
                <p className={item?.mobileNumber ? '' : 'hidden'}>
                  {item.mobileNumber}
                </p>
                <p className={item?.name ? '' : 'hidden'}>{item.name}</p>
              </Box>
              <div className="hidden sm:block last:hidden" aria-hidden="true">
                <div className="py-6 md:py-8">
                  <div className="border-t border-gray-200" />
                </div>
              </div>
            </>
          ))}
        </Box>
      </InfoPanelContainer>
    </>
  );
};

export default MoreOrderShippingInformation;
