import { useEffect, useState } from 'react';
import { IOrganisationUser } from '@/types/IOrganisation';
import ModalFullWidth from '../ModalFullWidth';
import MESSAGES from '@/constants/messages-en';
import EditorItem from './EditorItem';

interface IEditorsModal {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  users: IOrganisationUser[];
  onSuccess: (selectedUsers: IOrganisationUser[]) => void;
}

const EditorsModal: React.FC<IEditorsModal> = ({
  users,
  isOpen,
  setIsOpen,
  onSuccess,
}: IEditorsModal): JSX.Element => {
  const [selectedOrgRoles, setSelectedOrgRoles] = useState<IOrganisationUser[]>(
    [],
  );

  useEffect(() => {
    if (isOpen === false) {
      setSelectedOrgRoles([]);
    }
  }, [isOpen]);

  return (
    <ModalFullWidth
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dialogTitle="Select editor/s"
      dialogDescription="Select the editor/s for which you would like to add to this group."
      successButtonText="Add to group"
      onSuccess={() => {
        onSuccess(selectedOrgRoles);
        setIsOpen(false);
      }}
    >
      {users.length === 0 && (
        <div className="py-32">
          <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
            {MESSAGES.group_editors.list.empty.heading}
          </h3>
          <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
            {MESSAGES.group_editors.list.empty.description}
          </p>
        </div>
      )}

      {users.length > 0 && (
        <div className="flex flex-col mt-2">
          <div className="relative -mx-4 sm:-mx-6 md:mx-0 lg:-mx-8">
            <div className="block w-full lg:px-8">
              <div
                className="relative shadow-sm border border-gray-200 sm:rounded-md min-h-8"
                style={{ height: '500px', overflowY: 'scroll' }}
              >
                <ul>
                  <li key="header" className="hidden lg:block">
                    <div className="bg-gray-50">
                      <div className="flex items-center px-4 py-2 ml-10 sm:px-6">
                        <div className="min-w-0 flex-1 flex items-start md:items-center">
                          <div className="hidden lg:block min-w-full pr-4 lg:grid lg:grid-cols-4 xl:grid-cols-6">
                            <p className="lg:col-span-2 xl:col-span-3 xl:pl-4 text-sm lg:block text-start font-medium text-gray-900">
                              NAME
                            </p>
                            <p className="lg:col-span-2 xl:col-span-3 text-start md:mt-0 hidden lg:block text-sm font-medium text-gray-900">
                              GROUP
                            </p>
                          </div>
                          <div className="w-24" />
                        </div>
                      </div>
                    </div>
                  </li>

                  {users.map((user) => (
                    <EditorItem
                      organisationRole={user}
                      selected={
                        !!selectedOrgRoles.find(
                          (selectedOrgRole) => selectedOrgRole.id === user.id,
                        )
                      }
                      checkItem={(selected: boolean) => {
                        if (selected) {
                          setSelectedOrgRoles([...selectedOrgRoles, user]);
                        } else {
                          setSelectedOrgRoles(
                            selectedOrgRoles.filter(
                              (selectedOrgRole) =>
                                selectedOrgRole.id !== user.id,
                            ),
                          );
                        }
                      }}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </ModalFullWidth>
  );
};

export default EditorsModal;
