import filesAPI from "@/api/files";
import IFile from "@/types/IFile";
import { BASE_URL } from "../config";

const fileHelper = {
    downloadFile: async (file: IFile | File | undefined | null, callback?: { onSuccess?: () => void, onError?: () => void, finally?: () => void }) => {

        try {
            const a = document.createElement('a');

            if (file) {
                if (file instanceof File) {
                    const blob = new Blob([file]);
                    a.href = window.URL.createObjectURL(blob);
                    a.download = file?.name || 'file';
                } else {
                    const response = await filesAPI.downloadFile(`${BASE_URL}${file?.original_url}`);
                    a.href = window.URL.createObjectURL(response.data);
                    a.download = file?.file?.file_name || 'file';
                }
                a.click();
                callback?.onSuccess && callback.onSuccess();
            } else {
                callback?.onError && callback.onError();
            }
        } catch (e) {
            callback?.onError && callback.onError();
        } finally {
            callback?.finally && callback.finally();
        }
    }
}

export { fileHelper as default, fileHelper };