import { useState } from 'react';

export default function DiscountCalculator(): JSX.Element {
  const [noOfCards, setNoOfCards] = useState<string>('30');
  const discountMilestones = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  const baseCardPrice = 84.95;
  const premiumCardPrice = 40;

  const getDiscount = (noOfCards: string) => {
    const total = parseInt(noOfCards, 10);
    if (total >= 50) return 20;
    if (total >= 30) return 15;
    if (total >= 13) return 10;
    if (total >= 2) return 5;
    return 0;
  };

  const basicDiscount = (noOfCards: string) => {
    const total = parseInt(noOfCards, 10);
    const discount = getDiscount(noOfCards);
    return (total * discount).toFixed(2);
  };

  const basicTotal = (noOfCards: string) => {
    const total = parseInt(noOfCards, 10);
    const discount = getDiscount(noOfCards);
    return (total * (baseCardPrice - discount)).toFixed(2);
  }

  const premiumTotal = (noOfCards: string) => {
    const total = parseInt(noOfCards, 10);
    return (total * premiumCardPrice).toFixed(2);
  }

  const totalSave = (noOfCards: string) => {
    const total = parseInt(noOfCards, 10);
    const discount = getDiscount(noOfCards);

    return (total * (baseCardPrice - discount - premiumCardPrice)).toFixed(2);
  }
  
  return (
    <div className="flex justify-center">
      <div className="w-2/6 mr-10">
      <h3 className="text-2xl font-medium leading-7 text-gray-900 mb-">Save on new orders with Premium!</h3>
      <p className="leading-6 text-lg text-gray-500">
        Only <span className='text-brand-500'>$40 per card</span> when you reorder
      </p>
      </div>
      <div className="w-4/6">
        <ul className="flex justify-between w-full px-[10px]">
          {discountMilestones.map((milestone) => {
            return (
              <li className="flex justify-center relative">
                <span className="absolute text-base">{milestone}</span>
              </li>
            );
          })}
        </ul>
        <input
          type="range"
          className="range w-full mt-10"
          min="0"
          max="100"
          step="10"
          style={{ accentColor: '#873CFF' }}
          value={noOfCards}
          onChange={(e) => setNoOfCards(e.target.value)}
        />
        <div className="flex justify-between w-full">
          <div className="flex-1">
            <p className="text-gray-500 font-light">
              Basic total ${basicTotal(noOfCards)}
            </p>

            <p className="text-brand-500 font-light">
              Premium total ${premiumTotal(noOfCards)}
            </p>
          </div>
          <div className="flex-1 text-brand-500 text-right">
            <p className="font-light">
              Premium saved you
            </p>

            <p className="text-xl font-medium">
              ${totalSave(noOfCards)} AUD
            </p>
          </div>
        </div>
        
      </div>
    </div>
  );
}
