import { useState } from 'react';
import Layout from '@/components/Layout';
import Pagination from '@/components/Pagination';
import Search from '@/components/Search';
import useAuth from '@/hooks/useAuth';
import analyticsAPI from '@/api/analytics';
import AnalyticsListItem from '@/components/AnalyticsListItem';
import IAnalytics from '@/types/IAnalytics';
import Spinner from '@/components/Icons/Spinner';
import classNames from '@/helpers/classNames';
import MESSAGES from '@/constants/messages-en';
import Sort from '@/components/Sort';

export default function AnalyticsPage(): JSX.Element {
  const { orgID } = useAuth();
  const [data, setData] = useState<IAnalytics[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('page_views');
  const [order, setOrder] = useState('desc');

  async function listAnalytics(
    newPage: number = page,
    newPageSize: number = pageSize,
    newSort: string = sort,
    newOrder: string = order,
    newSearch: string = search,
  ) {
    const res = await analyticsAPI.oldListAnalytics({
      orgID,
      page: newPage,
      pageSize: newPageSize,
      sort: newSort,
      order: newOrder,
      search: newSearch,
    });

    setData(res.data.data);
    setPage(newPage);
    setPageSize(newPageSize);
    setSort(newSort);
    setOrder(newOrder);

    return res.data;
  }

  return (
    <Layout pageName="Analytics">
      <div className="pb-8 pt-8 md:pt-0">
        <div className="pb-8 flex flex-col space-y-3 items-start sm:flex-row sm:space-y-0 sm:items-center sm:justify-end">
          <div className="flex flex-shrink-0 space-x-3">
            <Search
              id={`Analytics-${page}-${pageSize}-${sort}-${order}-${search}`}
              search={search}
              setSearch={setSearch}
              fetchQuery={(newSearch) =>
                listAnalytics(page, pageSize, sort, order, newSearch)
              }
            />
            <Sort
              id={`Analytics-${page}-${pageSize}-${sort}-${order}-${search}`}
              options={[
                {
                  sort: 'page_views',
                  order: 'desc',
                  label: 'Page visits (Highest first)',
                },
                {
                  sort: 'page_views',
                  order: 'asc',
                  label: 'Page visits (Lowest first)',
                },
                {
                  sort: 'contacts',
                  order: 'desc',
                  label: 'Contacts (Highest first)',
                },
                {
                  sort: 'contacts',
                  order: 'asc',
                  label: 'Contacts (Lowest first)',
                },
              ]}
              sort={sort}
              setSort={setSort}
              order={order}
              setOrder={setOrder}
              fetchQuery={(newSort, newOrder) =>
                listAnalytics(page, pageSize, newSort, newOrder, search)
              }
            />
          </div>
        </div>
        {data && data.length > 0 ? (
          <div className="flex flex-col">
            <div className="relative -mx-4 sm:-mx-6 md:mx-0 lg:-mx-8">
              <div className="block w-full lg:px-8">
                <div className="relative shadow-sm border border-gray-200 sm:rounded-md min-h-8 overflow-hidden">
                  <ul
                    className={classNames(
                      isLoading ? 'opacity-40' : '',
                      'divide-y divide-gray-200',
                    )}
                  >
                    <li key="AnalyticsListHeader" className="hidden md:block">
                      <div className="bg-gray-50">
                        <div className="flex items-center px-4 py-2 sm:px-6">
                          <div className="min-w-0 flex-1 flex items-start md:items-center">
                            <div className="min-w-0 flex-1 pr-4 md:grid md:grid-cols-4 xl:grid-cols-6 md:gap-4">
                              <p className="md:col-span-2 text-sm font-medium text-gray-900">
                                PROFILE
                              </p>
                              <p className="mt-2 md:mt-0 hidden xl:block md:col-span-2 text-sm font-medium text-gray-900">
                                POSITION
                              </p>
                              <p className="mt-2 md:mt-0 xl:col-span-1 text-sm font-medium text-gray-900">
                                TOTAL PAGE VISITS
                              </p>
                              <p className="mt-2 md:mt-0 xl:col-span-1 text-sm font-medium text-gray-900">
                                TOTAL CONTACTS
                              </p>
                            </div>
                          </div>
                          <div className="w-5" />
                        </div>
                      </div>
                    </li>
                    {data?.map((item) => (
                      <AnalyticsListItem
                        key={item.profile_id}
                        id={item.profile_id}
                        firstName={item.first_name}
                        lastName={item.last_name}
                        title={item.job_title}
                        emailAddress={item.email}
                        photoUrl={item.photo?.thumb_url}
                        viewCount={item.analytics_view_count}
                        contactsCount={item.total_contacts}
                        profileHash={item.profile_hash}
                        viewProfile={`/view-profile/${item.profile_hash}`}
                      />
                    ))}
                  </ul>
                  {isLoading && (
                    <div className="absolute left-1/2 top-1/2 text-gray-500">
                      <Spinner className="text-brand-500" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="py-32">
            <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
              {MESSAGES.analytics.list.empty.heading}
            </h3>
            <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
              {MESSAGES.analytics.list.empty.description}
            </p>
          </div>
        )}
      </div>
      <Pagination
        id={`Analytics-${page}-${pageSize}-${sort}-${order}-${search}`}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        fetchQuery={(newPage, newPageSize) =>
          listAnalytics(newPage, newPageSize, sort, order, search)
        }
        setIsLoading={setIsLoading}
      />
    </Layout>
  );
}
