import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/solid';
import ErrorAlert from '@/components/ErrorAlert';
import Input from '@/components/Input';
import MESSAGES from '@/constants/messages-en';

interface ICompanyInfo {
  companyName: string;
  companyNameError?: boolean;
  companyWebsite: string;
  companyWebsiteError?: boolean;
  companyPhoneNumber: string;
  extraCompanyPhoneNumbers: string[];
  companyPhoneNumberError?: boolean;
  country: string;
  streetAddress: string;
  streetAddressError?: boolean;
  city: string;
  stateProvince: string;
  postCode: string;
  disabled?: boolean;
  shared?: boolean;
  setCompanyName: React.Dispatch<React.SetStateAction<string>>;
  setCompanyWebsite: React.Dispatch<React.SetStateAction<string>>;
  setCompanyPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  setExtraCompanyPhoneNumbers: React.Dispatch<React.SetStateAction<string[]>>;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
  setStreetAddress: React.Dispatch<React.SetStateAction<string>>;
  setCity: React.Dispatch<React.SetStateAction<string>>;
  setStateProvince: React.Dispatch<React.SetStateAction<string>>;
  setPostCode: React.Dispatch<React.SetStateAction<string>>;
}

type ExtraMobileNumberProps = {
  mobileNumber: string;
  mobileNumbers: string[];
  setMobileNumbers: React.Dispatch<React.SetStateAction<string[]>>;
  index: number;
  disabled: boolean;
};

const ExtraMobileNumber: React.FC<ExtraMobileNumberProps> = ({
  mobileNumber,
  mobileNumbers,
  setMobileNumbers,
  index,
  disabled,
}) => {
  return (
    <div key={index} className="col-span-6 sm:col-span-3">
      <Input
        label="Direct phone number"
        type="text"
        value={mobileNumber}
        onChange={(value) => {
          setMobileNumbers(
            Object.assign([...mobileNumbers], { [index]: value }),
          );
        }}
        disabled={disabled}
        copy={disabled}
        placeholder="Enter direct phone number"
        rightIcon={
          <MinusCircleIcon className="text-red-450 z-20 h-5 w-5 cursor-pointer" />
        }
        handleRightIconClick={() => {
          setMobileNumbers(mobileNumbers.filter((_, i) => i !== index));
        }}
      />
    </div>
  );
};

export default function CompanyInfoForm({
  companyName,
  companyNameError,
  companyWebsite,
  companyWebsiteError,
  companyPhoneNumber,
  extraCompanyPhoneNumbers,
  companyPhoneNumberError,
  country,
  streetAddress,
  streetAddressError,
  city,
  stateProvince,
  postCode,
  disabled,
  shared,
  setCompanyName,
  setCompanyWebsite,
  setCompanyPhoneNumber,
  setExtraCompanyPhoneNumbers,
  setCountry,
  setStreetAddress,
  setCity,
  setStateProvince,
  setPostCode,
}: ICompanyInfo): JSX.Element {
  return (
    <>
      {companyNameError && <ErrorAlert message={MESSAGES.error.companyName} />}
      {companyWebsiteError && (
        <ErrorAlert message={MESSAGES.error.companyWebsite} />
      )}
      {companyPhoneNumberError && (
        <ErrorAlert message={MESSAGES.error.companyPhoneNumber} />
      )}
      {streetAddressError && (
        <ErrorAlert message={MESSAGES.error.streetAddress} />
      )}
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <Input
            label="Company name"
            type="text"
            value={companyName}
            onChange={setCompanyName}
            error={companyNameError}
            disabled={disabled}
            copy={disabled}
            onFocus={() => {
              if (shared && companyName === 'Click to edit or clear')
                setCompanyName('');
            }}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <Input
            preLabel="https://"
            label="Company website"
            type="text"
            value={companyWebsite}
            onChange={setCompanyWebsite}
            error={companyWebsiteError}
            disabled={disabled}
            copy={disabled}
            onFocus={() => {
              if (shared && companyWebsite === 'Click to edit or clear')
                setCompanyWebsite('');
            }}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <Input
            label="Company phone number"
            placeholder="Enter company number"
            type="text"
            value={companyPhoneNumber}
            onChange={setCompanyPhoneNumber}
            error={companyPhoneNumberError}
            disabled={disabled}
            copy={disabled}
            onFocus={() => {
              if (shared && companyPhoneNumber === 'Click to edit or clear')
                setCompanyPhoneNumber('');
            }}
            rightIcon={
              shared ? null : (
                <PlusCircleIcon className="text-brand-500 z-20 h-5 w-5 cursor-pointer" />
              )
            }
            handleRightIconClick={() => {
              setExtraCompanyPhoneNumbers([...extraCompanyPhoneNumbers, '']);
            }}
          />
        </div>
        {extraCompanyPhoneNumbers.map((extraMobileNumber, index) => (
          <ExtraMobileNumber
            mobileNumber={extraMobileNumber}
            mobileNumbers={extraCompanyPhoneNumbers}
            setMobileNumbers={setExtraCompanyPhoneNumbers}
            index={index}
            disabled={!!disabled}
          />
        ))}
        <div className="col-span-6">
          <Input
            label="Street address"
            type="text"
            value={streetAddress}
            onChange={setStreetAddress}
            error={streetAddressError}
            disabled={disabled}
            copy={disabled}
            onFocus={() => {
              if (shared && streetAddress === 'Click to edit or clear')
                setStreetAddress('');
            }}
          />
        </div>

        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
          <Input
            label="City"
            type="text"
            value={city}
            onChange={setCity}
            disabled={disabled}
            copy={disabled}
            onFocus={() => {
              if (shared && city === 'Click to edit or clear') setCity('');
            }}
          />
        </div>

        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
          <Input
            label="State / Province"
            type="text"
            value={stateProvince}
            onChange={setStateProvince}
            disabled={disabled}
            copy={disabled}
            onFocus={() => {
              if (shared && stateProvince === 'Click to edit or clear')
                setStateProvince('');
            }}
          />
        </div>

        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
          <Input
            label="Postcode"
            type="text"
            value={postCode}
            onChange={setPostCode}
            disabled={disabled}
            copy={disabled}
            onFocus={() => {
              if (shared && postCode === 'Click to edit or clear')
                setPostCode('');
            }}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <Input
            label="Country"
            type="text"
            value={country}
            onChange={setCountry}
            disabled={disabled}
            copy={disabled}
            onFocus={() => {
              if (shared && country === 'Click to edit or clear')
                setCountry('');
            }}
          />
        </div>
      </div>
    </>
  );
}
