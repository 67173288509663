import { useCallback, useEffect, useState } from 'react';
import ModalFullWidth from '../ModalFullWidth';
import Search from '../Search';
import IProfile from '@/types/IProfile';
import profilesAPI from '@/api/profiles';
import useAuth from '@/hooks/useAuth';
import MESSAGES from '@/constants/messages-en';
import ProfileListGroupItem from '../ProfileListGroupItem';
import Spinner from '../Icons/Spinner';
import Sort from '../Sort';
import { BUTTON_KIND } from '../Button';

interface IRemoveProfilesModal {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  groupID: number;
  onSuccess: (profileIDs: number[]) => void;
}

const RemoveProfilesModal: React.FC<IRemoveProfilesModal> = ({
  isOpen,
  setIsOpen,
  groupID,
  onSuccess,
}: IRemoveProfilesModal): JSX.Element => {
  const { orgID } = useAuth();

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(50);
  const [sort, setSort] = useState('date');
  const [order, setOrder] = useState('desc');
  const [search, setSearch] = useState('');
  const [data, setData] = useState<IProfile[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<number[]>([]);

  const listProfiles = useCallback(
    async (
      newPage: number = page,
      newPageSize: number = pageSize,
      newSort: string = sort,
      newOrder: string = order,
      newSearch: string = search,
      initial = false,
    ) => {
      setIsLoading(true);
      const res = await profilesAPI.listProfilesInGroup({
        orgID,
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        order: newOrder,
        search: newSearch,
        groupID,
      });
      setIsLoading(false);

      if (!initial) {
        setData(res.data?.data);
        setPage(newPage);
        setPageSize(newPageSize);
        setSort(newSort);
        setOrder(newOrder);
      }

      return res.data;
    },
    [order, orgID, page, pageSize, search, sort],
  );

  const handleCheckItem = (profileID: number) => {
    setCheckedItems((prev) => {
      if (prev.includes(profileID)) {
        return prev.filter((e) => e !== profileID);
      }
      return [...prev, profileID];
    });
  };

  useEffect(() => {
    if (isOpen) {
      setCheckedItems([]);
    } 
  }, [isOpen]);

  return (
    <ModalFullWidth
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dialogTitle="Remove profiles/s from group"
      dialogDescription="Add, remove and change profiles in group."
      successButtonText="Remove profiles"
      successButtonKind={BUTTON_KIND.WHITE_BG_LIGHT_RED_TEXT}
      onSuccess={() => {
        setIsOpen(false);
        onSuccess(checkedItems);
      }}
    >
      <div className="my-8 flex flex-col space-y-3 items-start sm:flex-row sm:space-y-0 sm:items-center sm:justify-end">
        <div className="flex flex-shrink-0 space-x-3">
          <Search
            id={`ProfileList-${page}-${pageSize}-${sort}-${order}-${search}`}
            search={search}
            setSearch={setSearch}
            fetchQuery={(searchQuery) =>
              listProfiles(page, pageSize, sort, order, searchQuery)
            }
          />

          <Sort
            id={`ProfileList-${page}-${pageSize}-${sort}-${order}-${search}`}
            options={[
              {
                sort: 'date',
                order: 'desc',
                label: 'Date created (Newest first)',
              },
              {
                sort: 'date',
                order: 'asc',
                label: 'Date created (Oldest first)',
              },
              {
                sort: 'first_name',
                order: 'asc',
                label: 'Name (A-Z)',
              },
              {
                sort: 'first_name',
                order: 'desc',
                label: 'Name (Z-A)',
              },
            ]}
            sort={sort}
            setSort={setSort}
            order={order}
            setOrder={setOrder}
            fetchQuery={(
              sortQuery: string | undefined,
              orderQuery: string | undefined,
            ) => listProfiles(page, pageSize, sortQuery, orderQuery, search)}
          />
        </div>
      </div>
      {data && data.length > 0 ? (
        <div className="flex flex-col">
          <div className="relative -mx-4 sm:-mx-6 md:mx-0 lg:-mx-8">
            <div className="block w-full lg:px-8">
              <div
                className="relative shadow-sm border border-gray-200 sm:rounded-md min-h-8"
                style={{ height: '500px', overflowY: 'scroll' }}
              >
                <ul>
                  <li key="ProfileListAdminHeader" className="hidden lg:block">
                    <div className="bg-gray-50">
                      <div className="flex items-center px-4 py-2 ml-10 sm:px-6">
                        <div className="min-w-0 flex-1 flex items-start md:items-center">
                          <div className="hidden lg:block min-w-full pr-4 lg:grid lg:grid-cols-6 xl:grid-cols-9">
                            <p className="lg:col-span-2 xl:col-span-3 xl:pl-4 text-sm lg:block text-start font-medium text-gray-900">
                              NAME &amp; PROFILE ID
                            </p>
                            <p className="lg:col-span-2 xl:col-span-3 text-start md:mt-0 hidden lg:block text-sm font-medium text-gray-900">
                              CONTACT INFORMATION
                            </p>
                            <p className="lg:col-span-2 xl:col-span-3 text-start md:mt-0 hidden lg:block text-sm font-medium text-gray-900">
                              GROUP
                            </p>
                          </div>
                          <div className="w-24" />
                        </div>
                      </div>
                    </div>
                  </li>
                  {data?.map((item: IProfile) => (
                    <ProfileListGroupItem
                      key={item.id}
                      orgID={orgID || 0}
                      id={item.id}
                      firstName={item.first_name || ''}
                      lastName={item.last_name || ''}
                      title={item.job_title || ''}
                      emailAddress={item.email || ''}
                      groupID={item.group_id || 0}
                      groupName={item.group_name || ''}
                      groupSubheading={item.group_subheading || ''}
                      photoUrl={item.photo?.thumb_url}
                      status={item.status || ''}
                      selected={checkedItems.includes(item.id)}
                      profileHash={item.profile_hash}
                      checkItem={() => handleCheckItem(item.id)}
                    />
                  ))}
                </ul>
                {isLoading && (
                  <div className="absolute left-1/2 top-1/2 text-gray-500">
                    <Spinner />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="py-32">
          <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
            {MESSAGES.profile.list.empty.heading}
          </h3>
          <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
            {MESSAGES.profile.list.empty.description}
          </p>
        </div>
      )}
    </ModalFullWidth>
  );
};

export default RemoveProfilesModal;
