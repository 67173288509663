import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/solid';
import { IProfileQuickFileLink, IProfileSocialLink } from '@/types/IProfile';
import IFile from '@/types/IFile';
import profilesAPI from '@/api/profiles';
import validateEmail from '@/helpers/validateEmail';
import InfoPanelContainer from '@/components/InfoPanelContainer';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import EditSocialLink from '@/components/EditSocialLink';
import EditQuickLink from '@/components/EditQuickLink';
import EditFileLink from '@/components/EditFileLink';
import Button, { BUTTON_KIND } from '@/components/Button';
import PersonalInfoForm from '@/containers/PersonalInfoForm';
import Spinner from '@/components/Icons/Spinner';
import MESSAGES from '@/constants/messages-en';
import validateUrl from '@/helpers/validateUrl';
import ErrorAlert from '@/components/ErrorAlert';

export default function EditSelfProfilePage({
  match,
}: {
  match: { params: { profile_hash_id: string } };
}): JSX.Element {
  const profileHashID = match.params.profile_hash_id;
  const history = useHistory();
  const [orgID, setOrgID] = useState<number>();
  const [photo, setPhoto] = useState<IFile>();
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [pronouns, setPronouns] = useState('');
  const [email, setEmail] = useState('');
  const [extraEmails, setExtraEmails] = useState<string[]>([]);
  const [extraMobileNumbers, setExtraMobileNumbers] = useState<string[]>([]);
  const [emailError, setEmailError] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [note, setNote] = useState('');
  const [profileSocialLinks, setProfileSocialLinks] = useState<
    IProfileSocialLink[]
  >([]);
  const [socialLinkErrorIndex, setSocialLinkErrorIndex] = useState(-1);
  const [profileQuickFileLinks, setProfileQuickFileLinks] = useState<
    IProfileQuickFileLink[]
  >([]);
  const [cardholderEditingEnabled, setCardholderEditingEnabled] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const photoFileID = photo ? photo.id : null;

  const showProfile = useCallback(() => {
    profilesAPI
      .showProfileWithHashID(profileHashID)
      .then((res) => {
        const data = res?.data;
        if (data?.data?.organisation_id) setOrgID(data.data.organisation_id);
        if (data?.data?.first_name) setFirstName(data.data.first_name);
        if (data?.data?.last_name) setLastName(data.data.last_name);
        if (data?.data?.middle_name) setMiddleName(data.data.middle_name);
        if (data?.data?.email) setEmail(data.data.email);
        if (data?.data?.extra_emails) setExtraEmails(data.data.extra_emails);
        if (data?.data?.mobile_number) setMobileNumber(data.data.mobile_number);
        if (data?.data?.extra_mobile_numbers)
          setExtraMobileNumbers(data.data.extra_mobile_numbers);
        if (data?.data?.job_title) setJobTitle(data.data.job_title);
        if (data?.data?.note) setNote(data.data.note);
        if (data?.data?.photo) setPhoto(data.data.photo);
        if (data?.data?.status === 'editing') setCardholderEditingEnabled(true);
        if (data?.data?.profile_social_links?.length > 0)
          setProfileSocialLinks(
            data.data.profile_social_links.map((item, index) => ({
              ...item,
              order: index,
            })),
          );

        let newLinksArray: IProfileQuickFileLink[] = [];
        if (data?.data?.profile_quick_links?.length > 0) {
          newLinksArray = data.data.profile_quick_links;
        }

        if (data?.data?.profile_file_links?.length > 0) {
          newLinksArray = [...newLinksArray, ...data.data.profile_file_links];
        }

        newLinksArray.sort(
          (a: IProfileQuickFileLink, b: IProfileQuickFileLink) => {
            return a.order - b.order;
          },
        );
        setProfileQuickFileLinks(
          newLinksArray.map((item: IProfileQuickFileLink, index: number) => ({
            ...item,
            order: index,
          })),
        );
      })
      .finally(() => setLoading(false));
  }, [profileHashID]);

  const updateProfile = useCallback(() => {
    profilesAPI
      .updateProfileWithHashID(profileHashID, {
        profile: {
          email,
          extra_emails: extraEmails.filter((item) => !!item),
          first_name: firstName,
          is_editable: false,
          job_title: jobTitle,
          last_name: lastName,
          middle_name: middleName,
          mobile_number: mobileNumber,
          extra_mobile_numbers: extraMobileNumbers.filter((item) => !!item),
          note,
          photo_file_id: photoFileID,
          profile_file_links: profileQuickFileLinks.filter(
            (item) => !!item.file_id,
          ),
          profile_quick_links: profileQuickFileLinks.filter(
            (item) => !!item.value,
          ),
          profile_social_links: profileSocialLinks,
          status: 'active',
        },
      })
      .finally(() => history.push(`/view-profile/${profileHashID}`));
  }, [
    profileHashID,
    email,
    extraEmails,
    firstName,
    jobTitle,
    lastName,
    middleName,
    mobileNumber,
    extraMobileNumbers,
    note,
    photoFileID,
    profileQuickFileLinks,
    profileSocialLinks,
    history,
  ]);

  useEffect(() => {
    setLoading(true);
    showProfile();
  }, [showProfile]);

  const handleClick = () => {
    // set errors back to false
    setEmailError(false);
    setSocialLinkErrorIndex(-1);

    if (email !== '' && !validateEmail(email)) {
      setEmailError(true);
    } else if (
      profileSocialLinks.findIndex((item) => !validateUrl(item.value)) !== -1
    ) {
      setSocialLinkErrorIndex(
        profileSocialLinks.findIndex((item) => !validateUrl(item.value)),
      );
    } else {
      updateProfile();
    }
  };

  const handleAddSocialLinkClick = () => {
    setProfileSocialLinks([
      ...profileSocialLinks,
      {
        order: profileSocialLinks.length
          ? profileSocialLinks[profileSocialLinks.length - 1].order + 1
          : 1,
        type: 'facebook',
        value: '',
      },
    ]);
  };

  const handleAddQuickLinkClick = () => {
    setProfileQuickFileLinks([
      ...profileQuickFileLinks,
      {
        order: profileQuickFileLinks.length
          ? profileQuickFileLinks[profileQuickFileLinks.length - 1].order + 1
          : 1,
        title: '',
        value: '',
      },
    ]);
  };

  const handleAddFileLinkClick = () => {
    setProfileQuickFileLinks([
      ...profileQuickFileLinks,
      {
        order: profileQuickFileLinks.length
          ? profileQuickFileLinks[profileQuickFileLinks.length - 1].order + 1
          : 1,
        title: '',
        file_id: 1,
      },
    ]);
  };

  if (loading) {
    return (
      <div className="py-32">
        <Spinner className="h-16 w-16 text-brand-500 mx-auto" />
      </div>
    );
  }

  if (!cardholderEditingEnabled) {
    return (
      <div className="py-32">
        <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
          Access denied
        </h3>
        <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
          You do not have permissions to view/edit this page.
          <br />
          Please contact your administrator.
        </p>
      </div>
    );
  }

  return (
    <main className="flex-1 relative z-0 focus:outline-none">
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="mb-10 max-w-3xl">
            <h2 className="text-4xl leading-10 font-medium text-gray-900">
              Edit Profile
            </h2>
            <p className="mt-5 text-base leading-6">
              {MESSAGES.profile.edit.heading}
            </p>
          </div>
          <InfoPanelContainer
            title="Personal Information"
            information={MESSAGES.profile.edit.personalInfo}
          >
            {orgID && (
              <PersonalInfoForm
                orgID={orgID}
                photo={photo}
                firstName={firstName}
                middleName={middleName}
                lastName={lastName}
                pronouns={pronouns}
                email={email}
                extraEmails={extraEmails}
                extraMobileNumbers={extraMobileNumbers}
                emailError={emailError}
                mobileNumber={mobileNumber}
                jobTitle={jobTitle}
                note={note}
                setPhoto={setPhoto}
                setFirstName={setFirstName}
                setMiddleName={setMiddleName}
                setLastName={setLastName}
                setPronouns={setPronouns}
                setEmail={setEmail}
                setExtraEmails={setExtraEmails}
                setMobileNumber={setMobileNumber}
                setExtraMobileNumbers={setExtraMobileNumbers}
                setJobTitle={setJobTitle}
                setNote={setNote}
              />
            )}
          </InfoPanelContainer>

          <InfoPanelDivider />

          <InfoPanelContainer
            title="Social Media Links"
            information={MESSAGES.profile.edit.social}
          >
            {socialLinkErrorIndex !== -1 && (
              <ErrorAlert message={MESSAGES.error.url} />
            )}
            {orgID && profileSocialLinks.length > 0 && (
              <ul
                key={`ProfileSocialLinks-${profileSocialLinks.length}`}
                className="space-y-3 pb-3"
              >
                {profileSocialLinks.map((socialLink, index) => (
                  <EditSocialLink
                    key={`SocialLink-${socialLink.order}`}
                    setProfileSocialLinks={setProfileSocialLinks}
                    profileSocialLinks={profileSocialLinks}
                    socialLink={socialLink}
                    index={index}
                    error={index === socialLinkErrorIndex}
                  />
                ))}
              </ul>
            )}
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-4">
                <Button
                  kind={BUTTON_KIND.WHITE}
                  buttonText="Add new social link"
                  icon={<PlusIcon />}
                  onClick={() => handleAddSocialLinkClick()}
                />
              </div>
            </div>
          </InfoPanelContainer>

          <InfoPanelDivider />

          <InfoPanelContainer
            title="Quick Links and Files"
            information={MESSAGES.profile.edit.links.heading}
            hint={MESSAGES.profile.edit.links.description}
          >
            {orgID && profileQuickFileLinks?.length > 0 && (
              <ul
                key={`ProfileQuickFileLinks-${profileQuickFileLinks.length}`}
                className="space-y-3 pb-3"
              >
                {profileQuickFileLinks.map((quickFileLink, index) => {
                  if (quickFileLink.file_id) {
                    return (
                      <EditFileLink
                        key={`FileLink-${quickFileLink.order}`}
                        orgID={orgID}
                        setProfileFileLinks={setProfileQuickFileLinks}
                        profileFileLinks={profileQuickFileLinks}
                        fileLink={quickFileLink}
                        index={index}
                      />
                    );
                  }
                  return (
                    <EditQuickLink
                      key={`QuickLink-${quickFileLink.order}`}
                      setProfileQuickLinks={setProfileQuickFileLinks}
                      profileQuickLinks={profileQuickFileLinks}
                      quickLink={quickFileLink}
                      index={index}
                    />
                  );
                })}
              </ul>
            )}
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-4 flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                <Button
                  kind={BUTTON_KIND.WHITE}
                  buttonText="Add new quick link"
                  icon={<PlusIcon />}
                  onClick={() => handleAddQuickLinkClick()}
                />
                <Button
                  kind={BUTTON_KIND.WHITE}
                  buttonText="Add new File link"
                  icon={<PlusIcon />}
                  onClick={() => handleAddFileLinkClick()}
                />
              </div>
            </div>
          </InfoPanelContainer>

          <div className="block" aria-hidden="true">
            <div className="py-8" />
          </div>

          <InfoPanelFooter fullWidth className="md:left-0">
            <div className="flex space-x-4">
              <Button
                buttonText="Save and close"
                onClick={() => handleClick()}
              />
              <Button
                buttonText="Discard changes"
                kind={BUTTON_KIND.WHITE}
                onClick={() => history.push(`/view-profile/${profileHashID}`)}
              />
            </div>
          </InfoPanelFooter>
        </div>
      </div>
    </main>
  );
}
