import { Transition } from '@headlessui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import Button, {
  BUTTON_ICON_POSITION,
  BUTTON_KIND,
  BUTTON_SIZE,
} from '@/components/Button';
import MultiStepProgressBar from '@/components/ReOrderPage/shared/MultiStepProgressBar';
import InfoPanelFooter from '@/components/InfoPanelFooter';

import Step1 from '../../../components/ReOrderPage/shared/Step1';
import Step2 from '@/components/ReOrderPage/shared/Step2';
import Step3WithPaymentTerm from '@/components/ReOrderPage/Custom Payment/Step3';
import Step3WithoutPaymentTerm from '@/components/ReOrderPage/ShopifyPayment/Step3';
import Step4 from '@/components/ReOrderPage/Custom Payment/Step4';

import IFile from '@/types/IFile';
import designsAPI from '@/api/designs';
import IDesign from '@/types/IDesign';
import { InformationProps } from '@/components/DetailsForm';
import validateEmail from '@/helpers/validateEmail';
import organisationsAPI from '@/api/organisations';
import useShopify from '@/hooks/useShopify';
import ordersAPI, {
  IBillingAddress,
  ILineItem,
  IShippingAddress,
} from '@/api/orders';
import ordersV2API from '@/api/ordersV2';
import useAuth from '@/hooks/useAuth';
import {
  IBillingInformation,
  IReorderMultistepProps,
  IShippingInformation,
  ShippingCostAndTaxest,
} from '@/types/ReOrderExperience/IReorderMultistepPage';
import Modal from '@/components/Modal';
import TaptCustomDiscount from '@/helpers/taptCustomDiscount';

const initShipingInformation = {
  countryRegion: 'Australia',
  company: '',
  address: '',
  state: 'Australian Capital Territory',
  postCode: '',
  city: '',
};

const initBillingInformation = {
  countryRegion: 'Australia',
  company: '',
  address: '',
  state: 'Australian Capital Territory',
  postCode: '',
  city: '',
};

const ReorderMultistepPage = (props: IReorderMultistepProps) => {
  const { isOpen, setIsOpen, orgID, units, setUnits, totalProfiles } = props;
  const { subscriptionType } = useAuth();
  const hasPremiumPlan = subscriptionType === 'premium';
  const [selectedDesign, setSelectedDesign] = useState<IDesign | undefined>();
  const [paymentTerm, setPaymentTerm] = useState<boolean>(false);

  const [shippingInformation, setShippingInformation] =
    useState<IShippingInformation>(initShipingInformation);
  const [billingInformation, setBillingInformation] =
    useState<IBillingInformation>(initBillingInformation);

  const [step, setStep] = useState<number>(0);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [nameToggle, setNameToggle] = useState<boolean>(false);
  const [mobileNumberToggle, setMobileNumberToggle] = useState<boolean>(false);
  const [emailToggle, setEmailToggle] = useState<boolean>(false);
  const [jobTitleToggle, setJobTitleToggle] = useState<boolean>(false);
  const [companyWebsiteToggle, setCompanyWebsiteToggle] =
    useState<boolean>(false);
  const [companyAddressToggle, setCompanyAddressToggle] =
    useState<boolean>(false);
  const [companyPhoneNumberToggle, setCompanyPhoneNumberToggle] =
    useState<boolean>(false);
  const [companyNameToggle, setCompanyNameToggle] = useState<boolean>(false);
  const [designFile, setDesignFile] = useState<IFile>();
  const [design, setDesign] = useState<IDesign>();
  const [information, setInformation] = useState<Partial<InformationProps>[]>(
    [],
  );
  const [secondStepError, setSecondStepError] = useState<boolean>(false);

  const [importCSV, setImportCSV] = useState<File | undefined>();
  const [shippingFormError, setShippingFormError] = useState<boolean>(false);

  const designFileID = designFile ? designFile.id : null;
  const { taptCustom, checkout, proceedToCheckout } = useShopify();
  const [openUploadCsvDialog, setOpenUploadCsvDialog] = useState(false);
  const [shippingCostAndTaxes, setShippingCostAndTaxes] = useState<
    ShippingCostAndTaxest | undefined
  >();
  const { user } = useAuth();
  const [validStep, setValidStep] = useState<number[]>([]);
  const [isCancelModal, setIsCancelModal] = useState<boolean>(false);
  const [billAddressCheckBox, setBillAddressCheckBox] =
    useState<boolean>(false);

  const removeOrAddStep = (step: number, validStep: number[]): number[] => {
    if (validStep.includes(step)) {
      return validStep.filter((value) => value <= step - 1);
    }
    return [...validStep, step - 1];
  };

  const handleCheckStep = (step: number) => {
    const newValidStep = removeOrAddStep(step, validStep);
    setValidStep(newValidStep);
  };

  const taptCustomBasePrice = taptCustom
    ? Number(taptCustom.variants[0].priceV2.amount)
    : 84.95;

  const getDiscount = (cardsCount: number) => {
    if (hasPremiumPlan)
      return TaptCustomDiscount.getPremiumDiscountAmountPerItem();

    return TaptCustomDiscount.getDiscountAmountPerItem(
      //  eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      totalProfiles + cardsCount,
    );
  };

  const totalPrice =
    (taptCustomBasePrice - getDiscount(information.length)) *
    information.length;

  const countryTaxPrice =
    totalPrice * (shippingCostAndTaxes?.country_tax || 0.1);

  const provinceTax = totalPrice * (shippingCostAndTaxes?.province_tax || 0);

  const taxPrice = countryTaxPrice + provinceTax;

  const shippingPrice = shippingCostAndTaxes?.shipping_rates || 0;
  const finalPrice =
    Number(totalPrice) + Number(taxPrice) + Number(shippingPrice);

  const variables = useMemo(
    () => [
      {
        id: 'name',
        name: 'Name',
        selected: nameToggle,
        toggle: setNameToggle,
      },
      {
        id: 'job_title',
        name: 'Job Title',
        selected: jobTitleToggle,
        toggle: setJobTitleToggle,
      },
      {
        id: 'email',
        name: 'Email',
        selected: emailToggle,
        toggle: setEmailToggle,
      },
      {
        id: 'company_website',
        name: 'Company website',
        selected: companyWebsiteToggle,
        toggle: setCompanyWebsiteToggle,
      },
      {
        id: 'mobile_number',
        name: 'Mobile Number',
        selected: mobileNumberToggle,
        toggle: setMobileNumberToggle,
      },
      {
        id: 'company_name',
        name: 'Company name',
        selected: companyNameToggle,
        toggle: setCompanyNameToggle,
      },
    ],
    [
      nameToggle,
      setNameToggle,
      companyWebsiteToggle,
      setCompanyWebsiteToggle,
      emailToggle,
      setEmailToggle,
      jobTitleToggle,
      setJobTitleToggle,
      companyAddressToggle,
      setCompanyAddressToggle,
      mobileNumberToggle,
      setMobileNumberToggle,
      companyPhoneNumberToggle,
      setCompanyPhoneNumberToggle,
      companyNameToggle,
      setCompanyNameToggle,
    ],
  );

  const updateDesign = useCallback(() => {
    const designID = design?.id;
    if (orgID) {
      if (designID) {
        designsAPI
          .updateDesign({
            orgID,
            designID,
            body: {
              design: {
                ...design,
                design_file_id: designFileID,
                flags: {
                  ...design?.flags,
                  company_website: companyWebsiteToggle,
                  email: emailToggle,
                  name: nameToggle,
                  job_title: jobTitleToggle,
                  mobile_number: mobileNumberToggle,
                  street_address: companyAddressToggle,
                  company_phone_number: companyPhoneNumberToggle,
                  company_name: companyNameToggle,
                },
              },
            },
          })
          .then(() => {
            handleCheckStep(1);
          });
      } else {
        designsAPI
          .createDesign({
            orgID,
            body: {
              design: {
                design_file_id: designFileID,
                flags: {
                  company_name: companyNameToggle,
                  company_phone_number: companyPhoneNumberToggle,
                  company_website: companyWebsiteToggle,
                  email: emailToggle,
                  name: nameToggle,
                  job_title: jobTitleToggle,
                  mobile_number: mobileNumberToggle,
                  street_address: companyAddressToggle,
                },
              },
            },
          })
          .then((res) => {
            const customDesign = res?.data.data;
            if (customDesign) {
              setDesign(customDesign);
              if (customDesign.design_file)
                setDesignFile(customDesign.design_file);
            }
          })
          .catch(() => {});
      }
    }
  }, [
    orgID,
    design,
    designFileID,
    nameToggle,
    emailToggle,
    jobTitleToggle,
    mobileNumberToggle,
    companyAddressToggle,
    companyWebsiteToggle,
    companyNameToggle,
    companyPhoneNumberToggle,
  ]);

  const listDesigns = useCallback(() => {
    designsAPI
      .listDesigns({ orgID })
      .then((res) => {
        const customDesign = res?.data?.data[0];
        if (customDesign) {
          setDesign(customDesign);
          if (customDesign.design_file) setDesignFile(customDesign.design_file);
          type ObjectKey = keyof typeof customDesign.flags;
          variables.forEach(({ id, toggle }) => {
            toggle(customDesign?.flags[id as ObjectKey] as boolean);
          });
        }
      })
      .catch(() => {});
  }, [orgID, variables]);

  useEffect(() => {
    if (orgID) {
      organisationsAPI
        .showOrganisationSettings(orgID)
        .then((res) => {
          const term = res?.data?.data?.payment_terms || false;
          setPaymentTerm(term);
        })
        .catch((err) => console.log('Error happened'));
    }
  }, [orgID]);

  useEffect(() => {
    if (initialLoading && orgID) {
      listDesigns();
    }
    setInitialLoading(false);
  }, [orgID, listDesigns, initialLoading]);

  useEffect(() => {
    ordersV2API
      .showShippingZones(
        shippingInformation.countryRegion,
        shippingInformation.state,
      )
      .then((res) => {
        setShippingCostAndTaxes(res.data);
      });
  }, []);

  const renderReorderStep = useCallback(
    (curStep: number) => {
      let component = <></>;
      switch (curStep) {
        case 0:
          component = (
            <Step1
              orgID={orgID}
              variables={variables}
              selectedDesign={selectedDesign}
              setSelectedDesign={setSelectedDesign}
              initial={initialLoading}
              setInitial={setInitialLoading}
            />
          );
          break;
        case 1:
          component = (
            <Step2
              information={information}
              error={secondStepError}
              units={units}
              setUnits={setUnits}
              setInformation={setInformation}
              nameToggle={nameToggle}
              mobileNumberToggle={mobileNumberToggle}
              emailToggle={emailToggle}
              jobTitleToggle={jobTitleToggle}
              companyAddressToggle={companyAddressToggle}
              companyWebsiteURLToggle={companyWebsiteToggle}
              companyNameToggle={companyNameToggle}
              companyPhoneNumberToggle={companyPhoneNumberToggle}
              importCSV={importCSV}
              setImportCSV={setImportCSV}
              openUploadCsvDialog={openUploadCsvDialog}
              setOpenUploadCsvDialog={setOpenUploadCsvDialog}
            />
          );
          break;
        case 2:
          component = paymentTerm ? (
            <Step3WithPaymentTerm
              shippingInformation={shippingInformation}
              setShippingInformation={setShippingInformation}
              billingInformation={billingInformation}
              setBillingInformation={setBillingInformation}
              billAddressCheckBox={billAddressCheckBox}
              setBillAddressCheckBox={setBillAddressCheckBox}
              error={shippingFormError}
            />
          ) : (
            <Step3WithoutPaymentTerm
              information={information}
              totalPrice={totalPrice}
              selectedDesign={selectedDesign}
              setStep={setStep}
              units={Number(units)}
            />
          );
          break;
        case 3:
          component = (
            <Step4
              shippingCostAndTaxes={shippingCostAndTaxes}
              information={information}
              shippingInformation={shippingInformation}
              design={design}
              taxPrice={taxPrice}
              totalPrice={totalPrice}
              shippingPrice={shippingPrice}
              finalPrice={finalPrice}
            />
          );
          break;
        default:
          break;
      }

      return component;
    },
    [
      orgID,
      variables,
      selectedDesign,
      designFile,
      designFileID,
      information,
      secondStepError,
      units,
      setUnits,
      nameToggle,
      mobileNumberToggle,
      emailToggle,
      jobTitleToggle,
      companyAddressToggle,
      companyWebsiteToggle,
      companyNameToggle,
      companyPhoneNumberToggle,
      importCSV,
      shippingInformation,
      billingInformation,
      shippingFormError,
      openUploadCsvDialog,
      billAddressCheckBox,
      initialLoading,
    ],
  );

  function checkValidSecondStepForm(): boolean {
    let isValid = true;
    information.map((item) => {
      Object.entries(item).some((attribute) => {
        const key = attribute[0];
        const value = attribute[1];

        if (!value) {
          isValid = false;
          return true;
        }

        if (key === 'email' && !validateEmail(value)) {
          isValid = false;
          return true;
        }

        return false;
      });
      return item;
    });
    if (isValid) {
      setSecondStepError(false);
    } else {
      setSecondStepError(true);
    }
    return isValid;
  }

  const shopifyCheckout = (): void => {
    if (!taptCustom) {
      return;
    }
    const redirectUrl = new URL(window.location.origin);
    redirectUrl.searchParams.set('checkout', btoa(String(checkout?.id)));

    proceedToCheckout(taptCustom.variants[0].id, information.length, [
      {
        key: '_redirectUrl',
        value: redirectUrl.toString(),
      },
      {
        key: '_cardDesign',
        value: designFile?.original_url || '',
      },
      {
        key: '_cardInformation',
        value: JSON.stringify(information),
      },
      {
        key: '_designFileID',
        value: designFileID ? designFileID.toString() : '',
      },
      {
        key: '_subscriptionType',
        value: subscriptionType || 'basic',
      },
      {
        key: '_totalProfiles',
        value: totalProfiles.toString(),
      },
    ]);
  };

  const handleClickNext = (curStep: number) => {
    let isValid = true;
    switch (curStep) {
      case 0:
        updateDesign();
        break;
      case 1:
        isValid = checkValidSecondStepForm();
        break;
      default:
        break;
    }
    if (isValid) {
      setStep((prev) => prev + 1);
      handleCheckStep(2);
    } else {
      renderReorderStep(curStep);
    }
  };

  const validationAddress = () => {
    let isValid = true;
    Object.entries(shippingInformation).some((attribute) => {
      const key = attribute[0];
      const value = attribute[1];

      if (key === 'company' || key === 'apartment') {
        return false;
      }

      if (!value) {
        isValid = false;
        return true;
      }

      if (key === 'email' && !validateEmail(value)) {
        isValid = false;
        return true;
      }

      return false;
    });

    !billAddressCheckBox &&
      Object.entries(billingInformation).some((attribute) => {
        const key = attribute[0];
        const value = attribute[1];

        if (key === 'company' || key === 'apartment') {
          return false;
        }

        if (!value) {
          isValid = false;
          return true;
        }

        if (key === 'email' && !validateEmail(value)) {
          isValid = false;
          return true;
        }

        return false;
      });
    if (!isValid) {
      setShippingFormError(false);
      return;
    }
    setShippingFormError(true);
  };

  const extractVariantId = (): string => {
    // Sample variantGId: "gid://shopify/ProductVariant/40093303865404"
    const variantGId = taptCustom?.variants[0].id;
    if (variantGId) {
      return String(variantGId).split('/').pop() || '0';
    }

    return '0';
  }

  const handleBtnCheckout = {
    buttonClick: (curStep: number) => {
      if (paymentTerm && curStep === 2) {
        validationAddress();
        setStep(3);
        handleCheckStep(3);
      }
      if (!paymentTerm && curStep === 2) {
        shopifyCheckout();
      }
      if (curStep === 3) {
        const redirectUrl = new URL(window.location.origin);
        const organisationId = Number(orgID);
        const lineItems: ILineItem[] = [
          {
            quantity: Number(units),
            variant_id: extractVariantId(),
            properties: [
              {
                name: '_redirectUrl',
                value: redirectUrl.toString(),
              },
              {
                name: '_cardDesign',
                value: designFile?.original_url || '',
              },
              {
                name: '_cardInformation',
                value: JSON.stringify(information),
              },
              {
                name: '_designFileID',
                value: designFileID ? designFileID.toString() : '',
              },
            ],
          },
        ];
        const financialStatus = 'pending';
        const currency = 'AUD';
        const customer = { email: user?.email || '' };
        const shippingAddress: IShippingAddress = {
          first_name: user?.first_name || '',
          last_name: user?.last_name || '',
          address1: shippingInformation.address,
          city: shippingInformation.city || '',
          zip: shippingInformation.postCode,
          province_code: shippingInformation.state,
          country_code: shippingInformation.countryRegion,
          company: shippingInformation.company,
        };
        const billingAddress: IBillingAddress = {
          first_name: user?.first_name || '',
          last_name: user?.last_name || '',
          address1: billingInformation.address,
          city: billingInformation.city || '',
          zip: billingInformation.postCode,
          province_code: billingInformation.state,
          country_code: billingInformation.countryRegion,
          company: billingInformation.company,
        };

        const shipping_lines = [
          {
            title: 'Standard',
            price: String(shippingCostAndTaxes?.shipping_rates),
            code: 'AU',
          },
        ];

        const tax_lines = [
          {
            price: String(countryTaxPrice),
            rate: String(shippingCostAndTaxes?.country_tax),
            title: String(shippingCostAndTaxes?.country_tax_name),
          },
        ];

        if (provinceTax !== 0) {
          tax_lines.push({
            price: String(provinceTax),
            rate: String(shippingCostAndTaxes?.province_tax_percentage),
            title: String(shippingCostAndTaxes?.province_tax_name),
          });
        }

        ordersAPI
          .createReorder(
            organisationId,
            lineItems,
            financialStatus,
            currency,
            customer,
            shippingAddress,
            billingInformation !== initBillingInformation
              ? billingAddress
              : shippingAddress,
            shipping_lines,
            tax_lines,
          )
          .then((res) => {
            window.location.replace(res.data.data.order_status_url);
          })
          .catch((err) => console.log('err', err))
          .finally(() => {});
      }
    },
    buttonText: (curStep: number) => {
      let curText;
      switch (curStep) {
        case 0:
          curText = 'Proceed';
          break;
        case 1:
          curText = 'Proceed';
          break;

        case 2:
          curText = 'Proceed to payment';
          break;

        case 3:
          curText = 'Place order';
          break;

        default:
          curText = 'Proceed';
      }
      return curText;
    },
  };

  const handleInfoPanelFooter = {
    proceedButton: {
      disable: (currentStep: number) => {
        const designCondition = !selectedDesign;
        let condition;

        if (currentStep === 0) {
          return designCondition
        }

        if (currentStep === 1) {
          const variableCondition =
            nameToggle ||
            emailToggle ||
            jobTitleToggle ||
            companyWebsiteToggle ||
            mobileNumberToggle ||
            companyNameToggle;
          condition = designCondition || !variableCondition;
        }

        if (paymentTerm && currentStep === 2) {
          const variableShippingCondition =
            shippingInformation.address.length > 0 &&
            shippingInformation.city.length > 0 &&
            shippingInformation.company.length > 0 &&
            shippingInformation.countryRegion.length > 0 &&
            shippingInformation.postCode.length > 0 &&
            shippingInformation.state.length > 0;

          const variableBillingCondition = billAddressCheckBox
            ? true
            : billingInformation.address.length > 0 &&
              billingInformation.city.length > 0 &&
              billingInformation.company.length > 0 &&
              billingInformation.countryRegion.length > 0 &&
              billingInformation.postCode.length > 0 &&
              billingInformation.state.length > 0;

          condition =
            designCondition ||
            !variableShippingCondition ||
            !variableBillingCondition;
        }
        return condition;
      },
    },
  };

  return (
    <Transition show={isOpen}>
      <div className="md:flex-col mb-20">
        <MultiStepProgressBar
          step={step}
          setStep={setStep}
          setOpenUploadCsvDialog={setOpenUploadCsvDialog}
          paymentTerm={paymentTerm}
          validStep={validStep}
        />
        <div className="mt-8">{renderReorderStep(step)}</div>
        <InfoPanelFooter wrapClassName="fixed left-0 bottom-0 w-full bg-whiteshadow-sm transition transition-bottom overflow-x-auto md:w-footer bg-white border-t">
          {step === 0 || step === 1 || step === 2 ? (
            <div className="flex items-center justify-end flex-nowrap space-x-4">
              <Button
                kind={BUTTON_KIND.WHITE_ROUNDED_MD}
                buttonText="Cancel"
                onClick={() => setIsCancelModal(true)}
              />
              <Button
                onClick={() => {
                  step === 1 || step === 0
                    ? handleClickNext(step)
                    : handleBtnCheckout.buttonClick(step);
                }}
                kind={BUTTON_KIND.PRIMARY_ROUNDED_MD}
                buttonText={handleBtnCheckout.buttonText(step)}
                iconPos={BUTTON_ICON_POSITION.RIGHT}
                className="flex md:justify-center"
                disabled={handleInfoPanelFooter.proceedButton.disable(step)}
              />
              {shippingFormError && (
                <p className="text-sm leading-5 text-red-500 hidden sm:block">
                  {shippingFormError}
                </p>
              )}
            </div>
          ) : (
            <div className="flex items-center justify-end flex-nowrap space-x-4">
              <Button
                kind={BUTTON_KIND.WHITE_ROUNDED_MD}
                size={BUTTON_SIZE.BASE}
                buttonText="Cancel"
                onClick={() => setIsCancelModal(true)}
              />
              <Button
                onClick={() => handleBtnCheckout.buttonClick(step)}
                kind={BUTTON_KIND.PRIMARY_ROUNDED_MD}
                size={BUTTON_SIZE.BASE}
                buttonText={handleBtnCheckout.buttonText(step)}
                className=" flex md:justify-center"
              />
              {shippingFormError && (
                <p className="text-sm leading-5 text-red-500 hidden sm:block">
                  {shippingFormError}
                </p>
              )}
            </div>
          )}
        </InfoPanelFooter>
      </div>
      <Modal
        isOpen={isCancelModal}
        setIsOpen={setIsCancelModal}
        successButtonText="Yes, cancel order"
        successButtonKind={BUTTON_KIND.WHITE_BG_LIGHT_RED_TEXT}
        onSuccess={() => {
          setIsCancelModal(false);
          setIsOpen(false);
        }}
        onCancel={() => {
          setIsOpen(true);
          setIsCancelModal(false);
        }}
        cancelButtonText="Go back"
      >
        <Box className="flex justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="69"
            height="68"
            viewBox="0 0 69 68"
            fill="none"
          >
            <path
              d="M34.5 19.8333V34M34.5 48.1667H34.5354M66.375 34C66.375 38.1859 65.5505 42.3308 63.9487 46.198C62.3468 50.0653 59.9989 53.5792 57.039 56.539C54.0792 59.4989 50.5653 61.8468 46.698 63.4487C42.8308 65.0505 38.6859 65.875 34.5 65.875C30.3141 65.875 26.1692 65.0505 22.302 63.4487C18.4347 61.8468 14.9208 59.4989 11.961 56.539C9.0011 53.5792 6.65321 50.0653 5.05134 46.198C3.44947 42.3308 2.625 38.1859 2.625 34C2.625 25.5462 5.98325 17.4387 11.961 11.461C17.9387 5.48325 26.0462 2.125 34.5 2.125C42.9538 2.125 51.0613 5.48325 57.039 11.461C63.0167 17.4387 66.375 25.5462 66.375 34Z"
              stroke="#CFB1FF"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
        <Box className="flex justify-center items-center">
          <p className="text-2xl leading-8 font-medium mt-8 font-poppins ">
            Are you sure you want to cancel? Your progress will not be saved.
          </p>
        </Box>
      </Modal>
    </Transition>
  );
};

export default ReorderMultistepPage;
