import IProfile from '@/types/IProfile';
import { ISortPeriodParams } from '@/pages/DashBoardPage';
import axiosApiService from '@/services/axiosApiService';
import { BASE_URL } from '../config';

export interface IContactCount {
  link_tapped: number;
  received_contact_count: number;
  sent_contact_count: number;
  viewed_contact_count: number;
}

export interface ICountryAnalytics {
  country_analytics: IContactCount;
  country_name: string;
}

export interface ILinksTappedData {
  tapped: number;
  type: null | string;
  percent?: number | undefined;
}

export interface IMostActiveCard {
  count: number;
  profile: IProfile;
}

export interface IRecentContact {
  city_name: null | string;
  country_name: null | string;
  event_name: string;
  id: number;
  organisation_id: number;
  profile?: IProfile;
}

export interface IEventTracking {
  contact_count: IContactCount;
  countries_analytics: ICountryAnalytics[];
  links_tapped: ILinksTappedData[];
  most_active_card: IMostActiveCard[];
  recent_contacts: IRecentContact[];
}

const dashboardApi = {
  getEventTrackingForAdmin: (sortPeriod: ISortPeriodParams): Promise<{ data: IEventTracking }> => {
    const { startTime, endTime } = sortPeriod;
    return axiosApiService.get(`${BASE_URL}/admin/api/events_tracking`, {
      params: { start_at: startTime, end_at: endTime },
    });
  },
  getEventTracking: (sortPeriod: ISortPeriodParams, orgID: number, groupID?: number, most_active_card_limit?: number, recent_contacts_limit?: number): Promise<{ data: IEventTracking }> => {
    const { startTime, endTime } = sortPeriod;
    return axiosApiService.get(`${BASE_URL}/api/events_tracking`, {
      params: { start_at: startTime, end_at: endTime, organisation_id: orgID, group_id: groupID, most_active_card_limit, recent_contacts_limit}, 
    });
  },
};

export default dashboardApi;