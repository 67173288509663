import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RadioGroup } from '@headlessui/react';
import { CheckIcon, SparklesIcon } from '@heroicons/react/outline';

import Button, { BUTTON_KIND } from '@/components/Button';
import InfoPanelContainer from '../InfoPanelContainer';
import InfoPanelDivider from '../InfoPanelDivider';
import classNames from '@/helpers/classNames';
import useAuth from '@/hooks/useAuth';
import saasAPI from '@/api/saas';
import ModalNoCancel from '../ModalNoCancel';
import InfoPanelFooter from '../InfoPanelFooter';
import DiscountCalculator from './DiscountCalculator';
import profilesAPI from '@/api/profiles';

const PLANS = [
  {
    name: 'Basic',
    display_name: 'Basic',
    card_price: '$79.95/ card for reorders',
    feature: 'No premium features or exclusive discounts on future orders.',
    price: 'Free',
    price_sub_description: '(Current plan)',
    disabled: true,
    plan_id: 'basic',
    id: 'basic',
  },
  {
    name: 'Monthly',
    display_name: 'Monthly',
    card_price: '$40.00/card for reorders',
    feature: 'Access exclusive discounts and features.',
    price_sub_description: '',
    disabled: false,
    plan_id:
      process.env.REACT_APP_SAAS_PREMIUM_MONTHLY_PLAN_ID || 'v2-AUD-Monthly',
    id: 'monthly',
  },
  {
    name: 'Yearly',
    display_name: 'Yearly - Best Value',
    card_price: '$40.00/card for reorders',
    feature: 'Access exclusive discounts and features.',
    price_sub_description: '($2.50/month)',
    disabled: false,
    plan_id:
      process.env.REACT_APP_SAAS_PREMIUM_YEARLY_PLAN_ID || 'v2-AUD-Yearly',
    id: 'yearly',
  },
];

const FEATURES = [
  'CRM Integration',
  'Active Directory Integration',
  'Profile Grouping',
  'Analytics Dashboard',
  'Custom Email Signatures',
  'Access to exclusive discounts and offers',
];

export default function PlanSelection(props: {
  cbInstance: any | undefined;
  selectPlan: (plan: string) => void;
}): JSX.Element {
  const { selectPlan, cbInstance } = props;
  const history = useHistory();
  const { orgID, refreshCurrentUser } = useAuth();
  const [selected, setSelected] = useState(PLANS[1]);
  const [subscriptionCreated, setSubscriptionCreated] =
    useState<boolean>(false);
  const [showChargebeeModal, setShowChargebeeModal] = useState<boolean>();
  const [shownSuccessModal, setShownSuccessModal] = useState<boolean>(false);
  const [monthlyPrice, setMonthlyPrice] = useState<number>(5);
  const [yearlyPrice, setYearlyPrice] = useState<number>(50);

  useEffect(() => {
    if (showChargebeeModal === false && subscriptionCreated) {
      setShownSuccessModal(true);
    }
  }, [showChargebeeModal, subscriptionCreated]);

  const handleSelectPlan = () => {
    selectPlan(selected.id);

    if (orgID && cbInstance) {
      cbInstance.openCheckout({
        hostedPage: () => {
          return saasAPI
            .createCheckout(orgID, {
              plan_id: selected.plan_id,
            })
            .then((response) => response.data);
        },
        loaded: () => {
          setShowChargebeeModal(true);
        },
        close: () => {
          setShowChargebeeModal(false);
        },
        success: (hostedPageId: string) => {
          saasAPI.syncCheckout(orgID, hostedPageId).then(() => {
            setSubscriptionCreated(true);
          });
        },
      });
    }
  };

  const handleDiscoverPremium = () => {
    refreshCurrentUser().then(() => {
      history.push('/settings#plan');
    });
  };

  const getSubscriptionPrice = (totalProfiles: number) => {
    const VOLUME_PRICE = [
      { min: 1000, unit_price: 4 },
      { min: 0, unit_price: 5 },
    ];

    const tier = VOLUME_PRICE.filter((tier) => totalProfiles >= tier.min)[0];

    return tier.unit_price;
  };

  const listProfiles = useCallback(async () => {
    const res = await profilesAPI.listProfiles({
      orgID,
    });

    const totalProfiles = res.data?.paging.total_entries || 0;
    setMonthlyPrice(getSubscriptionPrice(totalProfiles));
    setYearlyPrice(getSubscriptionPrice(totalProfiles) * 10);

    return res.data;
  }, [orgID]);

  useEffect(() => {
    if (orgID) {
      listProfiles();
    }
  }, [orgID, listProfiles]);

  const renderPrice = (plan: any) => {
    switch (plan.id) {
      case 'monthly':
        return `$${monthlyPrice.toFixed(2)}/month`;
      case 'yearly':
        return `$${yearlyPrice.toFixed(2)}/year`;
      default:
        return plan.price;
    }
  };

  return (
    <>
      <div className="mt-10 hidden md:block">
        <DiscountCalculator />
        <InfoPanelDivider />
      </div>
      <InfoPanelContainer
        title="Premium Plans"
        information={
          <>
            Upgrade to a premium plan to save on future cards.
            <br />
            <br />
            <span className="font-medium text-gray-900">
              Features included:
            </span>
            <ul className="mt-1 space-y-2">
              {FEATURES.map((feature) => (
                <li key={feature} className="flex">
                  <CheckIcon
                    className="h-4 w-4 flex-shrink-0 text-brand-500"
                    aria-hidden="true"
                  />
                  <span className="ml-3">{feature}</span>
                </li>
              ))}
            </ul>
          </>
        }
        border={false}
        padding={false}
      >
        <RadioGroup value={selected} onChange={setSelected}>
          <RadioGroup.Label className="sr-only"> Plans </RadioGroup.Label>
          <div className="space-y-4">
            {PLANS.map((plan) => (
              <RadioGroup.Option
                key={plan.name}
                value={plan}
                className={({ checked, active }) =>
                  classNames(
                    !plan.disabled
                      ? 'cursor-pointer focus:outline-none'
                      : 'bg-gray-100 cursor-not-allowed',
                    checked ? 'border-transparent' : 'border-gray-300',
                    active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                    'relative block cursor-pointer rounded-md border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between',
                  )
                }
                disabled={plan.disabled}
              >
                {({ active, checked }) => (
                  <>
                    <span className="flex items-center">
                      <span className="flex flex-col text-sm">
                        <RadioGroup.Label
                          as="span"
                          className="text-lg font-medium text-brand-500"
                        >
                          {plan.display_name}
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="span"
                          className="text-gray-500"
                        >
                          <span className="block sm:inline">
                            {plan.card_price}
                          </span>
                        </RadioGroup.Description>
                        <RadioGroup.Description
                          as="span"
                          className="text-gray-900 mt-5"
                        >
                          {plan.feature}
                        </RadioGroup.Description>
                      </span>
                    </span>
                    <RadioGroup.Description
                      as="span"
                      className="mt-3 flex content-center items-center text-sm sm:mt-2 sm:ml-4 sm:flex-col sm:text-right"
                    >
                      <span className="font-medium text-lg text-gray-900">
                        {renderPrice(plan)}
                      </span>
                      <span className="ml-1 text-gray-500 sm:ml-0">
                        {plan.id === 'yearly'
                          ? `$${(yearlyPrice / 12).toFixed(2)}/month`
                          : plan.price_sub_description}
                      </span>
                    </RadioGroup.Description>
                    <span
                      className={classNames(
                        active ? 'border' : 'border-2',
                        checked ? 'border-indigo-500' : 'border-transparent',
                        'pointer-events-none absolute -inset-px rounded-lg',
                      )}
                      aria-hidden="true"
                    />
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </InfoPanelContainer>
      <InfoPanelFooter>
        <div className="grid grid-cols-2 hidden md:grid">
          <Button
            buttonText="Try Premium for free"
            onClick={handleSelectPlan}
          />
          <div className="flex space-x-4 justify-end">
            <Button
              kind={BUTTON_KIND.WHITE}
              buttonText="Cancel"
              onClick={() => history.push('/')}
            />
            <Button buttonText="Select plan" onClick={handleSelectPlan} />
          </div>
        </div>
        <div className="md:hidden">
          <p className="text-sm leading-5 text-gray-500 text-right pr-6 mb-1 sm:hidden">
            {`${selected.name} plan selected`}
          </p>
          <div className="flex items-center space-x-4 flex-nowrap">
            <Button
              kind={BUTTON_KIND.WHITE}
              icon={<SparklesIcon />}
              buttonText="Try Premium for free"
              onClick={handleSelectPlan}
            />
            <Button buttonText="Select plan" onClick={handleSelectPlan} />
          </div>
        </div>
      </InfoPanelFooter>
      <ModalNoCancel
        isOpen={shownSuccessModal}
        setIsOpen={() => {}}
        successButtonText="Discover Premium!"
        successButtonKind={BUTTON_KIND.PRIMARY}
        onSuccess={handleDiscoverPremium}
      >
        <>
          <div className="flex justify-center text-brand-300 text-center mb-2">
            <SparklesIcon
              className="mr-3 flex-shrink-0 h-16 w-16"
              aria-hidden="true"
            />
          </div>
          <p className="text-lg font-medium">Welcome to Premium!</p>
          Congratulations on joining Tapt Premium. We are very excited to have
          you here!
        </>
      </ModalNoCancel>
    </>
  );
}
