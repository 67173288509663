import { useState, useEffect, useCallback, Fragment } from 'react';
import { AxiosError } from 'axios';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, SearchIcon } from '@heroicons/react/solid';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import ErrorAlert from '@/components/ErrorAlert';
import InfoPanelContainer from '@/components/InfoPanelContainer';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import Input from '@/components/Input';
import Modal from '@/components/Modal';
import accountsAPI from '@/api/accounts';
import adminAPI from '@/api/admin';
import organisationsAPI from '@/api/organisations';
import Button, { BUTTON_KIND } from '@/components/Button';
import SuccessAlert from '@/components/SuccessAlert';
import IOrganisation, {
  IOrganisationDeleteError,
  IOrganisationGroup,
  IOrganisationUser,
} from '@/types/IOrganisation';
import validateEmail from '@/helpers/validateEmail';
import MESSAGES from '@/constants/messages-en';
import Search from '@/components/Search';
import useAuth from '@/hooks/useAuth';
import InputSelect from '@/components/InputSelect';
import Badge, { BADGE_KIND } from '@/components/Badge';

interface IOrganisationSettings {
  orgID: number;
  organisationName: string;
  setOrganisationName: React.Dispatch<React.SetStateAction<string>>;
  inviteUserEmail?: string;
  setInviteUserEmail?: React.Dispatch<React.SetStateAction<string>>;
  inviteUserRole?: string;
  setInviteUserRole?: React.Dispatch<React.SetStateAction<string>>;
  orgUsers: IOrganisationUser[];
  setOrgUsers: React.Dispatch<React.SetStateAction<IOrganisationUser[]>>;
  disabled?: boolean;
}

export default function OrganisationSettings({
  orgID,
  organisationName,
  setOrganisationName,
  inviteUserEmail = '',
  setInviteUserEmail,
  inviteUserRole = 'org_admin',
  setInviteUserRole,
  orgUsers,
  setOrgUsers,
  disabled,
}: IOrganisationSettings): JSX.Element {
  const [inviteUserIsOpen, setInviteUserIsOpen] = useState<boolean>(false);
  const [deleteAccountIsOpen, setDeleteAccountIsOpen] =
    useState<boolean>(false);
  const [deleteEditorIsOpen, setDeleteEditorIsOpen] = useState<boolean>(false);
  const [resetPasswordIsOpen, setResetPasswordIsOpen] =
    useState<boolean>(false);
  const [deleteOrganisationIsOpen, setDeleteOrganisationIsOpen] =
    useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<IOrganisationUser>();
  const [nameLoading, setNameLoading] = useState<boolean>(false);
  const [nameSuccess, setNameSuccess] = useState<string>('');
  const [addToGroupSuccess, setAddToGroupSuccess] = useState<string>('');
  const [removeFromGroupSuccess, setRemoveFromGroupSuccess] =
    useState<string>('');
  const [nameError, setNameError] = useState<boolean>(false);
  const [accountLoading, setAccountLoading] = useState<boolean>(false);
  const [adminAccountSuccess, setAdminAccountSuccess] = useState<string>('');
  const [editorAccountSuccess, setEditorAccountSuccess] = useState<string>('');
  const [accountError, setAccountError] = useState<boolean>(false);
  const [groupEditorError, setGroupEditorError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emptyOrgError, setEmptyOrgError] = useState<string>('');
  const [deleteOrgError, setDeleteOrgError] = useState<boolean>(false);
  const [orgInviteError, setOrgInviteError] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(25);
  const [orgMoveIsOpen, setOrgMoveIsOpen] = useState<boolean>(false);
  const [orgSearch, setOrgSearch] = useState<string>('');
  const [moveOrgID, setMoveOrgID] = useState<number>(-1);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [orgData, setOrgData] = useState<IOrganisation[]>();
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<string>('date');
  const [order, setOrder] = useState<string>('desc');
  const [dataGroup, setDataGroup] = useState<IOrganisationGroup[]>();
  const [dataGroupFiltered, setDataGroupFiltered] =
    useState<IOrganisationGroup[]>();
  const [userID, setUserID] = useState<number>(-1);
  const [roleName, setRoleName] = useState<string>('');
  const [isAssignToAdminOpen, setIsAssignToAdminOpen] =
    useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');

  const { subscriptionType, userScope } = useAuth();

  const isSuperUser = userScope === 'admin';
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  const isSaaSEnabled = true;
  const hasPremiumPlan = isSaaSEnabled
    ? !isSuperUser && subscriptionType === 'premium'
    : false;

  const [selectedValues, setSelectedValues] = useState([0]);

  const shouldApplyScrollStyle = orgUsers.length > 5;
  const shouldApplyScrollStyleDropdown =
    dataGroupFiltered !== undefined && dataGroupFiltered.length > 5;

  const listGroups = useCallback(
    async (
      newPage: number = page,
      newPageSize: number = pageSize,
      newSort: string = sort,
      newOrder: string = order,
      newSearch: string = search,
      initial = false,
    ) => {
      const res = await organisationsAPI.listOrganisationGroups({
        orgID,
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        order: newOrder,
        search: newSearch,
      });
      if (!initial) {
        setDataGroup(res.data?.data);
        setDataGroupFiltered(res.data?.data);
        setPage(newPage);
        setPageSize(newPageSize);
        setSort(newSort);
        setOrder(newOrder);
      }
      return res.data;
    },
    [order, orgID, page, pageSize, search, sort],
  );

  const addEditorToOrg = (roleID: number, groupID: number) => {
    organisationsAPI
      .createGroupEditor(groupID, {
        editor: {
          role_id: roleID,
        },
      })
      .then(() => listGroups())
      .then(() => {
        setAddToGroupSuccess('Group manager assigned to a new group');
      });
  };

  const deleteEditorFromGroup = (editorID: number, groupID: number) => {
    if (orgID !== undefined) {
      organisationsAPI
        .deleteGroupEditor(editorID, groupID)
        .then(() => listGroups())
        .then(() => {
          setRemoveFromGroupSuccess('Group manager removed from group');
        });
    }
  };

  const handleOptionClick = (
    group_editor: IOrganisationUser,
    group: IOrganisationGroup,
  ) => {
    if (selectedValues.includes(group.id)) {
      setSelectedValues(selectedValues.filter((v) => v !== group.id));
      group.editors.forEach((option) => {
        if (option.user.id === group_editor.user.id) {
          deleteEditorFromGroup(option.id, group.id);
        }
      });
    } else {
      setSelectedValues([...selectedValues, group.id]);
      addEditorToOrg(group_editor.id, group.id);
    }
  };

  const [selectedModalValues, setSelectedModalValues] = useState([0]);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleOptionModalClick = (value: number) => {
    if (selectedModalValues.includes(value)) {
      setSelectedModalValues(selectedModalValues.filter((v) => v !== value));
    } else {
      setSelectedModalValues([...selectedModalValues, value]);
    }
  };

  const handleDropdownModalClick = () => {
    setIsOpenModal(!isOpenModal);
  };

  const listOrganisations = useCallback(async (newOrgSearch: string) => {
    const res = await adminAPI.listOrganisations({
      pageSize: 5,
      search: newOrgSearch,
    });

    setOrgData(res.data?.data?.filter((item: IOrganisation) => item.name));

    return res.data;
  }, []);

  const handleOrganisationNameSave = () => {
    setNameSuccess('');
    setNameError(false);
    setNameLoading(true);
    organisationsAPI
      .updateOrganisation(orgID, {
        organisation: {
          name: organisationName,
        },
      })
      .then(() => setNameSuccess(MESSAGES.organisation.settings))
      .catch(() => setNameError(true))
      .finally(() => setNameLoading(false));
  };

  const handleInviteUser = () => {
    setAdminAccountSuccess('');
    setEditorAccountSuccess('');
    setEmailError(false);
    setAccountLoading(true);
    if (inviteUserEmail && validateEmail(inviteUserEmail)) {
      setEmailError(false);
      organisationsAPI
        .inviteUser(orgID, {
          invite: {
            organisation_id: orgID,
            email: inviteUserEmail,
            role: inviteUserRole,
          },
        })
        .then(() => {
          if (inviteUserRole === 'org_admin') {
            setAdminAccountSuccess(MESSAGES.organisation.user);
          } else {
            setEditorAccountSuccess(MESSAGES.organisation.user);
          }
        })
        .catch((e: AxiosError) => {
          if (e.response?.status === 422) {
            setOrgInviteError(MESSAGES.organisation.invite.error);
          } else {
            setAccountError(true);
          }
        })
        .then(() => setAccountLoading(false))
        .finally(() => setInviteUserIsOpen(false));
    } else {
      setEmailError(true);
      setAccountLoading(false);
    }
  };

  const showOrganisation = useCallback(() => {
    organisationsAPI
      .showOrganisation(orgID)
      .then((res) => {
        setOrganisationName(res?.data?.data?.name);
      })
      .finally(() => setNameLoading(false));
  }, [orgID, setOrganisationName]);

  async function listOrganisationRoles(
    newPage: number = page,
    newPageSize: number = pageSize,
  ) {
    const res = await organisationsAPI.listOrganisationRoles({
      orgID,
      page: newPage,
      pageSize: newPageSize,
    });
    setOrgUsers(res.data?.data);
    setPage(newPage);
    setPageSize(newPageSize);

    return res.data;
  }

  useEffect(() => {
    setDataGroupFiltered(
      dataGroup?.filter((option) =>
        option.name.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    );
  }, [searchTerm]);

  useEffect(() => {
    if (initialLoad) {
      showOrganisation();
      listOrganisationRoles();
      setInitialLoad(false);
    }
  }, [initialLoad]);

  useEffect(() => {
    listGroups();
  }, [setUserID]);

  const handleDeleteRole = (roleID: number) => {
    setEditorAccountSuccess('');
    setAccountError(false);
    setOrgInviteError('');
    setEmptyOrgError('');
    setAccountLoading(true);
    organisationsAPI
      .deleteOrganisationRole(orgID, roleID)
      .then(() => setEditorAccountSuccess(MESSAGES.account.delete.success))
      .catch(() => setGroupEditorError(true))
      .then(() => setDeleteAccountIsOpen(false))
      .then(() => listOrganisationRoles())
      .finally(() => setAccountLoading(false));
  };

  const handleGetRoleIDAndDelete = useCallback(
    async (
      newPage: number = page,
      newPageSize: number = pageSize,
      newSort: string = sort,
      newOrder: string = order,
      newSearch: string = search,
      userID: number,
    ) => {
      organisationsAPI
        .showOrganisationRoleFromUserID(
          orgID,
          newPage,
          newPageSize,
          newSort,
          newOrder,
          newSearch,
          userID,
        )
        .then((res) => {
          handleDeleteRole(res.data?.data[0].id);
        });
    },
    [handleDeleteRole, order, orgID, page, pageSize, search, sort],
  );

  const isInGroup = (userID: number, groupID: number) => {
    return organisationsAPI
      .showOrganisationGroup(orgID, groupID)
      .then((res) => {
        const data = res?.data?.data;
        if (data.editors.some((orgUser) => orgUser.user.id === userID)) {
          return groupID;
        }
        return null;
      });
  };

  const handleDeleteAccount = (userID: number) => {
    setAdminAccountSuccess('');
    setAccountError(false);
    setOrgInviteError('');
    setEmptyOrgError('');
    setAccountLoading(true);
    adminAPI
      .deleteUser(userID)
      .then(() => setAdminAccountSuccess(MESSAGES.account.delete.success))
      .catch(() => setAccountError(true))
      .then(() => setDeleteAccountIsOpen(false))
      .then(() => listOrganisationRoles())
      .finally(() => setAccountLoading(false));
  };

  const handleResetPassword = (email: string) => {
    setAdminAccountSuccess('');
    setAccountError(false);
    setOrgInviteError('');
    setEmptyOrgError('');
    setAccountLoading(true);
    accountsAPI
      .forgotPassword({ forgot: { email } })
      .then(() => setAdminAccountSuccess(MESSAGES.account.reset.success))
      .catch(() => setAccountError(true))
      .then(() => setAccountLoading(false))
      .finally(() => setResetPasswordIsOpen(false));
  };

  const handleMoveAccount = (roleID: number, organisation_id: number) => {
    setAdminAccountSuccess('');
    setAccountError(false);
    setOrgInviteError('');
    setEmptyOrgError('');
    setAccountLoading(true);
    adminAPI
      .updateOrganisationRole(roleID, {
        organisation_role: {
          organisation_id,
        },
      })
      .then(() => setAdminAccountSuccess(MESSAGES.account.organisation.success))
      .catch(() => setAccountError(true))
      .then(() => setAccountLoading(false))
      .finally(() => setOrgMoveIsOpen(false));
  };

  const handleAssignToAdmin = (roleID: number, organisation_id: number) => {
    setAdminAccountSuccess('');
    setAccountError(false);
    setOrgInviteError('');
    setEmptyOrgError('');
    setAccountLoading(true);
    organisationsAPI
      .updateOrganisationRole(organisation_id, roleID, {
        organisation_role: {
          role: 'org_admin',
        },
      })
      .then(() => setAdminAccountSuccess(MESSAGES.account.organisation.success))
      .catch(() => setAccountError(true))
      .then(() =>
        selectedValues.map((item) => deleteEditorFromGroup(roleID, item)),
      )
      .then(() => listOrganisationRoles())
      .then(() => setIsAssignToAdminOpen(false));
  };

  useEffect(() => {
    const groupsListPromises: Promise<number | null>[] = [];
    dataGroupFiltered?.forEach((option) => {
      groupsListPromises.push(isInGroup(userID, option.id));
    });
    Promise.all(groupsListPromises).then((groupsList) => {
      setSelectedValues(
        groupsList.filter((groupId) => groupId !== null) as number[],
      );
    });
  }, [userID, setSelectedAccount]);

  const handleDeleteOrganisation = () => {
    setAdminAccountSuccess('');
    setAccountError(false);
    setOrgInviteError('');
    setEmptyOrgError('');
    setAccountLoading(true);
    adminAPI
      .deleteOrganisation(orgID)
      .then(() => setAdminAccountSuccess(MESSAGES.organisation.delete.success))
      .catch((e: IOrganisationDeleteError) => {
        if (e.response?.status === 422) {
          setEmptyOrgError(e.response.data.errors[0]);
        } else {
          setDeleteOrgError(true);
        }
      })
      .then(() => setDeleteOrganisationIsOpen(false))
      .then(() => listOrganisationRoles())
      .finally(() => setAccountLoading(false));
  };

  const getGroupCount = (orgUser: IOrganisationUser) => {
    if (!dataGroup) return 0;

    return dataGroup?.reduce((acc, group) => {
      if (group.editors.some((editor) => editor.user.id === orgUser.user.id)) {
        return acc + 1;
      }
      return acc;
    }, 0);
  };

  return (
    <>
      {setInviteUserEmail && setInviteUserRole && (
        <Modal
          isOpen={inviteUserIsOpen}
          setIsOpen={setInviteUserIsOpen}
          dialogTitle="Invite user"
          successButtonText="Send email invitation"
          onSuccess={() => handleInviteUser()}
          isLoading={accountLoading}
        >
          <div className="mt-6">
            {emailError && <ErrorAlert message={MESSAGES.error.email} />}
            <Input
              label="Email"
              value={inviteUserEmail}
              onChange={setInviteUserEmail}
              type="text"
            />
            {hasPremiumPlan && (
              <div className="mt-4">
                <InputSelect
                  id="role"
                  label="Role"
                  value={inviteUserRole}
                  onChange={setInviteUserRole}
                  options={[
                    {
                      label: 'Account administrator',
                      value: 'org_admin',
                    },
                    {
                      label: 'Group Manager',
                      value: 'org_editor',
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </Modal>
      )}
      <Modal
        isOpen={deleteAccountIsOpen}
        setIsOpen={setDeleteAccountIsOpen}
        dialogTitle={MESSAGES.account.delete.heading}
        dialogDescription={MESSAGES.account.delete.description(
          selectedAccount?.user.email || '',
        )}
        successButtonText="Delete account"
        successButtonKind={BUTTON_KIND.RED}
        onSuccess={() => {
          if (selectedAccount) handleDeleteAccount(selectedAccount?.user.id);
        }}
        onCancel={() => setSelectedAccount(undefined)}
        isLoading={accountLoading}
      />
      <Modal
        isOpen={deleteEditorIsOpen}
        setIsOpen={setDeleteEditorIsOpen}
        dialogTitle="Are you sure you want to delete this group manager"
        dialogDescription={MESSAGES.account.delete.descriptionEditor(
          selectedAccount?.user.email || '',
        )}
        successButtonText="Delete account"
        successButtonKind={BUTTON_KIND.RED}
        onSuccess={() => {
          if (selectedAccount)
            handleGetRoleIDAndDelete(
              1,
              10,
              '',
              '',
              '',
              selectedAccount?.user.id,
            );
        }}
        onCancel={() => setSelectedAccount(undefined)}
        isLoading={accountLoading}
      />
      <Modal
        isOpen={resetPasswordIsOpen}
        setIsOpen={setResetPasswordIsOpen}
        dialogTitle={MESSAGES.account.reset.heading}
        dialogDescription={MESSAGES.account.reset.description}
        successButtonText="Reset password"
        onSuccess={() => {
          if (selectedAccount) handleResetPassword(selectedAccount?.user.email);
        }}
        onCancel={() => setSelectedAccount(undefined)}
        isLoading={accountLoading}
      />
      <Modal
        isOpen={orgMoveIsOpen}
        setIsOpen={setOrgMoveIsOpen}
        dialogTitle={MESSAGES.account.organisation.heading}
        dialogDescription={MESSAGES.account.organisation.description}
        successButtonText="Move account"
        onSuccess={() => {
          if (selectedAccount) {
            handleMoveAccount(selectedAccount?.id, moveOrgID);
          }
        }}
        onCancel={() => setSelectedAccount(undefined)}
        isDisabled={moveOrgID === -1}
        isLoading={accountLoading}
      >
        <div className="pt-4 relative">
          <Search
            id={`OrganisationList-1-20-date-desc--${orgSearch}`}
            placeholder="Search for organisations"
            search={orgSearch}
            setSearch={(value) => {
              if (value !== orgSearch) {
                setMoveOrgID(-1);
                setShowOptions(true);
              }
              setOrgSearch(value);
            }}
            fetchQuery={(newOrgSearch) => listOrganisations(newOrgSearch)}
          />
          {orgSearch && showOptions && (
            <div className="origin-top-left absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
              <div className="py-1">
                {orgData && orgData?.length ? (
                  orgData?.map((item: IOrganisation) => (
                    <button
                      type="button"
                      key={item.id}
                      className="appearance-none px-3 py-2 cursor-pointer hover:bg-gray-200 w-full text-left"
                      onClick={() => {
                        setMoveOrgID(item.id);
                        setShowOptions(false);
                        setOrgSearch(`#${item.id} ${item.name}`);
                      }}
                    >
                      #{item.id}{' '}
                      <span className="text-gray-500">{item.name}</span>
                    </button>
                  ))
                ) : (
                  <li className="px-3 py-2 text-center">
                    No matching items found
                  </li>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
      <Modal
        isOpen={deleteOrganisationIsOpen}
        setIsOpen={setDeleteOrganisationIsOpen}
        dialogTitle={MESSAGES.organisation.delete.heading}
        dialogDescription={MESSAGES.organisation.delete.description}
        successButtonText="Delete organisation"
        successButtonKind={BUTTON_KIND.RED}
        onSuccess={() => {
          if (orgID) handleDeleteOrganisation();
        }}
        isLoading={accountLoading}
      />
      <div className="mt-10">
        <InfoPanelContainer
          title="Organisation settings"
          footerContent={() =>
            !disabled && (
              <Button
                buttonText="Save"
                loading={nameLoading}
                onClick={handleOrganisationNameSave}
              />
            )
          }
        >
          {nameSuccess !== '' && <SuccessAlert message={nameSuccess} />}
          {nameError && (
            <ErrorAlert message="Something went wrong. Please try again later" />
          )}
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <Input
                label="Organisation name"
                type="text"
                value={organisationName}
                required
                onChange={setOrganisationName}
                disabled={disabled}
              />
            </div>
          </div>
        </InfoPanelContainer>
        <InfoPanelDivider />
        <InfoPanelContainer
          title="Admin accounts"
          information={
            <div className="space-y-4">
              {setInviteUserEmail && setInviteUserRole && (
                <Button
                  kind={BUTTON_KIND.WHITE}
                  buttonText="Invite user"
                  onClick={() => {
                    setInviteUserIsOpen(true);
                  }}
                />
              )}
            </div>
          }
        >
          {adminAccountSuccess !== '' && (
            <SuccessAlert message={adminAccountSuccess} />
          )}
          {accountError && <ErrorAlert message={MESSAGES.error.generic} />}
          {orgInviteError !== '' && <ErrorAlert message={orgInviteError} />}
          {emptyOrgError !== '' && <ErrorAlert message={emptyOrgError} />}
          {editorAccountSuccess !== '' && (
            <SuccessAlert message={editorAccountSuccess} />
          )}
          {groupEditorError && (
            <ErrorAlert message="Please ensure you have removed this user as a group manager from all groups before deletion" />
          )}
          {addToGroupSuccess !== '' && (
            <SuccessAlert message={addToGroupSuccess} />
          )}
          {removeFromGroupSuccess !== '' && (
            <SuccessAlert message={removeFromGroupSuccess} />
          )}
          <div
            style={
              shouldApplyScrollStyle
                ? { height: '300px', overflowY: 'scroll' }
                : {}
            }
          >
            <ul className="divide-y divide-gray-200">
              {orgUsers &&
                orgUsers.map(
                  (item) =>
                    item.role === 'org_admin' && (
                      <li key={item.id}>
                        <div className="block bg-white">
                          <div className="flex flex-row items-center justify-between py-4">
                            <div className="min-w-0">
                              <p className="text-sm font-medium text-gray-900 truncate">
                                {item.user?.first_name} {item.user?.last_name}
                              </p>
                              <p className="flex items-center text-sm text-gray-500 truncate">
                                {item.user?.email}
                              </p>
                            </div>
                            <div className="inline-flex flex-row space-x-6 items-center">
                              <Menu
                                as="div"
                                className="relative inline-block text-left mt-2 lg:mt-0"
                              >
                                <div>
                                  <Menu.Button className="inline-flex justify-center w-max rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:ring-brand-500 focus:border-brand-500">
                                    Admin
                                    <ChevronDownIcon
                                      className="-mr-1 ml-2 h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </Menu.Button>
                                </div>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                                    <div className="py-1">
                                      <Menu.Item>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setDeleteAccountIsOpen(true);
                                            setSelectedAccount(item);
                                          }}
                                          className="appearance-none text-red-500 w-full text-left block px-4 py-2 text-sm"
                                        >
                                          Delete account
                                        </button>
                                      </Menu.Item>
                                      <Menu.Item>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setResetPasswordIsOpen(true);
                                            setSelectedAccount(item);
                                          }}
                                          className="appearance-none text-gray-700 w-full text-left block px-4 py-2 text-sm"
                                        >
                                          Reset password
                                        </button>
                                      </Menu.Item>
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </div>
                          </div>
                        </div>
                      </li>
                    ),
                )}

              {hasPremiumPlan &&
                orgUsers &&
                orgUsers.map(
                  (item) =>
                    item.role === 'org_editor' && (
                      <li key={item.id}>
                        <Modal
                          isOpen={isAssignToAdminOpen}
                          setIsOpen={setIsAssignToAdminOpen}
                          dialogTitleIcon={<ExclamationCircleIcon className='h-16 w-16 text-red-450 stroke-1'/>}
                          dialogTitle="This user will gain access to all groups and ability to edit settings across the whole organisation."
                          successButtonText="Make admin"
                          onSuccess={() => {
                            handleAssignToAdmin(item.id, item.organisation_id);
                          }}
                        />
                        <div className="block bg-white">
                          <div className="flex flex-row items-center justify-between py-4">
                            <div className="min-w-0">
                              <p className="text-sm font-medium text-gray-900 truncate">
                                {item.user?.first_name} {item.user?.last_name}
                              </p>
                              <p className="flex items-center text-sm text-gray-500 truncate">
                                {item.user?.email}
                              </p>
                            </div>
                            <div className="inline-flex flex-col lg:flex-row lg:space-x-6 items-end lg:items-center">
                              <Menu
                                as="div"
                                className="relative inline-block text-left mt-2 lg:mt-0"
                              >
                                <div>
                                  <Menu.Button
                                    className="inline-flex justify-center w-max rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:ring-brand-500 focus:border-brand-500"
                                    onClick={() => {
                                      if (userID !== item.user.id) {
                                        setSelectedValues([]);
                                        setUserID(item.user.id);
                                        setSearchTerm('');
                                        listGroups();
                                      }
                                    }}
                                  >
                                    <Badge
                                      kind={BADGE_KIND.GRAY}
                                      className="mr-2"
                                      text={getGroupCount(item).toString()}
                                    />
                                    Groups
                                    <ChevronDownIcon
                                      className="-mr-1 ml-2 h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </Menu.Button>
                                </div>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items
                                    className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 overflow-hidden"
                                    style={
                                      shouldApplyScrollStyleDropdown
                                        ? {
                                            height: '250px',
                                            overflowY: 'scroll',
                                          }
                                        : {}
                                    }
                                  >
                                    <div className="py-1">
                                      <div className="sticky top-0 bg-white z-20 px-3 py-2">
                                        <Input
                                          id="search"
                                          label=""
                                          description=""
                                          placeholder="Search"
                                          type="text"
                                          autocomplete="off"
                                          value={searchTerm}
                                          onChange={setSearchTerm}
                                          icon={SearchIcon}
                                        />
                                      </div>
                                      {dataGroupFiltered?.map((option) => (
                                        <Menu.Item>
                                          <div
                                            key={option.id}
                                            className="flex items-center py-2 pl-3 pr-4 hover:bg-gray-100 cursor-pointer"
                                            onClick={() =>
                                              handleOptionClick(item, option)
                                            }
                                          >
                                            <input
                                              type="checkbox"
                                              className="h-4 w-4 text-brand-500 focus:ring-brand-500 border-gray-300 rounded"
                                              checked={selectedValues.includes(
                                                option.id,
                                              )}
                                              readOnly
                                            />
                                            <span className="ml-3 block text-sm text-gray-700">
                                              {option.name}
                                            </span>
                                          </div>
                                        </Menu.Item>
                                      ))}
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                              <Menu
                                as="div"
                                className="relative inline-block text-left mt-2 lg:mt-0"
                              >
                                <div>
                                  <Menu.Button className="inline-flex justify-center w-max rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:ring-brand-500 focus:border-brand-500">
                                    Group Manager
                                    <ChevronDownIcon
                                      className="-mr-1 ml-2 h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </Menu.Button>
                                </div>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                                    <div className="py-1">
                                      <Menu.Item>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setIsAssignToAdminOpen(true);
                                          }}
                                          className="appearance-none text-gray-700 w-full text-left block px-4 py-2 text-sm"
                                        >
                                          Assign to admin
                                        </button>
                                      </Menu.Item>
                                      <Menu.Item>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setResetPasswordIsOpen(true);
                                            setSelectedAccount(item);
                                          }}
                                          className="appearance-none text-gray-700 w-full text-left block px-4 py-2 text-sm"
                                        >
                                          Reset password
                                        </button>
                                      </Menu.Item>
                                      <Menu.Item>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setDeleteEditorIsOpen(true);
                                            setSelectedAccount(item);
                                          }}
                                          className="appearance-none text-red-500 w-full text-left block px-4 py-2 text-sm"
                                        >
                                          Delete account
                                        </button>
                                      </Menu.Item>
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </div>
                          </div>
                        </div>
                      </li>
                    ),
                )}
            </ul>
          </div>
        </InfoPanelContainer>
        {disabled && (
          <>
            <InfoPanelDivider />
            <InfoPanelContainer
              title="Login"
              information="Click this button to log into this organisation account."
            >
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <Button
                    kind={BUTTON_KIND.WHITE}
                    buttonText="Switch to Organisation view"
                    href={`/?scope=user&uOrgID=${orgID}`}
                    external
                  />
                </div>
              </div>
            </InfoPanelContainer>
          </>
        )}
        {disabled && (
          <>
            <InfoPanelDivider />
            <InfoPanelContainer
              title="Delete organisation"
              information="Click this button to delete this organisation permanently."
            >
              {deleteOrgError && (
                <ErrorAlert message={MESSAGES.error.generic} />
              )}
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <Button
                    kind={BUTTON_KIND.RED}
                    buttonText="Delete Organisation"
                    onClick={() => setDeleteOrganisationIsOpen(true)}
                  />
                </div>
              </div>
            </InfoPanelContainer>
          </>
        )}
      </div>
    </>
  );
}
