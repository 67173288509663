import { FC, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import DomainAnalytics from '@/components/Analytics/Blocks/DomainAnalytics';
import Layout from '@/components/Layout';
import analyticsAPI from '@/api/analytics';
import useAuth from '@/hooks/useAuth';
import { AnalyticsResponse } from '@/types/IAnalytics';
import 'react-loading-skeleton/dist/skeleton.css';

const LoadingSkeleton = () => (
  <>
    <div className="grid gap-2 grid-flow-col mb-4">
      <Skeleton width="280px" height="120px" />
      <Skeleton width="280px" height="120px" />
      <Skeleton width="280px" height="120px" />
      <Skeleton width="280px" height="120px" />
    </div>
    <div className="grid gap-2 grid-flow-col">
      <div className="grid gap-2">
        <Skeleton width="620px" height="350px" />
        <Skeleton width="620px" height="500px" />
      </div>
      <div className="grid gap-2">
        <Skeleton width="500px" height="250px" />
        <Skeleton width="500px" height="600px" />
      </div>
    </div>
  </>
);

const AnalyticsPage: FC = () => {
  const { orgID } = useAuth();

  const [data, setData] = useState<AnalyticsResponse>();

  useEffect(() => {
    const fetchData = async () => {
      if (!orgID) return;

      const response = await analyticsAPI.listAnalytics(orgID);
      setData(response.data);
    };

    fetchData();
  }, [orgID]);

  return (
    <Layout pageName="Enhanced Analytics">
      {data === undefined ? (
        <LoadingSkeleton />
      ) : (
        <DomainAnalytics data={data} />
      )}
    </Layout>
  );
};

export default AnalyticsPage;
