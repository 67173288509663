// I am disabling eslint for this file because it is a mess and I don't want to clean it up.
/* eslint-disable */

import { useCallback, useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Listbox, Tab, Transition } from '@headlessui/react';
import { AxiosError } from 'axios';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import Layout from '@/components/Layout';
import cardsAPI from '@/api/cards';
import Pagination from '@/components/Pagination';
import Sort from '@/components/Sort';
import Search from '@/components/Search';
import Modal from '@/components/Modal';
import ProfileListItem from '@/components/ProfileListItem';
import useAuth from '@/hooks/useAuth';
import accountsAPI from '@/api/accounts';
import Button, { BUTTON_KIND, BUTTON_SIZE } from '@/components/Button';
import InputCheckbox from '@/components/InputCheckbox';
import useAppState from '@/hooks/useAppState';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import FileUploadButtonComponent from '@/components/FileUploadButton';
import profilesAPI from '@/api/profiles';
import IProfile from '@/types/IProfile';
import Spinner from '@/components/Icons/Spinner';
import SuccessAlert from '@/components/SuccessAlert';
import ErrorAlert from '@/components/ErrorAlert';
import classNames from '@/helpers/classNames';
import MESSAGES from '@/constants/messages-en';
import ActivationKeySearch from '@/components/ActivationKeySearch';
import useShopify from '@/hooks/useShopify';
import { InformationProps } from '@/components/DetailsForm';
import { IOrganisationGroup } from '@/types/IOrganisation';
import organisationsAPI from '@/api/organisations';
import TrialBadge, {
  TRIAL_BADGE_KIND,
} from '@/components/ProfilePage/TrialBadge';
import Features from '@/types/Features';
import ButtonField from '@/components/ButtonField';

const SM_BREAKPOINT = 640;

interface IProfileListPage {
  location: {
    state: {
      success: string;
      setupInfo: boolean;
      activationKey?: string;
      activationKeyFromLogin?: string;
      checkout?: string;
    };
  };
}

const ProfileListPage: React.FC<IProfileListPage> = (props) => {
  const { location } = props;
  const {
    orgID,
    user,
    getCurrentUser,
    subscriptionType,
    userScope,
    userRole,
    hasFeature,
  } = useAuth();
  const { fetchCheckout } = useShopify();
  const {
    selectProfiles,
    profileStatus,
    selectProfileStatus,
    selectSettingsTab,
  } = useAppState();
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [isEditingModalOpen, setIsEditingModalOpen] = useState<boolean>(false);
  const [data, setData] = useState<IProfile[]>();
  const [activeCount, setActiveCount] = useState<number>();
  const [editingCount, setEditingCount] = useState<number>();
  const [allCount, setAllCount] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loginTime, setLoginTime] = useState(user?.first_log_in_at);
  const [success, setSuccess] = useState<string>(
    location?.state?.success || '',
  );
  const activationKeyFromSignUp = location?.state?.activationKey;
  const activationKeyFromLogin = location?.state?.activationKeyFromLogin;
  const setupInfo = location?.state?.setupInfo;
  const shopifyCheckoutId = new URLSearchParams(window.location.search).get(
    'checkout',
  );
  const [isSetupInfoOpen, setIsSetupInfoOpen] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [printError, setPrintError] = useState<boolean>(false);
  const [notFoundError, setNotFoundError] = useState<boolean>(false);
  const [usedKeyError, setUsedKeyError] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [sort, setSort] = useState('date');
  const [order, setOrder] = useState('desc');
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isEditViaCSVOpen, setIsEditViaCSVOpen] = useState(false);
  const [importCSV, setImportCSV] = useState<File>();
  const [isEditSharedOpen, setIsEditSharedOpen] = useState(false);
  const [isSelectGroupOpen, setIsSelectGroupOpen] = useState(false);
  const [isEnableEditingOpen, setIsEnableEditingOpen] =
    useState<boolean>(false);
  const [isEditAfterActivateOpen, setIsEditAfterActivateOpen] =
    useState<boolean>(false);
  const [activationKey, setActivationKey] = useState(
    activationKeyFromSignUp || '',
  );
  const [activationKeyType, setActivationKeyType] = useState('');
  const [profileSearch, setProfileSearch] = useState<string>('');
  const [profileIDForActivation, setProfileIDForActivation] = useState<
    number | undefined
  >();
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [profileDataForSearch, setProfileDataForSearch] =
    useState<IProfile[]>();
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const history = useHistory();
  const [dataGroup, setDataGroup] = useState<IOrganisationGroup[]>();

  const [status, setStatus] = useState<string>('');
  const [editorsGroups, setEditorsGroups] = useState<number[]>([]);
  const [isNoGroupsOpen, setIsNoGroupsOpen] = useState<boolean>(false);

  const [profileResetLoading, setProfileResetLoading] = useState(false);
  const [profileDeleteLoading, setProfileDeleteLoading] = useState(false);

  const isSuperUser = userScope === 'admin';
  const isSaaSEnabled = hasFeature(Features.SaaS);
  const isPremium = isSaaSEnabled
    ? !isSuperUser && subscriptionType === 'premium'
    : false;
  const [allGroups, setAllGroups] = useState<IOrganisationGroup>({
    bg_color: '#000000',
    button_bg_color: '#523cff',
    button_text_color: '#000000',
    description: 'Bluey',
    editors: [],
    group_logo: {
      blur_url: '/uploads/files/63/original.png',
      file: {
        file_name: 'image.png',
        updated_at: '2023-02-10T04:05:25',
      },
      id: 63,
      large_url: '/uploads/files/63/original.png',
      medium_url: '/uploads/files/63/medium.jpg',
      name: 'image.png',
      original_url: '/uploads/files/63/original.png',
      small_url: '/uploads/files/63/original.png',
      thumb_url: '/uploads/files/63/thumb.jpg',
      upload_url: '/api/files/63/upload',
    },
    id: 0,
    name: 'All groups',
    text_color: '#FFFFFF',
    organisation_id: 0,
    organisation_name: 'This org',
  });

  const [selected, setSelected] = useState({
    name: 'All groups',
    id: 0,
  });
  const [isTicked, setIsTicked] = useState<boolean>(false);
  const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth);

  window.addEventListener('resize', () => {
    setInnerWidth(window.innerWidth);
  });

  const listProfiles = useCallback(
    async (
      newPage: number = page,
      newPageSize: number = pageSize,
      newStatus: string = status,
      newSort: string = sort,
      newOrder: string = order,
      newSearch: string = search,
      initial = false,
    ) => {
      const res = await profilesAPI.listProfiles({
        orgID,
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        status: newStatus,
        order: newOrder,
        search: newSearch,
      });

      if (!initial) {
        setData(res.data?.data);
        setPage(newPage);
        setPageSize(newPageSize);
        setSort(newSort);
        setOrder(newOrder);
      }

      if (newStatus === 'active') {
        setActiveCount(res.data?.paging.total_entries);
      } else if (newStatus === 'editing') {
        setEditingCount(res.data?.paging.total_entries);
      } else {
        setAllCount(res.data?.paging.total_entries);
      }

      return res.data;
    },
    [order, orgID, page, pageSize, search, sort, status],
  );

  const listProfilesInGroup = useCallback(
    async (
      newPage: number = page,
      newPageSize: number = pageSize,
      newStatus: string = status,
      newSort: string = sort,
      newOrder: string = order,
      newSearch: string = search,
      newGroupID: number = selected.id,
    ) => {
      const res = await profilesAPI.listProfilesInGroup({
        orgID,
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        status: newStatus,
        order: newOrder,
        search: newSearch,
        groupID: newGroupID,
      });

      setData(res.data?.data);
      setPage(newPage);
      setPageSize(newPageSize);
      setSort(newSort);
      setOrder(newOrder);

      return res.data;
    },
    [order, orgID, page, pageSize, search, sort, status, selected],
  );

  const listGroups = useCallback(
    async (
      newPage: number = page,
      newPageSize: number = pageSize,
      newSort: string = sort,
      newOrder: string = order,
      newSearch: string = search,
      initial = false,
    ) => {
      const res = await organisationsAPI.listOrganisationGroups({
        orgID,
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        order: newOrder,
        search: newSearch,
      });
      if (allGroups !== undefined) {
        const allGroupOptions = [...res.data?.data, allGroups];
        setDataGroup(allGroupOptions);
      }
      return res.data;
    },
    [allGroups, order, orgID, page, pageSize, search, sort],
  );

  const listProfilesForSearch = useCallback(
    async (newSearch: string = search) => {
      const res = await profilesAPI.listProfiles({
        orgID,
        search: newSearch,
      });

      setProfileDataForSearch(
        res.data.data?.filter(
          (item: IProfile) =>
            item.status === 'active' || item.status === 'editing',
        ),
      );

      return res.data;
    },
    [orgID, search],
  );
  const countEditingProfiles = useCallback(
    async (
      newPage: number = page,
      newPageSize: number = pageSize,
      newSort: string = sort,
      newOrder: string = order,
      newSearch: string = search,
      initial = false,
    ) => {
      const res = await profilesAPI.listProfiles({
        orgID,
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        status: 'editing',
        order: newOrder,
        search: newSearch,
      });

      if (!initial) {
        setData(res.data?.data);
        setPage(newPage);
        setPageSize(newPageSize);
        setSort(newSort);
        setOrder(newOrder);
      }
      setEditingCount(res.data?.paging.total_entries);
      if (
        res.data?.paging.total_entries !== undefined &&
        res.data?.paging.total_entries > 0
      ) {
        setIsEditingModalOpen(true);
      }
    },
    [order, orgID, page, pageSize, search, sort],
  );

  const showActivatationKeyType = useCallback(
    async (newActivationKey: string = activationKey) => {
      const res = await cardsAPI.showActivatationKeyType(newActivationKey);

      if (res.data?.data?.type && activationKeyType !== res.data.data.type) {
        setActivationKeyType(res.data.data.type);
      }
    },
    [activationKey, activationKeyType],
  );

  const handleCardActivation = useCallback(() => {
    if (orgID) {
      setIsLoading(true);
      setSuccess('');
      setError(false);
      setNotFoundError(false);
      setUsedKeyError(false);
      cardsAPI
        .activateCards(activationKey, orgID, profileIDForActivation)
        .then((res) => {
          if (activationKeyFromSignUp && res.data?.data.profile?.id) {
            setProfileIDForActivation(res.data.data.profile.id);
            setIsEditAfterActivateOpen(true);
          }
          listProfiles(1, pageSize, '').finally(() => {});
          setSuccess('Profiles were added successfully');
        })
        .catch((e: AxiosError) => {
          if (e.response?.status === 404) {
            setNotFoundError(true);
          } else if (e.response?.status === 422) {
            setUsedKeyError(true);
          } else {
            setError(true);
          }
        })
        .then(() => setIsLoading(false))
        .finally(() => setIsOpen(false));
    }
  }, [
    activationKey,
    activationKeyFromSignUp,
    profileIDForActivation,
    listProfiles,
    orgID,
    pageSize,
  ]);

  function handleCheckItem(profileID: number) {
    let arr = checkedItems;
    if (checkedItems.includes(profileID)) {
      arr = arr.filter((e) => e !== profileID);
    } else {
      arr = [...checkedItems, profileID];
    }
    setCheckedItems(arr);
    selectProfiles(arr);
  }

  const showSetupInfo = useCallback(
    (override = false) => {
      const setupInfoShown = localStorage.getItem('setupInfo');
      if ((setupInfo || override) && !setupInfoShown) {
        setIsSetupInfoOpen(true);
        localStorage.setItem('setupInfo', 'true');
      }
    },
    [setupInfo],
  );

  const updateLoginStatus = () => {
    setSuccess('');
    accountsAPI
      .updateCurrentUser({
        first_log_in_at: new Date(),
      })
      .then(() => getCurrentUser());
  };

  const listEditorsGroups = () => {
    const groupList: number | number[] = [];
    dataGroup?.forEach((group) => {
      if (group.editors?.length > 0) {
        group.editors?.forEach((editor) => {
          if (
            editor.user.id === user?.id &&
            editorsGroups.indexOf(group.id) < 0
          ) {
            groupList.push(group.id);
          }
          if (groupList.length === 0 && editor.user.id === user?.id) {
            setSelected({
              name: group.name,
              id: group.id,
            });
          }
        });
      }
    });
    setEditorsGroups(groupList);
  };

  function updateProfile(
    profileID: number,
    newStatus: string,
    emailProfileEditing?: boolean,
  ) {
    setIsLoading(true);
    setSuccess('');
    setError(false);

    if (orgID) {
      profilesAPI
        .updateProfile({
          orgID,
          profileID,
          body: {
            email_profile_editing: emailProfileEditing,
            profile: {
              status: newStatus,
              is_editable: newStatus === 'editing',
            },
          },
        })
        .then(() => setSuccess(``))
        .catch(() => setError(true))
        .then(() => listProfiles(1, pageSize))
        .finally(() => setIsLoading(false));
    }
  }

  const handleEditViaCSV = useCallback(() => {
    if (orgID && importCSV) {
      setIsLoading(true);
      setSuccess('');
      setError(false);
      const formData = new FormData();
      formData.append('file', importCSV);
      formData.append('type', 'profile');
      profilesAPI
        .importProfilesCSV(orgID, formData)
        .then(() => setSuccess('CSV file was imported successfully'))
        .catch(() => setError(true))
        .then(() => setIsLoading(false))
        .then(() => listProfiles(1, pageSize))
        .finally(() => setIsEditViaCSVOpen(false));
    }
  }, [orgID, importCSV, listProfiles, pageSize]);

  const exportCSV = useCallback(async () => {
    if (orgID) {
      const response = await profilesAPI.exportProfilesCSV(orgID);
      const blobData = new Blob([response.data]);
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blobData);
      a.download = 'cards.csv';
      a.click();
    }
  }, [orgID]);

  function handleCompletedShopifyCheckout(checkoutId: string) {
    fetchCheckout(atob(checkoutId))
      .then((checkout) => {
        if (!checkout.completedAt) return;
        if (!orgID) return;

        // Clear checkout id in query params to avoid reloading the page -> duplicating data
        history.replace('/');

        checkout.lineItems.forEach(async (item) => {
          const card_information = item.customAttributes.find(
            (attr) => attr.key === '_cardInformation',
          );
          if (!card_information) return;
          const profiles = await Promise.all(
            JSON.parse(card_information.value).map(
              async (infomation: InformationProps) => {
                let profile_data = {
                  organisation_id: orgID,
                  ...(infomation.email ? { email: infomation.email } : {}),
                  ...(infomation.mobileNumber
                    ? { mobile_number: infomation.mobileNumber }
                    : {}),
                  ...(infomation.companyWebsiteURL
                    ? { company_website: infomation.companyWebsiteURL }
                    : {}),
                  ...(infomation.companyAddress
                    ? { street_address: infomation.companyAddress }
                    : {}),
                  ...(infomation.companyPhoneNumber
                    ? { company_phone_number: infomation.companyPhoneNumber }
                    : {}),
                };

                if (infomation.name) {
                  const [first_name, middle_name, last_name] =
                    infomation.name.split(' ');

                  profile_data = {
                    ...profile_data,
                    ...(first_name ? { first_name } : {}),
                    ...(middle_name ? { middle_name } : {}),
                    ...(last_name ? { last_name } : {}),
                  };
                }

                const res = await profilesAPI.createProfile(orgID, {
                  profile: profile_data,
                });
                return { ...res.data.data };
              },
            ),
          );

          const profile_ids = profiles.map(function (profile: IProfile) {
            return profile.id;
          });

          if (profile_ids) {
            setIsLoading(true);
            profilesAPI
              .updateProfilesSharedNew({
                orgID,
                body: {
                  ids: profile_ids,
                  profile: {
                    status: 'editing',
                  },
                },
              })
              .then(() => {
                setStatus('editing');
                selectProfileStatus(1);
                listProfiles(1, pageSize, 'editing').finally(() => {});

                const cards = profile_ids.length === 1 ? 'card' : 'cards';
                setSuccess(
                  `Congrats! You've added ${profile_ids.length} ${cards}. Click 'Edit profile' below to edit a profile's contact details.`,
                );
              })
              .finally(() => setIsLoading(false));
          }
        });
      })
      .catch(() => {});
  }

  useEffect(() => {
    if (initialLoading && orgID) {
      listGroups();
      if (user?.first_log_in_at === null) {
        countEditingProfiles(1, 20, 'date', 'desc', '', true);
        setLoginTime(new Date());
        updateLoginStatus();
      }
      if (activationKeyFromSignUp) {
        handleCardActivation();
      } else if (activationKeyFromLogin) {
        setActivationKey(activationKeyFromLogin);
        setIsOpen(true);
      } else {
        showSetupInfo();
      }
      if (shopifyCheckoutId !== null) {
        handleCompletedShopifyCheckout(shopifyCheckoutId);
      }
      setInitialLoading(false);
    }
  }, [
    listProfiles,
    initialLoading,
    orgID,
    showSetupInfo,
    activationKeyFromSignUp,
    handleCardActivation,
    activationKeyFromLogin,
    countEditingProfiles,
    shopifyCheckoutId,
    handleCompletedShopifyCheckout,
    loginTime,
    updateLoginStatus,
  ]);

  useEffect(() => {
    if (userRole === 'org_admin' && selected.id === 0) {
      listProfiles(page, pageSize);
    } else if (selected.id === 0) {
      listProfiles(page, pageSize);
    } else {
      listProfilesInGroup(page, pageSize, '', 'date', 'desc', '', selected.id);
    }
  }, [
    editorsGroups,
    dataGroup,
    listGroups,
    listProfiles,
    listProfilesInGroup,
    orgID,
    page,
    pageSize,
    selected,
    userRole,
    initialLoading,
  ]);

  useEffect(() => {
    listEditorsGroups();
  }, [dataGroup]);

  useEffect(() => {
    if (
      !initialLoading &&
      userRole === 'org_editor' &&
      editorsGroups.length < 1
    ) {
      setIsNoGroupsOpen(true);
    }
  }, [editorsGroups, initialLoading, userRole]);

  if (initialLoading) {
    return (
      <div className="min-h-screen bg-white flex flex-col justify-center py-6 sm:px-6 lg:px-8">
        <div className="py-32">
          <Spinner className="h-16 w-16 text-brand-500 mx-auto" />
        </div>
      </div>
    );
  }

  return (
    <Layout
      pageName="My Profiles"
      rightTitle={<TrialBadge kind={TRIAL_BADGE_KIND.SHORT} />}
    >
      <div className="hidden lg:block md:pb-8 md:flex md:items-center md:-mt-20">
        <div className="mt-3 flex justify-end space-x-4 mt-0">
          <TrialBadge kind={TRIAL_BADGE_KIND.LONG} />
          {userRole === 'org_admin' && (
            <>
              <div className="hidden lg:block">
                <Modal
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  buttonTitle="Activate products"
                  dialogTitle={MESSAGES.profile.activate.heading}
                  dialogDescription={
                    <div className="text-sm leading-5 mb-4 text-gray-500">
                      Enter the activation key from your Shopify order email or
                      on the screen when you tap your physical card on your
                      phone.
                      <br />
                      <br />
                      Please note - You will only need to activate your product
                      if you’ve ordered a Tapt Lite or Tapt Mobile sticker. Tapt
                      Custom and Tapt Black cards will arrive active and ready
                      to use. If you’re still having trouble, please
                      <a href="https://tapt.io/contact-us"> get in touch</a>
                    </div>
                  }
                  successButtonText="Activate"
                  onSuccess={() => handleCardActivation()}
                  isLoading={isLoading}
                  isDisabled={!activationKey}
                >
                  <a
                    href="https://tapt.io/pages/how-to-use"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Learn more.
                  </a>
                  <div className="mt-6">
                    <ActivationKeySearch
                      id={`ActivationKey-${activationKey}`}
                      label="Card activation key"
                      search={activationKey}
                      setSearch={setActivationKey}
                      fetchQuery={(searchQuery) =>
                        showActivatationKeyType(searchQuery)
                      }
                    />
                    <div className="mt-6 mb-6 relative">
                      <Search
                        id={`ProfileList-1-20-date-desc--${profileSearch}`}
                        label="Assign product to existing profile (optional)"
                        description="If you do not assign this product to an existing profile, we'll create a new one instead."
                        placeholder={
                          activationKeyType === 'card_generated'
                            ? 'Search for Profile ID or Name'
                            : 'Only available for card generated keys'
                        }
                        search={profileSearch}
                        disabled={activationKeyType !== 'card_generated'}
                        setSearch={(value) => {
                          if (value !== profileSearch) {
                            setProfileIDForActivation(undefined);
                            setShowOptions(true);
                          }
                          setProfileSearch(value);
                        }}
                        fetchQuery={(newSearch) =>
                          listProfilesForSearch(newSearch)
                        }
                      />
                      {profileSearch && showOptions && (
                        <div className="origin-top-left absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                          <div className="py-1">
                            {profileDataForSearch &&
                            profileDataForSearch?.length ? (
                              profileDataForSearch?.map((item: IProfile) => (
                                <button
                                  type="button"
                                  key={item.id}
                                  className="appearance-none px-3 py-2 cursor-pointer hover:bg-gray-200 w-full text-left"
                                  onClick={() => {
                                    setProfileIDForActivation(item.id);
                                    setShowOptions(false);
                                    setProfileSearch(`${item.id}`);
                                  }}
                                >
                                  #{item.id}{' '}
                                  <span className="text-gray-500">
                                    {item.first_name} {item.last_name}
                                  </span>
                                </button>
                              ))
                            ) : (
                              <li className="px-3 py-2 text-center">
                                No matching items found
                              </li>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Modal>
              </div>
              <Button
                className="hidden lg:block"
                href="/shop"
                buttonText="Shop"
              />
            </>
          )}
        </div>
      </div>
      {success !== '' && (
        <SuccessAlert
          className="rounded-md bg-green-50 p-4"
          message={success}
        />
      )}
      {error && <ErrorAlert message={MESSAGES.error.generic} />}
      {notFoundError && (
        <ErrorAlert message={MESSAGES.profile.activate.notFound} />
      )}
      {usedKeyError && (
        <ErrorAlert message={MESSAGES.profile.activate.usedKey} />
      )}
      <div>
        <Tab.Group
          key={`${profileStatus}-${status}`}
          defaultIndex={profileStatus}
          onChange={(index) => {
            setPage(1);
            setSuccess('');
            setError(false);
            setPrintError(false);
            setSearch('');
            setStatus(status);
            selectProfileStatus(index);
            setSelectAll(false);
            setCheckedItems([]);
            selectProfiles([]);
            if (orgID && orgID !== -1 && userRole === 'org_admin') {
              listProfiles(1, pageSize, status).finally(() => {});
            }
          }}
        >
          <div className="py-8">
            <div className="pb-8 flex flex-col space-y-3 items-start sm:flex-row sm:space-y-0 sm:items-center sm:justify-end">
              {orgID && orgID !== -1 && (
                <div className="flex flex-shrink-0 space-x-3">
                  <Search
                    id={`ProfileList-${page}-${pageSize}-${sort}-${order}-${status}-${search}`}
                    search={search}
                    setSearch={setSearch}
                    fetchQuery={(searchQuery) =>
                      listProfiles(page, pageSize, '', sort, order, searchQuery)
                    }
                  />
                  {isPremium && (
                    <Listbox value={selected} onChange={setSelected}>
                      {({ open }) => (
                        <>
                          {userRole !== 'org_editor' && (
                            <div className="relative w-56">
                              <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left text-sm font-medium text-gray-700 shadow-sm focus:border-brand-500 focus:outline-none focus:ring-1 focus:ring-brand-500 sm:text-sm">
                                <span className="block truncate">
                                  {selected.name}
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronDownIcon
                                    className="ml-2 h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {dataGroup &&
                                    dataGroup.map((group) => (
                                      <div>
                                        <Listbox.Option
                                          key={group.id}
                                          className={({ active }) =>
                                            classNames(
                                              active
                                                ? 'text-white bg-brand-500'
                                                : 'text-gray-900',
                                              'relative cursor-default select-none py-2 pl-3 pr-9',
                                            )
                                          }
                                          value={group}
                                        >
                                          {({ selected, active }) => (
                                            <>
                                              <span
                                                className={classNames(
                                                  selected
                                                    ? 'font-semibold'
                                                    : 'font-normal',
                                                  'block truncate',
                                                )}
                                              >
                                                {group.name}
                                              </span>
                                              {selected ? (
                                                <span
                                                  className={classNames(
                                                    active
                                                      ? 'text-white'
                                                      : 'text-indigo-600',
                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                  )}
                                                >
                                                  <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      </div>
                                    ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          )}
                          {userRole === 'org_editor' && (
                            <div className="relative w-56">
                              <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left text-sm font-medium text-gray-700 shadow-sm focus:border-brand-500 focus:outline-none focus:ring-1 focus:ring-brand-500 sm:text-sm">
                                <span className="block truncate">
                                  {selected.name}
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronDownIcon
                                    className="ml-2 h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {dataGroup &&
                                    dataGroup.map((group) => (
                                      <div>
                                        {editorsGroups.includes(group.id) && (
                                          <Listbox.Option
                                            key={group.id}
                                            className={({ active }) =>
                                              classNames(
                                                active
                                                  ? 'text-white bg-brand-500'
                                                  : 'text-gray-900',
                                                'relative cursor-default select-none py-2 pl-3 pr-9',
                                              )
                                            }
                                            value={group}
                                          >
                                            {({ selected, active }) => (
                                              <>
                                                <span
                                                  className={classNames(
                                                    selected
                                                      ? 'font-semibold'
                                                      : 'font-normal',
                                                    'block truncate',
                                                  )}
                                                >
                                                  {group.name}
                                                </span>
                                                {selected ? (
                                                  <span
                                                    className={classNames(
                                                      active
                                                        ? 'text-white'
                                                        : 'text-indigo-600',
                                                      'absolute inset-y-0 right-0 flex items-center pr-4',
                                                    )}
                                                  >
                                                    <CheckIcon
                                                      className="h-5 w-5"
                                                      aria-hidden="true"
                                                    />
                                                  </span>
                                                ) : null}
                                              </>
                                            )}
                                          </Listbox.Option>
                                        )}
                                      </div>
                                    ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          )}
                        </>
                      )}
                    </Listbox>
                  )}
                  <Sort
                    id={`ProfileList-${page}-${pageSize}-${sort}-${order}-${status}-${search}`}
                    options={[
                      {
                        sort: 'date',
                        order: 'desc',
                        label: 'Date created (Newest first)',
                      },
                      {
                        sort: 'date',
                        order: 'asc',
                        label: 'Date created (Oldest first)',
                      },
                      {
                        sort: 'first_name',
                        order: 'asc',
                        label: 'Name (A-Z)',
                      },
                      {
                        sort: 'first_name',
                        order: 'desc',
                        label: 'Name (Z-A)',
                      },
                    ]}
                    sort={sort}
                    setSort={setSort}
                    order={order}
                    setOrder={setOrder}
                    fetchQuery={(sortQuery, orderQuery) =>
                      listProfiles(
                        page,
                        pageSize,
                        '',
                        sortQuery,
                        orderQuery,
                        search,
                      )
                    }
                  />
                </div>
              )}
              {userRole === 'org_admin' && (
                <div className="hidden lg:block ml-3">
                  <Modal
                    isOpen={isEditViaCSVOpen}
                    setIsOpen={setIsEditViaCSVOpen}
                    buttonTitle="Edit via CSV"
                    buttonKind={BUTTON_KIND.PRIMARY}
                    dialogTitle={MESSAGES.profile.import.heading}
                    dialogDescription={MESSAGES.profile.import.description}
                    successButtonText="Import"
                    onSuccess={() => handleEditViaCSV()}
                    isLoading={isLoading}
                  >
                    <button
                      type="button"
                      className="appearance-none text-sm text-brand-500 font-medium cursor-pointer underline"
                      onClick={exportCSV}
                    >
                      Export a CSV
                    </button>
                    <div className="mt-6 space-y-4">
                      <div className="border rounded-md px-4 mt-6 flex flex-row items-center justify-between">
                        <FileUploadButtonComponent
                          filename={importCSV?.name}
                          fileFormat=".csv"
                          fileFormatMessage="(.csv only)"
                          onFileSelected={(file) => setImportCSV(file)}
                          loading={isLoading}
                        />
                        {importCSV && (
                          <button
                            type="button"
                            onClick={() => setImportCSV(undefined)}
                            className="appearance-none text-brand-500 text-sm font-medium"
                          >
                            Remove link
                          </button>
                        )}
                      </div>
                    </div>
                  </Modal>
                </div>
              )}
            </div>
            {printError && (
              <ErrorAlert message="Design requirements are not met. Please edit and update the profile to continue" />
            )}
            {data && data.length > 0 ? (
              <div className="flex flex-col">
                <div className="relative -mx-4 sm:-mx-6 md:mx-0 lg:-mx-8">
                  <div className="block w-full lg:px-8">
                    <div className="relative shadow-sm border border-gray-200 sm:rounded-md min-h-8 overflow-hidden">
                      <div className="lg:hidden flex items-center px-4 py-2 sm:px-6 bg-white border-b border-gray-200">
                        <InputCheckbox
                          id="select-all"
                          label=""
                          value={selectAll}
                          onChange={(value) => {
                            setSelectAll(value);
                            if (value) {
                              const arr = data.map(({ id }) => id);
                              setCheckedItems(arr);
                              selectProfiles(arr);
                            } else {
                              setCheckedItems([]);
                              selectProfiles([]);
                            }
                          }}
                        />
                      </div>
                      <ul
                        className={classNames(
                          isLoading ? 'opacity-40' : '',
                          'divide-y divide-gray-200',
                        )}
                      >
                        <li
                          key="ProfileListAdminHeader"
                          className="hidden lg:block"
                        >
                          <div className="bg-gray-50">
                            <div className="flex items-center px-4 py-2 sm:px-6">
                              <div className="min-w-0 flex-1 flex items-start md:items-center">
                                <div className="w-7">
                                  <InputCheckbox
                                    id="select-all"
                                    label=""
                                    value={selectAll}
                                    onChange={(value) => {
                                      setSelectAll(value);
                                      if (value) {
                                        const arr = data.map(({ id }) => id);
                                        setCheckedItems(arr);
                                        selectProfiles(arr);
                                      } else {
                                        setCheckedItems([]);
                                        selectProfiles([]);
                                      }
                                    }}
                                  />
                                </div>
                                <div className="hidden lg:block min-w-full pr-4 lg:grid lg:grid-cols-6 xl:grid-cols-8">
                                  <p className="lg:col-span-2 xl:col-span-2 xl:pl-4 text-sm lg:block text-start font-medium text-gray-900">
                                    NAME &amp; PROFILE ID
                                  </p>
                                  {isPremium && (
                                    <p className="xl:col-span-2 text-start md:mt-0 hidden xl:block text-sm font-medium text-gray-900">
                                      CONTACT INFORMATION
                                    </p>
                                  )}
                                  {!isPremium && (
                                    <p className="xl:col-span-4 text-start md:mt-0 hidden xl:block text-sm font-medium text-gray-900">
                                      CONTACT INFORMATION
                                    </p>
                                  )}
                                  {isPremium && (
                                    <p className="lg:col-span-2 text-start md:mt-0 text-sm font-medium text-gray-900">
                                      GROUP
                                    </p>
                                  )}
                                  <p className="xl:col-span-1 text-center md:mt-0 hidden xl:block text-sm font-medium text-gray-900">
                                    EDIT PROFILE
                                  </p>
                                  <p className="xl:col-span-1 text-center md:mt-0 hidden xl:block text-sm font-medium text-gray-900">
                                    VIEW PROFILE
                                  </p>
                                </div>
                                <div className="w-24" />
                              </div>
                            </div>
                          </div>
                        </li>
                        {userRole === 'org_admin' &&
                          data?.map((item: IProfile) => (
                            <ProfileListItem
                              key={item.id}
                              orgID={orgID || 0}
                              groupName={item.group_name || ''}
                              groupSubheading={item.group_subheading || ''}
                              id={item.id}
                              firstName={item.first_name || ''}
                              lastName={item.last_name || ''}
                              title={item.job_title || ''}
                              emailAddress={item.email || ''}
                              photoUrl={item.photo?.thumb_url}
                              status={item.status || ''}
                              selected={checkedItems.includes(item.id)}
                              profileHash={item.profile_hash}
                              checkItem={() => handleCheckItem(item.id)}
                              viewProfile={`/view-profile/${item.profile_hash}`}
                              editProfile={`/edit-profile/${item.id}`}
                              disableCardholderEditing={() =>
                                updateProfile(item.id, 'active')
                              }
                              enableCardholderEditing={(emailProfileEditing) =>
                                updateProfile(
                                  item.id,
                                  'editing',
                                  emailProfileEditing,
                                )
                              }
                              premium={isPremium}
                            />
                          ))}
                        {userRole === 'org_editor' &&
                          data
                            ?.filter(
                              (item: IProfile) =>
                                item.group_id !== null &&
                                editorsGroups.includes(item.group_id),
                            )
                            .map((item: IProfile) => (
                              <ProfileListItem
                                key={item.id}
                                orgID={orgID || 0}
                                groupName={item.group_name || ''}
                                groupSubheading={item.group_subheading || ''}
                                id={item.id}
                                firstName={item.first_name || ''}
                                lastName={item.last_name || ''}
                                title={item.job_title || ''}
                                emailAddress={item.email || ''}
                                photoUrl={item.photo?.thumb_url}
                                status={item.status || ''}
                                selected={checkedItems.includes(item.id)}
                                profileHash={item.profile_hash}
                                checkItem={() => handleCheckItem(item.id)}
                                viewProfile={`/view-profile/${item.profile_hash}`}
                                editProfile={`/edit-profile/${item.id}`}
                                disableCardholderEditing={() =>
                                  updateProfile(item.id, 'active')
                                }
                                enableCardholderEditing={(
                                  emailProfileEditing,
                                ) =>
                                  updateProfile(
                                    item.id,
                                    'editing',
                                    emailProfileEditing,
                                  )
                                }
                                premium={isPremium}
                              />
                            ))}
                      </ul>
                      {isLoading && (
                        <div className="absolute left-1/2 top-1/2 text-gray-500">
                          <Spinner className="text-brand-500" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="py-32">
                <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
                  {MESSAGES.profile.list.empty.heading}
                </h3>
                <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
                  {MESSAGES.profile.list.empty.description}
                </p>
              </div>
            )}
          </div>
        </Tab.Group>
      </div>
      {orgID && orgID !== -1 && userRole === 'org_admin' && (
        <Pagination
          id={`ProfileList-${page}-${pageSize}-${sort}-${order}-${status}-${search}`}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          fetchQuery={(pageQuery, pageSizeQuery) => {
            return listProfiles(
              pageQuery,
              pageSizeQuery,
              status,
              sort,
              order,
              search,
            );
          }}
          setIsLoading={setIsLoading}
        />
      )}
      <div className="block" aria-hidden="true">
        <div className="py-8" />
      </div>
      <InfoPanelFooter
        className={checkedItems?.length > 1 ? 'sm:pl-4 sm:pr-8' : '-bottom-28'}
      >
        <div className="flex justify-between items-center">
          <div className="flex space-x-4">
            <ButtonField
              buttonLabel={
                innerWidth <= SM_BREAKPOINT ? 'Clear' : 'Clear Profiles'
              }
              confirmationTitle={
                'Are you sure you want to clear the selected profiles?'
              }
              confirmationApproveText="Clear Profiles"
              confirmationRequired
              confirmationContent={
                'Clearing these profiles will wipe any associated data which cannot be retrieved'
              }
              loading={profileResetLoading}
              onClick={async () => {
                if (!orgID) return;

                setProfileResetLoading(true);
                setError(false);

                try {
                  await profilesAPI.resetProfiles(orgID, checkedItems);

                  setProfileResetLoading(false);
                  setSuccess('Profiles have been cleared');
                  listProfiles(1, pageSize);
                } catch (e) {
                  setError(true);
                } finally {
                  setProfileResetLoading(false);
                }
              }}
            />
            <ButtonField
              buttonLabel={
                innerWidth <= SM_BREAKPOINT ? 'Delete' : 'Delete Profiles'
              }
              confirmationTitle="Are you sure you want to delete the profile?"
              confirmationApproveText="Delete Profiles"
              confirmationRequired
              confirmationContent={
                <p>
                  Deleting this profile will also delete any contacts they may
                  have made
                </p>
              }
              loading={profileDeleteLoading}
              onClick={async () => {
                if (!orgID) return;

                setProfileDeleteLoading(true);
                setError(false);

                try {
                  await profilesAPI.deleteProfiles(orgID, checkedItems);

                  setProfileDeleteLoading(false);
                  setSuccess('Profiles have been deleted');
                  listProfiles(1, pageSize);
                } catch (e) {
                  setError(true);
                } finally {
                  setProfileDeleteLoading(false);
                }
              }}
            />
          </div>
          <div>
            <div className="flex items-center flex-nowrap space-x-4">
              <p className="text-sm leading-5 text-gray-500 hidden sm:block">{`${checkedItems.length} selected`}</p>
              <div>
                <Modal
                  isOpen={isEditSharedOpen}
                  setIsOpen={setIsEditSharedOpen}
                  buttonTitle={
                    status === 'unprinted'
                      ? 'Set up shared info'
                      : 'Edit shared info'
                  }
                  dialogTitle="Editing shared profile information"
                  dialogDescription={
                    <>
                      This feature enables you to add information to multiple
                      profiles at once.
                      <br />
                      <br />
                      <span className="font-medium">Important:</span> Changes
                      you make will overwrite existing information on the
                      selected profiles.
                    </>
                  }
                  onSuccess={() => history.push(`/edit-shared-profile`)}
                  successButtonText="Ok got it"
                  isLoading={isLoading}
                  buttonKind={BUTTON_KIND.PRIMARY}
                />
              </div>
            </div>
          </div>
        </div>
      </InfoPanelFooter>
      <Modal
        isOpen={isSetupInfoOpen}
        setIsOpen={setIsSetupInfoOpen}
        dialogHeroVideo="/images/customise-profile.mp4"
        dialogTitle="Start customising your digital profile"
        dialogDescription={
          <>
            Now your cards are all set up, start customising your digital
            profile to match your branding.{' '}
            <a
              href="https://help.tapt.io/knowledge/how-do-i-customise-my-profile-webpage"
              target="_blank"
              rel="noreferrer"
            >
              Learn more.
            </a>
          </>
        }
        onSuccess={() => {
          selectSettingsTab(2);
          history.push(`/settings`);
        }}
        successButtonText="Go to profile settings"
        cancelButtonText="Close"
        isLoading={isLoading}
        large
      />
      <Modal
        isOpen={isEditAfterActivateOpen}
        setIsOpen={setIsEditAfterActivateOpen}
        dialogTitle="You've activated a new card!"
        dialogDescription="If you would like to edit this card's profile now, click the 'Edit profile' button below. Otherwise, you can click 'Edit profile' next to the new profile in the cards list to edit later."
        onSuccess={() =>
          history.push(`/edit-profile/${String(profileIDForActivation)}`)
        }
        successButtonText="Edit profile"
        cancelButtonText="I'll edit later"
      />
      {/* <Modal
        isOpen={isNoGroupsOpen}
        setIsOpen={setIsNoGroupsOpen}
        dialogTitle="You aren't assigned to any groups yet"
        dialogDescription="If you would like to be added to a group, please reach out to one of your organisation admins."
        onSuccess={() => setIsNoGroupsOpen(false)}
        successButtonText="Ok"
      /> */}
    </Layout>
  );
};

export default ProfileListPage;

/* eslint-enable */
