import { IIcon } from '@/types/IIcon';

const SocialWhatsapp = ({ className }: IIcon): JSX.Element => {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C6.4775 2 2 6.4775 2 12C2 13.876 2.52695 15.6251 3.42578 17.1253L2.08952 22L7.06836 20.693C8.52446 21.5209 10.2054 22 12 22C17.5225 22 22 17.5225 22 12C22 6.4775 17.5225 2 12 2ZM8.57715 7.33529C8.73965 7.33529 8.90661 7.3343 9.05078 7.3418C9.22912 7.34596 9.42322 7.35902 9.60905 7.76986C9.82988 8.25819 10.3107 9.48325 10.3724 9.60742C10.4341 9.73159 10.4778 9.87786 10.3919 10.0404C10.3103 10.207 10.2678 10.3079 10.1478 10.4554C10.0236 10.5987 9.88757 10.7768 9.77506 10.8851C9.6509 11.0093 9.52268 11.1454 9.66602 11.3929C9.80935 11.6404 10.3071 12.4518 11.043 13.1068C11.9888 13.9518 12.7868 14.2114 13.0352 14.3356C13.2835 14.4598 13.4273 14.4404 13.5706 14.2738C13.7181 14.1113 14.1901 13.5537 14.3568 13.3053C14.5193 13.057 14.6852 13.1 14.9102 13.1816C15.1385 13.2633 16.3562 13.8631 16.6045 13.9873C16.8528 14.1115 17.0156 14.1729 17.0781 14.2738C17.1423 14.3779 17.1424 14.8738 16.9365 15.4521C16.7307 16.0296 15.7199 16.5881 15.2666 16.6273C14.8091 16.6698 14.3821 16.8329 12.293 16.0104C9.77297 15.0179 8.18374 12.4368 8.05957 12.2702C7.9354 12.1077 7.05046 10.9283 7.05046 9.71159C7.05046 8.49076 7.69055 7.89286 7.91471 7.64453C8.14305 7.3962 8.41048 7.33529 8.57715 7.33529Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SocialWhatsapp;
