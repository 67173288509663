import classNames from '@/helpers/classNames';

export const BADGE_KIND = {
  GRAY: 'gray',
  GREEN: 'green',
  YELLOW: 'yellow',
  RED: 'red',
} as const;

const Badge = ({
  text,
  kind,
  className = '',
  backgroundColor = `bg-${kind}-100`,
  textColor = `text-${kind}-800`,
}: {
  text: string;
  kind: string;
  className?: string;
  backgroundColor?: string;
  textColor?: string;
}): JSX.Element => (
  <span
    className={classNames(
      className,
      text
        ? `inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium`
        : `items-center p-2.5 text-xs rounded-full max-h-2.5 font-medium`,
      backgroundColor,
      textColor,
    )}
  >
    {text}
  </span>
);

export default Badge;
