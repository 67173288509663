import { IIcon } from '@/types/IIcon';

const PriorityIcon = ({ className }: IIcon): JSX.Element => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <rect width="20" height="20" rx="10" fill="#FF6D6D" />
      <path
        d="M10.75 6.66667C10.75 6.25245 10.4142 5.91667 10 5.91667C9.58579 5.91667 9.25 6.25245 9.25 6.66667V10C9.25 10.4142 9.58579 10.75 10 10.75C10.4142 10.75 10.75 10.4142 10.75 10V6.66667ZM10 12.5833C9.58579 12.5833 9.25 12.9191 9.25 13.3333C9.25 13.7475 9.58579 14.0833 10 14.0833H10.0083C10.4225 14.0833 10.7583 13.7475 10.7583 13.3333C10.7583 12.9191 10.4225 12.5833 10.0083 12.5833H10ZM16.75 10C16.75 10.8864 16.5754 11.7642 16.2362 12.5831C15.897 13.4021 15.3998 14.1462 14.773 14.773C14.1462 15.3998 13.4021 15.897 12.5831 16.2362C11.7642 16.5754 10.8864 16.75 10 16.75C9.11358 16.75 8.23583 16.5754 7.41689 16.2362C6.59794 15.897 5.85383 15.3998 5.22703 14.773C4.60023 14.1462 4.10303 13.4021 3.76381 12.5831C3.42459 11.7642 3.25 10.8864 3.25 10C3.25 8.20979 3.96116 6.4929 5.22703 5.22703C6.4929 3.96116 8.20979 3.25 10 3.25C11.7902 3.25 13.5071 3.96116 14.773 5.22703C16.0388 6.4929 16.75 8.20979 16.75 10Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PriorityIcon;
