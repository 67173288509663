// eslint-disable
import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import filesAPI from '@/api/files';
import OrderDetailModal from '../../OrderDetailModal';
import ordersAPI from '@/api/ordersV2';
import IFile from '@/types/IFile';
import IOrderV2 from '@/types/IOrderV2';
import IDesign from '@/types/IDesign';
import stage from '@/constants/stage';
import Modal from '@/components/Modal';
import FileUploadComponent from '../../FileUploadComponent';
// eslint-disable-next-line import/no-cycle
import OrderDetailCommentBox from './OrderDetailCommentBox';
import OrderDetailInformationBox from './OrderDetailInformationBox';

export interface IOrderFeedbackItem {
  change_request: IChangeRequest;
  change_request_file: IFile[] | [];
}

interface IChangeRequest {
  id: number;
  note: string;
  inserted_at: any;
  participant_name: string;
  type: string;
}
interface IOrderDetailUpdate {
  high_priority: boolean;
  design_spec: boolean;
  alternate_email: boolean;
}

interface IValidateAlternateEmail {
  validateMessage: string;
  valid: boolean;
  submitMessage: boolean;
}

interface IOrderDetailsProps {
  orderId: number;
  orgId: number;
  orderStage: string;
  isOpenOrderDetailModal: boolean;
  setIsOpenOrderDetailModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsMoveOrder: React.Dispatch<React.SetStateAction<boolean>>;
  // loading: boolean;
  orderAlternateEmail?: string;
  orderAlternateEmailFlag: boolean;
  orderNumber: string;
  design_specs?: boolean;
  isPriority?: boolean;
  handleClickLinkedOrg: (orgId: number) => void;
  handleOrderStatusChange: () => void;
  tag?: string;
  customerName: string;
  customerEmail: string;
  orderStatusUrl: string;
}

const OrderDetails = (props: IOrderDetailsProps): JSX.Element => {
  const {
    orderId,
    orgId,
    orderStage,
    isOpenOrderDetailModal,
    setIsOpenOrderDetailModal,
    orderNumber,
    isPriority,
    design_specs,
    handleClickLinkedOrg,
    setIsMoveOrder,
    orderAlternateEmail,
    orderAlternateEmailFlag,
    tag,
    handleOrderStatusChange,
    customerName,
    customerEmail,
    orderStatusUrl,
  } = props;

  // Order detail show
  const [orderComment, setOrderComment] = useState<IOrderFeedbackItem[]>([]);
  const [orderDesign, setOrderDesign] = useState<IDesign[]>([]);
  const [organisationPastOrder, setOrganisationPastOrder] = useState<
    IOrderV2[]
  >([]);

  const [updateOrderStatus, setUpdateOrderStatus] =
    useState<string>(orderStage);

  // Order detail comment
  const [newComment, setNewComment] = useState<string>('');
  const [newCommentFile, setNewCommentFile] = useState<File[]>([]);
  const [sendFeedBackStatus, setSendFeedBackStatus] = useState<boolean>(false);

  // Alternate email
  const [validateAlternateEmail, setValidateAlternateEmail] =
    useState<IValidateAlternateEmail>({
      validateMessage: '',
      valid: false,
      submitMessage: false,
    });
  const [inputAlternateEmail, setInputAlternateEmail] = useState<string>(
    orderAlternateEmail || '',
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Modal upload design
  const [isUploadDesign, setIsUploadDesign] = useState<boolean>(false);
  const [orderDesignFile, setOrderDesignFile] = useState<File>();

  const [sentToClientFlag, setSentToClientFlag] = useState<boolean>(false);
  const [orderTag, setOrderTag] = useState<string | undefined>(tag);
  const [orderDesignFileMessage, setOrderDesignFileMessage] =
    useState<string>('');
  const [isRerender, setIsReRender] = useState<boolean>(false);

  const handleUpdateOrderDetail = async (
    stage?: string,
    alternate_email?: string,
    priority?: boolean,
    alternate_email_flag?: boolean,
    orderTag?: string,
  ) => {
    await ordersAPI.updateOrderDetail(
      orderId,
      stage,
      alternate_email,
      priority,
      alternate_email_flag,
      orderTag,
    );
  };

  // Order detail checkbox
  const [orderDetailUpdate, setOrderDetailUpdate] =
    useState<IOrderDetailUpdate>({
      high_priority: isPriority || false,
      design_spec: design_specs || false,
      alternate_email: orderAlternateEmailFlag,
    });

  useEffect(() => {
    if (isOpenOrderDetailModal) {
      ordersAPI
        .showOrderChangeRequest(orderId)
        .then((res) => {
          if (res.data.data) {
            setOrderComment(res.data.data);
          }
        })
        .catch((err) => console.log('get change request errors: ', err));
    }
  }, [orderId, sendFeedBackStatus, isOpenOrderDetailModal, isRerender]);

  useEffect(() => {
    if (isOpenOrderDetailModal) {
      ordersAPI
        .organisation_past_order(orgId)
        .then((past_order) => {
          if (past_order.data.data) {
            setOrganisationPastOrder(past_order.data.data);
          }
        })
        .catch((err) => console.log('get organisation order errors: ', err));
    }
  }, [orderId, isOpenOrderDetailModal]);

  useEffect(() => {
    if (isOpenOrderDetailModal)
      ordersAPI
        .showOrderDesignFile(orderId)
        .then((res) => {
          setOrderDesign(res.data);
        })
        .catch((err) => console.log('get order designs errors: ', err));
  }, [orderId, isOpenOrderDetailModal]);

  useEffect(() => {
    if (isOpenOrderDetailModal)
      setInputAlternateEmail(orderAlternateEmail || '');
    setIsLoading(false);
  }, [isOpenOrderDetailModal]);

  const handleUploadChangeRequestFiles = (files: FileList) => {
    Array.from(files).forEach((file) => {
      setNewCommentFile((prevFiles) => [...prevFiles, file]);
    });
  };

  const handleSendOrderFeedback = (files: File[]) => {
    if (newCommentFile.length !== 0 || newComment !== '') {
      Promise.all(
        Array.from(files).map((file) => {
          return filesAPI
            .createAndUploadOrderFile(orgId, new File([file], file.name))
            .then((res) => res?.data?.data?.id)
            .catch((err) => {
              console.log(err);
              return null;
            });
        }),
      ).then((fileIds) => {
        const validFileIds = fileIds.filter((id): id is number => id !== null);
        ordersAPI
          .AdminOrderChangeRequest(orderId, newComment, validFileIds)
          .then(() => {
            setSendFeedBackStatus(!sendFeedBackStatus);
            setNewComment('');
          })
          .finally(() => {
            setNewCommentFile([]);
            setNewComment('');
          });
      });
    }
  };

  const handleSendOrderDesignFile = async (file: File) => {
    if (orderDesignFile) {
      await filesAPI
        .createAndUploadOrderFile(orgId, new File([file], file.name))
        .then((res) => {
          // Update Order changerequest
          ordersAPI
            .AdminOrderChangeRequest(orderId, orderDesignFileMessage, [
              res?.data?.data?.id,
            ])
            .finally(() => {
              setOrderDesignFile(undefined);
            });
          // Update order file and send to client
          ordersAPI.uploadFile(orderId, res?.data?.data?.id, sentToClientFlag);
          // Update order design file
          ordersAPI.uploadOrderDesign(orderId, orgId, [res?.data?.data?.id]);
        })
        .catch((err) => {
          console.log(err);
          return null;
        });
    }
  };

  const handleSubmitOrderDetailModal = async () => {
    if (
      (!validateAlternateEmail.valid && orderDetailUpdate.alternate_email) ||
      inputAlternateEmail === ''
    ) {
      setValidateAlternateEmail((prevState) => ({
        ...prevState,
        submitMessage: true,
      }));
    }

    if (validateAlternateEmail.valid || !orderDetailUpdate.alternate_email) {
      setIsLoading(true);

      const email =
        validateAlternateEmail.valid && orderDetailUpdate.alternate_email
          ? inputAlternateEmail
          : orderAlternateEmail || undefined;

      if (orderDesignFile) {
        await handleUpdateOrderDetail(
          'awaiting_client',
          email,
          orderDetailUpdate.high_priority,
          orderDetailUpdate.alternate_email,
          orderTag,
        );
        await handleSendOrderDesignFile(orderDesignFile);
      } else {
        orderTag ===
        stage.order_tag.find((tag) => tag.name === 'on_hold')?.value
          ? await handleUpdateOrderDetail(
              'on_hold',
              email,
              orderDetailUpdate.high_priority,
              orderDetailUpdate.alternate_email,
              orderTag,
            )
          : await handleUpdateOrderDetail(
              updateOrderStatus,
              email,
              orderDetailUpdate.high_priority,
              orderDetailUpdate.alternate_email,
              orderTag,
            );
      }

      handleSendOrderFeedback(newCommentFile);
      handleOrderStatusChange();

      setValidateAlternateEmail((prevState) => ({
        ...prevState,
        submitMessage: false,
      }));

      setInputAlternateEmail(orderAlternateEmail || '');
      setIsOpenOrderDetailModal(false);
    }
  };

  return (
    <OrderDetailModal
      isOpen={isOpenOrderDetailModal}
      setIsOpen={setIsOpenOrderDetailModal}
      successButtonText="Save & Exit"
      isLoading={isLoading}
      onSuccess={() => {
        handleSubmitOrderDetailModal();
      }}
      cancelButtonText="Close"
      large
      wider
    >
      <Box className="grid grid-cols-2 gap-11">
        <OrderDetailCommentBox
          orderComment={orderComment}
          newComment={newComment}
          setNewComment={setNewComment}
          newCommentFile={newCommentFile}
          onFileSelected={handleUploadChangeRequestFiles}
          handleSendOrderFeedback={handleSendOrderFeedback}
          orderDesignFile={orderDesignFile}
          orderId={orderId}
          isRerender={isRerender}
          setIsReRender={setIsReRender}
        />
        <OrderDetailInformationBox
          inputAlternateEmail={inputAlternateEmail}
          setInputAlternateEmail={setInputAlternateEmail}
          handleClickLinkedOrg={handleClickLinkedOrg}
          orgID={orgId}
          setIsMoveOrder={setIsMoveOrder}
          orderDetailUpdate={orderDetailUpdate}
          setOrderDetailUpdate={setOrderDetailUpdate}
          validateAlternateEmail={validateAlternateEmail}
          setValidateAlternateEmail={setValidateAlternateEmail}
          setIsUploadDesign={setIsUploadDesign}
          orderDesign={orderDesign}
          organisationPastOrder={organisationPastOrder}
          orderNumber={orderNumber}
          orderStatusUrl={orderStatusUrl}
          updateOrderStatus={updateOrderStatus}
          setUpdateOrderStatus={setUpdateOrderStatus}
          orderId={orderId}
          orderTag={orderTag}
          setOrderTag={setOrderTag}
          orderStage={orderStage}
          customerName={customerName}
          customerEmail={customerEmail}
        />
      </Box>
      <Modal
        key="Upload-Design-Modal"
        isOpen={isUploadDesign}
        setIsOpen={setIsUploadDesign}
        dialogTitle="Drag And Drop File"
        dialogDescription="Upload desired files"
        successButtonText="Upload"
        checkboxDescription="I want this sent to the client"
        onSuccess={() => {
          setIsUploadDesign(false);
          if (orderDesignFile) {
            sentToClientFlag
              ? setOrderDesignFileMessage('Design sent to client')
              : setOrderDesignFileMessage('');
          }
          if (!orderDesignFile) {
            setSentToClientFlag(false);
          }
        }}
        onCancel={() => {
          if (orderDesignFile) {
            sentToClientFlag
              ? setOrderDesignFileMessage('Design sent to client')
              : setOrderDesignFileMessage('');
          }
          if (!orderDesignFile) {
            setSentToClientFlag(false);
          }
        }}
        onClose={() => {
          if (!orderDesignFile) {
            setOrderDesignFileMessage('');
            setSentToClientFlag(false);
          }
          setIsUploadDesign(false);
        }}
        checkbox={sentToClientFlag}
        setCheckbox={setSentToClientFlag}
      >
        <FileUploadComponent
          onFileSelected={(file: File) => {}}
          fileFormat=".svg, .eps, .ai, .indd, .pdf"
          fileFormatMessage="(.svg, .eps, .ai, .indd, or .pdf only)"
          file={orderDesignFile}
          setFile={setOrderDesignFile}
        />
      </Modal>
    </OrderDetailModal>
  );
};

export default OrderDetails;
