import { useEffect, useRef } from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';

interface SuccessAlertProps {
  message: string;
  className?: string;
}

export default function SuccessAlert({
  message,
  className = 'rounded-md bg-green-50 p-4 mt-3 mb-3',
}: SuccessAlertProps): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ref]);

  return (
    <div ref={ref} className={className}>
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-green-800">{message}</p>
        </div>
      </div>
    </div>
  );
}
