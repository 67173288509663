import { FC } from 'react';
import {
  CreditCardIcon,
  UserIcon,
  DeviceMobileIcon,
} from '@heroicons/react/outline';
import AreaChart from '../Charts/Area';
import { AnalyticsResponse } from '@/types/IAnalytics';

type Props = {
  data: AnalyticsResponse;
};

const DomainAnalytics: FC<Props> = ({
  data: {
    profile_views: profileViews,
    contacts_sent: contactsSent,
    contacts_received: contactsReceived,
    profiles: profilesCount,
  },
}) => {
  return (
    <div>
      <h2 className="text-gray-900 font-poppins font-medium text-3xl">
        Domain Analytics
      </h2>
      <h3 className="text-gray-500 mt-2">
        Profiles with the most activity (profile views, contacts sent and
        contacts received)
      </h3>
      <hr className="h-px mt-4 mb-8 bg-gray-200 border-0 " />
      <div className="grid grid-cols-4 grid-flow-col gap-2 w-full">
        <div>
          <AreaChart
            title="Total profile views"
            total={profileViews.total}
            percent={profileViews.change}
            labels={Object.keys(profileViews.entries).map(
              (date) => date,
              // new Intl.DateTimeFormat('en-US').format(date),
            )}
            series={[
              {
                name: 'Views',
                data: Object.values(profileViews.entries),
              },
            ]}
          />
        </div>
        <div>
          <AreaChart
            title="Total contacts sent"
            total={contactsSent.total}
            percent={contactsSent.change}
            labels={Object.keys(contactsSent.entries).map(
              (date) => date,
              // new Intl.DateTimeFormat('en-US').format(date),
            )}
            series={[
              {
                name: 'Views',
                data: Object.values(contactsSent.entries),
              },
            ]}
          />
        </div>
        <div>
          <AreaChart
            title="Total contacts recieved"
            total={contactsReceived.total}
            percent={contactsReceived.change}
            labels={Object.keys(contactsReceived.entries).map(
              (date) => date,
              // new Intl.DateTimeFormat('en-US').format(date),
            )}
            series={[
              {
                name: 'Views',
                data: Object.values(contactsReceived.entries),
              },
            ]}
          />
        </div>
        <div className="flex flex-col justify-between">
          <div className="flex font-poppins">
            <div className="grid grid-flow-col gap-2 text-gray-500">
              <div className="flex">
                <div className="flex flex-col">
                  <div className="flex mb-1">
                    <UserIcon className="h-6 w-6 mr-1" />
                    Total profiles
                  </div>
                  <div className="text-gray-700 font-semibold text-2xl mb-4">
                    {String(profilesCount.card + profilesCount.digital)}
                  </div>
                </div>
              </div>
              <div className="flex">
                <div className="flex flex-col">
                  <div className="flex mb-1">
                    <CreditCardIcon className="h-6 w-6 mr-1" />
                    Card
                  </div>
                  <div className="text-gray-700 font-semibold text-2xl mb-1">
                    {(
                      (profilesCount.card /
                        (profilesCount.card + profilesCount.digital)) *
                      100
                    ).toFixed(1)}
                    %
                  </div>
                  <div className="text-gray-500 font-light text-xs">
                    {profilesCount.card} total
                  </div>
                </div>
              </div>
              <div className="flex">
                <div className="flex flex-col">
                  <div className="flex mb-1">
                    <DeviceMobileIcon className="h-6 w-6 mr-1" />
                    Digital
                  </div>
                  <div className="text-gray-700 font-semibold text-2xl mb-1">
                    {(
                      (profilesCount.digital /
                        (profilesCount.card + profilesCount.digital)) *
                      100
                    ).toFixed(1)}
                    %
                  </div>
                  <div className="text-gray-500 font-light text-xs">
                    {profilesCount.digital} total
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="w-full bg-brand-200 rounded h-5">
              <div
                className="bg-brand-500 h-5 rounded"
                style={{
                  width: `${(
                    (profilesCount.card /
                      (profilesCount.card + profilesCount.digital)) *
                    100
                  ).toFixed(1)}%`,
                }}
              />
            </div>
            <div className="flex justify-around text-xs mt-2 font-medium text-gray-500">
              <div className="grid grid-flow-col gap-1 items-center">
                <div className="h-2 w-2 rounded-full bg-brand-500" />
                <div>Card profiles</div>
              </div>
              <div className="grid grid-flow-col gap-1 items-center">
                <div className="h-2 w-2 rounded-full bg-brand-200" />
                <div>Digital profile only</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DomainAnalytics;
