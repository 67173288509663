import { useCallback, useEffect, useState } from 'react';
import { Tab, Transition } from '@headlessui/react';
import { SparklesIcon } from '@heroicons/react/outline';
import {
  UserIcon,
  MinusIcon,
  PlusIcon,
} from '@heroicons/react/solid';
import Layout from '@/components/Layout';
import TabList from '@/components/TabList';
import InputCheckbox from '@/components/InputCheckbox';
import InfoPanelContainer from '@/components/InfoPanelContainer';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import Button, { BUTTON_KIND, BUTTON_SIZE } from '@/components/Button';
import useAuth from '@/hooks/useAuth';
import useShopify from '@/hooks/useShopify';
import IProfile from '@/types/IProfile';
import Search from '@/components/Search';
import Sort from '@/components/Sort';
import profilesAPI from '@/api/profiles';
import MESSAGES from '@/constants/messages-en';
import Spinner from '@/components/Icons/Spinner';
import classNames from '@/helpers/classNames';
import Pagination from '@/components/Pagination';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import ReorderMultistepPage from './OrderV2Page/ReorderExperience/ReorderMultistepPage';
import NumberHelper from '@/helpers/number';
import TaptCustomDiscount from '@/helpers/taptCustomDiscount';

const tabs = [
  {
    name: 'Order More',
    value: 'order-more',
    current: true,
  },
  {
    name: 'Replacement Cards',
    value: 'replacement-cards',
    current: false,
  },
];

export default function ShopPage(): JSX.Element {
  const { orgID, subscriptionType } = useAuth();
  const { taptCustom, taptReplacement, checkout, proceedToCheckout } =
    useShopify();
  const [tab, setTab] = useState<string>(tabs[0].value);

  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [data, setData] = useState<IProfile[]>();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [sort, setSort] = useState('date');
  const [order, setOrder] = useState('desc');
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isAddDetails, setIsAddDetails] = useState<boolean>(false);
  const [units, setUnits] = useState<string>('1');
  const [totalProfiles, setTotalProfiles] = useState<number>(0);

  const hasPremiumPlan = subscriptionType === 'premium';
  const taptCustomBasePrice = taptCustom
    ? Number(taptCustom.variants[0].priceV2.amount)
    : 84.95;

  const taptReplacementBasePrice = taptReplacement
    ? Number(taptReplacement.variants[0].priceV2.amount)
    : 49.95;

  const generateProfileUrl = (profile_hash: string) =>
    `${window.location.origin}/view-profile/${profile_hash}`;

  const listAllProfiles = useCallback(
    async (
      newPage: number = page,
      newPageSize: number = pageSize,
      newSort: string = sort,
      newOrder: string = order,
      newSearch: string = search,
      initial = false,
    ) => {
      const res = await profilesAPI.listProfiles({
        orgID,
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        order: newOrder,
        search: newSearch,
      });

      if (!initial) {
        setData(res.data?.data);
        setPage(newPage);
        setPageSize(newPageSize);
        setSort(newSort);
        setOrder(newOrder);
      }

      if (initial) {
        setTotalProfiles(res.data?.paging.total_entries);
      }

      return res.data;
    },
    [order, orgID, page, pageSize, search, sort],
  );

  function handleCheckItem(profile: IProfile) {
    let arr = checkedItems;
    if (checkedItems.includes(profile.profile_hash)) {
      arr = arr.filter((e) => e !== profile.profile_hash);
    } else {
      arr = [...checkedItems, profile.profile_hash];
    }
    setCheckedItems(arr);
  }

  const getDiscount = (cardsCount: number) => {
    if (hasPremiumPlan)
      return TaptCustomDiscount.getPremiumDiscountAmountPerItem();

    return TaptCustomDiscount.getDiscountAmountPerItem(
      totalProfiles + cardsCount,
    );
  };

  function onCheckout(): void {
    if (!taptReplacement) return;

    if (checkedItems.length === 0) return;

    proceedToCheckout(
      taptReplacement.variants[0].id,
      checkedItems.length,
      checkedItems.map((profile_hash, index) => ({
        key: `_cardProfile_${index + 1}`,
        value: generateProfileUrl(profile_hash),
      })),
    );
  }

  useEffect(() => {
    if (initialLoading && orgID) {
      listAllProfiles(page, pageSize, sort, order, search, true);
    }
    setInitialLoading(false);
  }, [initialLoading, orgID]);

  return (
    <Layout pageName={isAddDetails ? 'Reorder ' : 'Shop'}>
      <Transition show={!isAddDetails}>
        <Tab.Group
          onChange={(index: number) => {
            setTab(tabs[index].value);
          }}
        >
          <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full md:border-b border-gray-200">
            <div className="pt-4 md:pt-0 w-full md:w-auto">
              <TabList tabs={tabs} value={tab} />
            </div>
          </div>
          <Tab.Panels>
            <Tab.Panel className="outline-none">
              <div className="mt-10">
                <InfoPanelContainer
                  title={
                    <div>
                      <p className="text-2xl font-medium leading-6 text-gray-900 mb-2">
                        Tapt Custom
                      </p>
                    </div>
                  }
                  information={
                    <>
                      Order more tapt Custom cards using a previous proof or a
                      new design.
                      <br />
                      <br />
                    </>
                  }
                  footerContent={() => (
                    <div className="flex justify-start items-center w-full ">
                      <p className="flex w-full text-white font-poppins font-medium text-xl leading-6">
                        Upgrade to premium and only pay $40.00
                      </p>
                      <div>
                        <Button
                          iconPos="left"
                          icon={<SparklesIcon />}
                          buttonText="Discount this way!"
                          size={BUTTON_SIZE.BASE}
                          kind={BUTTON_KIND.WHITE}
                          onClick={() => {
                            window.location.replace('/premium-coming-soon');
                          }}
                        />
                      </div>
                    </div>
                  )}
                  footerContentClassName="text-right space-x-3 flex p-6 bg-brand-500 rounded-b-md"
                  border="shadow sm:rounded-md border border-gray-100 border-b-0"
                >
                  <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between sha">
                    <div className="min-w-0">
                      {getDiscount(Number(units)) ? (
                        <div>
                          <span className="text-brand-500 font-semibold text-2xl mr-2">
                            Now $
                            {taptCustomBasePrice - getDiscount(Number(units))}
                          </span>
                          <span className="line-through text-base">
                            ${taptCustomBasePrice}
                          </span>
                        </div>
                      ) : (
                        <span className="text-brand-500 font-semibold text-2xl mr-2">
                          ${taptCustomBasePrice}
                        </span>
                      )}
                      <p className="text-sm pt-2 pr-10">
                        A discount will be applied depending on the number of
                        cards being ordered and the number of profiles currently
                        on this account.
                      </p>
                      <p className="text-sm">
                        See our pricing structure{' '}
                        <a
                          href="https://tapt.io/collections/all/products/tapt-custom"
                          target="_blank"
                          rel="noreferrer"
                          className="text-sm"
                        >
                          here
                        </a>
                        .
                      </p>
                    </div>
                    <div className="inline-flex flex-col lg:flex-row lg:space-x-6 items-start lg:items-center mt-5 sm:mt-0">
                      <div className="flex flex-row">
                        {/* <div className="inline-flex flex-row w-20 mr-2">
                          <Input
                            label=""
                            value={units}
                            type="number"
                            onChange={setUnits}
                            min="1"
                            max="99999"
                          />
                        </div>
                        <div className="inline-flex flex-row">
                          <Button
                            buttonText="Order"
                            onClick={() => {
                              setIsAddDetails(true);
                            }}
                          />
                        </div> */}
                        <div className="flex flex-row justify-evenly w-full gap-3">
                          <div className="flex flex-row justify-evenly items-center border-2 border-gray-100 rounded-lg px-6 py-3">
                            <div
                              className="flex items-center cursor-pointer mr-3"
                              onClick={() => {
                                if (Number(units) !== 1)
                                  setUnits((prev) => String(Number(prev) - 1));
                              }}
                            >
                              <MinusIcon
                                className="h-5 w-5 text-gray-700"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="text-sm mr-3">
                              {NumberHelper.toLeadingZero(String(units))}
                            </div>
                            <div
                              className="flex items-center cursor-pointer"
                              onClick={() =>
                                setUnits((prev) => String(Number(prev) + 1))
                              }
                            >
                              <PlusIcon
                                className="h-5 w-5 text-gray-700"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                          <Button
                            buttonText="Order"
                            size={BUTTON_SIZE.LARGE}
                            kind={BUTTON_KIND.PRIMARY}
                            onClick={() => setIsAddDetails(true)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </InfoPanelContainer>

                <InfoPanelDivider />

                <InfoPanelContainer
                  title={
                    <div>
                      <p className="text-2xl font-medium leading-6 text-gray-900 mb-2">
                        Other products
                      </p>
                    </div>
                  }
                  information={
                    <>
                      Your personal social links. These will be displayed as
                      icons on your profile.
                      <br />
                      <br />
                    </>
                  }
                >
                  <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between">
                    <div className="min-w-0 text-sm text-gray-900 font-medium font-poppins">
                      For tapt black, Tapt mobile and stickers click the link to
                      <br />
                      order
                    </div>
                    <div className="inline-flex flex-col lg:flex-row lg:space-x-6 items-start lg:items-center mt-5 sm:mt-0">
                      <Button
                        buttonText="Go to website"
                        href="https://tapt.io/collections/all"
                        external
                      />
                    </div>
                  </div>
                </InfoPanelContainer>
              </div>
            </Tab.Panel>
            <Tab.Panel className="outline-none">
              <div className="py-8">
                <div className="pb-8 flex flex-col space-y-3 items-start sm:flex-row sm:space-y-0 sm:items-center sm:justify-between">
                  <div className="text-sm leading-5" />
                  {orgID && orgID !== -1 && (
                    <div className="flex flex-shrink-0 space-x-3">
                      <Search
                        id={`ProfileList-${page}-${pageSize}-${sort}-${order}-active-${search}`}
                        search={search}
                        setSearch={setSearch}
                        fetchQuery={(searchQuery) =>
                          listAllProfiles(
                            page,
                            pageSize,
                            sort,
                            order,
                            searchQuery,
                          )
                        }
                      />
                      <Sort
                        id={`ProfileList-${page}-${pageSize}-${sort}-${order}-active-${search}`}
                        options={[
                          {
                            sort: 'date',
                            order: 'desc',
                            label: 'Date created (Newest first)',
                          },
                          {
                            sort: 'date',
                            order: 'asc',
                            label: 'Date created (Oldest first)',
                          },
                          {
                            sort: 'first_name',
                            order: 'asc',
                            label: 'Name (A-Z)',
                          },
                          {
                            sort: 'first_name',
                            order: 'desc',
                            label: 'Name (Z-A)',
                          },
                        ]}
                        sort={sort}
                        setSort={setSort}
                        order={order}
                        setOrder={setOrder}
                        fetchQuery={(sortQuery, orderQuery) =>
                          listAllProfiles(
                            page,
                            pageSize,
                            sortQuery,
                            orderQuery,
                            search,
                          )
                        }
                      />
                    </div>
                  )}
                </div>
                {data && data.length > 0 ? (
                  <div className="flex flex-col">
                    <div className="relative -mx-4 sm:-mx-6 md:mx-0 lg:-mx-8">
                      <div className="block w-full lg:px-8">
                        <div className="relative shadow-sm border border-gray-200 sm:rounded-md min-h-8 overflow-hidden">
                          <div className="lg:hidden flex items-center px-4 py-2 sm:px-6 bg-white border-b border-gray-200">
                            <InputCheckbox
                              id="select-all"
                              label=""
                              value={selectAll}
                              onChange={(value) => {
                                setSelectAll(value);
                                if (value) {
                                  const arr = data.map(
                                    ({ profile_hash }) => profile_hash,
                                  );
                                  setCheckedItems(arr);
                                } else {
                                  setCheckedItems([]);
                                }
                              }}
                            />
                          </div>
                          <ul
                            className={classNames(
                              isLoading ? 'opacity-40' : '',
                              'divide-y divide-gray-200',
                            )}
                          >
                            <li
                              key="ProfileListAdminHeader"
                              className="hidden lg:block"
                            >
                              <div className="bg-gray-50">
                                <div className="flex items-center px-4 py-2 sm:px-6">
                                  <div className="min-w-0 flex-1 flex items-start md:items-center">
                                    <div className="w-7">
                                      <InputCheckbox
                                        id="select-all"
                                        label=""
                                        value={selectAll}
                                        onChange={(value) => {
                                          setSelectAll(value);
                                          if (value) {
                                            const arr = data.map(
                                              ({ profile_hash }) =>
                                                profile_hash,
                                            );
                                            setCheckedItems(arr);
                                          } else {
                                            setCheckedItems([]);
                                          }
                                        }}
                                      />
                                    </div>
                                    <div className="min-w-0 flex-1 md:grid md:grid-cols-3 md:gap-4 xl:grid-cols-4">
                                      <p className="text-sm font-medium text-gray-900">
                                        PROFILE NAME
                                      </p>
                                      <p className="mt-2 md:mt-0 hidden xl:block text-sm font-medium text-gray-900">
                                        TITLE &amp; EMAIL
                                      </p>
                                      <p className="mt-2 md:mt-0 xl:col-span-2 text-sm font-medium text-gray-900" />
                                    </div>
                                    <div className="w-24" />
                                  </div>
                                </div>
                              </div>
                            </li>
                            {data?.map((item: IProfile) => (
                              <li key={item.id}>
                                <div className="block bg-white hover:bg-gray-50">
                                  <div className="flex items-center px-4 py-4 sm:px-6">
                                    <div className="self-start md:self-center">
                                      <InputCheckbox
                                        id={`Checkbox-${item.id}`}
                                        label=""
                                        value={checkedItems.includes(
                                          item.profile_hash,
                                        )}
                                        onChange={() => handleCheckItem(item)}
                                      />
                                    </div>
                                    {/* ---------------------------------------FULL SCREEN ---------------------------------------*/}
                                    <div className="min-w-0 flex-1 flex items-start md:items-center">
                                      <div className="min-w-0 flex-1 lg:grid lg:grid-cols-3 lg:gap-4 xl:grid-cols-4">
                                        <div className="hidden lg:block col-span-4 md:col-span-1 relative">
                                          <div className="flex flex-col md:flex-row relative items-start md:items-center">
                                            <div className="flex-shrink-0">
                                              {item.photo?.thumb_url ? (
                                                <img
                                                  className="h-10 w-10 rounded-full"
                                                  src={item.photo?.thumb_url}
                                                  alt=""
                                                />
                                              ) : (
                                                <div className="hidden lg:block h-10 w-10 rounded-full bg-gray-200 overflow-hidden text-center">
                                                  <UserIcon className="h-10 w-10 mt-1 rounded-full text-gray-600" />
                                                </div>
                                              )}
                                            </div>
                                            <div className="min-w-0 mt-0 ml-12 md:mt-0 md:ml-4">
                                              <p className="text-sm font-medium text-gray-900 ml-4 truncate">
                                                {item.first_name}{' '}
                                                {item.last_name}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="md:mt-0 lg:hidden col-span-4 xl:col-span-1 xl:flex flex-col">
                                          <p className="hidden lg:block text-sm text-gray-900 truncate">
                                            {item.job_title}
                                          </p>
                                          <p className="hidden lg:block text-sm text-gray-500 truncate">
                                            {item.email}
                                          </p>
                                        </div>

                                        {/* ---------------------------------------MOBILE SCREEN ---------------------------------------*/}
                                        <div className="lg:hidden flex flex-col md:flex-row relative items-start">
                                          <div className="mt-0 flex space-x-1 md:mt-0 md:ml-4">
                                            <div className="flex-shrink-0">
                                              {item.photo?.thumb_url ? (
                                                <img
                                                  className="h-10 w-10 rounded-full"
                                                  src={item.photo?.thumb_url}
                                                  alt=""
                                                />
                                              ) : (
                                                <div className="h-10 w-10 rounded-full bg-gray-200 overflow-hidden text-center">
                                                  <UserIcon className="h-10 w-10 mt-1 rounded-full text-gray-600" />
                                                </div>
                                              )}
                                            </div>
                                            <div className="min-w-0 mt-0 ml-12 md:mt-0 md:ml-4">
                                              <p className="text-sm font-medium text-gray-900 ml-4 truncate">
                                                {item.first_name}{' '}
                                                {item.last_name}
                                              </p>
                                              <p className="text-sm text-gray-900 truncate ml-4">
                                                {item.job_title}
                                              </p>
                                            </div>
                                          </div>
                                        </div>

                                        {/* ---------------------------------------BUTTONS ---------------------------------------*/}
                                        <div className="mt-2 lg:mt-0 col-span-2">
                                          <div className="flex flex-col h-full lg:flex-row lg:space-x-4 space-y-1 lg:space-y-0 lg:items-center xl:justify-end">
                                            <Button
                                              buttonText="Order replacement"
                                              onClick={() => {
                                                if (taptReplacement) {
                                                  proceedToCheckout(
                                                    taptReplacement.variants[0]
                                                      .id,
                                                    1,
                                                    [
                                                      {
                                                        key: `_cardProfile_1`,
                                                        value:
                                                          generateProfileUrl(
                                                            item.profile_hash,
                                                          ),
                                                      },
                                                    ],
                                                  );
                                                }
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                          {isLoading && (
                            <div className="absolute left-1/2 top-1/2 text-gray-500">
                              <Spinner className="text-brand-500" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="py-32">
                    <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
                      {MESSAGES.profile.list.empty.heading}
                    </h3>
                    <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
                      {MESSAGES.profile.list.empty.description}
                    </p>
                  </div>
                )}
              </div>
              {orgID && orgID !== -1 && (
                <Pagination
                  id={`ProfileList-${page}-${pageSize}-${sort}-${order}-active-${search}`}
                  page={page}
                  setPage={setPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  fetchQuery={(pageQuery, pageSizeQuery) => {
                    return listAllProfiles(
                      pageQuery,
                      pageSizeQuery,
                      sort,
                      order,
                      search,
                    );
                  }}
                  setIsLoading={setIsLoading}
                />
              )}

              <div className="block" aria-hidden="true">
                <div className="py-8" />
              </div>
              <InfoPanelFooter
                className={checkedItems?.length > 1 ? '' : '-bottom-28'}
              >
                <p className="text-sm leading-5 text-gray-500 mb-1 sm:hidden">
                  {`${checkedItems.length}x replacement`} $
                  {(checkedItems.length * taptReplacementBasePrice).toFixed(2)}
                </p>
                <div className="flex items-center flex-nowrap space-x-4">
                  <Button
                    onClick={onCheckout}
                    buttonText="Proceed to checkout"
                  />
                  <p className="text-sm leading-5 text-gray-500 hidden sm:block">
                    {`${checkedItems.length}x replacement`} $
                    {(checkedItems.length * taptReplacementBasePrice).toFixed(
                      2,
                    )}
                  </p>
                </div>
              </InfoPanelFooter>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </Transition>
      <ReorderMultistepPage
        totalProfiles={totalProfiles}
        isOpen={isAddDetails}
        setIsOpen={setIsAddDetails}
        orgID={orgID}
        units={units}
        setUnits={setUnits}
      />
    </Layout>
  );
}
