import { Switch } from '@headlessui/react';
import { useState } from 'react';
import classNames from '@/helpers/classNames';
import ModalNoSuccess from './ModalNoSuccess';
import { BUTTON_KIND } from './Button';

interface IToggle {
  enabled: boolean;
  label: string;
  description?: string;
  setter: (enabled: boolean) => void;
  disabled?: boolean;
  message?: string;
  modalTitle: string;
  modalDescription: string;
}

export default function ToggleFieldWithInfo({
  label,
  setter,
  enabled,
  description,
  disabled,
  message,
  modalTitle,
  modalDescription,
}: IToggle): JSX.Element {
  const handleChange = () => {
    setter(!enabled);
  };
  const [isDisplayOpen, setIsDisplayOpen] = useState<boolean>(false);

  return (
    <Switch.Group
      as="div"
      className="flex items-center space-x-2 justify-between"
    >
      <span className="flex-grow flex flex-col">
        <div className="flex">
          <Switch.Label
            as="span"
            className="text-sm leading-5 font-medium text-gray-900 mt-0.5"
            passive
          >
            {label}
          </Switch.Label>
          <ModalNoSuccess
            buttonClassName="mt-0 ml-2"
            isOpen={isDisplayOpen}
            setIsOpen={setIsDisplayOpen}
            buttonTitle="!"
            buttonKind={BUTTON_KIND.CIRCULAR}
            buttonSize="circle"
            dialogTitle={modalTitle}
            dialogDescription={modalDescription}
          />
        </div>
        {description && (
          <Switch.Description
            as="span"
            className="text-sm leading-5 text-gray-500"
          >
            {description}
          </Switch.Description>
        )}
      </span>
      <div className="flex items-center justify-end space-x-2">
        {message && <span className="text-sm text-gray-500">{message}</span>}
        <Switch
          checked={enabled}
          disabled={disabled}
          onChange={() => handleChange()}
          className={classNames(
            enabled && !disabled ? 'bg-brand-500' : '',
            enabled && disabled ? 'bg-brand-200' : 'bg-gray-200',
            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500',
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
            )}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
}
