import React, { useState, useEffect, useCallback } from 'react';
import { ArrowNarrowRightIcon, EyeIcon } from '@heroicons/react/solid';
import { Tab } from '@headlessui/react';
import { useHistory, useLocation } from 'react-router-dom';
import { cx } from '@emotion/css';
import ErrorAlert from '@/components/ErrorAlert';
import Layout from '@/components/Layout';
import TabList from '@/components/TabList';
import InfoPanelContainer from '@/components/InfoPanelContainer';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import Input from '@/components/Input';
import ToggleField from '@/components/ToggleField';
import Modal from '@/components/Modal';
import useAuth from '@/hooks/useAuth';
import useAppState from '@/hooks/useAppState';
import accountsAPI from '@/api/accounts';
import organisationsAPI from '@/api/organisations';
import ColorPicker from '@/components/ColorPicker';
import UploadPhoto from '@/components/UploadPhoto';
import SuccessAlert from '@/components/SuccessAlert';
import { IOrganisationUser } from '@/types/IOrganisation';
import IFile from '@/types/IFile';
import OrganisationSettings from '@/containers/OrganisationSettings';
import MESSAGES from '@/constants/messages-en';
import Button, { BUTTON_KIND } from '@/components/Button';
import filesAPI from '@/api/files';
import FileUploadButtonComponent from '@/components/FileUploadButton';
import ToggleFieldWithInfo from '@/components/ToggleFieldWithInfo';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import profilesAPI from '@/api/profiles';
import IProfile from '@/types/IProfile';
import PremiumPlanTab from '@/components/SettingsPage/PremiumPlanTab';
import saasAPI from '@/api/saas';
import Features from '@/types/Features';

const tabs = [
  {
    name: 'General',
    value: 'general',
    current: true,
  },
  {
    name: 'Organisation',
    value: 'organisation',
    current: false,
  },
  {
    name: 'Profile',
    value: 'profile',
    current: false,
  },
  {
    name: 'Contact Exchange',
    value: 'contact-exchange',
    current: false,
  },
  {
    name: 'Profile & Editing',
    value: 'profile-editing',
    current: false,
  },
  {
    name: 'Manage Plan',
    value: 'manage-plan',
    current: false,
  },
];

interface IAccountSettingsPage {
  location: {
    state: {
      [key: string]: string;
    };
  };
}

export default function AccountSettingsPage(
  props: IAccountSettingsPage,
): JSX.Element {
  const history = useHistory();
  const { hash } = useLocation();
  const { user, orgID, subscriptionType, getCurrentUser, userRole, hasFeature } = useAuth();
  const { settingsTab, selectSettingsTab } = useAppState();
  const { location } = props;
  const [tabs, setTabs] = useState([
    {
      name: 'General',
      value: 'general',
      current: true,
    },
    {
      name: 'Organisation',
      value: 'organisation',
      current: false,
    },
    {
      name: 'Profile',
      value: 'profile',
      current: false,
    },
    {
      name: 'Contact Exchange',
      value: 'contact-exchange',
      current: false,
    },
    {
      name: 'Profile & Editing',
      value: 'profile-editing',
      current: false,
    },
  ]);
  const [selectedIndex, setSelectedIndex] = useState<number>(
    tabs[settingsTab] ? settingsTab : 0,
  );
  const [tab, setTab] = useState<string>(
    tabs[settingsTab] ? tabs[settingsTab].value : tabs[0].value,
  );
  const [firstName, setFirstName] = useState<string>(user?.first_name || '');
  const [lastName, setLastName] = useState<string>(user?.last_name || '');
  const [email, setEmail] = useState<string>(user?.email || '');
  const [organisationName, setOrganisationName] = useState<string>('');
  const [logoFile, setLogoFile] = useState<IFile>();
  const [backgroundColour, setBackgroundColour] = useState<string>('#0A0A0A');
  const [textColour, setTextColour] = useState<string>('#FFFFFF');
  const [buttonBackgroundColour, setButtonBackgroundColour] =
    useState<string>('#873CFF');
  const [buttonTextColour, setButtonTextColour] = useState<string>('#FFFFFF');
  const [fullNameToggle, setFullNameToggle] = useState<boolean>(true);
  const [emailToggle, setEmailToggle] = useState<boolean>(true);
  const [mobileNumberToggle, setMobileNumberToggle] = useState<boolean>(true);
  const [jobTitleToggle, setJobTitleToggle] = useState<boolean>(false);
  const [companyNameToggle, setCompanyNameToggle] = useState<boolean>(false);
  const [consentStatementToggle, setConsentStatementToggle] =
    useState<boolean>(false);
  const [smsToggle, setSmsToggle] = useState<boolean>(true);
  const [consentStatement, setConsentStatement] = useState<IFile>();
  const [consentSizeError, setConsentSizeError] = useState<boolean>(false);
  const [consentFileError, setConsentFileError] = useState<boolean>(false);
  const [consentLoading, setConsentLoading] = useState<boolean>(false);
  const [organisationError, setOrganisationError] = useState<boolean>(false);
  const [resetPasswordIsOpen, setResetPasswordIsOpen] =
    useState<boolean>(false);
  const [inviteUserEmail, setInviteUserEmail] = useState<string>('');
  const [inviteUserRole, setInviteUserRole] = useState<string>('org_admin');
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [orgUsers, setOrgUsers] = useState<IOrganisationUser[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordLoading, setPasswordLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>('');
  const [passwordSuccess, setPasswordSuccess] = useState<string>('');
  const [isCardholderEditingEnabled, setIsCardholderEditingEnabled] =
    useState<boolean>(true);
  const [isCardholderEditingEmailOpen, setIsCardholderEditingEmailOpen] =
    useState<boolean>(false);
  const [isPremium, setIsPremium] = useState<boolean>();
  const [cbInstance, setCbInstance] = useState<any>();
  const [cbPortal, setCbPortal] = useState<any>();

  const handleGeneralSettingsSave = () => {
    setSuccess('');
    setLoading(true);
    accountsAPI
      .updateCurrentUser({
        first_name: firstName,
        last_name: lastName,
      })
      .then(() => setSuccess(MESSAGES.account.settings.success))
      .then(() => getCurrentUser())
      .finally(() => setLoading(false));
  };

  const handleOrganisationSettingsSave = () => {
    setSuccess('');
    setOrganisationError(false);
    setLoading(true);

    if (orgID) {
      organisationsAPI
        .updateOrganisationSettings(orgID, {
          organisation_setting: {
            bg_color: backgroundColour,
            text_color: textColour,
            button_bg_color: buttonBackgroundColour,
            button_text_color: buttonTextColour,
            contact_exchange: {
              company_name: companyNameToggle,
              email: emailToggle,
              job_title: jobTitleToggle,
              mobile_number: mobileNumberToggle,
              name: fullNameToggle,
              company_consent: consentStatementToggle,
            },
            company_logo_file_id: logoFile ? logoFile.id : null,
            company_consent_file: consentStatement,
            company_consent_file_id: consentStatement
              ? consentStatement.id
              : null,
            is_contact_exchange_sms_enabled: smsToggle,
            is_cardholder_editing_enabled: isCardholderEditingEnabled,
          },
        })
        .then(() => setSuccess(MESSAGES.organisation.settings))
        .catch(() => setOrganisationError(true))
        .finally(() => setLoading(false));
    }
  };

  const handleProfileEditingAccessSave = (emailProfileEditing?: boolean) => {
    setSuccess('');
    setOrganisationError(false);
    setLoading(true);

    if (orgID) {
      organisationsAPI
        .updateOrganisationSettings(orgID, {
          email_profile_editing: emailProfileEditing,
          organisation_setting: {
            is_cardholder_editing_enabled: isCardholderEditingEnabled,
          },
        })
        .then(() => setSuccess(MESSAGES.organisation.settings))
        .catch(() => setOrganisationError(true))
        .finally(() => setLoading(false));
    }
  };

  const handleEmailModal = () => {
    if (isCardholderEditingEnabled) {
      setIsCardholderEditingEmailOpen(true);
    } else {
      handleProfileEditingAccessSave(false);
    }
  };

  const handleOrganisationSettingsPreview = () => {
    history.push(`/settings/preview`, {
      bg_color: backgroundColour,
      text_color: textColour,
      button_bg_color: buttonBackgroundColour,
      button_text_color: buttonTextColour,
      company_logo_file_id: logoFile ? logoFile.id : null,
      company_consent_file_id: consentStatement ? consentStatement.id : null,
    });
  };

  const handleResetPassword = () => {
    setPasswordSuccess('');
    setPasswordError(false);
    setPasswordLoading(true);
    accountsAPI
      .forgotPassword({ forgot: { email } })
      .then(() => setPasswordSuccess(MESSAGES.account.reset.success))
      .catch(() => setPasswordError(true))
      .then(() => setPasswordLoading(false))
      .finally(() => setResetPasswordIsOpen(false));
  };

  const showOrganisationSettings = useCallback(() => {
    if (orgID) {
      setLoading(true);
      organisationsAPI
        .showOrganisationSettings(orgID)
        .then((res) => {
          const data = res?.data?.data;
          if (data.bg_color) setBackgroundColour(data.bg_color);
          if (data.text_color) setTextColour(data.text_color);
          if (data.button_bg_color)
            setButtonBackgroundColour(data.button_bg_color);
          if (data.button_text_color)
            setButtonTextColour(data.button_text_color);
          if (data?.contact_exchange?.name)
            setFullNameToggle(data.contact_exchange.name);
          if (data?.contact_exchange?.company_name)
            setCompanyNameToggle(data.contact_exchange.company_name);
          if (data?.contact_exchange?.email)
            setEmailToggle(data.contact_exchange.email);
          if (data?.contact_exchange?.job_title)
            setJobTitleToggle(data.contact_exchange.job_title);
          if (data?.contact_exchange?.mobile_number)
            setMobileNumberToggle(data.contact_exchange.mobile_number);
          if (data?.contact_exchange?.company_consent)
            setConsentStatementToggle(data.contact_exchange.company_consent);
          if (data?.company_consent_file)
            setConsentStatement(data.company_consent_file);
          if (data.company_logo) setLogoFile(data.company_logo);
          setSmsToggle(data.is_contact_exchange_sms_enabled);
          setIsCardholderEditingEnabled(data.is_cardholder_editing_enabled);
        })
        .finally(() => {
          setLoading(false);
          if (location && location.state) {
            const { fromPage } = location.state;
            if (fromPage === 'preview') {
              if (location.state.bg_color)
                setBackgroundColour(location.state.bg_color);
              if (location.state.text_color)
                setTextColour(location.state.text_color);
              if (location.state.button_bg_color)
                setButtonBackgroundColour(location.state.button_bg_color);
              if (location.state.button_text_color)
                setButtonTextColour(location.state.button_text_color);
              if (location.state.company_logo_file_id) {
                filesAPI
                  .showFile(orgID, Number(location.state.company_logo_file_id))
                  .then((res) => setLogoFile(res.data.data))
                  .finally(() => {});
              }
            }
            if (location.state.company_consent_file_id) {
              filesAPI
                .showFile(orgID, Number(location.state.company_consent_file_id))
                .then((res) => setConsentStatement(res.data.data))
                .finally(() => {});
            }
          }
        });
    }
  }, [orgID, location]);

  useEffect(() => {
    showOrganisationSettings();
  }, [showOrganisationSettings]);

  const loadChargebee = () => {
    if (window && document) {
      const script = document.createElement('script');
      const body = document.getElementsByTagName('body')[0];
      script.src = 'https://js.chargebee.com/v2/chargebee.js';
      body.appendChild(script);
      script.addEventListener('load', () => {
        // Initialize chargebee.js
        window.Chargebee?.init({
          site: process.env.REACT_APP_CHARGEBEE_SITE || '',
          publishableKey: process.env.REACT_APP_CHARGEBEE_KEY || '',
          isItemsModel: true,
        });

        setCbInstance(window.Chargebee.getInstance());
      });
    }
  };

  useEffect(() => {
    if (isPremium === undefined) {
      const isSaaSEnabled = hasFeature(Features.SaaS);
      setIsPremium(isSaaSEnabled && subscriptionType === 'premium');
    }

    if (isPremium === true) {
      loadChargebee();

      const newTabs = [...tabs];
      newTabs.push({
        name: 'Manage Plan',
        value: 'manage-plan',
        current: false,
      });
      setTabs(newTabs);

      console.log(hash);
      if (hash === '#plan') {
        setSelectedIndex(newTabs.length - 1);
      }
    }
  }, [isPremium]);

  useEffect(() => {
    if (cbInstance && orgID) {
      cbInstance.setPortalSession(() => {
        return saasAPI
          .getPortalSession(orgID)
          .then((response) => response.data);
      });
      cbInstance.setPortalCallbacks({
        loaded: () => {
          console.log('loaded');
        },
        close: () => {
          console.log('close');
        },
        visit: (sectionType: string) => {
          console.log('visit', sectionType);
        },
        subscriptionChanged: (data: { subscription: { id: string } }) => {
          console.log('subscriptionChanged', data);
        },
        subscriptionCustomFieldsChanged: (data: {
          subscription: { id: string };
        }) => {
          console.log('subscriptionCustomFieldsChanged', data);
        },
        subscriptionCancelled: (data: { subscription: { id: string } }) => {
          console.log('subscriptionCancelled', data);
        },
      });
      setCbPortal(cbInstance.createChargebeePortal());
    }
  }, [cbInstance]);

  return (
    <Layout pageName="Settings">
      {userRole === 'org_admin' && (
        <Tab.Group
          defaultIndex={settingsTab}
          selectedIndex={selectedIndex}
          onChange={(index: number) => {
            setSelectedIndex(index);
            setTab(tabs[index].value);
            selectSettingsTab(index);
            setSuccess('');
            setOrganisationError(false);
            setPasswordError(false);
            setLoading(false);
          }}
        >
          <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full md:border-b border-gray-200">
            <div className="pt-4 md:pt-0 w-full md:w-auto">
              <TabList tabs={tabs} value={tab} />
            </div>
          </div>
          <Tab.Panels>
            {success !== '' && <SuccessAlert message={success} />}
            <Tab.Panel className="outline-none">
              <Modal
                isOpen={resetPasswordIsOpen}
                setIsOpen={setResetPasswordIsOpen}
                dialogTitle="Reset password"
                dialogDescription="This will send an email with instructions to reset your password."
                successButtonText="Reset password"
                onSuccess={() => handleResetPassword()}
                isLoading={passwordLoading}
              />
              <div className="mt-10">
                <InfoPanelContainer
                  title="Account settings"
                  footerContent={() => (
                    <Button
                      buttonText="Save"
                      loading={loading}
                      onClick={handleGeneralSettingsSave}
                    />
                  )}
                >
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <Input
                        label="First name"
                        type="text"
                        value={firstName}
                        onChange={setFirstName}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <Input
                        label="Last Name"
                        type="text"
                        value={lastName}
                        onChange={setLastName}
                      />
                    </div>
                    <div className="mt-3 col-span-8 sm:col-span-4">
                      <Input
                        label="Email Address"
                        type="text"
                        value={email}
                        onChange={setEmail}
                        disabled
                      />
                    </div>
                  </div>
                </InfoPanelContainer>
                <InfoPanelDivider />
                <InfoPanelContainer title="Account security">
                  {passwordSuccess !== '' && (
                    <SuccessAlert message={passwordSuccess} />
                  )}
                  {passwordError && (
                    <ErrorAlert message="Something went wrong. Please try again later" />
                  )}
                  <button
                    type="button"
                    className={cx(
                      'inline-flex items-center px-4 py-2',
                      'border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50',
                      'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500',
                    )}
                    onClick={() => setResetPasswordIsOpen(true)}
                  >
                    Reset Password
                  </button>
                </InfoPanelContainer>
              </div>
            </Tab.Panel>
            <Tab.Panel className="outline-none">
              {orgID && (
                <OrganisationSettings
                  orgID={orgID}
                  organisationName={organisationName}
                  setOrganisationName={setOrganisationName}
                  inviteUserEmail={inviteUserEmail}
                  setInviteUserEmail={setInviteUserEmail}
                  inviteUserRole={inviteUserRole}
                  setInviteUserRole={setInviteUserRole}
                  orgUsers={orgUsers}
                  setOrgUsers={setOrgUsers}
                />
              )}
            </Tab.Panel>
            <Tab.Panel className="outline-none">
              <div className="mt-10">
                <InfoPanelContainer
                  information="Customise the look of the profile pages of your cardholders. Changes will apply to all profile pages belonging to your organisation."
                  title="Profile page appearance"
                  footerContent={() => (
                    <>
                      <Button
                        buttonText="Save"
                        loading={loading}
                        onClick={handleOrganisationSettingsSave}
                      />
                      <Button
                        buttonText="Preview"
                        kind={BUTTON_KIND.WHITE}
                        icon={<EyeIcon />}
                        onClick={handleOrganisationSettingsPreview}
                      />
                    </>
                  )}
                >
                  {organisationError && (
                    <ErrorAlert message="Something went wrong. Please try again later" />
                  )}
                  <h4 className="text-xl leading-7 font-medium text-gray-900">
                    Cover image
                  </h4>
                  <p className="text-sm leading-5 text-gray-500">
                    Choose an image to display at the top of cardholder profile
                    pages.
                  </p>
                  <div className="flex items-center mt-4 mb-2">
                    {orgID && (
                      <UploadPhoto
                        title="Cover image"
                        orgID={orgID}
                        photo={logoFile}
                        setPhoto={setLogoFile}
                        size="large"
                        aspectRatio={16 / 11}
                        fileFormatMessage="Recommended dimensions 1024px x 704px"
                      />
                    )}
                  </div>
                  <h4 className="text-xl leading-7 font-medium text-gray-900">
                    Colours
                  </h4>
                  <p className="text-sm leading-5 text-gray-500">
                    Create a custom theme for cardholder profile pages. Maintain
                    good readability by ensuring there is sufficient contrast
                    between text and background colours.
                  </p>
                  <div className="grid grid-cols-8 gap-4">
                    <div className="col-span-6">
                      <div className="mt-4 text-left">
                        <p className="block mb-2 text-sm leading-5 font-medium text-gray-900">
                          Page background color
                        </p>
                        <ColorPicker
                          color={backgroundColour}
                          setColor={setBackgroundColour}
                        />
                      </div>
                    </div>
                    <div className="col-span-6">
                      <div className="mt-4 text-left">
                        <p className="block mb-2 text-sm leading-5 font-medium text-gray-900">
                          Text color
                        </p>
                        <ColorPicker
                          color={textColour}
                          setColor={setTextColour}
                        />
                      </div>
                    </div>
                    <div className="col-span-6">
                      <div className="mt-4 text-left">
                        <p className="block mb-2 text-sm leading-5 font-medium text-gray-900">
                          Button background color
                        </p>
                        <ColorPicker
                          color={buttonBackgroundColour}
                          setColor={setButtonBackgroundColour}
                        />
                      </div>
                    </div>
                    <div className="col-span-6">
                      <div className="mt-4 text-left">
                        <p className="block mb-2 text-sm leading-5 font-medium text-gray-900">
                          Button text color
                        </p>
                        <ColorPicker
                          color={buttonTextColour}
                          setColor={setButtonTextColour}
                        />
                      </div>
                    </div>
                  </div>
                </InfoPanelContainer>
              </div>
            </Tab.Panel>
            <Tab.Panel className="outline-none">
              <div className="mt-10">
                <InfoPanelContainer
                  title="Contact exchange form settings"
                  information={MESSAGES.contact.exchange.description}
                >
                  {organisationError && (
                    <ErrorAlert message="Something went wrong. Please try again later" />
                  )}
                  <div className="grid grid-cols-1 gap-5">
                    <ToggleField
                      label="Full name"
                      enabled={fullNameToggle}
                      setter={setFullNameToggle}
                      disabled
                      message="Mandatory field"
                    />
                    <ToggleField
                      label="Email"
                      enabled={emailToggle}
                      setter={setEmailToggle}
                    />
                    <ToggleField
                      label="Mobile number"
                      enabled={mobileNumberToggle}
                      setter={setMobileNumberToggle}
                    />
                    <ToggleField
                      label="Job title/Position"
                      enabled={jobTitleToggle}
                      setter={setJobTitleToggle}
                    />
                    <ToggleField
                      label="Company name"
                      enabled={companyNameToggle}
                      setter={setCompanyNameToggle}
                    />
                  </div>
                  <div className="grid grid-cols-1 gap-5 mt-5">
                    {!consentStatement && (
                      <ToggleFieldWithInfo
                        label="Consent Statement"
                        enabled={consentStatementToggle}
                        setter={setConsentStatementToggle}
                        disabled
                        modalTitle="Consent Statement"
                        modalDescription="If your company has data policies they would like future contacts to consent to, add the policy document here. We will ask contacts to approve the policies before they send their details back to you!"
                      />
                    )}
                    {consentStatement && (
                      <ToggleFieldWithInfo
                        label="Consent Statement"
                        enabled={consentStatementToggle}
                        setter={setConsentStatementToggle}
                        modalTitle="Consent Statement"
                        modalDescription="If your company has data policies they would like future contacts to consent to, add the policy document here. We will ask contacts to approve the policies before they send their details back to you!"
                      />
                    )}
                  </div>
                  <div className="flex mt-0 pt-0">
                    {orgID && (
                      <FileUploadButtonComponent
                        id="consent-statement-upload"
                        filename={consentStatement?.file?.file_name}
                        url={consentStatement?.original_url}
                        onFileSelected={(file) => {
                          setConsentLoading(true);
                          filesAPI
                            .createAndUploadFile(
                              orgID,
                              new File([file], file.name),
                            )

                            .then((res) => {
                              if (res?.data?.data.id) {
                                setConsentStatement(res.data.data);
                                setConsentStatementToggle(true);
                              }
                            })
                            .catch(() => setConsentFileError(true))
                            .finally(() => setConsentLoading(false));
                        }}
                        buttonTitle="Upload consent statement"
                        fileFormat=".pdf, .jpeg, .png"
                        fileFormatMessage="(.jpeg, .png or .pdf only)"
                        loading={consentLoading}
                        sizeError={consentSizeError}
                        fileError={consentFileError}
                      />
                    )}
                    {consentStatement?.file?.file_name && (
                      <button
                        type="button"
                        onClick={() => {
                          setConsentStatement(undefined);
                          setConsentStatementToggle(false);
                        }}
                        className="appearance-none ml-2 text-sm leading-5 text-brand-500"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </InfoPanelContainer>
              </div>
              <InfoPanelDivider />
              <div className="mb-8">
                <InfoPanelContainer
                  title="Send an SMS"
                  information={MESSAGES.contact.sms.description}
                >
                  {organisationError && (
                    <ErrorAlert message="Something went wrong. Please try again later" />
                  )}
                  <div className="grid grid-cols-1 gap-6">
                    <ToggleFieldWithInfo
                      label="Send an SMS"
                      enabled={smsToggle}
                      setter={setSmsToggle}
                      modalTitle="Why send an SMS?"
                      modalDescription="sms_content"
                    />
                  </div>
                  <InfoPanelFooter>
                    <div className="flex">
                      <Button
                        buttonText="Save"
                        onClick={() => handleOrganisationSettingsSave()}
                      />
                      <Button
                        buttonText="Discard changes"
                        className="ml-3"
                        kind={BUTTON_KIND.WHITE}
                        onClick={() => window.location.reload()}
                      />
                    </div>
                  </InfoPanelFooter>
                </InfoPanelContainer>
              </div>
            </Tab.Panel>
            <Tab.Panel className="outline-none">
              <div className="mt-10">
                <InfoPanelContainer
                  title="Employee editing access"
                  information="Grant employees access to edit their own profiles within the Tapt app"
                >
                  <ToggleField
                    label="Grant all employees profile editing access"
                    enabled={isCardholderEditingEnabled}
                    setter={setIsCardholderEditingEnabled}
                  />
                  <InfoPanelFooter>
                    <div className="flex">
                      <Button
                        buttonText="Save"
                        onClick={() => handleEmailModal()}
                      />
                      <Button
                        buttonText="Discard changes"
                        className="ml-3"
                        kind={BUTTON_KIND.WHITE}
                        onClick={() => window.location.reload()}
                      />
                    </div>
                  </InfoPanelFooter>
                </InfoPanelContainer>
                <Modal
                  isOpen={isCardholderEditingEmailOpen}
                  setIsOpen={setIsCardholderEditingEmailOpen}
                  dialogTitle="Do you want to notify the cardholders?"
                  dialogDescription="By clicking send email, these people will receive an email notification that they can edit their profiles on the Tapt App."
                  onSuccess={() => {
                    setIsCardholderEditingEmailOpen(false);
                    handleProfileEditingAccessSave(true);
                  }}
                  onCancel={() => {
                    setIsCardholderEditingEmailOpen(false);
                    handleProfileEditingAccessSave(false);
                  }}
                  successButtonText="Send Email"
                  cancelButtonText="Don't send"
                  buttonKind={BUTTON_KIND.CIRCULAR}
                />
              </div>
            </Tab.Panel>
            {isPremium && (
              <Tab.Panel className="outline-none">
                <div className="mt-10">
                  {cbPortal && <PremiumPlanTab cbPortal={cbPortal} />}
                </div>
              </Tab.Panel>
            )}
          </Tab.Panels>
        </Tab.Group>
      )}
    </Layout>
  );
}
