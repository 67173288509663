import { useCallback, useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import styled from 'styled-components';
import useBreakpoint from 'use-breakpoint';
import ReactCountryFlag from 'react-country-flag';
import Spinner from './Icons/Spinner';

const GeoMapStyled = styled.div({
  position: 'relative',
  minHeight: '200px',
  '& .geo-chart': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: 'fit-content',
  },
  '& .google-visualization-tooltip': {
    display: 'none !important',
  },
});

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 };

const initDataBackGround = [
  ['Country', 'value'],
  ['', 0],
];

const GeoMap = ({
  data,
  displayMode = 'markers',
  loading = false,
}: {
  data: (string | number)[][];
  displayMode?: string;
  loading?: boolean;
}): JSX.Element => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop');
  const [region, setRegion] = useState<(string | number)[]>();

  const dataMarker = () => {
    return data?.map((e, i) => {
      if (i === 0) {
        return [e?.[0], 'value'];
      }
      return [regionNames.of(e?.[0] as string), 1];
    });
  };

  const [dataBackGround, setDataBackGround] =
    useState<(string | number | undefined)[][]>(initDataBackGround);

  const resetGeoMap = useCallback(() => {
    setRegion(undefined);
    setDataBackGround(initDataBackGround);
  }, []);

  useEffect(() => {
    resetGeoMap();
  }, [data, resetGeoMap]);

  return (
    <GeoMapStyled className="w-full">
      <div className="geo-chart-background">
        <Chart
          chartType="GeoChart"
          width="100%"
          height="300px"
          data={dataBackGround}
          options={{
            displayMode: 'regions',
            legend: 'none',
            sizeAxis: { minSize: 4, maxSize: 4, size: 4 },
            backgroundColor: breakpoint === 'desktop' ? '#F9FAFB' : '#FFFFFF',
            colors: ['#E1CEFF', '#E1CEFF'],
            defaultColor: '#E5E7EB',
            datalessRegionColor: '#E5E7EB',
            forceIFrame: true,
            enableRegionInteractivity: false,
          }}
          rootProps={{ 'data-geoid': '1' }}
        />
      </div>
      {!loading ? (
        <>
          <div className="geo-chart">
            <Chart
              chartEvents={[
                {
                  eventName: 'select',
                  callback: ({ chartWrapper }) => {
                    const chart = chartWrapper.getChart();
                    try {
                      const selection = chart.getSelection();
                      if (selection.length === 0) return;

                      const row = Number(selection[0].row) + 1;
                      const selectedData = data[row];
                      if (region && selectedData[0] === region[0]) {
                        resetGeoMap();
                      } else {
                        setRegion(data[row]);
                        const newDataBackGround = [
                          ...[initDataBackGround[0]],
                          dataMarker()[row],
                        ];
                        setDataBackGround([...newDataBackGround]);
                      }
                    } catch (error) {
                      false;
                    }
                  },
                },
              ]}
              mapsApiKey={`${process.env.REACT_APP_GEO_MAP_API_KEY || ''}`}
              chartType="GeoChart"
              width="100%"
              height="300px"
              data={dataMarker()}
              options={{
                displayMode,
                showTip: false,
                legend: 'none',
                mapType: 'styledMap',
                sizeAxis: { minSize: 4, maxSize: 4, size: 4 },
                backgroundColor: 'transparent',
                colors: ['#873CFF', '#873CFF'],
                defaultColor: 'transparent',
                datalessRegionColor: 'transparent',
                forceIFrame: true,
                enableRegionInteractivity: false,
                tooltip: { isHtml: true },
              }}
              rootProps={{ 'data-geoid': '2' }}
            />
          </div>

          {region?.length ? (
            <ul className="flex md:mt-8 items-center gap-x-2 gap-y-4 flex-wrap">
              <li className="flex items-center gap-x-2 gap-y-4">
                <ReactCountryFlag
                  width={23}
                  countryCode={region[0] as string}
                  svg
                />
                <span
                  className="font-semibold text-xs text-gray-800 "
                  style={{ lineHeight: '14.3px' }}
                >
                  {regionNames.of(region[0] as string)}
                </span>
              </li>
              <div className="flex flex-grow justify-evenly">
                {region?.length &&
                  region?.slice(1)?.map((e, i) => {
                    return (
                      <li
                        key={`${data[0]
                          .slice(1)
                          [i].toString()
                          .replaceAll(' ', '_')}_${i}`}
                        className="font-medium"
                        style={{ fontSize: '9.29534px' }}
                      >
                        <span
                          className="text-poppins text-brand-500 md:text-gray-800 md:text-xs font-medium"
                          style={{ fontSize: '9.3px', lineHeight: '14.3px' }}
                        >
                          {e}
                        </span>
                        <span
                          className="text-poppins text-gray-500 md:text-xs font-normal "
                          style={{ fontSize: '9.3px', lineHeight: '14.3px' }}
                        >
                          {' '}
                          {data[0].slice(1)[i]}
                        </span>
                      </li>
                    );
                  })}
              </div>
            </ul>
          ) : (
            <div className="pb-9" />
          )}
        </>
      ) : (
        <div className="flex justify-center leading-7 text-brand-500 w-full h-full absolute top-0 left-0">
          {' '}
          <Spinner />{' '}
        </div>
      )}
    </GeoMapStyled>
  );
};

export { GeoMap as default };
