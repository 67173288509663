const stringHasContent = (string?: string | null): boolean => {
  if (typeof string === 'undefined' || string === null) {
    return false;
  }
  const array = /(.|\s)*\S(.|\s)*/.exec(string);
  if (array && array.length >= 0) {
    return true;
  }
  return false;
};

const stringTruncate = (str: string): string => {
  const stringLength = str.length;
  const truncatedString = [
    str.substr(0, 1),
    '...',
    str.substr(stringLength - 2, 2),
  ].join('');
  return truncatedString;
};

const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export { stringHasContent as default, stringHasContent, stringTruncate, capitalize };
