import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import Input from '@/components/Input';

interface IActivationKeySearch {
  id: string;
  label?: string;
  placeholder?: string;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  fetchQuery: (search: string) => Promise<void>;
}

export default function ActivationKeySearch({
  id,
  label = '',
  placeholder = '',
  search,
  setSearch,
  fetchQuery,
}: IActivationKeySearch): JSX.Element {
  const [debouncedValue, setDebouncedValue] = useState(search);
  useEffect(() => {
    const handler: NodeJS.Timeout = setTimeout(() => {
      setSearch(debouncedValue);
    }, 500);

    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => {
      clearTimeout(handler);
    };
  }, [debouncedValue, setSearch]);

  useEffect(() => {
    setDebouncedValue(search);
  }, [search]);

  useQuery(id, () => fetchQuery(search), {
    enabled: search.length >= 6,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 0,
  });

  return (
    <Input
      id="activationKeySearch"
      label={label}
      placeholder={placeholder}
      type="text"
      autocomplete="off"
      value={debouncedValue}
      onChange={setDebouncedValue}
    />
  );
}
