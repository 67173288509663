import { useCallback, useEffect, useState } from 'react';
import { CheckCircleIcon, PlusIcon, UserIcon } from '@heroicons/react/solid';
import {
  EyeIcon,
  PencilIcon,
  UserAddIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import { useHistory, Link } from 'react-router-dom';
import { Spinner } from '@react-pdf-viewer/core';
import { stringTruncate } from '@/helpers/strings';
import Button, { BUTTON_KIND } from '@/components/Button';
import InputCheckbox from '@/components/InputCheckbox';
import Copy from '@/components/Icons/Copy';
import ModalFullWidth from './ModalFullWidth';
import profilesAPI from '@/api/profiles';
import IProfile from '@/types/IProfile';
import useAuth from '@/hooks/useAuth';
import ProfileListGroupItem from './ProfileListGroupItem';
import useAppState from '@/hooks/useAppState';
import MESSAGES from '@/constants/messages-en';
import organisationsAPI from '@/api/organisations';
import { IOrganisationGroup } from '@/types/IOrganisation';
import Search from './Search';

interface IGroupListItem {
  id: number;
  noOfEditors?: number;
  noOfProfiles?: number;
  groupName?: string;
  description?: string;
  organisationID?: number;
  organisationName?: string;
  logoURL?: string;
  checkItem: () => void;
  selected: boolean;
}

export default function GroupListItem({
  id,
  noOfEditors,
  noOfProfiles,
  groupName,
  description,
  organisationID,
  organisationName,
  logoURL,
  checkItem,
  selected,
}: IGroupListItem): JSX.Element {
  const { orgID, user, getCurrentUser, userRole } = useAuth();
  const history = useHistory();
  const [isGroupProfilesOpen, setIsGroupProfilesOpen] =
    useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [sort, setSort] = useState('date');
  const [order, setOrder] = useState('desc');
  const [search, setSearch] = useState('');
  const [data, setData] = useState<IProfile[]>();
  const [dataGroup, setDataGroup] = useState<IOrganisationGroup[]>();
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const shouldApplyScrollStyle = data !== undefined && data.length > 6;

  const {
    selectProfiles,
    profileStatus,
    selectProfileStatus,
    selectSettingsTab,
  } = useAppState();

  function handleCheckItem(profileID: number) {
    let arr = checkedItems;
    if (checkedItems.includes(profileID)) {
      arr = arr.filter((e) => e !== profileID);
    } else {
      arr = [...checkedItems, profileID];
    }
    setCheckedItems(arr);
    selectProfiles(arr);
  }

  const listGroups = useCallback(
    async (
      newPage: number = page,
      newPageSize: number = pageSize,
      newSort: string = sort,
      newOrder: string = order,
      newSearch: string = search,
      initial = false,
    ) => {
      const res = await organisationsAPI.listOrganisationGroups({
        orgID,
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        order: newOrder,
        search: newSearch,
      });
      if (!initial) {
        setDataGroup(res.data?.data);
        setPage(newPage);
        setPageSize(newPageSize);
        setSort(newSort);
        setOrder(newOrder);
      }

      return res.data;
    },
    [order, orgID, page, pageSize, search, sort],
  );

  const listProfiles = useCallback(
    async (
      newPage: number = page,
      newPageSize: number = pageSize,
      newSort: string = sort,
      newOrder: string = order,
      newSearch: string = search,
      initial = false,
    ) => {
      const res = await profilesAPI.listProfiles({
        orgID,
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        order: newOrder,
        search: newSearch,
      });
      if (!initial) {
        setData(res.data?.data);
        setPage(newPage);
        setPageSize(newPageSize);
        setSort(newSort);
        setOrder(newOrder);
      }

      return res.data;
    },
    [order, orgID, page, pageSize, search, sort],
  );

  const removeProfilesFromGroup = useCallback(
    (uncheckedItems: number[]) => {
      if (orgID) {
        profilesAPI
          .updateProfilesShared({
            orgID,
            body: {
              ids: uncheckedItems,
              profile: {
                group_id: null,
              },
            },
          })
          .then(() => {
            setIsGroupProfilesOpen(false);
            history.push('/groups', {
              success: 'Profiles have been successfully removed from the group',
            });
          });
      }
    },
    [orgID],
  );

  const addProfilesToGroup = useCallback(
    (checkedItems: number[]) => {
      if (orgID) {
        profilesAPI
          .updateProfilesShared({
            orgID,
            body: {
              ids: checkedItems,
              profile: {
                group_id: id,
              },
            },
          })
          .then(() => {
            setIsGroupProfilesOpen(false);
          })
          .then(() => {
            history.push('/groups', {
              success: 'Profiles have been successfully added to the group',
            });
          });
      }
      // If an originally checked item is unchecked, remove it from the group
    },
    [orgID],
  );

  return (
    <li key={id}>
      <div className="">
        <div className="flex flex-row min-w">
          <div className="min-w-0 flex-1 flex items-center justify-center">
            <div>
              <ModalFullWidth
                isOpen={isGroupProfilesOpen}
                setIsOpen={setIsGroupProfilesOpen}
                dialogTitle="Edit profiles in group"
                dialogDescription="Add, remove and change profiles in group."
                onSuccess={() => addProfilesToGroup(checkedItems)}
                successButtonText="Assign to this group"
                cancelButtonText="Cancel"
                onRemove={() => removeProfilesFromGroup(checkedItems)}
                removeButtonText="Remove profiles"
              >
                <div className="my-8">
                  <Search
                    id={`ProfileList-${page}-${pageSize}-${sort}-${order}-${search}`}
                    search={search}
                    setSearch={setSearch}
                    fetchQuery={(searchQuery) =>
                      listProfiles(page, pageSize, sort, order, searchQuery)
                    }
                  />
                </div>
                {data && data.length > 0 ? (
                  <div className="flex flex-col">
                    <div className="relative -mx-4 sm:-mx-6 md:mx-0 lg:-mx-8">
                      <div className="block w-full lg:px-8">
                        <div
                          className="relative shadow-sm border border-gray-200 sm:rounded-md min-h-8 overflow-hidden"
                          style={
                            shouldApplyScrollStyle
                              ? { height: '500px', overflowY: 'scroll' }
                              : {}
                          }
                        >
                          <ul>
                            <li
                              key="ProfileListAdminHeader"
                              className="hidden lg:block"
                            >
                              <div className="bg-gray-50">
                                <div className="flex items-center px-4 py-2 ml-10 sm:px-6">
                                  <div className="min-w-0 flex-1 flex items-start md:items-center">
                                    <div className="hidden lg:block min-w-full pr-4 lg:grid lg:grid-cols-6 xl:grid-cols-9">
                                      <p className="lg:col-span-2 xl:col-span-3 xl:pl-4 text-sm lg:block text-start font-medium text-gray-900">
                                        NAME &amp; PROFILE ID
                                      </p>
                                      <p className="lg:col-span-2 xl:col-span-3 text-start md:mt-0 hidden lg:block text-sm font-medium text-gray-900">
                                        CONTACT INFORMATION
                                      </p>
                                      <p className="lg:col-span-2 xl:col-span-3 text-start md:mt-0 hidden lg:block text-sm font-medium text-gray-900">
                                        GROUP
                                      </p>
                                    </div>
                                    <div className="w-24" />
                                  </div>
                                </div>
                              </div>
                            </li>
                            {data?.map((item: IProfile) => (
                              <ProfileListGroupItem
                                key={item.id}
                                orgID={orgID || 0}
                                id={item.id}
                                firstName={item.first_name || ''}
                                lastName={item.last_name || ''}
                                title={item.job_title || ''}
                                emailAddress={item.email || ''}
                                groupID={item.group_id || 0}
                                groupName={item.group_name || ''}
                                groupSubheading={item.group_subheading || ''}
                                photoUrl={item.photo?.thumb_url}
                                status={item.status || ''}
                                selected={checkedItems.includes(item.id)}
                                profileHash={item.profile_hash}
                                checkItem={() => handleCheckItem(item.id)}
                              />
                            ))}
                          </ul>
                          {isLoading && (
                            <div className="absolute left-1/2 top-1/2 text-gray-500">
                              <Spinner />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="py-32">
                    <h3 className="w-full text-center text-2xl leading-5 text-gray-900 font-medium">
                      {MESSAGES.profile.list.empty.heading}
                    </h3>
                    <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
                      {MESSAGES.profile.list.empty.description}
                    </p>
                  </div>
                )}
              </ModalFullWidth>
            </div>
          </div>
        </div>
      </div>
      <div className="shadow-sm border border-gray-200 divide-y sm:rounded-md min-h-8 lg:w-56 2xl:w-72 overflow-hidden my-8">
        <div
          className="p-5 lg:justify-center"
          style={{ gridTemplateRows: '45px 45px' }}
        >
          {groupName && (
            <h3 className="text-sm font-medium justify-center text-gray-900 flex mb-0.5">
              {groupName}
            </h3>
          )}
          {!groupName && <div style={{ height: '20px' }} />}

          <div className="flex justify-center">
            <dd className=" text-xs row-span-1 text-center justify-center text-brand-500 font-medium flex items-start">
              {noOfEditors}
            </dd>
            {noOfEditors === 1 && (
              <dd className="text-xs ml-1 row-span-1 text-center justify-center text-gray-500 flex items-start">
                Group Manager
              </dd>
            )}
            {noOfEditors !== 1 && (
              <dd className="text-xs ml-1 row-span-1 text-center justify-center text-gray-500 flex items-start">
                Group Managers
              </dd>
            )}
          </div>
          {description && (
            <dd className=" text-xs text-center justify-center text-gray-500 flex items-start">
              {description}
            </dd>
          )}
          {!description && <div style={{ height: '20px' }} />}
        </div>
        <div>
          <div className="-mt-px flex divide-x divide-gray-200">
            <div className="flex w-0 flex-1">
              <Link
                to={`/edit-group/${id}`}
                className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
              >
                <PencilIcon
                  className="h-3 w-3 text-gray-600"
                  aria-hidden="true"
                />
                <span className="ml-1 text-xs">Edit Group</span>
              </Link>
            </div>
            {userRole !== 'org_editor' && (
              <div className="-ml-px flex w-0 flex-1 justify-center">
                <Button
                  className="mx-1 my-1"
                  buttonText="Add profiles"
                  kind={BUTTON_KIND.COLOR_OMITTED_NO_BORDER}
                  size="xxsmall"
                  icon={<UserAddIcon />}
                  onClick={() => setIsGroupProfilesOpen(true)}
                  external
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
}
