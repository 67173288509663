
import SocialFacebook from '@/components/Icons/SocialFacebook';
import SocialInstagram from '@/components/Icons/SocialInstagram';
import SocialLinkedIn from '@/components/Icons/SocialLinkedIn';
import SocialPinterest from '@/components/Icons/SocialPinterest';
import SocialTiktok from '@/components/Icons/SocialTiktok';
import SocialTwitter from '@/components/Icons/SocialTwitter';
import SocialYoutube from '@/components/Icons/SocialYoutube';
import SocialWhatsapp from '@/components/Icons/SocialWhatsapp';
import SocialWechat from '@/components/Icons/SocialWechat';
import SocialGoogleMyBusiness from '@/components/Icons/SocialGoogleMyBusiness';

const SOCIALICONS: Record<string, JSX.Element> = {
  facebook: <SocialFacebook />,
  instagram: <SocialInstagram />,
  linkedin: <SocialLinkedIn />,
  pinterest: <SocialPinterest />,
  tiktok: <SocialTiktok />,
  twitter: <SocialTwitter />,
  youtube: <SocialYoutube />,
  whatsapp: <SocialWhatsapp />,
  wechat: <SocialWechat />,
  googlemybusiness: (
    <SocialGoogleMyBusiness />
  ),
};



export { SOCIALICONS as default, SOCIALICONS };