import React from 'react';
import { cx } from '@emotion/css';
import { Link } from 'react-router-dom';
import IButton from '@/types/IButton';
import Spinner from '@/components/Icons/Spinner';

export const BUTTON_KIND = {
  PRIMARY: 'primary',
  WHITE: 'white',
  RED: 'red',
  LIGHT_RED: 'lightRed',
  CIRCULAR: 'circular',
  CIRCULAR_WHITE: 'circularWhite',
  CIRCULAR_CLEAR: 'circularClear',
  COLOR_OMITTED: 'colorOmitted',
  COLOR_OMITTED_NO_BORDER: 'colorOmittedNoBorder',
  COLOR_OMITTED_PURPLE: 'colorOmittedPurple',
  PRIMARY_ROUNDED_MD: 'primaryRoundedMd',
  WHITE_ROUNDED_MD: 'whiteRoundedMd',
  WHITE_BG_LIGHT_RED_TEXT: 'WhiteBglightRedText',
} as const;

export const BUTTON_SIZE = {
  XXSMALL: 'xxsmall',
  TINY: 'tiny',
  XSMALL: 'xsmall',
  SMALL: 'small',
  BASE: 'base',
  LARGE: 'large',
  XLARGE: 'xlarge',
} as const;

export const BUTTON_ICON_POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
};

const Button: React.FC<IButton> = (props) => {
  const {
    buttonText,
    className,
    disabled = false,
    external = false,
    externalBlankTarget = true,
    fullWidth = false,
    center = false,
    href,
    icon,
    iconPos = BUTTON_ICON_POSITION.LEFT,
    kind = BUTTON_KIND.PRIMARY,
    loading = false,
    onClick,
    size = BUTTON_SIZE.BASE,
  } = props;

  const [disabledState, setDisabledState] = React.useState<boolean>(loading);

  React.useEffect(() => {
    setDisabledState(loading);
  }, [loading]);

  React.useEffect(() => {
    setDisabledState(disabled);
  }, [disabled]);

  const baseCX =
    'appearance-none font-medium transform transition-all transition duration-300 flex flex-shrink-0 items-center border';
  const baseHoverCX = '';

  const classCX: {
    size: { [key: string]: string };
    kind: { [key: string]: { [key: string]: string } };
  } = {
    size: {
      notext: 'py-2 px-1',
      circle: 'text-xs leading-4 py-0.5 px-2',
      xxsmall: 'text-xs leading-4 py-1 px-1.5 text-gray-600',
      tiny: 'text-xs leading-4 py-1.5 px-0',
      xsmall: 'text-xs leading-4 py-1.5 px-3',
      small: 'text-sm leading-4 py-2 px-3.5',
      base: 'text-sm leading-5 py-2 px-4',
      large: 'text-base leading-6 py-2 px-4',
      xlarge: 'text-base leading-6 py-3 px-6',
    },
    kind: {
      primary: {
        base: 'rounded text-white bg-brand-500 border-brand-500',
        focus:
          'focus:outline-none focus:ring-brand-700 focus:shadow-sm focus:ring-2 focus:ring-offset-2',
        disabled:
          'disabled:font-normal disabled:opacity-50 disabled:cursor-not-allowed disabled:border-gray-200',
        hover:
          'hover:text-white hover:bg-brand-700 hover:border-brand-700 hover:no-underline',
      },
      white: {
        base: 'rounded text-gray-700 bg-white border-gray-300',
        focus:
          'focus:outline-none focus:ring-brand-700 focus:shadow-sm focus:ring-2 focus:ring-offset-2',
        disabled:
          'disabled:font-normal disabled:bg-gray-300 disabled:text-gray-700 disabled:border-gray-300 disabled:cursor-not-allowed ',
        hover: 'hover:bg-gray-100 hover:text-gray-700 hover:no-underline',
      },
      red: {
        base: 'rounded text-white bg-red-500 border-red-500',
        focus:
          'focus:outline-none focus:ring-red-700 focus:shadow-sm focus:ring-2 focus:ring-offset-2',
        disabled:
          'disabled:font-normal disabled:opacity-50 disabled:cursor-not-allowed disabled:border-gray-200',
        hover:
          'hover:text-white hover:bg-red-700 hover:border-red-700 hover:no-underline',
      },
      lightRed: {
        base: 'rounded text-white bg-red-450 border-red-450',
        focus:
          'focus:outline-none focus:ring-red-700 focus:shadow-sm focus:ring-2 focus:ring-offset-2',
        disabled:
          'disabled:font-normal disabled:opacity-50 disabled:cursor-not-allowed disabled:border-gray-200',
        hover:
          'hover:text-white hover:bg-red-700 hover:border-red-700 hover:no-underline',
      },
      WhiteBglightRedText: {
        base: 'rounded-md bg-white text-red-450 border-red-450',
        focus:
          'focus:outline-none focus:ring-red-700 focus:shadow-sm focus:ring-2 focus:ring-offset-2',
        disabled:
          'disabled:font-normal disabled:opacity-50 disabled:cursor-not-allowed disabled:border-gray-200',
        hover:
          'hover:text-white hover:bg-red-700 hover:border-red-700 hover:no-underline',
      },
      circular: {
        base: 'rounded-full text-white bg-brand-500 border-brand-500 rounded-full',
        focus:
          'focus:outline-none focus:ring-brand-700 focus:shadow-sm focus:ring-2 focus:ring-offset-2',
        disabled:
          'disabled:font-normal disabled:opacity-50 disabled:cursor-not-allowed disabled:border-gray-200',
        hover:
          'hover:text-white hover:bg-brand-700 hover:border-brand-700 hover:no-underline',
      },
      circularWhite: {
        base: 'rounded-full bg-white border-white shadow-sm rounded-full',
        focus:
          'focus:outline-none focus:ring-brand-700 focus:shadow-md focus:ring-2 focus:ring-offset-2',
        disabled:
          'disabled:font-normal disabled:bg-gray-200 disabled:text-gray-500 disabled:border-gray-200',
        hover: 'hover:border-brand-700 hover:no-underline',
      },
      colorOmitted: {
        base: 'rounded border-transparent',
        focus:
          'focus:outline-none focus:ring-brand-700 focus:shadow-sm focus:ring-2 focus:ring-offset-2',
        disabled:
          'disabled:font-normal disabled:opacity-50 disabled:cursor-not-allowed disabled:border-gray-200',
        hover: 'hover:no-underline',
      },
      colorOmittedNoBorder: {
        base: 'rounded border-transparent',
        focus: 'focus:outline-none',
        disabled:
          'disabled:font-normal disabled:opacity-50 disabled:cursor-not-allowed disabled:border-gray-200',
        hover: 'hover:no-underline',
      },
      colorOmittedPurple: {
        base: 'rounded text-brand-700 border-transparent',
        focus:
          'focus:outline-none focus:ring-brand-700 focus:shadow-sm focus:ring-2 focus:ring-offset-2',
        disabled:
          'disabled:font-normal disabled:opacity-50 disabled:cursor-not-allowed disabled:border-gray-200',
        hover: 'hover:text-brand hover:no-underline',
      },
      primaryRoundedMd: {
        base: 'rounded-md text-white bg-brand-500 border-brand-500',
        focus:
          'focus:outline-none focus:ring-brand-700 focus:shadow-sm focus:ring-2 focus:ring-offset-2',
        disabled:
          'disabled:font-normal disabled:opacity-50 disabled:cursor-not-allowed disabled:border-gray-200',
        hover:
          'hover:text-white hover:bg-brand-700 hover:border-brand-700 hover:no-underline',
      },
      whiteRoundedMd: {
        base: 'rounded-md text-gray-700 bg-white border-gray-300',
        focus:
          'focus:outline-none focus:ring-brand-700 focus:shadow-sm focus:ring-2 focus:ring-offset-2',
        disabled:
          'disabled:font-normal disabled:bg-gray-300 disabled:text-gray-700 disabled:border-gray-300 disabled:cursor-not-allowed ',
        hover: 'hover:bg-gray-100 hover:text-gray-700 hover:no-underline',
      },
    },
  };

  const buttonCX = cx(
    baseCX,
    baseHoverCX,
    className,
    className || (!fullWidth && 'w-max'),
    fullWidth && 'w-full',
    !icon && 'justify-center',
    icon && (center ? 'justify-center' : 'justify-between'),
    classCX.size[`${size}`],
    classCX.kind[`${kind}`].base,
    classCX.kind[`${kind}`].disabled,
    classCX.kind[`${kind}`].focus,
    classCX.kind[`${kind}`].hover,
  );

  if (href && !onClick) {
    if (external) {
      const target = externalBlankTarget ? '_blank' : '_self';
      return (
        <a
          className={buttonCX}
          href={href}
          rel="noreferrer noopener nofollow"
          target={target}
        >
          {icon && iconPos === BUTTON_ICON_POSITION.LEFT && (
            <div className="mr-2">
              {React.cloneElement(icon, { className: 'h-5 w-5' })}
            </div>
          )}
          {buttonText}
          {loading && <Spinner />}
          {icon && iconPos === BUTTON_ICON_POSITION.RIGHT && (
            <div className="ml-2">
              {React.cloneElement(icon, { className: 'h-5 w-5' })}
            </div>
          )}
          {icon && iconPos === BUTTON_ICON_POSITION.CENTER && (
            <div className="mx-0.5 px-1">
              {React.cloneElement(icon, { className: 'h-5 w-5' })}
            </div>
          )}
        </a>
      );
    }

    return (
      <Link className={buttonCX} to={href}>
        {icon && iconPos === BUTTON_ICON_POSITION.LEFT && (
          <div className="mr-2">
            {React.cloneElement(icon, { className: 'h-5 w-5' })}
          </div>
        )}
        {buttonText}
        {loading && <Spinner />}
        {icon && iconPos === BUTTON_ICON_POSITION.RIGHT && (
          <div className="ml-2">
            {React.cloneElement(icon, { className: 'h-5 w-5' })}
          </div>
        )}
        {icon && iconPos === BUTTON_ICON_POSITION.CENTER && (
          <div className="mx-0.5 px-1">
            {React.cloneElement(icon, { className: 'h-5 w-5' })}
          </div>
        )}
      </Link>
    );
  }

  return (
    <button
      className={buttonCX}
      disabled={disabledState}
      onClick={onClick}
      type="button"
    >
      {icon && iconPos === BUTTON_ICON_POSITION.LEFT && size !== 'xxsmall' && (
        <div className={buttonText ? 'mr-2' : ''}>
          {React.cloneElement(icon, { className: 'h-5 w-5' })}
        </div>
      )}
      {icon && iconPos === BUTTON_ICON_POSITION.LEFT && size === 'xxsmall' && (
        <div className={buttonText ? 'mr-1' : ''}>
          {React.cloneElement(icon, { className: 'h-3 w-3' })}
        </div>
      )}
      {buttonText}
      {loading && <Spinner className="ml-2" />}
      {icon && iconPos === BUTTON_ICON_POSITION.RIGHT && (
        <div className="ml-2">
          {React.cloneElement(icon, { className: 'h-5 w-5' })}
        </div>
      )}
    </button>
  );
};

export default Button;
