import { Box } from '@material-ui/core';
import { filesize } from 'filesize';
import moment from 'moment-timezone';
import useAuth from '@/hooks/useAuth';

interface ITaptNewComment {
  comment: string | undefined;
  commentFiles: File[];
  orderDesignFile: File | undefined;
}

export default function TaptNewComment({
  comment,
  commentFiles,
  orderDesignFile,
}: ITaptNewComment): JSX.Element {
  const { user } = useAuth();
  return (
    <>
      {orderDesignFile && (
        <Box className="flex justify-end items-end w-full">
          <Box className="w-1/5 flex justify-center items-end gap-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M5.79427 2.5415H2.2526C1.87688 2.5415 1.51655 2.69075 1.25087 2.95643C0.985193 3.22211 0.835938 3.58244 0.835938 3.95816V11.7498C0.835938 12.1256 0.985193 12.4859 1.25087 12.7516C1.51655 13.0172 1.87688 13.1665 2.2526 13.1665H10.0443C10.42 13.1665 10.7803 13.0172 11.046 12.7516C11.3117 12.4859 11.4609 12.1256 11.4609 11.7498V8.20816M10.4594 1.53991C10.59 1.40461 10.7464 1.29668 10.9192 1.22244C11.092 1.14819 11.2779 1.10911 11.466 1.10748C11.6541 1.10584 11.8407 1.14168 12.0148 1.21292C12.1889 1.28415 12.3471 1.38934 12.4801 1.52235C12.6131 1.65537 12.7183 1.81354 12.7895 1.98765C12.8607 2.16175 12.8966 2.34829 12.895 2.5364C12.8933 2.7245 12.8542 2.9104 12.78 3.08324C12.7058 3.25608 12.5978 3.4124 12.4625 3.54308L6.38077 9.62483H4.3776V7.62166L10.4594 1.53991Z"
                stroke="#873CFF"
                strokeWidth="1.41667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
            >
              <path
                d="M5.58594 6.79167V11.0417M8.41927 6.79167V11.0417M1.33594 3.95833H12.6693M11.9609 3.95833L11.3468 12.5589C11.3214 12.9163 11.1614 13.2508 10.8992 13.495C10.637 13.7392 10.292 13.875 9.93369 13.875H4.07152C3.7132 13.875 3.36819 13.7392 3.10598 13.495C2.84377 13.2508 2.68384 12.9163 2.6584 12.5589L2.04427 3.95833H11.9609ZM9.1276 3.95833V1.83333C9.1276 1.64547 9.05298 1.4653 8.92014 1.33247C8.7873 1.19963 8.60713 1.125 8.41927 1.125H5.58594C5.39808 1.125 5.21791 1.19963 5.08507 1.33247C4.95223 1.4653 4.8776 1.64547 4.8776 1.83333V3.95833H9.1276Z"
                stroke="#9CA3AF"
                strokeWidth="1.41667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Box>
          <Box className="w-4/5">
            <Box className="flex py-3 gap-6 w-full font-poppins text-sm font-medium text-gray-400 items-center">
              <p className="flex-1 text-brand-500">Tapt</p>
              <p>
                {moment.utc(String(new Date())).local().format('hh:mm')}{' '}
                {moment.utc(String(new Date())).local().format('DD/MM/YY')}
              </p>
              <p>{`${user?.first_name} ${user?.last_name}`}</p>
            </Box>
            <Box className="border rounded-lg px-4 pt-3 pb-5 bg-white">
              <pre className="break-words text-sm leading-5 font-normal text-gray-900 font-poppins whitespace-pre-wrap">
                This is design proof
              </pre>
              {orderDesignFile && (
                <>
                  <Box className="hidden sm:block" aria-hidden="true">
                    <Box className="py-2 md:py-3">
                      <Box className="border-t border-gray-200" />
                    </Box>
                  </Box>
                  <Box className="text-sm leading-5 font-medium font-poppins">
                    <p>Files</p>
                    <Box className="flex flex-wrap justify-start items-center gap-3 mt-4 text-brand-500">
                      <Box className="flex justify-start items-center gap-3  text-brand-500">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M1.33594 11.333V12.1663C1.33594 12.8294 1.59933 13.4653 2.06817 13.9341C2.53701 14.4029 3.1729 14.6663 3.83594 14.6663H12.1693C12.8323 14.6663 13.4682 14.4029 13.937 13.9341C14.4059 13.4653 14.6693 12.8294 14.6693 12.1663V11.333M11.3359 7.99967L8.00261 11.333M8.00261 11.333L4.66927 7.99967M8.00261 11.333V1.33301"
                            stroke="#873CFF"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <p>{orderDesignFile.name}</p>
                        <p>
                          {filesize(orderDesignFile.size, {
                            base: 2,
                            standard: 'jedec',
                          }).toString()}
                        </p>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      )}
      {(comment || commentFiles.length > 0) && (
        <Box className="flex justify-end items-end w-full">
          <Box className="w-1/5 flex justify-center items-end gap-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M5.79427 2.5415H2.2526C1.87688 2.5415 1.51655 2.69075 1.25087 2.95643C0.985193 3.22211 0.835938 3.58244 0.835938 3.95816V11.7498C0.835938 12.1256 0.985193 12.4859 1.25087 12.7516C1.51655 13.0172 1.87688 13.1665 2.2526 13.1665H10.0443C10.42 13.1665 10.7803 13.0172 11.046 12.7516C11.3117 12.4859 11.4609 12.1256 11.4609 11.7498V8.20816M10.4594 1.53991C10.59 1.40461 10.7464 1.29668 10.9192 1.22244C11.092 1.14819 11.2779 1.10911 11.466 1.10748C11.6541 1.10584 11.8407 1.14168 12.0148 1.21292C12.1889 1.28415 12.3471 1.38934 12.4801 1.52235C12.6131 1.65537 12.7183 1.81354 12.7895 1.98765C12.8607 2.16175 12.8966 2.34829 12.895 2.5364C12.8933 2.7245 12.8542 2.9104 12.78 3.08324C12.7058 3.25608 12.5978 3.4124 12.4625 3.54308L6.38077 9.62483H4.3776V7.62166L10.4594 1.53991Z"
                stroke="#873CFF"
                strokeWidth="1.41667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
            >
              <path
                d="M5.58594 6.79167V11.0417M8.41927 6.79167V11.0417M1.33594 3.95833H12.6693M11.9609 3.95833L11.3468 12.5589C11.3214 12.9163 11.1614 13.2508 10.8992 13.495C10.637 13.7392 10.292 13.875 9.93369 13.875H4.07152C3.7132 13.875 3.36819 13.7392 3.10598 13.495C2.84377 13.2508 2.68384 12.9163 2.6584 12.5589L2.04427 3.95833H11.9609ZM9.1276 3.95833V1.83333C9.1276 1.64547 9.05298 1.4653 8.92014 1.33247C8.7873 1.19963 8.60713 1.125 8.41927 1.125H5.58594C5.39808 1.125 5.21791 1.19963 5.08507 1.33247C4.95223 1.4653 4.8776 1.64547 4.8776 1.83333V3.95833H9.1276Z"
                stroke="#9CA3AF"
                strokeWidth="1.41667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Box>
          <Box className="w-4/5">
            <Box className="flex py-3 gap-6 w-full font-poppins text-sm font-medium text-gray-400 items-center">
              <p className="flex-1 text-brand-500">Tapt</p>
              <p>
                {moment.utc(String(new Date())).local().format('hh:mm')}{' '}
                {moment.utc(String(new Date())).local().format('DD/MM/YY')}
              </p>
              <p>{`${user?.first_name} ${user?.last_name}`}</p>
            </Box>
            <Box className="border rounded-lg px-4 pt-3 pb-5 bg-white">
              <pre className="break-words text-sm leading-5 font-normal text-gray-900 font-poppins whitespace-pre-wrap">
                {comment}
              </pre>
              {commentFiles.length > 0 && (
                <>
                  <Box className="hidden sm:block" aria-hidden="true">
                    <Box className="py-2 md:py-3">
                      <Box className="border-t border-gray-200" />
                    </Box>
                  </Box>
                  <Box className="text-sm leading-5 font-medium font-poppins">
                    <p>Files</p>
                    <Box className="flex flex-wrap justify-start items-center gap-3 mt-4 text-brand-500">
                      {commentFiles.map((item) => {
                        return (
                          <Box className="flex justify-start items-center gap-3 text-brand-500">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M1.33594 11.333V12.1663C1.33594 12.8294 1.59933 13.4653 2.06817 13.9341C2.53701 14.4029 3.1729 14.6663 3.83594 14.6663H12.1693C12.8323 14.6663 13.4682 14.4029 13.937 13.9341C14.4059 13.4653 14.6693 12.8294 14.6693 12.1663V11.333M11.3359 7.99967L8.00261 11.333M8.00261 11.333L4.66927 7.99967M8.00261 11.333V1.33301"
                                stroke="#873CFF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <p>{item.name}</p>
                            <p>
                              {' '}
                              {filesize(item.size, {
                                base: 2,
                                standard: 'jedec',
                              }).toString()}
                            </p>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
