import { useCallback, useState, useEffect } from 'react';
import adminAPI from '@/api/admin';
import ordersAPI from '@/api/ordersV2';
import Button, { BUTTON_KIND } from '@/components/Button';
import ErrorAlert from '@/components/ErrorAlert';
import Spinner from '@/components/Icons/Spinner';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import Layout from '@/components/Layout';
import OrdersListItem from '@/components/OrdersPage/OrdersV2List/Item';
import Pagination from '@/components/Pagination';
import Search from '@/components/Search';
import Sort from '@/components/Sort';
import SuccessAlert from '@/components/SuccessAlert';
import MESSAGES from '@/constants/messages-en';
import classNames from '@/helpers/classNames';
import { IListNew, IListPaging } from '@/types/IList';
import IOrder from '@/types/IOrderV2';
import useAuth from '@/hooks/useAuth';
import organisationsAPI from '@/api/organisations';

export default function OrdersListPage(): JSX.Element {
  const { userScope, orgID, user, userRole } = useAuth();

  const [data, setData] = useState<IOrder[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');
  const [status, setStatus] = useState('new');
  const [sort, setSort] = useState('updated_at');
  const [order, setOrder] = useState('desc');

  const listOrders = useCallback(
    async ({
      newPage = page,
      newPageSize = pageSize,
      newSort = sort,
      newOrder = order,
      newStatus = 'new',
      newSearch = search,
      initial = false,
    }: IListNew): Promise<{ data: IOrder[]; paging: IListPaging }> => {
      const res = await ordersAPI.listOrdersUserOrganisation(orgID, {
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        order: newOrder,
        status: newStatus,
        search: newSearch,
      });

      setData(res.data?.data);
      setPage(newPage);
      setPageSize(newPageSize);
      setStatus(newStatus);
      setSearch(newSearch);
      setIsLoading(false);

      return res.data;
    },
    [page, pageSize, sort, status, order, search],
  );

  const exportCSV = useCallback(
    async (arr?: string[]) => {
      if (arr || checkedItems) {
        const response = await adminAPI.exportProfilesViaOrderCSV(
          arr || checkedItems,
        );
        const blob = new Blob([response.data]);
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = 'admin_profiles.csv';
        a.click();
      }
    },
    [checkedItems],
  );

  return (
    <Layout pageName="Orders">
      {success !== '' && <SuccessAlert message={success} />}
      {error && <ErrorAlert message={MESSAGES.error.generic} />}
      {userRole === 'org_admin' && (
        <>
          <>
            <div>
              <div className="outline-none">
                <div className="py-7">
                  <div className="pb-8 flex flex-col space-y-3 items-start sm:flex-row sm:space-y-0 sm:items-center sm:justify-end">
                    <div className="flex flex-shrink-0 space-x-3">
                      <div className="inline-flex">
                        <div className="relative mr-4">
                          <Search
                            id={`OrdersList-${page}-${pageSize}-${sort}-${order}-${search}`}
                            placeholder="Search"
                            search={search}
                            setSearch={setSearch}
                            fetchQuery={(newSearch) =>
                              listOrders({ newSearch })
                            }
                          />
                        </div>
                        <Sort
                          id={`OrdersList-${page}-${pageSize}-${sort}-${order}-${search}`}
                          options={[
                            {
                              sort: 'inserted_at',
                              order: 'desc',
                              label: 'Date created (Newest first)',
                            },
                            {
                              sort: 'inserted_at',
                              order: 'asc',
                              label: 'Date created (Oldest first)',
                            },
                            {
                              sort: 'quantity',
                              order: 'asc',
                              label: 'No of Profile (Lowest first)',
                            },
                            {
                              sort: 'quantity',
                              order: 'desc',
                              label: 'No of Profile (Highest first)',
                            },
                            {
                              sort: 'type',
                              order: 'shop',
                              label: 'Order Type (Shop)',
                            },
                            {
                              sort: 'type',
                              order: 'draft',
                              label: 'Order Type (Draft)',
                            },
                          ]}
                          sort={sort}
                          setSort={setSort}
                          order={order}
                          setOrder={setOrder}
                          fetchQuery={(newSort, newOrder) =>
                            listOrders({ newSort, newOrder })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {data && data.length > 0 ? (
                    <div className="flex flex-col">
                      <div className="relative -mx-4 sm:-mx-6 md:mx-0 lg:-mx-8">
                        <div className="block w-full lg:px-8">
                          <div className="relative shadow-sm border border-gray-200 sm:rounded-md min-h-8">
                            <ul
                              className={classNames(
                                isLoading ? 'opacity-40' : '',
                                'divide-y divide-gray-200',
                              )}
                            >
                              <li
                                key="OrderListHeader"
                                className="hidden md:block"
                              >
                                <div className="bg-gray-50">
                                  <div className="flex items-center px-4 py-2.5 sm:px-6">
                                    <div className="min-w-0 flex-1 flex items-start md:items-center">
                                      <div className=" md:grid-cols-4 md:gap-4 xl:grid-cols-6 min-w-0 flex-1 md:grid text-black uppercase">
                                        <p className="flex text-xs font-medium text-center  justify-center items-center">
                                          order no.
                                        </p>
                                        <p className="flex text-xs font-medium text-center  justify-center items-center">
                                          date
                                        </p>
                                        <p className="hidden xl:flex text-xs font-medium text-center  justify-center items-center">
                                          Fulfilment Status
                                        </p>
                                        <p className="hidden xl:flex text-xs font-medium text-center  justify-center items-center">
                                          order info
                                        </p>
                                        {/* <p className="hidden xl:flex text-xs font-medium text-center  justify-center items-center">
invoice
</p> */}
                                        <p className="flex text-xs font-medium text-center  justify-center items-center">
                                          Design Status
                                        </p>
                                        <p className="flex text-xs font-medium text-center  justify-center items-center">
                                          design
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>

                              {data.map((item: IOrder) => (
                                <OrdersListItem
                                  key={item.id}
                                  id={item.id}
                                  uuid={item.uuid}
                                  orderNumber={item.order_number}
                                  numberOfProfiles={item.order_quantity}
                                  orderStatus={item.order_stage}
                                  order_status_url={item.order_status_url}
                                  inserted_at={item?.inserted_at}
                                />
                              ))}
                            </ul>
                            {isLoading && (
                              <div className="absolute left-1/2 top-1/2 text-gray-500">
                                <Spinner className="text-brand-500" />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="py-32">
                      <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
                        {MESSAGES.order.empty.heading}
                      </h3>
                      <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
                        {MESSAGES.order.empty.description}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Pagination
              id={`OrdersList-${page}-${pageSize}-${sort}-${order}-${search}`}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              fetchQuery={(newPage, newPageSize) =>
                listOrders({ newPage, newPageSize })
              }
              setIsLoading={setIsLoading}
            />
            <div className="block" aria-hidden="true">
              <div className="py-8" />
            </div>
          </>
          <InfoPanelFooter
            className={checkedItems?.length > 0 ? '' : '-bottom-28'}
          >
            <p className="text-sm leading-5 text-gray-500 mb-1 sm:hidden">{`${checkedItems.length} selected`}</p>
            <div className="flex items-center flex-nowrap space-x-4">
              <Button
                kind={BUTTON_KIND.WHITE}
                buttonText="Export profiles from selected orders"
                onClick={() => {
                  setIsLoading(true);
                  setSuccess('');
                  setError(false);
                  exportCSV()
                    .then(() => setSuccess(MESSAGES.profile.export.success))
                    .catch(() => setError(true))
                    .then(() => setIsLoading(false))
                    .finally(() => setCheckedItems([]));
                }}
              />
              <p className="text-sm leading-5 text-gray-500 hidden sm:block">{`${checkedItems.length} selected`}</p>
            </div>
          </InfoPanelFooter>
        </>
      )}
    </Layout>
  );
}
