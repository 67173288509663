import { useState } from 'react';
import Button, { BUTTON_KIND } from './Button';
import Modal from './Modal';

interface Props {
  label?: string;
  buttonLabel: string;
  description?: string;
  className?: string;
  spaceX?: number;
  lableFont?: string;
  confirmationTitle?: string | JSX.Element;
  confirmationApproveText?: string;
  confirmationRequired?: boolean;
  confirmationContent: string | JSX.Element;
  onClick: () => void;
  loading?: boolean;
}

export default function ButtonField({
  label,
  buttonLabel,
  description,
  className,
  spaceX = 2,
  lableFont = 'medium',
  confirmationTitle,
  confirmationApproveText,
  confirmationRequired,
  onClick,
  confirmationContent,
  loading = false,
}: Props): JSX.Element {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  return (
    <>
      {confirmationRequired && (
        <Modal
          isOpen={isConfirmOpen}
          setIsOpen={setIsConfirmOpen}
          dialogTitle={confirmationTitle || 'Are you sure?'}
          successButtonText={confirmationApproveText || 'Yes'}
          onSuccess={() => {
            setIsConfirmOpen(false);
            onClick();
          }}
          successButtonKind={BUTTON_KIND.RED}
        >
          <div className="pt-3 text-gray-500">{confirmationContent}</div>
        </Modal>
      )}
      <div
        className={`flex items-center space-x-${spaceX} justify-between ${
          className || ''
        }`}
      >
        {label && description && (
          <>
            <span className="flex-grow flex flex-col">
              <span
                className={`text-sm leading-5 font-${lableFont} text-gray-900`}
              >
                {label}
              </span>
              {description && (
                <span className="text-sm leading-5 text-gray-500 pr-2">
                  {description}
                </span>
              )}
            </span>
          </>
        )}
        <div>
          <Button
            kind={BUTTON_KIND.RED}
            buttonText={buttonLabel}
            loading={loading}
            disabled={loading}
            onClick={() =>
              confirmationRequired ? setIsConfirmOpen(true) : onClick()
            }
          />
        </div>
      </div>
    </>
  );
}
