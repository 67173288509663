import { Link } from 'react-router-dom';
import React from 'react';
import Logo from '@/assets/logos/primary.svg';

interface INewLayoutStandard {
  children?: React.ReactNode;
  heading: string;
  description?: string;
}

const NewLayoutStandard: React.FC<INewLayoutStandard> = (props) => {
  const { children, heading, description } = props;

  return (
    <div className="min-h-screen bg-white flex flex-col justify-between py-6 sm:px-6 lg:px-8">
      {/* <a
        href="https://tapt.io/"
        target="_blank"
        rel="noreferrer"
        className="flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 px-4"
      >
        <ArrowNarrowLeftIcon className="h-4 w-4 mr-2" /> Back to Shop
      </a> */}
      <div>
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <Link to="/">
            <img
              className="mx-auto h-24 w-24 mt-10 mb-10"
              src={Logo}
              alt="Tapt"
            />
          </Link>
          <h2 className="mt-6 mb-4 text-center text-2xl leading-8 font-medium text-gray-900">
            {heading}
            {process.env.REACT_APP_DISPLAY_ENV
              ? ` (${process.env.REACT_APP_DISPLAY_ENV})`
              : ``}
          </h2>
          {description && (
            <p className="mt-4 px-4 text-center leading-6 text-gray-900">
              {description}
            </p>
          )}
        </div>
        {children}
      </div>
      <div>
        {/* <ul className="mt-6 flex justify-center flex-wrap">
          <li className="inline-flex">
            <a
              className="py-2 px-5 text-base leading-6 font-normal text-gray-500 hover:text-gray-700"
              href="https://tapt.io/pages/contact-us"
              target="_blank"
              rel="noreferrer"
            >
              Help
            </a>
          </li>
          <li className="inline-flex">
            <a
              className="py-2 px-5 text-base leading-6 font-normal text-gray-500 hover:text-gray-700"
              href={PrivacyPolicy}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </li>
          <li className="inline-flex">
            <a
              className="py-2 px-5 text-base leading-6 font-normal text-gray-500 hover:text-gray-700"
              href={TandCs}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </a>
          </li>
        </ul> */}
      </div>
    </div>
  );
};

export { NewLayoutStandard as default };
