import { ReactNode, Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button, { BUTTON_KIND } from '@/components/Button';
import classNames from '@/helpers/classNames';

export interface IModal {
  isOpen: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  buttonKind?: string;
  buttonSize?: string;
  buttonClassName?: string;
  dialogHeroVideo?: string;
  buttonTitle?: string;
  dialogTitleIcon?: JSX.Element;
  dialogTitle?: string | JSX.Element;
  dialogDescription?: string | JSX.Element;
  checkboxDescription?: string | JSX.Element;
  dialogTitleClassName?: string;
  footerButtonContainerClassName?: string;
  wider?: boolean;
  icon?: JSX.Element;
  children?: ReactNode;
  successButtonText: string;
  successButtonKind?: string;
  cancelButtonText?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  large?: boolean;
  checkbox?: boolean;
  setCheckbox?: React.Dispatch<React.SetStateAction<boolean>>;
  onCancel?: () => void;
  onClose?: () => void;
  onSuccess: () => void;
}

export default function Modal({
  isOpen,
  setIsOpen,
  buttonKind = BUTTON_KIND.WHITE,
  buttonSize,
  buttonClassName,
  buttonTitle,
  dialogHeroVideo,
  dialogTitle,
  dialogTitleIcon,
  dialogDescription,
  checkboxDescription,
  dialogTitleClassName = ' text-base text-center md:text-2xl md:text-left leading-8 font-medium text-gray-900',
  footerButtonContainerClassName = 'w-full flex justify-center md:justify-end space-x-4',
  wider,
  icon,
  children,
  successButtonText,
  successButtonKind = BUTTON_KIND.PRIMARY,
  cancelButtonText = 'Cancel',
  isLoading,
  isDisabled = false,
  large = false,
  checkbox,
  setCheckbox,
  onCancel,
  onClose = () => setIsOpen && setIsOpen(false),
  onSuccess,
}: IModal): JSX.Element {
  return (
    <>
      {buttonTitle && (
        <Button
          kind={buttonKind}
          size={buttonSize}
          icon={icon}
          iconPos="right"
          className={buttonClassName}
          onClick={() => setIsOpen && setIsOpen(true)}
          buttonText={buttonTitle}
        />
      )}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={onClose}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-300 bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={classNames(
                  'inline-block my-8 text-left align-middle transition-all transform bg-white shadow-xl ',
                  large
                    ? 'w-full md:w-3/4 xl:w-1/2 p-6 rounded-2xl'
                    : `${
                        wider
                          ? 'w-full md:w-538 p-5 rounded-xl'
                          : 'w-full max-w-lg p-6 rounded-2xl'
                      }`,
                )}
              >
                {dialogHeroVideo && (
                  <video
                    src={dialogHeroVideo}
                    muted
                    autoPlay
                    loop
                    playsInline
                    className="w-full mb-6 bg-white"
                  />
                )}

                {dialogTitleIcon && (
                  <div className="flex justify-center items-center">
                    {dialogTitleIcon}
                  </div>
                )}

                {dialogTitle && (
                  <Dialog.Title as="h3" className={dialogTitleClassName}>
                    {dialogTitle}
                  </Dialog.Title>
                )}

                {dialogDescription && (
                  <div className={classNames(dialogTitle ? 'mt-2' : '')}>
                    <Dialog.Description className="text-sm leading-5 text-gray-500">
                      {dialogDescription}
                    </Dialog.Description>
                  </div>
                )}

                {children}
                <div className="mt-6 flex justify-between">
                  {checkboxDescription && (
                    <div className="w-full flex justify-start space-x-4 items-center">
                      <input
                        id="model_checkbox"
                        type="checkbox"
                        className="focus:ring-brand-500 h-4 w-4 text-brand-500 border-gray-300 rounded"
                        defaultChecked={checkbox}
                        onClick={() => {
                          setCheckbox?.(!checkbox);
                        }}
                      />
                      <label className="text-sm" htmlFor="model_checkbox">
                        {checkboxDescription}
                      </label>
                    </div>
                  )}
                  <div className={footerButtonContainerClassName}>
                    <Button
                      kind={BUTTON_KIND.WHITE}
                      onClick={() => {
                        onCancel?.();
                        setIsOpen?.(false);
                      }}
                      buttonText={cancelButtonText}
                    />
                    <Button
                      kind={successButtonKind}
                      onClick={onSuccess}
                      buttonText={successButtonText}
                      loading={isLoading}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
