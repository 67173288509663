import { IIcon } from '@/types/IIcon';

const ContactReceiveIcon = ({ className }: IIcon): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="33"
      viewBox="0 0 38 33"
      fill="none"
    >
      <path
        d="M20.3891 13.5141C21.4205 12.4826 22 11.0837 22 9.625C22 8.16631 21.4205 6.76736 20.3891 5.73591C19.3576 4.70446 17.9587 4.125 16.5 4.125C15.0413 4.125 13.6424 4.70446 12.6109 5.73591C11.5795 6.76736 11 8.16631 11 9.625C11 11.0837 11.5795 12.4826 12.6109 13.5141C13.6424 14.5455 15.0413 15.125 16.5 15.125C17.9587 15.125 19.3576 14.5455 20.3891 13.5141Z"
        stroke="#9CA3AF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.6941 22.0691C11.4991 20.2641 13.9473 19.25 16.5 19.25C19.0527 19.25 21.5009 20.2641 23.3059 22.0691C25.1109 23.8741 26.125 26.3223 26.125 28.875H6.875C6.875 26.3223 7.88906 23.8741 9.6941 22.0691Z"
        stroke="#9CA3AF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.4697 16.4697C23.1768 16.7626 23.1768 17.2374 23.4697 17.5303L28.2426 22.3033C28.5355 22.5962 29.0104 22.5962 29.3033 22.3033C29.5962 22.0104 29.5962 21.5355 29.3033 21.2426L25.0607 17L29.3033 12.7574C29.5962 12.4645 29.5962 11.9896 29.3033 11.6967C29.0104 11.4038 28.5355 11.4038 28.2426 11.6967L23.4697 16.4697ZM37 17.75C37.4142 17.75 37.75 17.4142 37.75 17C37.75 16.5858 37.4142 16.25 37 16.25V17.75ZM24 17.75L37 17.75V16.25L24 16.25V17.75Z"
        fill="#9CA3AF"
      />
    </svg>
  );
};

export default ContactReceiveIcon;
