import { useCallback, useState, useEffect, Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Listbox, Tab, Transition } from '@headlessui/react';
import { AxiosError } from 'axios';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { ReactComponent as ToggleOnIcon } from '@/assets/icons/ToggleOnIcon.svg';
import { ReactComponent as ToggleOffIcon } from '@/assets/icons/ToggleOffIcon.svg';
import Layout from '@/components/Layout';
import cardsAPI from '@/api/cards';
import Pagination from '@/components/Pagination';
import Sort from '@/components/Sort';
import Search from '@/components/Search';
import Modal from '@/components/Modal';
import ProfileListItem from '@/components/ProfileListItem';
import useAuth from '@/hooks/useAuth';
import accountsAPI from '@/api/accounts';
import Button, {
  BUTTON_ICON_POSITION,
  BUTTON_KIND,
  BUTTON_SIZE,
} from '@/components/Button';
import InputCheckbox from '@/components/InputCheckbox';
import useAppState from '@/hooks/useAppState';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import FileUploadButtonComponent from '@/components/FileUploadButton';
import profilesAPI from '@/api/profiles';
import IProfile from '@/types/IProfile';
import Spinner from '@/components/Icons/Spinner';
import SuccessAlert from '@/components/SuccessAlert';
import ErrorAlert from '@/components/ErrorAlert';
import classNames from '@/helpers/classNames';
import MESSAGES from '@/constants/messages-en';
import ActivationKeySearch from '@/components/ActivationKeySearch';
import useShopify from '@/hooks/useShopify';
import { InformationProps } from '@/components/DetailsForm';
import organisationsAPI from '@/api/organisations';
import { IOrganisationGroup } from '@/types/IOrganisation';
import GroupListItem from '@/components/GroupListItem';

interface IGroupListPage {
  location: {
    state: {
      success: string;
      setupInfo: boolean;
      activationKey?: string;
      activationKeyFromLogin?: string;
      checkout?: string;
    };
  };
}

const GroupListPage: React.FC<IGroupListPage> = (props) => {
  // paragon is the SDK singleton or `undefined`
  const history = useHistory();
  const { orgID, user, userRole } = useAuth();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [sort, setSort] = useState('date');
  const [order, setOrder] = useState('desc');
  const [search, setSearch] = useState('');
  const [data, setData] = useState<IOrganisationGroup[]>();
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [editorsGroups, setEditorsGroups] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const { selectProfiles } = useAppState();
  const location = useLocation();
  const [success, setSuccess] = useState<string>('');

  interface LocationState {
    success: string;
    // other properties...
  }
  const state = location.state as LocationState;

  const listEditorsGroups = () => {
    const allEditorsGroups: number[] = [];
    data?.forEach((group) => {
      if (group.editors?.length > 0) {
        group.editors?.forEach((editor) => {
          if (
            editor.user.id === user?.id &&
            allEditorsGroups.indexOf(group.id) < 0
          ) {
            allEditorsGroups.push(group.id);
          }
        });
      }
    });
    setEditorsGroups(allEditorsGroups);
  };

  const listGroups = useCallback(
    async (
      newPage: number = page,
      newPageSize: number = pageSize,
      newSort: string = sort,
      newOrder: string = order,
      newSearch: string = search,
      initial = false,
    ) => {
      const res = await organisationsAPI.listOrganisationGroups({
        orgID,
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        order: newOrder,
        search: newSearch,
      });
      if (!initial) {
        setData(res.data?.data);
        setPage(newPage);
        setPageSize(newPageSize);
        setSort(newSort);
        setOrder(newOrder);
      }

      return res.data;
    },
    [order, orgID, page, pageSize, search, sort],
  );
  function handleCheckItem(profileID: number) {
    let arr = checkedItems;
    if (checkedItems.includes(profileID)) {
      arr = arr.filter((e) => e !== profileID);
    } else {
      arr = [...checkedItems, profileID];
    }
    setCheckedItems(arr);
    selectProfiles(arr);
  }

  useEffect(() => {
    if (userRole === 'org_editor') {
      listEditorsGroups();
    }
  }, [data]);

  useEffect(() => {
    if (state) {
      setSuccess(state.success);
    }
  }, [state]);

  return (
    <Layout pageName="Profile Grouping">
      <div className="space-y-1 mt-8">
        {success !== '' && <SuccessAlert message={success} />}
        <div className="flex flex-col space-y-3 items-start sm:flex-row sm:space-y-0 sm:items-center sm:justify-between pt-8">
          {userRole === 'org_admin' && (
            <Button
              kind={BUTTON_KIND.PRIMARY}
              buttonText="Create Group"
              onClick={() => history.push(`/edit-group`)}
            />
          )}
          {orgID && orgID !== -1 && (
            <div className="flex flex-shrink-0 space-x-3">
              <Search
                id={`ProfileList-${page}-${pageSize}-${sort}-${order}-${search}`}
                search={search}
                setSearch={setSearch}
                fetchQuery={(searchQuery) =>
                  listGroups(page, pageSize, sort, order, searchQuery)
                }
              />
              <div className="hidden lg:block">
                <Sort
                  id={`ProfileList-${page}-${pageSize}-${sort}-${order}-${search}`}
                  options={[
                    {
                      sort: 'date',
                      order: 'desc',
                      label: 'Date created (Newest first)',
                    },
                    {
                      sort: 'date',
                      order: 'asc',
                      label: 'Date created (Oldest first)',
                    },
                    {
                      sort: 'first_name',
                      order: 'asc',
                      label: 'Name (A-Z)',
                    },
                    {
                      sort: 'first_name',
                      order: 'desc',
                      label: 'Name (Z-A)',
                    },
                  ]}
                  sort={sort}
                  setSort={setSort}
                  order={order}
                  setOrder={setOrder}
                  fetchQuery={(sortQuery, orderQuery) =>
                    listGroups(page, pageSize, sort, order, sortQuery)
                  }
                />
              </div>
            </div>
          )}
        </div>
        {data && data.length > 0 ? (
          <div className="grid lg:grid-cols-3 xl:grid-cols-4">
            {data?.map((item: IOrganisationGroup) => (
              <>
                {userRole === 'org_editor' && editorsGroups.includes(item.id) && (
                  <div className="relative -mx-4 sm:-mx-6 md:mx-0 lg:-mx-8">
                    <div className="block lg:px-8  w-full lg:flex lg:flex-row">
                      <ul className="col-span-1 mr-1">
                        <GroupListItem
                          key={item.id}
                          id={item.id}
                          noOfEditors={item.editors?.length || 0}
                          groupName={item.name || ''}
                          description={item.description || ''}
                          organisationID={item.organisation_id}
                          organisationName={item.organisation_name || ''}
                          logoURL={item.group_logo?.thumb_url}
                          selected={checkedItems.includes(item.id)}
                          checkItem={() => handleCheckItem(item.id)}
                        />
                      </ul>
                    </div>
                  </div>
                )}
                {userRole !== 'org_editor' && (
                  <div className="relative -mx-4 sm:-mx-6 md:mx-0 lg:-mx-8">
                    <div className="block lg:px-8  w-full lg:flex lg:flex-row">
                      <ul className="col-span-1 mr-1">
                        <GroupListItem
                          key={item.id}
                          id={item.id}
                          noOfEditors={item.editors?.length || 0}
                          groupName={item.name || ''}
                          description={item.description || ''}
                          organisationID={item.organisation_id}
                          organisationName={item.organisation_name || ''}
                          logoURL={item.group_logo?.thumb_url}
                          selected={checkedItems.includes(item.id)}
                          checkItem={() => handleCheckItem(item.id)}
                        />
                      </ul>
                    </div>
                  </div>
                )}
                {isLoading && (
                  <div className="absolute left-1/2 top-1/2 text-gray-500">
                    <Spinner />
                  </div>
                )}
              </>
            ))}
          </div>
        ) : (
          <div className="py-32">
            <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
              {MESSAGES.profile.list.empty.heading}
            </h3>
            <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
              {MESSAGES.profile.list.empty.description}
            </p>
          </div>
        )}
      </div>
      {orgID && orgID !== -1 && userRole === 'org_admin' && (
        <Pagination
          id={`ProfileList-${page}-${pageSize}-${sort}-${order}-${search}`}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          fetchQuery={(pageQuery, pageSizeQuery) => {
            return listGroups(pageQuery, pageSizeQuery, sort, order, search);
          }}
          setIsLoading={setIsLoading}
        />
      )}
      <div className="block" aria-hidden="true">
        <div className="py-8" />
      </div>{' '}
    </Layout>
  );
};

export default GroupListPage;
