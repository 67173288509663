import { useEffect } from 'react';
import {
  Switch,
  Route,
  RouteProps,
  Redirect,
  useLocation,
} from 'react-router-dom';

import ReactGA from 'react-ga4';

// hooks
import useAuth from '@/hooks/useAuth';

// pages
import AccountRecoveryPage from '@/pages/AccountRecoveryPage';
import AccountSettingsPage from '@/pages/SettingsPage';
import AccountSettingsPreviewPage from '@/pages/SettingsPreviewPage';
import AccountSettingsAdminPage from '@/pages/SettingsAdminPage';
import ActivationKeysListPage from '@/pages/ActivationKeysListPage';
import ActivationPage from '@/pages/ActivationPage';
import OldAnalyticsPage from '@/pages/OldAnalyticsPage';
import AnalyticsPage from '@/pages/AnalyticsPage';
import CardsListPage from '@/pages/CardsListPage';
import ContactListPage from '@/pages/ContactListPage';
import ContactListAdminPage from '@/pages/ContactListAdminPage';
import EditProfilePage from '@/pages/EditProfilePage';
import EditProfileAdminPage from '@/pages/EditProfileAdminPage';
import EditSelfProfilePage from '@/pages/EditSelfProfilePage';
import EditSharedProfilePage from '@/pages/EditSharedProfilePage';
import GroupPreviewPage from '@/pages/GroupPreviewPage';
import LoginPage from '@/pages/LoginPage';
import NotFoundPage from '@/pages/NotFoundPage';
import OrganisationsListPage from '@/pages/OrganisationsListPage';
import OrdersListPage from '@/pages/OrdersListPage';
import PreViewPage from '@/pages/PreviewPage';
import ProfileListPage from '@/pages/ProfileListPage';
import ProfileListAdminPage from '@/pages/ProfileListAdminPage';
import ResetPasswordPage from '@/pages/ResetPasswordPage';
import SignUpPage from '@/pages/SignUpPage';
import ViewOrganisationPage from '@/pages/ViewOrganisationPage';
import ViewProfilePage from '@/pages/ViewProfilePage';
import TestComponents from '@/pages/TestComponents';
import AnalyticsAdminPage from '@/pages/AnalyticsAdminPage';
import ShopPage from '@/pages/ShopPage';
import OrdersV2ListPage from '@/pages/OrderV2Page/OrdersV2List/Page';
import OrdersV2ListAdminPage from '@/pages/OrderV2Page/OrdersV2ListAdmin/Page';
import DashBoardPage from '@/pages/DashBoardPage';
import EditGroupPage from '@/pages/EditGroupPage';
import PilotsListPage from '@/pages/PilotsListPage';
import PremiumPage from '@/pages/PremiumPage';
import GroupListPage from '@/pages/GroupListPage';
import PremiumPageComingSoon from '@/pages/PremiumPageComingSoon';
import ViewOrganisationAdminPage from '@/pages/ViewOrganisationAdminPage';
import ShopPageWithoutReorder from '@/pages/ShopPageWithoutReorder';
import PlanPage from '@/pages/PlanPage';
import PlanSelectionPage from '@/pages/PlanSelectionPage';
import Features from '@/types/Features';

const allowReorderOnShopPage =
  process.env.REACT_APP_ALLOW_NEW_REORDER_FLOW === 'true';

function AuthenticatedRoute({ path, component }: RouteProps) {
  const { user } = useAuth();

  if (!user) return <Redirect to="/login" />;

  return <Route exact path={path} component={component} />;
}

export default function Router(): JSX.Element {
  const {
    userScope,
    hasFeature,
    subscriptionType,
    isUserViewForAdmin,
    userRole,
  } = useAuth();
  const isSuperUser = userScope === 'admin';
  const isSaaSEnabled = hasFeature(Features.SaaS);
  const hasPremiumPlan = isSaaSEnabled
    ? !isSuperUser && subscriptionType === 'premium'
    : false;
  const location = useLocation();

  useEffect(() => {
    const gaMeasurementId =
      process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID || 'G-12345';
    ReactGA.initialize(gaMeasurementId);
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${location.pathname}${location.search}`,
    });
  }, [location]);

  return (
    <Switch>
      {isSuperUser && (
        <AuthenticatedRoute exact path="/" component={CardsListPage} />
      )}
      {isSuperUser ? (
        <AuthenticatedRoute
          exact
          path="/profiles-list"
          component={ProfileListAdminPage}
        />
      ) : (
        <AuthenticatedRoute exact path="/" component={ProfileListPage} />
      )}
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/reset-password" component={ResetPasswordPage} />
      <Route exact path="/sign-up" component={SignUpPage} />
      <Route exact path="/account-recovery" component={AccountRecoveryPage} />
      <Route exact path="/view-card/:card_hash_id" component={ActivationPage} />
      <Route exact path="/edit-group" component={EditGroupPage} />
      <Route
        exact
        path="/view-profile/:profile_hash_id"
        component={ViewProfilePage}
      />
      <Route exact path="/view-profile/sample" component={ViewProfilePage} />
      <Route exact path="/preview/:order_uuid" component={PreViewPage} />
      {isSuperUser ? (
        <AuthenticatedRoute
          exact
          path="/settings"
          component={AccountSettingsAdminPage}
        />
      ) : (
        <AuthenticatedRoute
          exact
          path="/settings"
          component={AccountSettingsPage}
        />
      )}
      <AuthenticatedRoute
        exact
        path="/settings/preview"
        component={AccountSettingsPreviewPage}
      />
      <AuthenticatedRoute
        exact
        path="/edit-group/preview"
        component={GroupPreviewPage}
      />
      <AuthenticatedRoute
        exact
        path="/manage-account/:account_id/:first_name/:last_name/:email"
        component={AccountSettingsPage}
      />
      {isSuperUser ? (
        <AuthenticatedRoute
          exact
          path="/contact-list"
          component={ContactListAdminPage}
        />
      ) : (
        <AuthenticatedRoute
          exact
          path="/contact-list"
          component={ContactListPage}
        />
      )}
      {isSuperUser ? (
        <AuthenticatedRoute
          exact
          path="/edit-profile/:profile_id"
          component={EditProfileAdminPage}
        />
      ) : (
        <AuthenticatedRoute
          exact
          path="/edit-profile/:profile_id"
          component={EditProfilePage}
        />
      )}
      <AuthenticatedRoute
        exact
        path="/edit-shared-profile"
        component={EditSharedProfilePage}
      />
      <AuthenticatedRoute exact path="/groups" component={GroupListPage} />
      {isSuperUser ? (
        <AuthenticatedRoute
          exact
          path="/analytics"
          component={AnalyticsAdminPage}
        />
      ) : (
        <AuthenticatedRoute
          exact
          path="/analytics"
          component={OldAnalyticsPage}
        />
      )}
      {isSuperUser ? (
        <AuthenticatedRoute
          exact
          path="/analytics"
          component={AnalyticsAdminPage}
        />
      ) : (
        <AuthenticatedRoute
          exact
          path="/analytics2"
          component={AnalyticsPage}
        />
      )}
      {(isSuperUser ||
        isUserViewForAdmin ||
        (hasPremiumPlan && userRole === 'org_admin')) && (
        <AuthenticatedRoute
          exact
          path="/enhanced_analytics"
          component={DashBoardPage}
        />
      )}
      <AuthenticatedRoute
        exact
        path="/analytics"
        component={OldAnalyticsPage}
      />
      <AuthenticatedRoute exact path="/analytics2" component={AnalyticsPage} />
      {isSuperUser && (
        <AuthenticatedRoute
          exact
          path="/activation-keys"
          component={ActivationKeysListPage}
        />
      )}
      <Route
        exact
        path="/self-edit-profile/:profile_hash_id"
        component={EditSelfProfilePage}
      />
      <Route exact path="/edit-group/:id" component={EditGroupPage} />
      {isSuperUser && (
        <AuthenticatedRoute
          exact
          path="/organisations-list"
          component={OrganisationsListPage}
        />
      )}
      {isSuperUser ? (
        <AuthenticatedRoute
          exact
          path="/view-organisation/:organisation_id"
          component={ViewOrganisationAdminPage}
        />
      ) : (
        <AuthenticatedRoute
          exact
          path="/view-organisation/:organisation_id"
          component={ViewOrganisationPage}
        />
      )}
      {isSuperUser && (
        <AuthenticatedRoute exact path="/orders" component={OrdersListPage} />
      )}
      <Route
        exact
        path="/orders-v2/:orderDetailUUID?"
        render={({ match }) => {
          const { orderDetailUUID } = match.params;
          return isSuperUser ? (
            <OrdersV2ListAdminPage orderDetailUUID={orderDetailUUID} />
          ) : (
            <OrdersV2ListPage />
          );
        }}
      />
      {isSuperUser && (
        <AuthenticatedRoute exact path="/pilots" component={PilotsListPage} />
      )}
      {isSuperUser && (
        <AuthenticatedRoute exact path="/pilots" component={PilotsListPage} />
      )}
      <AuthenticatedRoute
        exact
        path="/shop"
        component={allowReorderOnShopPage ? ShopPage : ShopPageWithoutReorder}
      />
      <AuthenticatedRoute exact path="/premium" component={PremiumPage} />
      <AuthenticatedRoute
        exact
        path="/premium-plans"
        component={PlanSelectionPage}
      />
      <AuthenticatedRoute
        exact
        path="/premium-coming-soon"
        component={PremiumPageComingSoon}
      />
      <AuthenticatedRoute exact path="/plan" component={PlanPage} />
      <Route exact path="/test" component={TestComponents} />
      <Route exact path="/*" component={NotFoundPage} />
      <Route path="/404" component={NotFoundPage} />
    </Switch>
  );
}
