import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import addMonths from 'date-fns/addMonths';
import format from 'date-fns/format';
import { SparklesIcon } from '@heroicons/react/outline';
import Button, { BUTTON_KIND } from '@/components/Button';
import InfoPanelContainer from '../InfoPanelContainer';
import InfoPanelDivider from '../InfoPanelDivider';
import profilesAPI from '@/api/profiles';
import useAuth from '@/hooks/useAuth';
import ModalNoCancel from '../ModalNoCancel';
import saasAPI from '@/api/saas';

const PLANS = new Map([
  [
    'yearly',
    {
      id: process.env.REACT_APP_SAAS_PREMIUM_YEARLY_PLAN_ID ||  'v2-AUD-Yearly',
      name: 'Yearly Premium',
      description: 'AUD $30.00 per year per user',
      unitPrice: 30,
    },
  ],
  [
    'monthly',
    {
      id: process.env.REACT_APP_SAAS_PREMIUM_MONTHLY_PLAN_ID ||  'v2-AUD-Monthly',
      name: 'Monthly Premium',
      description: 'AUD $3.00 per month per user',
      unitPrice: 3,
    },
  ],
]);

export default function OrderOverview(props: { plan: string }): JSX.Element {
  const { plan } = props;
  const { orgID, getCurrentUser } = useAuth();
  const history = useHistory();
  const [totalProfiles, setTotalProfiles] = useState<number>(0);
  const totalPrice = useMemo(() => {
    return (PLANS.get(plan)?.unitPrice ?? 0) * totalProfiles;
  }, [plan, totalProfiles]);
  const [shownSuccessModal, setShownSuccessModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const trialEndDate = addMonths(new Date(), 1);

  const countProfiles = useCallback(() => {
    profilesAPI.listProfiles({ orgID }).then((response) => {
      setTotalProfiles(response.data.paging.total_entries);
    });
  }, [orgID]);

  const createSubscription = (plan: string, quantity: number) => {
    const plan_id = PLANS.get(plan)?.id;
    if (orgID && plan_id) {
      saasAPI.createSubscription(orgID, {plan_id, quantity}).then(() => {
        setShownSuccessModal(true);
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }

  const handleSubmit = () => {
    setIsLoading(true);
    createSubscription(plan, totalProfiles);
  }

  useEffect(() => {
    countProfiles();
  }, [countProfiles]);

  const handleDiscoverPremium = () => {
    getCurrentUser()
    history.push('/plan');
  }

  return (
    <>
      <InfoPanelContainer
        title="Order Summary"
        information="Summary of premium plan order"
      >
        <>
          <div className="block bg-white">
            <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between py-4">
              <div className="min-w-0">
                <p className="text-sm font-medium text-gray-900 truncate">
                  {PLANS.get(plan)?.name}
                </p>
                <p className="flex items-center text-sm text-gray-500 truncate">
                  &nbsp;
                </p>
              </div>
              <div className="inline-flex flex-col lg:flex-row lg:space-x-6 items-start lg:items-center">
                <div className="min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate text-right">
                    7 days free
                  </p>
                  <p className="flex items-center text-sm text-gray-500 truncate  text-right">
                    {PLANS.get(plan)?.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="block bg-white border-b">
            <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between py-4">
              <div className="min-w-0">
                <p className="text-sm font-medium text-gray-900 truncate">
                  Number of profiles
                </p>
                <p className="flex items-center text-sm text-gray-500 truncate">
                  All profiles in the organisation must be part of the same plan
                </p>
              </div>
              <div className="inline-flex flex-col lg:flex-row lg:space-x-6 items-start lg:items-center">
                <div className="min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate text-right">
                    {totalProfiles} profiles
                  </p>
                  <p className="flex items-center text-sm text-gray-500 truncate  text-right">
                    &nbsp;
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="block bg-white">
            <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between py-4">
              <div className="min-w-0">
                <p className="text-sm font-medium text-gray-900 truncate">
                  Subtotal
                </p>
              </div>
              <div className="inline-flex flex-col lg:flex-row lg:space-x-6 items-start lg:items-center">
                <div className="min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate text-right">
                    AUD ${totalPrice.toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="block bg-white border-b">
            <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between py-4">
              <div className="min-w-0">
                <p className="text-sm text-gray-900 truncate">
                  GST (%10 included)
                </p>
              </div>
              <div className="inline-flex flex-col lg:flex-row lg:space-x-6 items-start lg:items-center">
                <div className="min-w-0">
                  <p className="text-sm text-gray-900 truncate text-right">
                    AUD ${(totalPrice / 10).toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="block bg-white">
            <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between py-4">
              <div className="min-w-0">
                <p className="text-sm text-gray-900 truncate">
                  Total after trial
                </p>
              </div>
              <div className="inline-flex flex-col lg:flex-row lg:space-x-6 items-start lg:items-center">
                <div className="min-w-0">
                  <p className="text-sm text-gray-900 truncate text-right">
                    AUD ${totalPrice.toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="block bg-white ">
            <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between py-4">
              <div className="min-w-0">
                <p className="text-sm font-medium text-gray-900 truncate">
                  Total due today
                </p>
              </div>
              <div className="inline-flex flex-col lg:flex-row lg:space-x-6 items-start lg:items-center">
                <div className="min-w-0">
                  <p className="text-sm font-medium text-brand-500 truncate text-right">
                    AUD $0.00
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      </InfoPanelContainer>
      <InfoPanelDivider />
      <InfoPanelContainer
        title="Billing"
        information={
          <>
            After your trial ends, you will be charged AUD ${totalPrice.toFixed(2)} per month starting{' '}
            {format(trialEndDate, 'MMM d, Y')}. You can always cancel for free
            before then.
          </>
        }
      >
        <div className="block bg-white">
          <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between py-4">
            <div className="min-w-0">
              <p className="text-sm font-medium text-gray-900 truncate">
                Trial ends
              </p>
              <p className="flex items-center text-sm text-gray-500 truncate">
                &nbsp;
              </p>
            </div>
            <div className="inline-flex flex-col lg:flex-row lg:space-x-6 items-start lg:items-center">
              <div className="min-w-0">
                <p className="text-sm font-medium text-gray-900 truncate text-right">
                  {format(trialEndDate, 'dd/MM/yy')}
                </p>
                <p className="flex items-center text-sm text-gray-500 truncate  text-right">
                  First billing
                </p>
              </div>
            </div>
          </div>
        </div>
      </InfoPanelContainer>
      <div className="flex space-x-4 justify-end mt-10">
        <Button
          kind={BUTTON_KIND.WHITE}
          buttonText="Cancel"
          onClick={() => setShownSuccessModal(true)}
        />
        <Button
          buttonText="Start free trial"
          loading={isLoading}
          onClick={handleSubmit}
        />
      </div>
      <ModalNoCancel
        isOpen={shownSuccessModal}
        setIsOpen={setShownSuccessModal}
        successButtonText="Discover Premium!"
        successButtonKind={BUTTON_KIND.PRIMARY}
        onSuccess={handleDiscoverPremium}
      >
        <>
          <div className="flex justify-center text-brand-300 text-center mb-2">
            <SparklesIcon
              className="mr-3 flex-shrink-0 h-16 w-16"
              aria-hidden="true"
            />
          </div>
          <p className="text-lg font-medium">Welcome to Premium!</p>
          Congratulations on joining Tapt Premium. We are very excited to have
          you here!
        </>
      </ModalNoCancel>
    </>
  );
}
