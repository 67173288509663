import { useCallback, useEffect, useState } from 'react';
import { UserIcon } from '@heroicons/react/solid';
import { EyeIcon, PencilIcon } from '@heroicons/react/outline';
import { stringTruncate } from '@/helpers/strings';
import Button, { BUTTON_KIND } from '@/components/Button';
import InputCheckbox from '@/components/InputCheckbox';
import Copy from '@/components/Icons/Copy';
import organisationsAPI from '@/api/organisations';

interface IProfileListGroupItem {
  id: number;
  orgID: number;
  firstName?: string;
  lastName?: string;
  title?: string;
  emailAddress?: string;
  groupID: number;
  groupName?: string;
  groupSubheading?: string;
  photoUrl?: string;
  status: string;
  selected: boolean;
  profileHash: string;
  checkItem: () => void;
}

export default function ProfileListGroupItem({
  id,
  orgID,
  firstName,
  lastName,
  title,
  emailAddress,
  groupID,
  groupName,
  groupSubheading,
  photoUrl,
  status,
  selected,
  profileHash,
  checkItem,
}: IProfileListGroupItem): JSX.Element {
  const [clipboardCopy, setClipboardCopy] = useState<boolean>(false);

  return (
    <li key={id}>
      <div className="block bg-white hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="hidden lg:block self-start md:self-center">
            <InputCheckbox
              id={`Checkbox-${status}-${id}`}
              label=""
              value={selected}
              onChange={checkItem}
            />
          </div>
          <div className="lg:hidden self-start md:self-center">
            <InputCheckbox
              id={`Checkbox-${status}-${id}`}
              label=""
              value={selected}
              onChange={checkItem}
            />
          </div>
          {/* ---------------------------------------FULL SCREEN ---------------------------------------*/}
          <div className="min-w-0 flex-1 flex items-start md:items-center">
            <div className="min-w-0 flex-1 lg:grid lg:grid-cols-6 xl:grid-cols-9">
              <div className="hidden lg:block lg:col-span-2 xl:col-span-3 relative">
                <div className="flex flex-col md:flex-row relative items-start md:items-center">
                  <div className="flex-shrink-0 ml-4">
                    {photoUrl ? (
                      <img
                        className="h-10 w-10 rounded-full"
                        src={photoUrl}
                        alt=""
                      />
                    ) : (
                      <div className="hidden lg:block h-10 w-10 rounded-full bg-gray-200 overflow-hidden text-center">
                        <UserIcon className="h-10 w-10 mt-1 rounded-full text-gray-600" />
                      </div>
                    )}
                  </div>
                  <div className="min-w-0 mt-0 ml-12 md:mt-0 md:ml-4">
                    <p className="text-sm font-medium text-gray-900 truncate">
                      {firstName} {lastName}
                    </p>
                    <p className="flex items-center text-sm font-medium text-gray-500">
                      ID:&nbsp;
                      <button
                        type="button"
                        className="appearance-none text-brand-600 text-sm font-medium flex items-center"
                        onClick={() => {
                          navigator.clipboard
                            .writeText(profileHash)
                            .then(() => setClipboardCopy(true))
                            .finally(() =>
                              setTimeout(() => {
                                setClipboardCopy(false);
                              }, 2000),
                            );
                        }}
                      >
                        {clipboardCopy
                          ? 'Copied!'
                          : stringTruncate(profileHash)}
                        <Copy className="h-4 w-4 ml-1" />
                      </button>
                    </p>
                  </div>
                </div>
              </div>
              <div className="hidden lg:block md:mt-0 lg:ml-1 lg:col-span-2 xl:col-span-3 xl:flex flex-col">
                <p className="text-sm text-gray-900 truncate">{title}</p>
                <p className="text-sm text-gray-500 truncate">{emailAddress}</p>
              </div>
              <div className="hidden lg:block lg:col-span-2 xl:col-span-3 relative">
                <div className="flex flex-col md:flex-row relative items-start md:items-center">
                  <div className="min-w-0 mt-0 md:mt-0">
                    <p className="text-sm font-medium text-gray-900 truncate">
                      {groupName}
                    </p>
                    <p className="text-sm text-gray-500 truncate">
                      {groupSubheading}
                    </p>
                  </div>
                </div>
              </div>
              {/* ---------------------------------------MOBILE SCREEN ---------------------------------------*/}
              <div className="lg:hidden items-start">
                <div className="grid grid-cols-6">
                  <div className="col-span-4 mt-0 flex space-x-1 md:mt-0 md:ml-4">
                    <div className="min-w-0 mt-0 ml-12 md:mt-0 md:ml-4">
                      <p className="text-sm font-medium text-gray-900 ml-4 truncate">
                        {firstName} {lastName}
                      </p>
                      <p className="text-sm text-gray-900 truncate ml-4">
                        {emailAddress}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
