/*
Requires password to have:
  - lowercase letter
  - capital letter
  - a number
  - minimum eight characters
*/
export default function validateComplexPassword(password: string): boolean {
  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
  return re.test(String(password));
}
