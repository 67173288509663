import { Fragment, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  EyeIcon,
  UserIcon,
} from '@heroicons/react/outline';
import { Menu, Transition } from '@headlessui/react';
import {
  ChevronDownIcon,
  UserIcon as UserIconSolid,
} from '@heroicons/react/solid';
import moment from 'moment-timezone';
import ColumnChart from '@/components/ColumnChart';
import Layout from '@/components/Layout';
import { CustomScroll } from '../styleds/CustomScroll';
import dashboardApi, {
  ICountryAnalytics,
  ILinksTappedData,
  IRecentContact,
} from '@/api/dashboard';
import useAuth from '@/hooks/useAuth';
import Spinner from '@/components/Icons/Spinner';
import NumberHelper from '@/helpers/number';
import { SOCIALICONS } from '@/constants/socialIcons';
import GeoMap from '@/components/GeoMap';
import organisationsAPI from '@/api/organisations';
import ContactReceiveIcon from '@/components/Icons/ContactReceiveIcon';
import ContactSentIcon from '@/components/Icons/ContactSentIcon';
import { IOrganisationGroup } from '@/types/IOrganisation';

export interface ILinksTapped {
  type: string;
  tapped: number;
  percent?: number;
}

export interface ISortPeriod {
  key: string;
  name: string;
}

export interface ISortPeriodParams {
  startTime: string | null;
  endTime: string | null;
}

interface ITab {
  id: number;
  icon: JSX.Element;
  name: string;
  nameMobile: string;
  number: number;
}

const TIME_FORMAT = 'DD/MM/YYYY';

const SORT_PERIODS = [
  {
    key: 'day',
    name: 'Today',
  },
  {
    key: 'week',
    name: 'This week',
  },
  {
    key: 'month',
    name: 'This month',
  },
  {
    key: 'year',
    name: 'This year',
  },
  {
    key: 'all_time',
    name: 'All time',
  },
];

const DEFAULT_COUNTRY_ANALYTICS = [
  ['Country', 'Taps', 'Profile Views', 'Contacts Added', 'Contacts Exchanged'],
  ['AU', 0, 0, 0, 0],
];

const DEFAULT_LINKS_TAPPED = Array.from({ length: 3 }, (_, i) => i + 1).map(
  () => ({
    type: 'none',
    tapped: 0,
    percent: 0,
  }),
);

function getOrganisationName(isSuperUser: boolean, organisationName: string) {
  return isSuperUser ? 'Tapt’s' : organisationName;
}

export default function DashBoardPage(): JSX.Element {
  const current = moment().format(TIME_FORMAT);
  const defaultPeriod = {
    startTime: current || undefined,
    endTime: current || undefined,
  } as ISortPeriodParams;
  const { orgID, userScope, user } = useAuth();
  const isSuperUser = userScope === 'admin';

  const [dataLinksTapped, setDataLinksTapped] = useState<
    ILinksTappedData[] | []
  >(DEFAULT_LINKS_TAPPED);
  const [countryAnalytics, setCountryAnalytics] = useState<
    (string | number)[][]
  >(DEFAULT_COUNTRY_ANALYTICS);
  const [periodParams, setPeriodParams] = useState(defaultPeriod);
  const [organisationName, setOrganisationName] = useState<string>('');
  const [period, setPeriod] = useState<string>();
  const [groupID, setGroupID] = useState<number>();
  const [groupName, setGroupName] = useState<string>();
  const [listOrganisationlist, setListOrganisationlist] =
    useState<IOrganisationGroup[]>();
  const most_active_card_limit = 6;
  const recent_contacts_limit = 10;

  const { data: eventTrackingData, isLoading: isLoadingEventTracking } =
    useQuery(['get_event_tracking', periodParams, groupID, isSuperUser], () => {
      if (isSuperUser) {
        return dashboardApi.getEventTrackingForAdmin(periodParams);
      }
      return dashboardApi.getEventTracking(
        periodParams,
        orgID || 1,
        groupID,
        most_active_card_limit,
        recent_contacts_limit,
      );
    });

  useEffect(() => {
    if (!orgID) {
      return;
    }
    organisationsAPI
      .showOrganisation(orgID)
      .then((res) => res.data.data)
      .then((data) => {
        setOrganisationName(data.name);
      })
      .catch(() => {})
      .finally(() => {});

    organisationsAPI
      .showAllOrganisationGroup(orgID)
      .then((res) => {
        const data = res.data?.data;
        if (Array.isArray(data)) {
          return data;
        }
        throw new Error('Invalid response from API');
      })
      .then((data: IOrganisationGroup[]) => {
        setListOrganisationlist(data);
      });
  }, [orgID]);

  useEffect(() => {
    if (eventTrackingData) {
      const analyticsHeader = [
        'Country',
        'Taps',
        'Profile Views',
        'Contacts Added',
        'Contacts Exchanged',
      ];
      const analyticsData = eventTrackingData.data.countries_analytics?.map(
        (item: ICountryAnalytics) => {
          const result = [
            item.country_name,
            item.country_analytics?.link_tapped,
            item.country_analytics?.viewed_contact_count,
            item.country_analytics?.sent_contact_count,
            item.country_analytics?.received_contact_count,
          ];
          return result;
        },
      );
      setCountryAnalytics([analyticsHeader, ...analyticsData]);
      const linksTapped = eventTrackingData?.data?.links_tapped;
      if (!linksTapped) {
        return;
      }
      const totalLinksTapped: number | undefined = linksTapped?.reduce(
        (accumulator: number, currentValue: ILinksTappedData) =>
          Number(accumulator) + Number(currentValue?.tapped || 0),
        0,
      );
      const newLinksTapped: ILinksTappedData[] = linksTapped
        ?.map((e: ILinksTappedData) => {
          const tapped = Number(e?.tapped) || 0;
          return {
            type: e?.type,
            tapped,
            percent: NumberHelper.toFixedIfNecessary(
              totalLinksTapped ? (tapped / (totalLinksTapped || 1)) * 100 : 0,
              2,
            ),
          } as ILinksTapped;
        })
        .sort((a: ILinksTapped, b: ILinksTapped) => b.tapped - a.tapped)
        .slice(0, 3);
      if (newLinksTapped.length > 0) {
        setDataLinksTapped(newLinksTapped);
      } else {
        setDataLinksTapped(DEFAULT_LINKS_TAPPED);
      }
    }
  }, [eventTrackingData]);

  const getPeriod = (periodType: string) => {
    let startTime;
    let endTime;
    switch (periodType) {
      case 'all_time':
        startTime = null;
        endTime = null;
        break;

      case 'year':
        startTime = moment().startOf('year').format(TIME_FORMAT);
        endTime = moment().format(TIME_FORMAT);
        break;

      case 'month':
        startTime = moment().startOf('month').format(TIME_FORMAT);
        endTime = moment().format(TIME_FORMAT);
        break;

      case 'week':
        startTime = moment().startOf('week').format(TIME_FORMAT);
        endTime = moment().format(TIME_FORMAT);
        break;

      default:
        startTime = moment().format(TIME_FORMAT);
        endTime = moment().format(TIME_FORMAT);
        break;
    }
    return { startTime, endTime };
  };

  const handleSortByPeriod = (period: ISortPeriod) => {
    const params = getPeriod(period.key);
    setPeriod(period.name);
    setPeriodParams(params);
  };
  const handleFilterByGroup = (groupID: number, groupName: string) => {
    setGroupID(groupID);
    setGroupName(groupName);
  };

  const tabs = [
    {
      id: 1,
      icon: (
        <div className="flex items-center justify-center w-7 h-7 md:w-13 md:h-13 md:ml-1">
          <ContactReceiveIcon />
        </div>
      ),
      name: 'Total contacts recieved',
      nameMobile: 'Contacts recieved',
      number:
        eventTrackingData?.data?.contact_count?.received_contact_count || 0,
    },
    {
      id: 2,
      nameMobile: 'Profiles views',
      icon: (
        <div className="flex items-center justify-center w-7 h-7 md:w-13 md:h-13">
          <EyeIcon
            className="text-gray-400  md:stroke-1.3 stroke-2"
            style={{ width: '42.24px', height: '29.25px' }}
          />
        </div>
      ),
      name: 'Total profiles views',
      number: eventTrackingData?.data?.contact_count?.viewed_contact_count || 0,
    },
    {
      id: 3,
      nameMobile: 'Contacts sent',
      icon: (
        <div className="flex items-center justify-center w-7 h-7 md:w-13 md:h-13 sm:ml-1">
          {/* <UserIcon
            className="w-7 text-gray-400 md:stroke-1.3 stroke-2"
          />
          <ArrowRightIcon
            className="w-4 text-gray-400 md:stroke-1.3 stroke-2"
            style={{ marginLeft: '-6px' }}
          /> */}
          <ContactSentIcon />
        </div>
      ),
      name: 'Total contacts sent',
      number: eventTrackingData?.data?.contact_count?.sent_contact_count || 0,
    },
  ];

  return (
    <>
      <Layout pageName="Dashboard" showPageName={false}>
        <div className="mt-8 sm:mt-20"> </div>
        <div>
          <p className="hidden sm:block text-brand-500 text-5xl leading-7 font-normal ">
            <span className="font-semibold">Hi</span> {user?.first_name}!
          </p>
          <p className="hidden sm:block text-gray-900 font-poppins text-4xl leading-7 font-normal mt-8">
            {' '}
            Welcome to{' '}
            <span className="font-medium">
              {getOrganisationName(isSuperUser, organisationName)}
            </span>{' '}
            enhanced analytics.
          </p>
          <p className="sm:hidden text-gray-900 font-poppins text-3xl leading-7 font-normal mt-9">
            {' '}
            <span className="font-medium">
              {getOrganisationName(isSuperUser, organisationName)}
            </span>{' '}
            analytics.
          </p>
        </div>
        <div className="grid grid-cols-6">
          <div className="col-span-6 sm:col-span-5 grid grid-cols-3 sm:gap-3 mt-4 p-2 md:p-7 bg-white border rounded-md border-gray-50 shadow-sm sm:shadow-none  sm:bg-gray-50 sm:border-none">
            {tabs.map((item: ITab, index: number) => (
              <div
                className={`${
                  index === tabs.length - 1
                    ? 'border-none'
                    : 'border-r md:border-r-2 border-gray-50 md:border-white md:mr-7'
                }`}
                key={item.id}
              >
                <div className="flex flex-col-reverse sm:flex-row items-center">
                  <div className="flex gap-3">
                    <div className="flex items-center justify-center rounded-md sm:w-13 bg-white">
                      {item.icon}
                    </div>
                    <div className="flex items-center justify-center">
                      {' '}
                      {isLoadingEventTracking ? (
                        <Spinner />
                      ) : (
                        <span className="text-brand-500 font-medium text-xl sm:text-3xl sm:leading-7 flex items-end">
                          {item.number}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-end sm:mt-2">
                    <span
                      className="hidden sm:block text-gray-900 sm:text-gray-800 font-poppins font-medium text-tiny sm:text-xs sm:ml-1 md:mt-1"
                      style={{ fontSize: 10, lineHeight: '28px' }}
                    >
                      {item.name}
                    </span>
                    <span className="sm:hidden text-gray-900 sm:text-gray-800 font-poppins leading-7 font-medium text-tiny sm:text-xs sm:ml-1">
                      {item.nameMobile}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            className={
              isSuperUser
                ? 'fixed md:static top-2 right-7  col-span-1 md:flex justify-end items-center'
                : 'flex fixed md:static top-2 right-7 md:mt-4 col-span-1 md:grid md:row-span-2 justify-end'
            }
          >
            <Menu as="div" className="text-left relative">
              <div>
                <Menu.Button
                  className="inline-flex justify-center w-max rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-brand-500"
                  style={{ width: '146px' }}
                >
                  {period !== undefined ? period : 'Sort by date'}
                  <ChevronDownIcon
                    className="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                  <div className="py-1">
                    {SORT_PERIODS.map((period: ISortPeriod) => (
                      <Menu.Item key={period.key}>
                        <button
                          type="button"
                          onClick={() => {
                            handleSortByPeriod(period);
                          }}
                          className="appearance-none text-gray-700 w-full text-left block px-4 py-2 text-sm"
                        >
                          {period.name}
                        </button>
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            <Menu
              as="div"
              className={
                isSuperUser
                  ? 'hidden'
                  : 'text-left md:flex md:items-end relative hidden '
              }
            >
              <div>
                <Menu.Button
                  className="inline-flex justify-center w-max rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-brand-500 "
                  style={{ width: '146px' }}
                >
                  {groupName !== undefined ? groupName : 'Filters group'}
                  <ChevronDownIcon
                    className="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                  <div className="py-1">
                    {listOrganisationlist?.map((item: IOrganisationGroup) => (
                      <Menu.Item key={item.id}>
                        <button
                          type="button"
                          onClick={() => {
                            handleFilterByGroup(item.id, item.name);
                          }}
                          className="appearance-none text-gray-700 w-full text-left block px-4 py-2 text-sm"
                        >
                          {item.name}
                        </button>
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>

        <div className="mt-6"> </div>
        <div className="flex lg:flex-row flex-col-reverse gap-6 pb-9">
          <div className="flex flex-col md:w-5/12">
            {/* Desktop: Most active card */}
            <div className="hidden md:block md:bg-gray-50 rounded-md h-80">
              <p
                className="md:pt-5 md:pl-4 text-gray-900 font-medium text-lg"
                style={{ lineHeight: '18px' }}
              >
                Most Active Cards
              </p>
              {eventTrackingData && (
                <div className="mt-3 border rounded-md border-gray-50 shadow-sm md:shadow-none  md:bg-gray-50 md:border-none">
                  <ColumnChart
                    dataMostActiveCards={
                      eventTrackingData.data?.most_active_card
                    }
                  />
                </div>
              )}
              {!eventTrackingData && <div className="h-64" />}
            </div>

            {/* Mobile: Geo */}
            <div className="md:hidden sm:p-5 sm:bg-gray-50 w-full">
              <p
                className="text-gray-900 font-medium text-lg "
                style={{ lineHeight: '18px' }}
              >
                Tap Geolocation
              </p>
              <div className="w-full h-full px-2 box-border mt-4">
                <GeoMap data={countryAnalytics} />
              </div>
            </div>
            <div style={{ marginTop: '26px' }}> </div>

            {/* Recent contacts */}
            <div className="flex-1 flex flex-col">
              <p
                className=" text-gray-900 font-medium text-lg"
                style={{ lineHeight: '18px' }}
              >
                Recent Contacts
              </p>
              <div className="flex flex-col mt-2 md:mt-5 flex-1">
                <div className="block w-full relative shadow-sm border border-gray-200 rounded-md min-h-8 h-full">
                  <div className="border-b px-4 py-2.5 font-semibold grid grid-cols-2 min-w-0 flex-1 text-black uppercase">
                    <p className="col-span-1 flex text-xs md:text-xs font-medium text-start justify-start">
                      Name
                    </p>
                    <p className="col-span-1 flex text-xs md:text-sm font-medium justify-center items-center">
                      Contact information
                    </p>
                  </div>

                  {eventTrackingData?.data?.recent_contacts ? (
                    <CustomScroll
                      className={`${
                        (eventTrackingData?.data.recent_contacts || [])
                          .length === 0
                          ? 'flex justify-center items-center h-full'
                          : ''
                      } outside-scroll`}
                      style={{ maxHeight: '131px' }}
                    >
                      <ul
                        className="divide-y divide-gray-200 overflow-auto p-0 "
                        style={{ maxHeight: '131px' }}
                      >
                        {(eventTrackingData?.data.recent_contacts || [])
                          .length > 0 ? (
                          (eventTrackingData?.data.recent_contacts || []).map(
                            (item: IRecentContact, index: number) => (
                              <li
                                className="z-50 grid grid-cols-2 gap-1 items-center hover:bg-gray-50 p-3 md:p-4 sm:px-6"
                                key={index}
                              >
                                <div className="col-span-1 flex gap-1">
                                  {item?.profile?.photo?.thumb_url ? (
                                    <img
                                      className="h-7 w-7 md:h-10 md:w-10 rounded-full"
                                      src={item.profile.photo?.thumb_url}
                                      alt=""
                                    />
                                  ) : (
                                    <div className="h-7 w-7 md:h-10 md:w-10 rounded-full bg-gray-200 overflow-hidden text-center">
                                      <UserIconSolid className="h-7 w-7 md:h-10 md:w-10 mt-1 rounded-full text-gray-600" />
                                    </div>
                                  )}

                                  <div className="flex-1 md:ml-2 w-1/2">
                                    <p className="text-xs md:text-sm leading-5 font-medium text-gray-800 truncate ">
                                      {`${item?.profile?.first_name || ''} ${
                                        item?.profile?.last_name || ''
                                      }`}
                                    </p>
                                    <p
                                      title={item.profile?.job_title || ''}
                                      className="w-full text-xs md:text-sm leading-5 font-medium text-gray-500 truncate block"
                                    >
                                      {item.profile?.job_title || ''}
                                    </p>
                                  </div>
                                </div>

                                <div className="col-span-1 flex-col max-w-10">
                                  <p className="text-xs md:text-sm leading-5 font-medium text-gray-800 truncate">
                                    {item.profile?.mobile_number || ''}
                                  </p>
                                  <p
                                    title={item.profile?.email || ''}
                                    className="text-xs md:text-sm leading-5 font-medium text-gray-500 truncate"
                                  >
                                    {item.profile?.email || ''}
                                  </p>
                                </div>
                              </li>
                            ),
                          )
                        ) : (
                          <p className="text-gray-500 text-poppins">
                            No recent contact available to show.
                          </p>
                        )}
                      </ul>
                    </CustomScroll>
                  ) : (
                    <div className="h-8.6rem flex justify-center items-center text-brand-500">
                      <Spinner />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex-1 min-w-0">
            {/* Linked Tapped */}
            <div className="md:bg-gray-50 rounded-md">
              <div className="sm:p-5">
                <p
                  className="text-gray-900 font-medium text-lg "
                  style={{ lineHeight: '18px' }}
                >
                  Links Tapped
                </p>
                <div className="mt-3 sm:mt-4"> </div>
                <div
                  className={`${
                    dataLinksTapped && dataLinksTapped.length > 0
                      ? 'grid grid-rows-1 sm:gap-3 mt-4 p-2 sm:p-0 bg-white border rounded-md  border-gray-50 shadow-sm sm:shadow-none  sm:bg-gray-50 sm:border-none'
                      : ''
                  } grid grid-cols-6 sm:gap-8 `}
                >
                  {dataLinksTapped ? (
                    dataLinksTapped.map(
                      (item: ILinksTappedData, index: number) => (
                        <div
                          className={`${
                            index === dataLinksTapped.length - 1
                              ? 'border-none'
                              : 'border-r border-gray-50'
                          } col-span-2 inline-flex items-center justify-center md:items-center sm:border-none`}
                          key={index}
                        >
                          <div className=" h-10 w-10 text-gray-400">
                            {item?.type && SOCIALICONS[item.type]}
                            {item?.type === 'none' && SOCIALICONS.linkedin}
                          </div>

                          <div className="flex-col ml-3 flex items-center justify-center sm:items-start sm:justify-start">
                            {' '}
                            <p className="text-brand-500 sm:text-brand-400 text-xl sm:text-sm font-medium sm:leading-6">
                              {item.tapped}
                              <span className="hidden sm:ml-1 sm:inline-block text-gray-800 text-sm font-normal leading-6">
                                taps
                              </span>
                            </p>
                            <p className="hidden sm:inline-block text-brand-400 text-sm font-medium leading-6">
                              {item.percent}
                              {'% '}
                              <span className="text-gray-800 text-sm font-normal leading-6">
                                of total taps
                              </span>
                            </p>
                          </div>
                        </div>
                      ),
                    )
                  ) : (
                    <div className="h-12 w-full flex justify-center items-center text-brand-500">
                      <Spinner />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Mobile: Most active card part */}
            <div className="md:hidden md:bg-gray-50 rounded-md mt-5">
              <p
                className="md:pt-5 md:pl-4 text-gray-900 font-medium text-lg"
                style={{ lineHeight: '18px' }}
              >
                Most Active Cards
              </p>
              {eventTrackingData && (
                <div className="mt-3 border rounded-md md:w-488  border-gray-50 shadow-sm md:shadow-none  md:bg-gray-50 md:border-none">
                  <ColumnChart
                    dataMostActiveCards={
                      eventTrackingData.data?.most_active_card
                    }
                  />
                </div>
              )}
              {!eventTrackingData && <div className="h-64" />}
            </div>

            <div className="mt-6"> </div>

            {/* Desktop: Geo */}
            <div className="hidden md:block sm:p-5 sm:bg-gray-50 w-full">
              <p
                className="text-gray-900 font-medium text-lg "
                style={{ lineHeight: '18px' }}
              >
                Tap Geolocation
              </p>
              <div className="w-full h-full px-2 box-border mt-4">
                <GeoMap data={countryAnalytics} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
