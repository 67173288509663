import { useState } from 'react';
import { ChromePicker } from 'react-color';

export default function ColorPicker({
  color,
  setColor,
}: {
  color: string;
  setColor: React.Dispatch<React.SetStateAction<string>>;
}): JSX.Element {
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);

  function handleChangeComplete(chromeColor: { hex: string }) {
    setColor(chromeColor.hex);
  }

  return (
    <div className="relative flex space-x-2">
      <div className="relative flex rounded-md">
        <span
          className="inline-flex items-center px-5 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm"
          style={{ backgroundColor: color }}
        />
        <span className="flex-1 block w-full px-4 py-2 border shadow-sm rounded-r-md">
          {color}
        </span>
      </div>
      <button
        type="button"
        onClick={() => setDisplayColorPicker(true)}
        className="appearance-none text-brand-500 text-sm font-medium"
      >
        Pick color
      </button>
      {displayColorPicker ? (
        <div className="absolute right-0 z-10">
          <div
            className="fixed inset-0"
            onClick={() => setDisplayColorPicker(false)}
            aria-hidden="true"
          />
          <ChromePicker
            color={color}
            onChangeComplete={(chromeColor) =>
              handleChangeComplete(chromeColor)
            }
          />
        </div>
      ) : null}
    </div>
  );
}
