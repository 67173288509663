import { useCallback, useEffect, useRef, useState } from 'react';
import {
  CardComponent,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from '@chargebee/chargebee-js-react-wrapper';
import ChargebeeComponents from '@chargebee/chargebee-js-react-wrapper/dist/components/ComponentGroup';
import Button, { BUTTON_KIND } from '@/components/Button';
import Input from '@/components/Input';
import useAuth from '@/hooks/useAuth';
import organisationsAPI from '@/api/organisations';
import InputSelect from '../InputSelect';
import saasAPI from '@/api/saas';
import ErrorAlert from '../ErrorAlert';


export default function BillingInformation(props: {
  onNext: () => void;
}): JSX.Element {
  const { onNext } = props;
  const { user, orgID } = useAuth();

  const [customerId, setCustomerId] = useState<string>('');
  const [organisationName, setOrganisationName] = useState<string>('');
  const [firstName, setFirstName] = useState<string>(user?.first_name || '');
  const [lastName, setLastName] = useState<string>(user?.last_name || '');
  const [email, setEmail] = useState<string>(user?.email || '');
  const [country, setCountry] = useState<string>('AU');
  const [streetAddress, setStreetAddress] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [stateProvince, setStateProvince] = useState<string>('');
  
  const [loading, setLoading] = useState<boolean>(false);
  const [cardError, setCardError] = useState<string>('');
  const cardRef = useRef<ChargebeeComponents>(null);

  const showOrganisation = useCallback(() => {
    if (orgID) {
      organisationsAPI.showOrganisation(orgID).then((res) => {
        setOrganisationName(res?.data?.data?.name);
      });
    }
  }, [orgID, setOrganisationName]);

  const addPaymentCard = (orgID: number) => {
    if (cardRef.current) {
      cardRef.current
        .tokenize({})
        .then((data: any) => {
          saasAPI.addPaymentCard(orgID, { token_id: data.token }).then((res) => {
            onNext();
          });
        })
        .catch((e:any) => {
          setCardError(
            'Problem while tokenizing your card details. Please try again later',
          );
        });
    }
  }
  const createBillingAddress = () => {
    if (orgID) {
      if (customerId) {
        addPaymentCard(orgID);
      } else {
        saasAPI.createBillingAddress(orgID, {
          first_name: firstName,
          last_name: lastName,
          email,
          company: organisationName,
          country,
          line1: streetAddress,
          city,
          state: stateProvince,
          zip: postalCode,
        }).then((res) => {
          addPaymentCard(orgID);
          setCustomerId(res?.data?.data?.customer_id);
        }).catch((e) => {
          console.log('error creating billing address');
        })
      }
    }
  };

  useEffect(() => {
    showOrganisation();
  }, [showOrganisation]);

  function handleSubmit(): void {
    setLoading(true);
    createBillingAddress();
  }

  return (
    <>
      <section aria-labelledby="billing-details-heading">
        <div className="mt-6 border-b border-gray-200 pb-6">
          <div className="space-y-6 bg-white">
            <div>
              <h2
                id="billing-details-heading"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Billing details
              </h2>
            </div>
          </div>

          <div className="mt-6">
            <div className="px-4 py-5 bg-white sm:p-6  rounded-md border border-gray-200">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <Input
                    label="First name"
                    type="text"
                    value={firstName}
                    onChange={setFirstName}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <Input
                    label="Last name"
                    type="text"
                    value={lastName}
                    onChange={setLastName}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <Input
                    label="Email"
                    type="text"
                    value={email}
                    onChange={setEmail}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section aria-labelledby="billing-address-heading">
        <div className="mt-6 border-b border-gray-200 pb-6">
          <div className="space-y-6 bg-white">
            <div>
              <h2
                id="billing-address-heading"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Billing address
              </h2>
            </div>
          </div>

          <div className="mt-6">
            <div className="px-4 py-5 bg-white sm:p-6 rounded-md border border-gray-200">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-4">
                  <Input
                    label="Company name"
                    type="text"
                    value={organisationName}
                    onChange={setOrganisationName}
                  />
                </div>
                <div className="col-span-4">
                  <InputSelect
                    id="country"
                    label="Country / Region"
                    value={country}
                    onChange={(value) => {
                      setCountry(value);
                    }}
                    options={[{ label: 'Australia', value: 'AU' }]}
                  />
                </div>
                <div className="col-span-6">
                  <Input
                    label="Street address"
                    type="text"
                    value={streetAddress}
                    onChange={setStreetAddress}
                  />
                </div>
                <div className="col-span-6 sm:col-span-2">
                  <Input
                    label="City"
                    type="text"
                    value={city}
                    onChange={setCity}
                  />
                </div>
                <div className="col-span-6 sm:col-span-2">
                  <Input
                    label="State / Province"
                    type="text"
                    value={stateProvince}
                    onChange={setStateProvince}
                  />
                </div>
                <div className="col-span-6 sm:col-span-2">
                  <Input
                    label="Zip / Postal"
                    type="text"
                    value={postalCode}
                    onChange={setPostalCode}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section aria-labelledby="payment-details-heading">
        <div className="mt-6 pb-6">
          <div className="space-y-6 bg-white">
            <div>
              <h2
                id="payment-details-heading"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Payment details
              </h2>
            </div>
          </div>
        </div>
        <div>
          <div className="px-4 py-5 bg-white sm:p-6 rounded-md border border-gray-200">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-4">
                {cardError && <ErrorAlert message={cardError} />}
                <CardComponent ref={cardRef} className="fieldset field" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="flex space-x-4 justify-end mt-10">
        <Button
          kind={BUTTON_KIND.WHITE}
          buttonText="Cancel"
          onClick={() => console.log('cancel')}
        />
        <Button loading={loading} buttonText="Purchase plan" onClick={createBillingAddress} />
      </div>
    </>
  );
}
