import { IIcon } from '@/types/IIcon';

const ContactSentIcon = ({ className }: IIcon): JSX.Element => {
  return (
    <svg
      width="38"
      height="33"
      viewBox="0 0 38 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3891 13.5141C21.4205 12.4826 22 11.0837 22 9.625C22 8.16631 21.4205 6.76736 20.3891 5.73591C19.3576 4.70446 17.9587 4.125 16.5 4.125C15.0413 4.125 13.6424 4.70446 12.6109 5.73591C11.5795 6.76736 11 8.16631 11 9.625C11 11.0837 11.5795 12.4826 12.6109 13.5141C13.6424 14.5455 15.0413 15.125 16.5 15.125C17.9587 15.125 19.3576 14.5455 20.3891 13.5141Z"
        stroke="#9CA3AF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.6941 22.0691C11.4991 20.2641 13.9473 19.25 16.5 19.25C19.0527 19.25 21.5009 20.2641 23.3059 22.0691C25.1109 23.8741 26.125 26.3223 26.125 28.875H6.875C6.875 26.3223 7.88906 23.8741 9.6941 22.0691Z"
        stroke="#9CA3AF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 16.25C23.5858 16.25 23.25 16.5858 23.25 17C23.25 17.4142 23.5858 17.75 24 17.75V16.25ZM37.5303 17.5303C37.8232 17.2374 37.8232 16.7626 37.5303 16.4697L32.7574 11.6967C32.4645 11.4038 31.9896 11.4038 31.6967 11.6967C31.4038 11.9896 31.4038 12.4645 31.6967 12.7574L35.9393 17L31.6967 21.2426C31.4038 21.5355 31.4038 22.0104 31.6967 22.3033C31.9896 22.5962 32.4645 22.5962 32.7574 22.3033L37.5303 17.5303ZM24 17.75L37 17.75V16.25L24 16.25V17.75Z"
        fill="#9CA3AF"
      />
    </svg>
  );
};

export default ContactSentIcon;
