import { useState } from 'react';
import { ICOUNTRIES, IStep3, ISTATES } from '@/types/ReOrderExperience/IStep3';
import ShippingAddress from '@/components/ReOrderPage/Custom Payment/Step3/ShippingAddress';
import BillingAddress from '@/components/ReOrderPage/Custom Payment/Step3/BillingAddress';

export default function ReorderConfirmation(props: IStep3): JSX.Element {
  const {
    shippingInformation,
    setShippingInformation,
    billingInformation,
    setBillingInformation,
    billAddressCheckBox,
    setBillAddressCheckBox,
    error,
  } = props;

  const COUNTRIES: ICOUNTRIES[] = [{ label: 'Australia', value: 'AU' }];

  const STATES: ISTATES[] = [
    {
      label: 'Australian Capital Territory',
      value: 'ACT',
    },
    { label: 'New South Wales', value: 'NSW' },
    { label: 'Northern Territory', value: 'NT' },
    { label: 'Queensland', value: 'QLD' },
    { label: 'South Australia', value: 'SA' },
    { label: 'Tasmania', value: 'VIC' },
    { label: 'Western Australia', value: 'WA' },
  ];

  return (
    <>
      <ShippingAddress
        shippingInformation={shippingInformation}
        setShippingInformation={setShippingInformation}
        billAddressCheckBox={billAddressCheckBox}
        setBillAddressCheckBox={setBillAddressCheckBox}
        COUNTRIES={COUNTRIES}
        STATES={STATES}
        error={error}
      />
      {!billAddressCheckBox && (
        <BillingAddress
          billingInformation={billingInformation}
          setBillingInformation={setBillingInformation}
          COUNTRIES={COUNTRIES}
          STATES={STATES}
        />
      )}
    </>
  );
}
