import { useState } from 'react';
import { Tab } from '@headlessui/react';
import { useHistory } from 'react-router-dom';
import { ConnectSDK, IntegrationMetadata } from 'paragon';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Layout from '@/components/Layout';
import TabList from '@/components/TabList';
import useParagonGlobal from '@/hooks/useParagonGlobal';
import useParagonAuth from '@/hooks/useParagonAuth';
import useAuth from '@/hooks/useAuth';
import Button from '@/components/Button';
import UserProvisioningTab from '@/components/PremiumPage/UserProvisioningTab';
import useAppState from '@/hooks/useAppState';
import { IOrganisationGroup } from '@/types/IOrganisation';

const tabs = [
  {
    name: 'CRM Integrations',
    value: 'crm-integrations',
    current: true,
  },
  {
    name: 'User Provisioning',
    value: 'user-provisioning',
  },
];

export default function PremiumPage(): JSX.Element {
  // paragon is the SDK singleton or `undefined`
  const paragon = useParagonGlobal();
  const { orgID } = useAuth();
  const { user } = useParagonAuth(paragon, orgID ? orgID.toString(10) : '');
  const [tab, setTab] = useState<string>(tabs[0].value);
  const [success, setSuccess] = useState<string>('Change successful');
  const [error, setError] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [sort, setSort] = useState('date');
  const [order, setOrder] = useState('desc');
  const [search, setSearch] = useState('');
  const [data, setData] = useState<IOrganisationGroup[]>();
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<IOrganisationGroup>();
  const { selectProfiles } = useAppState();

  const renderLoadingSekeleton = () => {
    const list = ['hubspot', 'salesforce', 'msdynamics'];
    return list.map((integrationName) => {
      return (
        <li
          key={integrationName}
          className="col-span-1 flex flex-col rounded-lg bg-white text-center shadow"
        >
          <div className="flex flex-1 flex-col p-6">
            <Skeleton className="mx-auto h-16 w-16 flex-shrink-0" />
            <h3 className="mt-3 text-sm font-medium text-gray-900">
              <Skeleton />
            </h3>
            <dl className="mt-3 flex flex-grow flex-col justify-between">
              <dt className="sr-only">Title</dt>
              <dd className="text-sm text-gray-500">
                <Skeleton />
              </dd>
              <dt className="sr-only">Status</dt>
              <dd className="mt-3">
                <Skeleton />
              </dd>
            </dl>
          </div>
          <div className="px-6 pb-6">
            <Skeleton />
          </div>
        </li>
      );
    });
  };

  const renderIntegrations = (paragon: ConnectSDK | undefined) => {
    if (!paragon) return renderLoadingSekeleton();
    if (!paragon.getIntegrationMetadata()) return renderLoadingSekeleton();
    if (paragon.getIntegrationMetadata().length === 0) return renderLoadingSekeleton();

    return paragon.getIntegrationMetadata().map((integration) => {
          const integrationEnabled =
            user?.integrations?.[integration.type]?.enabled;
          return (
            <li
              key={integration.type}
              className="col-span-1 flex flex-col rounded-lg bg-white text-center shadow"
            >
              <div className="flex flex-1 flex-col p-6">
                <img
                  className="mx-auto h-16 w-16 flex-shrink-0"
                  src={integration.icon}
                  alt=""
                />
                <h3 className="mt-3 text-sm font-medium text-gray-900">
                  {integration.name}
                </h3>
                <dl className="mt-3 flex flex-grow flex-col justify-between">
                  <dt className="sr-only">Title</dt>
                  <dd className="text-sm text-gray-500">
                    Connect Tapt to 4000+ apps to automate any task.
                  </dd>
                  <dt className="sr-only">Status</dt>
                  <dd className="mt-3">
                    {integrationEnabled ? (
                      <span className="rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800">
                        Connected
                      </span>
                    ) : (
                      <span className="rounded-full bg-gray-100 px-2 py-1 text-xs font-medium text-gray-800">
                        Not connected
                      </span>
                    )}
                  </dd>
                </dl>
              </div>
              <div className="px-6 pb-6">
                <Button
                  buttonText="Configure"
                  onClick={() => paragon.connect(integration.type)}
                  fullWidth
                />
              </div>
            </li>
          );
        });
  };

  return (
    <Layout pageName="Premium">
      <Tab.Group
        onChange={(index: number) => {
          setTab(tabs[index].value);
        }}
      >
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full md:border-b border-gray-200">
          <div className="pt-4 md:pt-0 w-full md:w-auto">
            <TabList tabs={tabs} value={tab} />
          </div>
        </div>

        <Tab.Panels>
          <Tab.Panel className="outline-none">
            <div className="space-y-1 mt-10">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                CRM Integrations
              </h3>
              <p className="max-w-2xl text-sm text-gray-500">
                Connect your Tapt data to your CRM platform through our CRM
                integration support.
              </p>
            </div>

            <div className="mt-10">
              <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                {renderIntegrations(paragon)}

              </ul>
            </div>
          </Tab.Panel>
          <Tab.Panel className="outline-none">
            <UserProvisioningTab />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </Layout>
  );
}
