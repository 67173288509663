import { css, cx } from '@emotion/css';
import Iframe from 'react-iframe';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import useAuth from '@/hooks/useAuth';
import Layout from '@/components/Layout';
import Button, { BUTTON_KIND } from '@/components/Button';
import { buildNewUrl } from '@/helpers/urlHandler';

interface ISettingsPreviewPage {
  history: RouteComponentProps['history'];
  location: {
    state: {
      [key: string]: string;
    };
  };
}

const SettingsPreviewPage: React.FC<ISettingsPreviewPage> = (props) => {
  const { orgID } = useAuth();
  const { history, location } = props;

  if (!location.state) {
    return <Redirect to="/settings" />;
  }

  const desktopPreviewHeight = '787px';
  const desktopPreviewWidth = '375px';

  const phoneStyles = css`
    // TODO: Improvement - replace media query value with Tailwind config reference
    @media (min-width: 768px) {
      background: #e5e7eb;
      border-radius: 40px;
      width: ${desktopPreviewWidth};
      max-height: ${desktopPreviewHeight};
    }
  `;
  const innerPhoneStyles = css`
    // TODO: Improvement - replace media query value with Tailwind config reference
    @media (min-width: 768px) {
      border-radius: 33px;
    }
  `;
  const urlParams = buildNewUrl({
    ...location.state,
    organisation_id: `${orgID || ''}`,
  });
  const previewPageUrl = `/view-profile/sample${urlParams}`;

  return (
    <Layout pageName="Settings" showPageName={false}>
      <div className="md:flex justify-center absolute inset-0 bg-gray-50">
        <div className="flex flex-col h-full w-full">
          <div className="w-full mx-auto px-4 sm:px-4 lg:px-6 py-4 border-b bg-white md:mb-4">
            <Button
              buttonText="Exit Preview"
              kind={BUTTON_KIND.WHITE}
              onClick={() =>
                history.push('/settings', {
                  fromPage: 'preview',
                  ...location.state,
                })
              }
            />
          </div>
          <div
            className={cx(
              phoneStyles,
              'w-full h-full inline-flex md:p-px md:shadow-2xl flex-grow mx-auto',
            )}
          >
            <Iframe
              className={cx(
                innerPhoneStyles,
                'w-full h-full pointer-events-none',
              )}
              display="block"
              position="relative"
              url={previewPageUrl}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SettingsPreviewPage;
