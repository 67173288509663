import { useEffect, useMemo, useRef, useState } from 'react';
import { CheckCircleIcon } from '@heroicons/react/outline';
import Lottie from 'lottie-web';
import Cookies from 'js-cookie';
import axios from 'axios';
import Layout from '@/components/Layout';
import Button, { BUTTON_KIND } from '@/components/Button';
import useAuth from '@/hooks/useAuth';
import SuccessAlert from '@/components/SuccessAlert';
import CRMIntegrationLottie from '@/assets/lotties/crm-integration.json';
import EnhancedAnalyticsLottie from '@/assets/lotties/enhanced-analytics.json';
import GroupingLottie from '@/assets/lotties/grouping.json';
import ActiveDirectoryLottie from '@/assets/lotties/active-directory.json';
import classNames from '@/helpers/classNames';

export default function PremiumPageComingSoon(): JSX.Element {
  const hostname =
    typeof window !== 'undefined' && window.location.hostname
      ? window.location.hostname
      : '';
  const [isCRMIntegrationPlaying, setIsCRMIntegrationPlaying] =
    useState<boolean>(false);
  const [isEnhancedAnalyticsPlaying, setIsEnhancedAnalyticsPlaying] =
    useState<boolean>(false);
  const [isGroupingPlaying, setIsGroupingPlaying] = useState<boolean>(false);
  const [isActiveDirectoryPlaying, setIsActiveDirectoryPlaying] =
    useState<boolean>(false);
  const [isCRMIntegrationSmallPlaying, setIsCRMIntegrationSmallPlaying] =
    useState<boolean>(true);
  const [isEnhancedAnalyticsSmallPlaying, setIsEnhancedAnalyticsSmallPlaying] =
    useState<boolean>(false);
  const [isGroupingSmallPlaying, setIsGroupingSmallPlaying] =
    useState<boolean>(false);
  const [isActiveDirectorySmallPlaying, setIsActiveDirectorySmallPlaying] =
    useState<boolean>(false);
  const { user } = useAuth();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [taptOrgID, setTaptOrgID] = useState<number>(0);

  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const [ipAddress, setIPAddress] = useState('');

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

  const getIPAddress = () => {
    axios
      .get('https://geolocation-db.com/json/')
      .then((res: { data: { IPv4: string } }) => {
        setIPAddress(res.data.IPv4);
      })
      .finally(() => {});
  };

  useEffect(() => {
    const cmsIntegrationLottie = Lottie.loadAnimation({
      name: 'CRMIntegration',
      container: document.querySelector('#CRMIntegration') as Element,
      animationData: CRMIntegrationLottie,
      autoplay: false,
      loop: true,
    });
    const enhancedAnalyticsLottie = Lottie.loadAnimation({
      name: 'EnhancedAnalytics',
      container: document.querySelector('#EnhancedAnalytics') as Element,
      animationData: EnhancedAnalyticsLottie,
      autoplay: false,
      loop: true,
    });
    const groupingLottie = Lottie.loadAnimation({
      name: 'Grouping',
      container: document.querySelector('#Grouping') as Element,
      animationData: GroupingLottie,
      autoplay: false,
      loop: true,
    });
    const activeDirectoryLottie = Lottie.loadAnimation({
      name: 'ActiveDirectory',
      container: document.querySelector('#ActiveDirectory') as Element,
      animationData: ActiveDirectoryLottie,
      autoplay: false,
      loop: true,
    });
    const cmsIntegrationSmallLottie = Lottie.loadAnimation({
      name: 'CRMIntegrationSmall',
      container: document.querySelector('#CRMIntegrationSmall') as Element,
      animationData: CRMIntegrationLottie,
      autoplay: false,
      loop: true,
    });
    const enhancedAnalyticsSmallLottie = Lottie.loadAnimation({
      name: 'EnhancedAnalyticsSmall',
      container: document.querySelector('#EnhancedAnalyticsSmall') as Element,
      animationData: EnhancedAnalyticsLottie,
      autoplay: false,
      loop: true,
    });
    const groupingLottieSmall = Lottie.loadAnimation({
      name: 'GroupingSmall',
      container: document.querySelector('#GroupingSmall') as Element,
      animationData: GroupingLottie,
      autoplay: false,
      loop: true,
    });
    const activeDirectorySmallLottie = Lottie.loadAnimation({
      name: 'ActiveDirectorySmall',
      container: document.querySelector('#ActiveDirectorySmall') as Element,
      animationData: ActiveDirectoryLottie,
      autoplay: false,
      loop: true,
    });
  }, []);

  useEffect(() => {
    setFirstName(user?.first_name || '');
    setLastName(user?.last_name || '');
    setEmail(user?.email || '');
    setTaptOrgID(user?.organisation_id || 0);
    getIPAddress();
  }, []);

  function sendForm() {
    const url =
      'https://api.hsforms.com/submissions/v3/integration/submit/9191954/05a34fd6-a84d-46c8-aa16-9a352856cfcc';

    // Example request JSON:
    const data = {
      submittedAt: `${new Date().getTime()}`,
      fields: [
        {
          objectTypeId: '0-1',
          name: 'firstname',
          value: firstName,
        },
        {
          objectTypeId: '0-1',
          name: 'lastname',
          value: lastName,
        },
        {
          objectTypeId: '0-1',
          name: 'email',
          value: email,
        },
        {
          objectTypeId: '0-1',
          name: 'company',
          value: company,
        },
        {
          objectTypeId: '0-1',
          name: 'tapt_org_id',
          value: taptOrgID,
        },
      ],
      context: {
        pageName: 'Contact us',
        // pageUri: `${hostname}${asPath}`,
        pageUri: `${hostname}`,
        hutk: Cookies.get('hubspotutk') as string,
        ipAddress,
      },
    };

    const finalData = JSON.stringify(data);
    axios
      .post(url, finalData, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((res: { data: { inlineMessage: string } }) => {
        setSuccess(res.data.inlineMessage);
      })
      .catch((err: { response: { data: { message: string } } }) => {
        setError(err?.response?.data?.message);
      })
      .finally(() => {});
    console.log(error);
  }

  function useIsInViewport(ref: any) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting),
        ),
      [],
    );

    useEffect(() => {
      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);

    return isIntersecting;
  }

  const isInViewport1 = useIsInViewport(ref1);

  const isInViewport2 = useIsInViewport(ref2);

  const isInViewport3 = useIsInViewport(ref3);

  const isInViewport4 = useIsInViewport(ref4);

  useEffect(() => {
    if (isInViewport4) {
      Lottie.play('CRMIntegrationSmall');
      setIsCRMIntegrationSmallPlaying(true);
    }
    if (isInViewport1) {
      Lottie.play('EnhancedAnalyticsSmall');
      setIsEnhancedAnalyticsSmallPlaying(true);
    }
    if (isInViewport2) {
      Lottie.play('GroupingSmall');
      setIsGroupingSmallPlaying(true);
    }
    if (isInViewport3) {
      Lottie.play('ActiveDirectorySmall');
      setIsActiveDirectorySmallPlaying(true);
    }
  }, [isInViewport1, isInViewport2, isInViewport3, isInViewport4]);

  function playCMSIntegration() {
    Lottie.play('CRMIntegration');
    setIsCRMIntegrationPlaying(true);
  }
  function playEnhancedAnalytics() {
    Lottie.play('EnhancedAnalytics');
    setIsEnhancedAnalyticsPlaying(true);
  }
  function playGrouping() {
    Lottie.play('Grouping');
    setIsGroupingPlaying(true);
  }
  function playActiveDirectory() {
    Lottie.play('ActiveDirectory');
    setIsActiveDirectoryPlaying(true);
  }
  function pauseCMSIntegration() {
    Lottie.pause('CRMIntegration');
    setIsCRMIntegrationPlaying(false);
  }
  function pauseEnhancedAnalytics() {
    Lottie.pause('EnhancedAnalytics');
    setIsEnhancedAnalyticsPlaying(false);
  }
  function pauseGrouping() {
    Lottie.pause('Grouping');
    setIsGroupingPlaying(false);
  }
  function pauseActiveDirectory() {
    Lottie.pause('ActiveDirectory');
    setIsActiveDirectoryPlaying(false);
  }

  return (
    <Layout pageName="Premium coming soon..." showPageName={false}>
      <p className="pt-8 pb-4 text-2xl md:text-4xl leading-10 font-medium text-brand-500">
        Premium{' '}
        <span className="text-gray-900 leading-10 font-normal">
          coming soon...
        </span>
      </p>
      {success && (
        <SuccessAlert message="We have collected your details and will be in touch shortly" />
      )}
      <div className="hidden lg:block pt-4 ">
        <table className="table w-full shadow md:rounded-lg">
          <tbody className="divide-y divide-gray-20 bg-white overflow-visible transform">
            <tr
              className={classNames(
                isCRMIntegrationPlaying
                  ? `bg-white hover:shadow-sm hover:shadow-md hover:shadow-lg hover:shadow-xl hover:shadow-2xl transform hover:scale-105 hover:z-1`
                  : 'divide-x divide-gray-200',
              )}
              onMouseEnter={playCMSIntegration}
              onMouseLeave={pauseCMSIntegration}
            >
              <td className="px-12 py-12">
                <p>
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-8 w-8 text-brand-500"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-4 mt-0.5">
                      <span className="font-medium text-lg">
                        CRM Integration
                      </span>
                    </div>
                  </div>
                </p>
                <p className="ml-12">
                  Connecting your tapt connections with Hubspot, Salesforce and
                  Microsoft Dynamics.
                </p>
              </td>
              <td>
                <div id="CRMIntegration" style={{ width: 400 }} />
              </td>
            </tr>
            <tr
              className={classNames(
                isEnhancedAnalyticsPlaying
                  ? `bg-white hover:shadow-sm hover:shadow-md hover:shadow-lg hover:shadow-xl hover:shadow-2xl transform hover:scale-105`
                  : 'divide-x divide-gray-200',
              )}
              onMouseEnter={playEnhancedAnalytics}
              onMouseLeave={pauseEnhancedAnalytics}
            >
              <td className="px-12 py-12">
                <p className="font-medium">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-8 w-8 text-brand-500"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-4 mt-0.5">
                      <span className="font-medium text-lg">Analytics</span>
                    </div>
                  </div>
                </p>
                <p className="ml-12">
                  Company wide and individual user analytics dashboard.
                </p>
              </td>
              <td>
                <div id="EnhancedAnalytics" style={{ width: 400 }} />
              </td>
            </tr>
            <tr
              className={classNames(
                isGroupingPlaying
                  ? `bg-white hover:shadow-sm hover:shadow-md hover:shadow-lg hover:shadow-xl hover:shadow-2xl transform hover:scale-105`
                  : 'divide-x divide-gray-200',
              )}
              onMouseEnter={playGrouping}
              onMouseLeave={pauseGrouping}
            >
              <td className="px-12 py-12">
                <p className="font-medium">
                  {' '}
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-8 w-8 text-brand-500"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-4 mt-0.5">
                      <span className="font-medium text-lg">
                        Profile Groups
                      </span>
                    </div>
                  </div>
                </p>
                <p className="ml-12 text-base">
                  Create groups to organise users and design custom profiles.
                </p>
              </td>
              <td>
                <div id="Grouping" style={{ width: 400 }} />
              </td>
            </tr>
            <tr
              className={classNames(
                isActiveDirectoryPlaying
                  ? `bg-white hover:shadow-sm hover:shadow-md hover:shadow-lg hover:shadow-xl hover:shadow-2xl transform hover:scale-105`
                  : 'divide-x divide-gray-200',
              )}
              onMouseEnter={playActiveDirectory}
              onMouseLeave={pauseActiveDirectory}
            >
              <td className="px-12 py-12">
                <p className="font-medium">
                  {' '}
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-8 w-8 text-brand-500"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-4 mt-0.5">
                      <span className="font-medium text-lg">
                        Active Directory
                      </span>
                    </div>
                  </div>
                </p>
                <p className="ml-12">
                  Connect your Active Directory to Tapt to provision user.
                </p>
              </td>
              <td>
                <div id="ActiveDirectory" style={{ width: 400 }} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="lg:hidden block">
        <table className="mt-9 table w-fit border-collapse border border-slate-400">
          <tbody className="divide-y divide-gray-200">
            <tr className="divide-x divide-gray-200 bg-white hover:bg-gray">
              <td className="px-12 py-12">
                <p>
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-8 w-8 text-brand-500"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-4 mt-0.5">
                      <span className="font-medium text-lg">
                        CRM Integration
                      </span>
                    </div>
                  </div>
                </p>
                <p className="ml-12">
                  Connecting your tapt connections with hubspot and salesforce.
                </p>
                <td>
                  <div
                    id="CRMIntegrationSmall"
                    style={{ width: 250, height: 200 }}
                    ref={ref4}
                  />
                </td>
              </td>
            </tr>
            <tr className="divide-x divide-gray-200 bg-white hover:bg-gray">
              <td className="px-12 py-12">
                <p className="font-medium">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-8 w-8 text-brand-500"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-4 mt-0.5">
                      <span className="font-medium text-lg">Analytics</span>
                    </div>
                  </div>
                </p>
                <p className="ml-12">
                  Company wide and individual user analytics dashboard.
                </p>
                <td>
                  <div
                    id="EnhancedAnalyticsSmall"
                    style={{ width: 250, height: 200 }}
                    ref={ref1}
                  />
                </td>
              </td>
            </tr>
            <tr className="divide-x divide-gray-200 bg-white hover:bg-gray">
              <td className="px-12 py-12">
                <p className="font-medium">
                  {' '}
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-8 w-8 text-brand-500"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-4 mt-0.5">
                      <span className="font-medium text-lg">
                        Profile Groups
                      </span>
                    </div>
                  </div>
                </p>
                <p className="ml-12 text-base">
                  Create groups to organise users and design custom profiles.
                </p>
                <td>
                  <div
                    id="GroupingSmall"
                    style={{ width: 250, height: 200 }}
                    ref={ref2}
                  />
                </td>
              </td>
            </tr>
            <tr className="divide-x divide-gray-200 bg-white hover:bg-gray">
              <td className="px-12 py-12">
                <p className="font-medium">
                  {' '}
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-8 w-8 text-brand-500"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-4 mt-0.5">
                      <span className="font-medium text-lg">
                        Active Directory
                      </span>
                    </div>
                  </div>
                </p>
                <p className="ml-12">
                  Connect your Active Directory to Tapt to provision user.
                </p>
                <td>
                  <div
                    id="ActiveDirectorySmall"
                    style={{ width: 250, height: 200 }}
                    ref={ref3}
                  />
                </td>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex justify-end">
        <Button
          className="my-8"
          buttonText="Enquire now"
          kind={BUTTON_KIND.PRIMARY}
          onClick={() => sendForm()}
        />
      </div>
    </Layout>
  );
}
