import { useEffect, ChangeEvent } from 'react';
import { PaperClipIcon } from '@heroicons/react/solid';
import Spinner from '@/components/Icons/Spinner';
import classNames from '@/helpers/classNames';

const FileUploadComponent = ({
  buttonTitle,
  url,
  fileFormat,
  fileFormatMessage = '',
  onFileSelected,
  sizeError,
  setSizeError,
  fileError,
  loading,
  file,
  setFile,
  fileSize = 134217728,
}: {
  buttonTitle?: string;
  url?: string;
  fileFormat?: string;
  fileFormatMessage?: string;
  onFileSelected: (file: File) => void;
  sizeError?: boolean;
  setSizeError?: React.Dispatch<React.SetStateAction<boolean>>;
  fileError?: boolean;
  loading?: boolean;
  file: any;
  setFile: React.Dispatch<any>;
  fileSize?: number;
}): JSX.Element => {
  useEffect(() => {
    if (sizeError || fileError) {
      setFile(undefined);
    }
  }, [sizeError, fileError]);

  return (
    <div>
      {file !== undefined ? (
        <div className="inline-flex justify-center items-center w-full">
          <a
            href={url}
            download
            target="_blank"
            rel="noreferrer"
            className="text-sm leading-5 py-2.5 my-1 border border-transparent flex items-center justify-start break-all"
          >
            {loading ? (
              <Spinner className="text-brand-500 mr-2" />
            ) : (
              <PaperClipIcon
                className="text-brand-500 h-5 w-5 mr-2 flex-shrink-0"
                aria-hidden="true"
              />
            )}
            {loading ? 'Uploading ...: ' : buttonTitle || 'Upload file: '}

            {file.name}
          </a>
          <button
            className="ml-4 appearance-none text-brand-500 text-sm font-medium"
            onClick={() => {
              setFile(undefined);
              
            }}
          >
            Remove File
          </button>
        </div>
      ) : (
        <div className="relative w-full h-32 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none mt-4">
          <span className="flex font-medium w-full h-full py-2.5 border border-transparent flex items-center justify-start">
            {loading ? (
              <Spinner className="text-brand-500 mr-2" />
            ) : (
              <span className="flex items-center justify-center space-x-2 w-full h-full ">
                <svg
                  width="64"
                  height="63"
                  viewBox="0 0 84 83"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.6509 55.1019V58.5227C14.6509 61.2444 15.7321 63.8547 17.6566 65.7792C19.5812 67.7038 22.1914 68.785 24.9132 68.785H59.1208C61.8426 68.785 64.4528 67.7038 66.3774 65.7792C68.3019 63.8547 69.3831 61.2444 69.3831 58.5227V55.1019M55.7001 27.7358L42.017 14.0527M42.017 14.0527L28.3339 27.7358M42.017 14.0527V55.1019"
                    stroke="#D1D5DB"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <rect
                    x="1.96777"
                    y="1.36963"
                    width="80.0984"
                    height="80.0984"
                    rx="6"
                    stroke="#D1D5DB"
                    strokeWidth="2"
                  />
                </svg>
              </span>
            )}
          </span>
          <input
            className="absolute top-0 left-0 opacity-0 w-full h-full"
            type="file"
            accept={fileFormat}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (e.target.files && e.target.files[0]) {
                if (e.target.files[0].size > fileSize && setSizeError) {
                  setSizeError(true);
                  return;
                }
                onFileSelected(e.target.files[0]);
                setFile(e.target.files[0]);
              }
            }}
          />

          <span
            className={classNames(
              'pl-2 text-sm',
              sizeError || fileError ? 'text-red-500' : 'text-gray-500',
            )}
          >
            {sizeError && 'File size is too big'}
            {fileError && 'File is invalid.'}
            {!sizeError &&
              !fileError &&
              `Maximum file size 1 GB ${fileFormatMessage}`}
          </span>
        </div>
      )}
    </div>
  );
};

export default FileUploadComponent;
