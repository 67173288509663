// eslint-disable
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { Menu, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/outline';
import Badge, { BADGE_KIND } from '@/components/Badge';
import classNames from '@/helpers/classNames';
import { orderHandler } from '@/helpers/order_handler';
import { STAGE } from '@/constants/stage';
import adminAPI from '@/api/admin';
import IUser from '@/types/IUser';

interface IOrderDetailUpdate {
  high_priority: boolean;
  design_spec: boolean;
  alternate_email: boolean;
}

interface IValidateAlternateEmail {
  validateMessage: string;
  valid: boolean;
  submitMessage: boolean;
}

interface ISalesInformation {
  inputAlternateEmail: string;
  setInputAlternateEmail: Dispatch<SetStateAction<string>>;
  orderDetailUpdate: IOrderDetailUpdate;
  setOrderDetailUpdate: Dispatch<SetStateAction<IOrderDetailUpdate>>;
  validateAlternateEmail: IValidateAlternateEmail;
  setValidateAlternateEmail: Dispatch<
    React.SetStateAction<IValidateAlternateEmail>
  >;
  updateOrderStatus: string;
  setUpdateOrderStatus: Dispatch<SetStateAction<string>>;
  orderId: number;
  orderTag: string | undefined;
  setOrderTag: Dispatch<SetStateAction<string | undefined>>;
}

export default function SalesInformation({
  inputAlternateEmail,
  setInputAlternateEmail,
  orderDetailUpdate,
  setOrderDetailUpdate,
  validateAlternateEmail,
  setValidateAlternateEmail,
  updateOrderStatus,
  setUpdateOrderStatus,
  orderId,
  orderTag,
  setOrderTag,
}: ISalesInformation): JSX.Element {
  const saleStatus = [
    {
      id: 'sales',
      name: 'Sales',
      onClick: '',
    },
    {
      id: 'set_up_profiles',
      name: 'Set up profiles ',
      onClick: '',
    },
    {
      id: 'assign_design',
      name: 'Designer Assigned',
      onClick: '',
    },
  ];
  const [saleAccounts, setSaleAccounts] = useState<IUser[]>();
  const [designAccounts, setDesignerAccounts] = useState<IUser[]>();
  const [isLoading, setIsLoading] = useState<boolean>();

  function handleBadgeColor(status: string) {
    switch (status) {
      case STAGE.order.new:
        return 'red';
      case STAGE.order.awaiting_client:
        return 'orange';
      case STAGE.order.design_edits:
        return 'yellow';
      case STAGE.order.ready_for_print:
        return 'green';
      case STAGE.order.in_print:
        return 'in_print';
      case STAGE.order.fulfilled:
        return 'fullfiled';
      case STAGE.order.all_orders:
        return 'gray';
      case STAGE.order.on_hold:
        return 'gray';
      default:
        return '';
    }
  }
  function handleBadgeText(status: string) {
    switch (status) {
      case STAGE.order.new:
        return STAGE.order_stage_name.new;
      case STAGE.order.awaiting_client:
        return STAGE.order_stage_name.awaiting_client;
      case STAGE.order.design_edits:
        return STAGE.order_stage_name.design_edits;
      case STAGE.order.ready_for_print:
        return STAGE.order_stage_name.ready_for_print;
      case STAGE.order.in_print:
        return STAGE.order_stage_name.in_print;
      case STAGE.order.fulfilled:
        return STAGE.order_stage_name.fulfilled;
      case STAGE.order.all_orders:
        return STAGE.order_stage_name.all_orders;
      case STAGE.order.on_hold:
        return STAGE.order_stage_name.on_hold;
      default:
        return '';
    }
  }
  const handleTag = (tag: string) => {
    let value;
    switch (tag) {
      case 'sales':
        value = 'awaiting';
        break;
      case 'set_up_profiles':
        value = 'setup';
        break;
      case 'assign_design':
        value = 'ready';
        break;

      default:
        break;
    }
    setOrderTag(value);
  };
  const handleDefaultChecked = (tagValue: string) => {
    let value;
    switch (tagValue) {
      case 'sales':
        value = 'awaiting';
        break;
      case 'set_up_profiles':
        value = 'setup';
        break;
      case 'assign_design':
        value = 'ready';
        break;

      default:
        break;
    }
    return value === orderTag;
  };

  const handleAlternateEmailNotification = () => {
    return (
      orderDetailUpdate.alternate_email &&
      validateAlternateEmail.validateMessage !== '' &&
      !validateAlternateEmail.valid &&
      validateAlternateEmail.submitMessage
    );
  };

  const handleEmailRegex = () => {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
    emailRegex.test(inputAlternateEmail)
      ? setValidateAlternateEmail({
          ...validateAlternateEmail,
          valid: true,
        })
      : setValidateAlternateEmail({
          ...validateAlternateEmail,
          valid: false,
          validateMessage: 'Incorrect email format',
          submitMessage: false,
        });
  };

  const handleOrdersUsers = (status: boolean, userId: number) => {
    setIsLoading(true);
    status
      ? adminAPI
          .deleteOrdersUsers(orderId, userId)
          .then(() => setIsLoading(!isLoading))
      : adminAPI
          .createOrdersUsers({
            order_id: orderId,
            user_id: userId,
          })
          .then(() => setIsLoading(!isLoading));
  };

  useEffect(() => {
    if (inputAlternateEmail.length !== 0) {
      handleEmailRegex();
    }
    if (inputAlternateEmail.length === 0) {
      setValidateAlternateEmail({
        ...validateAlternateEmail,
        valid: false,
        validateMessage: 'Email not input',
        submitMessage: false,
      });
    }
  }, [inputAlternateEmail]);

  useEffect(() => {
    let isMounted = true; // Track whether the component is mounted or not

    const fetchData = async () => {
      try {
        const saleRes = await adminAPI.listUserByRole('sale');
        const designerRes = await adminAPI.listUserByRole('design');

        if (isMounted) {
          setSaleAccounts(saleRes.data.data);
          setDesignerAccounts(designerRes.data.data);
        }
      } catch (error) {
        // Handle error
      }
    };

    fetchData();

    return () => {
      isMounted = false; // Cleanup function to cancel any ongoing tasks or subscriptions
    };
  }, [isLoading]);

  return (
    <Box className=" font-poppins font-medium text-base leading-5 text-gray-900 mt-2 grid gap-3">
      <p>Sales Information</p>
      <Box className="flex gap-4 w-full">
        {/* Order status */}
        <Menu as="div" className="relative inline-block text-left">
          <div className="flex items-center">
            <Menu.Button className="appearance-none">
              <Box className="flex items-center justify-center py-2 px-4 gap-2 border rounded-lg text-sm leading-5 font-medium">
                <Badge
                  kind={
                    updateOrderStatus
                      ? handleBadgeColor(updateOrderStatus)
                      : BADGE_KIND.RED
                  }
                  text={
                    updateOrderStatus
                      ? handleBadgeText(updateOrderStatus)
                      : 'New'
                  }
                  textColor="text-gray-700"
                />

                <p>
                  {updateOrderStatus !== STAGE.order.ready_for_print
                    ? 'Order status'
                    : 'Status'}
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.292893 0.292893C0.683416 -0.097631 1.31658 -0.097631 1.7071 0.292893L4.99999 3.58579L8.29288 0.292893C8.6834 -0.0976311 9.31657 -0.0976311 9.70709 0.292893C10.0976 0.683417 10.0976 1.31658 9.70709 1.70711L5.7071 5.70711C5.31657 6.09763 4.68341 6.09763 4.29289 5.70711L0.292893 1.70711C-0.0976309 1.31658 -0.0976309 0.683417 0.292893 0.292893Z"
                    fill="#374151"
                  />
                </svg>
              </Box>
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute left-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
              {orderHandler.orderDetailUpdateStatus().map((item) => (
                <Menu.Item
                  key={`Badge-${item.name}-${item.order_status}-${item.kind}`}
                >
                  <button
                    className="text-gray-700 w-full text-left px-4 py-2 text-sm flex items-center justify-start group"
                    onClick={() => setUpdateOrderStatus(item.order_status)}
                  >
                    <Badge
                      kind={item.kind}
                      text={item.name}
                      textColor="text-gray-700"
                    />
                  </button>
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
        {/* Assign sales */}
        <Menu as="div" className="relative inline-block text-left">
          <div className="flex items-center">
            <Menu.Button className="appearance-none">
              <Box className="flex items-center justify-center py-2 px-4 gap-2 border rounded-lg text-sm leading-5 font-medium">
                <p>Assign sales</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.292893 0.292893C0.683416 -0.097631 1.31658 -0.097631 1.7071 0.292893L4.99999 3.58579L8.29288 0.292893C8.6834 -0.0976311 9.31657 -0.0976311 9.70709 0.292893C10.0976 0.683417 10.0976 1.31658 9.70709 1.70711L5.7071 5.70711C5.31657 6.09763 4.68341 6.09763 4.29289 5.70711L0.292893 1.70711C-0.0976309 1.31658 -0.0976309 0.683417 0.292893 0.292893Z"
                    fill="#374151"
                  />
                </svg>
              </Box>
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-left absolute left-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
              {saleAccounts?.map((item) => {
                return (
                  <Menu.Item key={`saleAccount - ${item.id}`}>
                    <button
                      className="text-gray-700 w-full text-left px-4 py-2 text-sm flex items-center justify-start group  gap-3"
                      onClick={() =>
                        handleOrdersUsers(
                          item.order.some((item) => item.id === orderId),
                          item.id,
                        )
                      }
                    >
                      <img
                        src={`http://localhost:4000${item?.photo_file?.thumb_url}`}
                        alt="design-file"
                        className=" object-scale-down rounded-full w-6 h-6"
                      />
                      <p className="group-hover:text-brand-400">
                        {item.first_name} {item.last_name}
                      </p>
                      {item.order.some((item) => item.id === orderId) && (
                        <CheckIcon className="w-6 h-6" />
                      )}
                    </button>
                  </Menu.Item>
                );
              })}
            </Menu.Items>
          </Transition>
        </Menu>
        {/* Assign designer */}
        <Menu as="div" className="relative inline-block text-left">
          <div className="flex items-center">
            <Menu.Button className="appearance-none">
              <Box className="flex items-center justify-center py-2 px-4 gap-2 border rounded-lg text-sm leading-5 font-medium">
                <p>Assign designer</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.292893 0.292893C0.683416 -0.097631 1.31658 -0.097631 1.7071 0.292893L4.99999 3.58579L8.29288 0.292893C8.6834 -0.0976311 9.31657 -0.0976311 9.70709 0.292893C10.0976 0.683417 10.0976 1.31658 9.70709 1.70711L5.7071 5.70711C5.31657 6.09763 4.68341 6.09763 4.29289 5.70711L0.292893 1.70711C-0.0976309 1.31658 -0.0976309 0.683417 0.292893 0.292893Z"
                    fill="#374151"
                  />
                </svg>
              </Box>
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-left absolute left-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
              {designAccounts?.map((item) => {
                return (
                  <Menu.Item key={`designAccounts - ${item.id}`}>
                    <button
                      className="text-gray-700 w-full text-left px-4 py-2 text-sm flex items-center justify-start group  gap-3"
                      onClick={() =>
                        handleOrdersUsers(
                          item.order.some((item) => item.id === orderId),
                          item.id,
                        )
                      }
                    >
                      <img
                        src={`http://localhost:4000${item?.photo_file?.thumb_url}`}
                        alt="design-file"
                        className=" object-scale-down rounded-full w-6 h-6"
                      />
                      <p className="group-hover:text-brand-400">
                        {item.first_name} {item.last_name}
                      </p>
                      {item.order.some((item) => item.id === orderId) && (
                        <CheckIcon className="w-6 h-6" />
                      )}
                    </button>
                  </Menu.Item>
                );
              })}
            </Menu.Items>
          </Transition>
        </Menu>
      </Box>
      <Box className="flex items-center justify-start gap-4">
        <Box className="flex justify-center items-center text-sm leading-5 font-medium gap-2 px-3 py-2 border rounded-lg border-gray-200 text-red-450">
          <input
            type="checkbox"
            className=" rounded text-brand-500"
            id="high_priority"
            onClick={() => {
              setOrderDetailUpdate({
                ...orderDetailUpdate,
                high_priority: !orderDetailUpdate.high_priority,
              });
            }}
            defaultChecked={orderDetailUpdate.high_priority}
          />
          <label htmlFor="high_priority">High priority</label>
        </Box>
        <Box className="flex divide-x border rounded-lg border-gray-200">
          {saleStatus.map((item) => {
            return (
              <Box className="py-2 px-3 flex gap-2  col-span-1" key={item.id}>
                <input
                  type="radio"
                  id={item.id}
                  name="sales_information"
                  className="text-brand-500"
                  onClick={() => handleTag(item.id)}
                  defaultChecked={handleDefaultChecked(item.id)}
                />
                <label
                  htmlFor={item.id}
                  className="text-sm leading-5 font-medium"
                >
                  {item.name}
                </label>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box className="flex divide-x bg-gray-50 border rounded-lg relative">
        <Box className=" flex items-center justify-center text-sm leading-5 font-medium px-3 py-2 gap-2 ">
          <input
            type="checkbox"
            id="alternate-email-checkbox"
            name="alternate-email-checkbox"
            className="rounded text-brand-500"
            onClick={() => {
              setOrderDetailUpdate({
                ...orderDetailUpdate,
                alternate_email: !orderDetailUpdate.alternate_email,
              });
            }}
            checked={
              inputAlternateEmail.length > 0 ||
              orderDetailUpdate.alternate_email
            }
          />
          <label
            htmlFor="alternate-email-checkbox"
            className="text-sm leading-5 font-medium text-gray-700"
          >
            Send to additional email
          </label>
        </Box>
        <input
          type="text"
          className={classNames(
            'flex flex-grow border-0 rounded-r-lg bg-gray-50 font-poppins text-base font-normal ',
            handleAlternateEmailNotification()
              ? '  border-red-450 ring-1 ring-red-450'
              : 'focus:ring-brand-500',
          )}
          placeholder="|"
          value={inputAlternateEmail}
          onChange={(e) => {
            setInputAlternateEmail(e.target.value);
            setOrderDetailUpdate({
              ...orderDetailUpdate,
              alternate_email: e.target.value.length > 0,
            });
          }}
        />
        <p
          className={classNames(
            handleAlternateEmailNotification()
              ? 'absolute text-xs leading-5 font-poppins font-medium text-red-450 border-0'
              : 'hidden',
          )}
          style={{
            top: '50%',
            right: '12px',
            transform: 'translateY(-50%)',
            borderWidth: '0px',
          }}
        >
          {validateAlternateEmail.validateMessage}
        </p>
      </Box>
    </Box>
  );
}
