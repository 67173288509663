import { IIcon } from '@/types/IIcon';

const SocialPinterest = ({ className }: IIcon): JSX.Element => {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C6.4775 2 2 6.4775 2 12C2 16.2367 4.63583 19.855 8.35583 21.3117C8.26833 20.5208 8.18917 19.3067 8.39083 18.4433C8.5725 17.6633 9.56333 13.4725 9.56333 13.4725C9.56333 13.4725 9.26417 12.8733 9.26417 11.9883C9.26417 10.5975 10.07 9.56 11.0733 9.56C11.9267 9.56 12.3383 10.2008 12.3383 10.9683C12.3383 11.8267 11.7925 13.1092 11.51 14.2975C11.2742 15.2933 12.0092 16.105 12.9908 16.105C14.7683 16.105 16.1342 14.2308 16.1342 11.5258C16.1342 9.13167 14.4142 7.4575 11.9575 7.4575C9.1125 7.4575 7.4425 9.59167 7.4425 11.7975C7.4425 12.6567 7.77333 13.5783 8.18667 14.08C8.26833 14.1792 8.28 14.2658 8.25583 14.3667C8.18 14.6825 8.01167 15.3617 7.97833 15.5008C7.935 15.6842 7.83333 15.7225 7.64417 15.6342C6.395 15.0525 5.61417 13.2267 5.61417 11.76C5.61417 8.60583 7.90583 5.70833 12.2217 5.70833C15.6908 5.70833 18.3867 8.18 18.3867 11.4842C18.3867 14.9308 16.2133 17.7042 13.1975 17.7042C12.1842 17.7042 11.2317 17.1775 10.9058 16.5558C10.9058 16.5558 10.4042 18.465 10.2825 18.9333C10.0567 19.8017 9.4475 20.8908 9.03917 21.555C9.975 21.8433 10.9692 22 12 22C17.5225 22 22 17.5225 22 12C22 6.4775 17.5225 2 12 2Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SocialPinterest;
