import { IIcon } from '@/types/IIcon';

const ExternalLinkIcon = ({ className }: IIcon): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
    >
      <g filter="url(#filter0_d_6773_79782)">
        <path
          d="M14.5859 8.75065H8.7526C7.97906 8.75065 7.23719 9.05794 6.69021 9.60492C6.14323 10.1519 5.83594 10.8938 5.83594 11.6673V26.2506C5.83594 27.0242 6.14323 27.7661 6.69021 28.313C7.23719 28.86 7.97906 29.1673 8.7526 29.1673H23.3359C24.1095 29.1673 24.8513 28.86 25.3983 28.313C25.9453 27.7661 26.2526 27.0242 26.2526 26.2506V20.4173M20.4193 5.83398H29.1693M29.1693 5.83398V14.584M29.1693 5.83398L14.5859 20.4173"
          stroke="#D1D5DB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_6773_79782"
          x="-2"
          y="-1"
          width="39"
          height="39"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6773_79782"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6773_79782"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default ExternalLinkIcon;
