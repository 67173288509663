import { Link } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import {
  IdentificationIcon,
  KeyIcon,
  QuestionMarkCircleIcon,
  UserGroupIcon,
  ViewBoardsIcon,
  XIcon,
  ClipboardListIcon,
  PaperAirplaneIcon,
  SparklesIcon,
  StarIcon,
  ViewGridIcon,
  ChevronRightIcon,
} from '@heroicons/react/outline';
import Analytics from '@/components/Icons/Analytics';
import Card from '@/components/Icons/Card';
import Contacts from '@/components/Icons/Contacts';
import Logout from '@/components/Icons/Logout';
import Settings from '@/components/Icons/Settings';
import Shop from '@/components/Icons/Shop';
import useAuth from '@/hooks/useAuth';
import Logo from '@/assets/logos/primary.svg';
import classNames from '@/helpers/classNames';
import Button from './Button';
import DashboardIcon from './Icons/DashboardIcon';
import Features from '@/types/Features';

interface ISidebarNavigation {
  pageName: string;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SidebarNavigation: React.FC<ISidebarNavigation> = ({
  pageName,
  setSidebarOpen,
}) => {
  const {
    subscriptionType,
    userScope,
    isUserViewForAdmin,
    getCurrentUser,
    logout,
    userRole,
    hasFeature,
  } = useAuth();

  const isSuperUser = userScope === 'admin';

  const isSaaSEnabled = hasFeature(Features.SaaS);
  const hasBasicPlan = isSaaSEnabled
    ? !isSuperUser && subscriptionType === 'basic'
    : false;
  const hasPremiumPlan = isSaaSEnabled
    ? !isSuperUser && subscriptionType === 'premium'
    : false;

  const AUTH = {
    superUser: 'admin',
    user: 'user',
    undefined,
  };

  const myProfileNavItem = () => {
    if (hasPremiumPlan) {
      return {
        name: 'Profiles',
        href: '/',
        icon: Card,
        authen: [AUTH.user],
        defaultOpen: ['My Profiles', 'Profile Grouping'].includes(pageName),
        hideInSideBar: false,
        children: [
          {
            name: 'My Profiles',
            href: '/',
            icon: Card,
            current: pageName === 'My Profiles',
            authen: [AUTH.user],
          },
          {
            name: 'Groups',
            href: '/groups',
            icon: ViewBoardsIcon,
            current: pageName === 'Profile Grouping',
            authen: [AUTH.user],
          },
        ],
      };
    }

    return {
      name: 'My Profiles',
      href: '/',
      icon: Card,
      current: pageName === 'My Profiles',
      authen: [AUTH.user],
      hideInSideBar: false,
    };
  };

  const navigation = [
    ...(isSuperUser || isUserViewForAdmin || (hasPremiumPlan && userRole === 'org_admin')
      ? [
          {
            name: 'Dashboard',
            href: '/enhanced_analytics',
            icon: DashboardIcon,
            current: pageName === 'Dashboard',
            authen: [AUTH.superUser, AUTH.user],
            newFeature: true,
          },
        ]
      : []),
    {
      name: 'Cards',
      href: '/',
      icon: Card,
      current: pageName === 'Cards',
      authen: [AUTH.superUser],
    },
    {
      name: 'Profiles',
      href: '/profiles-list',
      icon: IdentificationIcon,
      current: pageName === 'Profiles',
      authen: [AUTH.superUser],
    },
    {
      name: 'Activation Keys',
      href: '/activation-keys',
      icon: KeyIcon,
      current: pageName === 'Activation Keys',
      authen: [AUTH.superUser],
    },
    {
      name: 'Organisations',
      href: '/organisations-list',
      icon: UserGroupIcon,
      current: pageName === 'Organisations',
      authen: [AUTH.superUser],
    },
    myProfileNavItem(),
    {
      name: 'Contacts',
      href: '/contact-list',
      icon: Contacts,
      current: pageName === 'Contacts',
      authen: [AUTH.superUser, AUTH.user],
    },
    {
      name: 'Analytics',
      href: '/analytics',
      icon: Analytics,
      current: pageName === 'Analytics',
      authen: [AUTH.superUser, AUTH.user],
    },
    ...(userRole === 'org_admin'
      ? [
          {
            name: 'Design Review',
            href: '/preview',
            icon: Analytics,
            current: pageName === 'Orders',
            authen: [AUTH.superUser, AUTH.user],
            hideInSideBar: true,
          },
        ]
      : []),
    ...(userRole === 'org_admin'
      ? [
          {
            name: 'Shop',
            href: '/shop',
            icon: Shop,
            current: ['Shop', 'Add your details'].includes(pageName),
            authen: [AUTH.user],
          },
        ]
      : []),
    ...(hasPremiumPlan && userRole === 'org_admin'
      ? [
          {
            name: 'Premium',
            href: '/premium',
            icon: SparklesIcon,
            current: ['Premium'].includes(pageName),
            authen: [AUTH.user],
          },
        ]
      : []),
    {
      name: 'Orders',
      href: '/orders',
      icon: Shop,
      current: pageName === 'Orders',
      authen: [AUTH.superUser, AUTH.undefined],
      hideInSideBar: true,
    },
    ...(isSuperUser || userRole === 'org_admin'
      ? [
          {
            name: 'Orders',
            href: '/orders-v2',
            icon: ClipboardListIcon,
            current: pageName === 'Orders' || pageName === 'Shopify Orders',
            authen: [AUTH.superUser, AUTH.user],
            // hideInSideBar: true,
          },
        ]
      : []),
    {
      name: 'Pilots',
      href: '/pilots',
      icon: PaperAirplaneIcon,
      current: pageName === 'Pilots',
      authen: [AUTH.superUser],
    },
    ...(!hasPremiumPlan && userRole === 'org_admin'
      ? [
          {
            name: 'Premium',
            href: '/premium-coming-soon',
            icon: SparklesIcon,
            current: pageName === 'Premium coming soon...',
            authen: [AUTH.user],
            newFeature: true,
          },
        ]
      : []),
    ...(isSuperUser || userRole === 'org_admin'
      ? [
          {
            name: 'Settings',
            href: '/settings',
            icon: Settings,
            current: pageName === 'Settings',
            authen: [AUTH.superUser, AUTH.user],
          },
        ]
      : []),
  ];

  const checkAuth = (navigationAuthen: any) => {
    return navigationAuthen.includes(userScope);
  };

  const renderNavigationItem = (item: any) => {
    return !item.children ? (
      <Link
        key={item.name}
        to={item.href}
        className={classNames(
          item.current
            ? 'bg-brand-100 text-brand-500'
            : 'text-gray-900 hover:bg-brand-100',
          'group flex items-center px-3 py-2 text-base leading-6 md:text-sm md:leading-5 font-medium rounded-md transition',
        )}
      >
        <item.icon className="mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
        {item.name}
        {item.newFeature && (
          <span
            className={classNames(
              item.current
                ? 'bg-white text-brand-500'
                : 'bg-brand-400 text-white',
              'text-xs font-medium rounded-full px-3 py-1 ml-auto',
            )}
          >
            New!
          </span>
        )}
      </Link>
    ) : (
      <Disclosure as="div" defaultOpen={item.defaultOpen}>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={classNames(
                item.current
                  ? 'bg-brand-100 text-brand-500'
                  : 'text-gray-900 hover:bg-brand-100',
                'flex items-center w-full text-left rounded-md px-3 py-2 text-base gap-x-3 leading-6 md:text-sm md:leading-5 font-medium',
              )}
            >
              <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
              {item.name}
              <ChevronRightIcon
                className={classNames(
                  open ? 'transform rotate-90 text-gray-500' : 'text-gray-400',
                  'ml-auto h-5 w-5 shrink-0',
                )}
                aria-hidden="true"
              />
            </Disclosure.Button>
            <Disclosure.Panel as="ul" className="mt-1 px-2">
              {item.children.map((subItem: any) => (
                <li key={subItem.name}>
                  {/* 44px */}
                  <Disclosure.Button
                    as={Link}
                    to={subItem.href}
                    className={classNames(
                      subItem.current
                        ? 'bg-brand-100 text-brand-500'
                        : 'text-gray-900 hover:bg-brand-100',
                      'block rounded-md py-2 pr-2 pl-10 text-sm leading-6 text-gray-700',
                    )}
                  >
                    {subItem.name}
                  </Disclosure.Button>
                </li>
              ))}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  };

  return (
    <div className="relative flex-1 flex flex-col justify-between max-w-xs w-full max-h-full bg-white overflow-y-auto">
      <div className="pt-2 sm:pt-3 md:pt-8 pb-4">
        <div className="px-2 sm:px-3 md:px-5 flex items-center">
          <button
            className="inline-flex md:hidden items-center justify-center h-10 w-10 rounded-full focus:outline-none"
            onClick={() => setSidebarOpen(false)}
            type="button"
          >
            <span className="sr-only">Close sidebar</span>
            <XIcon className="h-6 w-6 text-gray-700" aria-hidden="true" />
          </button>
          <div className="flex-shrink-0 inline-flex items-center">
            <Link to="/">
              <img className="h-7 w-auto" src={Logo} alt="Tapt" />
            </Link>
            {process.env.REACT_APP_DISPLAY_ENV
              ? ` (${process.env.REACT_APP_DISPLAY_ENV})`
              : ``}
          </div>
        </div>
        <nav className="mt-5 px-2 space-y-1">
          {navigation.map((item) => {
            return (
              checkAuth(item.authen) &&
              !item.hideInSideBar &&
              renderNavigationItem(item)
            );
          })}
        </nav>
      </div>
      <div className="flex p-4 border-t border-gray-200 md:border-0">
        <div className="flex items-center">
          <div className="px-2 space-y-4 w-full">
            {hasBasicPlan && (
              <Link
                to="/premium-plans"
                className="text-gray-500 hover:text-gray-700 flex items-center text-sm font-normal rounded-md transition"
              >
                <StarIcon
                  className="mr-3 flex-shrink-0 h-5 w-5"
                  aria-hidden="true"
                />
                Upgrade Plan
              </Link>
            )}
            {hasPremiumPlan && (
              <Link
                to="/settings#plan"
                className="text-gray-500 hover:text-gray-700 flex items-center text-sm font-normal rounded-md transition"
              >
                <ViewGridIcon
                  className="mr-3 flex-shrink-0 h-5 w-5"
                  aria-hidden="true"
                />
                Manage Plan
              </Link>
            )}
            <a
              href="https://tapt.gorgias.help/en-US"
              target="_blank"
              rel="noreferrer"
              className="text-gray-500 hover:text-gray-700 flex items-center text-sm font-normal rounded-md transition"
            >
              <QuestionMarkCircleIcon
                className="mr-3 flex-shrink-0 h-5 w-5"
                aria-hidden="true"
              />
              Help
            </a>
            <button
              className="text-gray-500 hover:text-gray-700 flex items-center text-sm font-normal rounded-md transition"
              onClick={() => logout()}
              type="button"
            >
              <Logout
                className="mr-3 flex-shrink-0 h-5 w-5"
                aria-hidden="true"
              />
              Logout
            </button>
            {isUserViewForAdmin && (
              <Button
                buttonText="Switch to Admin view"
                onClick={getCurrentUser}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { SidebarNavigation as default };
