import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { PlusIcon, EyeIcon, MinusIcon } from '@heroicons/react/solid';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import Layout from '@/components/Layout';
import IFile from '@/types/IFile';
import ErrorAlert from '@/components/ErrorAlert';
import profilesAPI from '@/api/profiles';
import InfoPanelContainer from '@/components/InfoPanelContainer';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import Button, { BUTTON_KIND } from '@/components/Button';
import MESSAGES from '@/constants/messages-en';
import GroupInfoForm from '@/containers/GroupInfoForm';
import UploadPhoto from '@/components/UploadPhoto';
import ColorPicker from '@/components/ColorPicker';
import { IOrganisationUser } from '@/types/IOrganisation';
import useAuth from '@/hooks/useAuth';
import organisationsAPI from '@/api/organisations';
import Modal from '@/components/Modal';
import SuccessAlert from '@/components/SuccessAlert';
import filesAPI from '@/api/files';
import EditorsModal from '@/components/GroupPage/EditorsModal';
import AddProfilesModal from '@/components/GroupPage/AddProfilesModal';
import RemoveProfilesModal from '@/components/GroupPage/RemoveProfilesModal';

interface IEditGroupPage {
  location: {
    state: {
      [key: string]: string;
    };
  };
}

export default function EditGroupPage(props: IEditGroupPage): JSX.Element {
  const { orgID, userRole } = useAuth();
  const history = useHistory();
  const [orgUsers, setOrgUsers] = useState<IOrganisationUser[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [groupName, setGroupName] = useState('');
  const [loading, setLoading] = useState(false);
  const [groupNameError, setGroupNameError] = useState(false);
  const [subheading, setSubheading] = useState('');
  const [backgroundColour, setBackgroundColour] = useState<string>('#0A0A0A');
  const [textColour, setTextColour] = useState<string>('#FFFFFF');
  const [isEditorsModalOpen, setIsEditorsModalOpen] = useState<boolean>(false);
  const [isAddProfilesModalOpen, setIsAddProfilesModalOpen] =
    useState<boolean>(false);
  const [isRemoveProfilesModalOpen, setIsRemoveProfilesModalOpen] =
    useState<boolean>(false);
  const [isDeleteGroupModalOpen, setIsDeleteGroupModalOpen] = useState<boolean>(false);
  const [buttonBackgroundColour, setButtonBackgroundColour] =
    useState<string>('#873CFF');
  const [buttonTextColour, setButtonTextColour] = useState<string>('#FFFFFF');
  const [groupCoverImageFile, setGroupCoverImageFile] = useState<IFile>();
  const [orgCoverImageFile, setOrgCoverImageFile] = useState<IFile>();
  const groupCoverImageFileID = groupCoverImageFile
    ? groupCoverImageFile.id
    : null;
  const orgCoverImageFileID = orgCoverImageFile ? orgCoverImageFile.id : null;
  const [pageName, setPageName] = useState('');
  const [pageDescription, setPageDescription] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>('');
  const [profilesSuccess, setProfilesSuccess] = useState<string>('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [removeEditorSuccess, setRemoveEditorSuccess] = useState<string>('');
  const [groupEditorsList, setGroupEditorsList] = useState<IOrganisationUser[]>(
    [],
  );
  const [addedProfileIDs, setAddedProfileIDs] = useState<number[]>([]);
  const [newEditorsList, setNewEditorsList] = useState<IOrganisationUser[]>([]);
  const { location } = props;
  const lastSegment = window.location.pathname.split('/').pop(); // get the last segment of the URL
  const groupID =
    lastSegment && /^\d+$/.test(lastSegment) ? parseInt(lastSegment, 10) : undefined;

  const addNewEditors = (groupID: number) => {
    if (!groupID) return null;

    const promises = newEditorsList.map((organisationRole) => {
      return organisationsAPI.createGroupEditor(groupID, {
        editor: {
          role_id: organisationRole.id,
        },
      });
    });

    if (newEditorsList.length === 0) return null;

    return Promise.all(promises);
  };

  const showOriginalOrganisationSettings = () => {
    if (orgID) {
      setLoading(true);
      organisationsAPI
        .showOrganisationSettings(orgID)
        .then((res) => {
          const data = res?.data?.data;
          if (data.company_logo) setGroupCoverImageFile(data.company_logo);
          if (data.company_logo) setOrgCoverImageFile(data.company_logo);
          if (data.bg_color) setBackgroundColour(data.bg_color);
          if (data.text_color) setTextColour(data.text_color);
          if (data.button_bg_color)
            setButtonBackgroundColour(data.button_bg_color);
          if (data.button_text_color)
            setButtonTextColour(data.button_text_color);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const showOriginalOrganisationCoverPhoto = () => {
    if (orgID) {
      setLoading(true);
      organisationsAPI
        .showOrganisationSettings(orgID)
        .then((res) => {
          const data = res?.data?.data;
          if (data.company_logo) setOrgCoverImageFile(data.company_logo);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const showGroupSettings = useCallback(() => {
    if (orgID && groupID) {
      setLoading(true);
      organisationsAPI
        .showOrganisationGroup(orgID, groupID)
        .then((res) => {
          const data = res?.data?.data;
          if (data.name) setGroupName(data.name);
          if (data.description) setSubheading(data.description);
          if (data.group_logo) setGroupCoverImageFile(data.group_logo);
          if (data.bg_color) setBackgroundColour(data.bg_color);
          if (data.text_color) setTextColour(data.text_color);
          if (data.button_bg_color)
            setButtonBackgroundColour(data.button_bg_color);
          if (data.button_text_color)
            setButtonTextColour(data.button_text_color);
          if (data.editors) setGroupEditorsList(data.editors);
        })
        .catch(() => {
          history.push('/404');
        })
        .finally(() => {
          setLoading(false);
          setNewEditorsList([]);
          if (location && location.state) {
            const { fromPage } = location.state;
            if (fromPage === '/edit-group/preview') {
              if (location.state.backgroundColour)
                setBackgroundColour(location.state.backgroundColour);
              if (location.state.textColour)
                setTextColour(location.state.textColour);
              if (location.state.buttonBackgroundColour)
                setButtonBackgroundColour(
                  location.state.buttonBackgroundColour,
                );
              if (location.state.buttonTextColour)
                setButtonTextColour(location.state.buttonTextColour);
              if (location.state.companyLogoFileID) {
                filesAPI
                  .showFile(orgID, Number(location.state.companyLogoFileID))
                  .then((res) => setGroupCoverImageFile(res.data.data))
                  .finally(() => {});
              }
            }
          }
        });
    }
  }, [groupID, orgID]);

  const handleAddProfiles = (profileIDs: number[]) => {
    if (orgID === undefined) return;

    if (profileIDs.length === 0) return;

    if (groupID !== undefined) {
      profilesAPI
        .updateProfilesShared({
          orgID,
          body: {
            ids: profileIDs,
            profile: {
              group_id: groupID,
            },
          },
        })
        .then(() => {
          setProfilesSuccess('Profiles added to group successfully');
        });
    } else {
      setAddedProfileIDs(profileIDs);
    }
  };

  const handleRemoveProfiles = (profileIDs: number[]) => {
    if (orgID === undefined) return;

    if (profileIDs.length === 0) return;

    if (groupID !== undefined) {
      profilesAPI
        .updateProfilesShared({
          orgID,
          body: {
            ids: profileIDs,
            profile: {
              group_id: null,
            },
          },
        })
        .then(() => {
          setProfilesSuccess('Profiles removed from group successfully');
        });
    }
  };

  const handleDeleteGroup = () => {
    if (orgID !== undefined && groupID !== undefined) {
      organisationsAPI
        .deleteOrganisationGroup(orgID, groupID)
        .then(() => history.push('/groups'))
        .catch(() => {
          setIsDeleteModalOpen(true);
        });
    }
  };

  const deleteEditorFromGroup = (roleID: number) => {
    if (!orgID) return;

    if (groupID !== undefined) {
      organisationsAPI
        .deleteGroupEditor(roleID, groupID)
        .then(() => setSuccess('Editor removed from group'))
        .then(() => showGroupSettings());
    } else {
      setGroupEditorsList(
        groupEditorsList.filter((editor) => editor.id !== roleID),
      );
    }
  };

  const handleSave = () => {
    // If its creating a group
    if (groupID === undefined && orgID !== undefined) {
      organisationsAPI
        .createOrganisationGroup(orgID, {
          organisation_group: {
            name: groupName,
            description: subheading,
            group_logo_file_id: groupCoverImageFileID || orgCoverImageFileID,
            bg_color: backgroundColour,
            text_color: textColour,
            button_bg_color: buttonBackgroundColour,
            button_text_color: buttonTextColour,
          },
        })
        .then(async (res) => {
          const newGroupID = res.data.data.id;

          await addNewEditors(newGroupID);

          return newGroupID;
        })
        .then((newGroupID) => {
          profilesAPI.updateProfilesShared({
            orgID,
            body: {
              ids: addedProfileIDs,
              profile: {
                group_id: newGroupID,
              },
            },
          });
        })
        .then(() => {
          history.push('/groups', {
            success: 'Group has been created successfully',
          });
        });
    } else if (groupID !== undefined && orgID !== undefined) {
      // If its updating a group
      organisationsAPI
        .updateOrganisationGroup(orgID, groupID, {
          organisation_group: {
            name: groupName,
            description: subheading,
            group_logo_file_id: groupCoverImageFileID || orgCoverImageFileID,
            bg_color: backgroundColour,
            text_color: textColour,
            button_bg_color: buttonBackgroundColour,
            button_text_color: buttonTextColour,
          },
        })
        .then(() => {
          history.push('/groups', {
            success: 'Group has been updated successfully',
          });
        });
    }
  };

  const handleGroupSettingsPreview = () => {
    history.push(`/edit-group/preview`, {
      bg_color: backgroundColour,
      text_color: textColour,
      button_bg_color: buttonBackgroundColour,
      button_text_color: buttonTextColour,
      company_logo_file_id: groupCoverImageFile ? groupCoverImageFile.id : null,
      fromPage: window.location.pathname,
    });
  };

  const listOrganisationRoles = () => {
    if (!orgID) return;

    organisationsAPI
      .listOrganisationRoles({
        orgID,
        page: 1,
        pageSize: 100,
      })
      .then((res) => {
        setOrgUsers(res.data.data);
      });
  };

  const getAvailableEditors = () => {
    if (orgUsers) {
      return orgUsers.filter((user) => {
        if (user.role !== 'org_editor') return false;

        const editor = groupEditorsList.find(
          (editor) => editor.user.id === user.id,
        );
        return !editor;
      });
    }
    return [];
  };

  useEffect(() => {
    if (!orgID) return;

    if (groupID) {
      addNewEditors(groupID)
        ?.then(() => {
          setSuccess('Editors added successfully');
        })
        .finally(() => {
          showGroupSettings();
          listOrganisationRoles();
        });
    } else {
      setGroupEditorsList([...groupEditorsList, ...newEditorsList]);
    }
  }, [newEditorsList]);

  useEffect(() => {
    if (groupID === undefined) {
      setPageName('Create Group');
      setPageDescription('Create a new group to manage profiles and teams');
      showOriginalOrganisationSettings();
    } else {
      setPageName('Edit Group');
      setPageDescription('Edit group details and profiles');
      showOriginalOrganisationCoverPhoto();
      showGroupSettings();
    }

    listOrganisationRoles();
  }, []);

  return (
    <Layout pageName={pageName} description={pageDescription}>
      {error && <ErrorAlert message={MESSAGES.error.generic} />}
      <InfoPanelContainer
        title="Group Information"
        information="Add details to better identify your group"
      >
        {orgID && (
          <GroupInfoForm
            orgID={orgID}
            groupName={groupName}
            groupNameError={groupNameError}
            subheading={subheading}
            setGroupName={setGroupName}
            setSubheading={setSubheading}
          />
        )}
      </InfoPanelContainer>

      <InfoPanelDivider />
      {userRole !== 'org_editor' && (
        <>
          <InfoPanelContainer
            title="Select Profiles"
            information="Add profiles from your organisation to this group"
          >
            {profilesSuccess && <SuccessAlert message={profilesSuccess} />}
            <div className="flex space-x-4 justify-end">
              {pageName === 'Edit Group' && (
                <Button
                  kind={BUTTON_KIND.WHITE_BG_LIGHT_RED_TEXT}
                  buttonText="Remove profiles"
                  onClick={() => setIsRemoveProfilesModalOpen(true)}
                />
              )}
              <Button
                kind={BUTTON_KIND.PRIMARY}
                buttonText="Add profiles"
                onClick={() => setIsAddProfilesModalOpen(true)}
              />
            </div>
          </InfoPanelContainer>
          <InfoPanelDivider />
        </>
      )}
      <InfoPanelContainer
        information="Choose an image to display at the top of the profile pages of cardholders in this group."
        title="Diplay Settings"
      >
        <div className="flex items-center mt-4 mb-2">
          {orgID && (
            <UploadPhoto
              title=""
              orgID={orgID}
              photo={groupCoverImageFile}
              setPhoto={setGroupCoverImageFile}
              size="large"
              aspectRatio={16 / 11}
              fileFormatMessage="Recommended dimensions 1024px x 704px"
            />
          )}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="col-span-1">
            <div className="mt-4 text-left">
              <p className="block mb-2 text-sm leading-5 font-medium text-gray-900">
                Page background color
              </p>
              <ColorPicker
                color={backgroundColour}
                setColor={setBackgroundColour}
              />
            </div>
          </div>
          <div className="col-span-1">
            <div className="mt-4 text-left">
              <p className="block mb-2 text-sm leading-5 font-medium text-gray-900">
                Text color
              </p>
              <ColorPicker color={textColour} setColor={setTextColour} />
            </div>
          </div>
          <div className="col-span-1">
            <div className="mt-4 text-left">
              <p className="block mb-2 text-sm leading-5 font-medium text-gray-900">
                Button background color
              </p>
              <ColorPicker
                color={buttonBackgroundColour}
                setColor={setButtonBackgroundColour}
              />
            </div>
          </div>
          <div className="col-span-1">
            <div className="mt-4 text-left">
              <p className="block mb-2 text-sm leading-5 font-medium text-gray-900">
                Button text color
              </p>
              <ColorPicker
                color={buttonTextColour}
                setColor={setButtonTextColour}
              />
            </div>
          </div>
        </div>
        <Button
          className="mt-4"
          buttonText="Preview"
          kind={BUTTON_KIND.PRIMARY}
          icon={<EyeIcon />}
          onClick={handleGroupSettingsPreview}
        />
      </InfoPanelContainer>
      {userRole === 'org_admin' && (
        <>
          <InfoPanelDivider />
          <InfoPanelContainer
            title="Editing Access"
            information="These users will have access to the group and be able to manage it."
          >
            <div>
              <ul className="divide-y divide-gray-200">
                {success !== '' && <SuccessAlert message={success} />}
                {removeEditorSuccess !== '' && (
                  <SuccessAlert message={removeEditorSuccess} />
                )}
                {orgUsers &&
                  orgUsers.map(
                    (item) =>
                      item.role === 'org_admin' && (
                        <li key={item.id}>
                          <div className="block bg-white">
                            <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between py-4">
                              <div className="min-w-0">
                                <p className="text-sm font-medium text-gray-900 truncate">
                                  {item.user?.first_name} {item.user?.last_name}
                                </p>
                                <p className="flex items-center text-sm text-gray-500 truncate">
                                  {item.user?.email}
                                </p>
                              </div>
                              <div className="inline-flex flex-col lg:flex-row lg:space-x-6 items-start lg:items-center">
                                <p className="flex items-center text-sm text-gray-500 truncate">
                                  Admin
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      ),
                  )}
                {groupEditorsList &&
                  groupEditorsList.map((item) => (
                    <li key={item.id}>
                      <div className="block bg-white">
                        <div className="flex flex-row items-center justify-between py-4">
                          <div className="min-w-0">
                            <p className="text-sm font-medium text-gray-900 truncate">
                              {item.user?.first_name} {item.user?.last_name}
                            </p>
                            <p className="flex items-center text-sm text-gray-500 truncate">
                              {item.user?.email}
                            </p>
                          </div>
                          {userRole === 'org_admin' && (
                            <div className="inline-flex flex-row space-x-6 items-center">
                              <Button
                                buttonText="Remove"
                                loading={isLoading}
                                kind={BUTTON_KIND.WHITE_BG_LIGHT_RED_TEXT}
                                onClick={() => {
                                  deleteEditorFromGroup(item.id);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
                <li key="add-editors-butn">
                  <div className="block bg-white">
                    <div className="flex justify-end py-4">
                      <Button
                        kind={BUTTON_KIND.PRIMARY}
                        buttonText="Add Editors"
                        onClick={() => setIsEditorsModalOpen(true)}
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </InfoPanelContainer>
        </>
      )}
      <InfoPanelDivider />
      {pageName === 'Edit Group' && userRole === 'org_admin' && (
        <>
          <InfoPanelContainer
            title="Delete Group"
            information="Deleting this group will remove all profile from the group"
          >
            <div className="flex justify-end">
              <ul className="divide-y divide-gray-200 mb-8 sm:mb-0">
                <Button
                  buttonText="Delete group"
                  kind={BUTTON_KIND.WHITE_BG_LIGHT_RED_TEXT}
                  onClick={() => setIsDeleteGroupModalOpen(true)}
                />
              </ul>
            </div>
          </InfoPanelContainer>
          <InfoPanelDivider />
        </>
      )}

      <InfoPanelFooter>
        <div className="flex space-x-4">
          <Button buttonText="Save and close" onClick={() => handleSave()} />
          <Button
            buttonText="Discard changes"
            kind={BUTTON_KIND.WHITE}
            onClick={() => history.push('/')}
          />
        </div>
      </InfoPanelFooter>
      <Modal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        dialogTitle="Remove profiles and managers from group"
        dialogDescription="This group has profiles and/or group managers. If you want to delete this group, you must first remove all profiles and/or group managers from this group."
        successButtonText="Remove profiles from group"
        successButtonKind={BUTTON_KIND.PRIMARY}
        onSuccess={() => {
          setIsDeleteModalOpen(false);
          setIsRemoveProfilesModalOpen(true);
        }}
        isLoading={isLoading}
      />
      <EditorsModal
        isOpen={isEditorsModalOpen}
        setIsOpen={setIsEditorsModalOpen}
        users={getAvailableEditors()}
        onSuccess={(selectedUsers: IOrganisationUser[]) => {
          setNewEditorsList(selectedUsers);
        }}
      />
      <AddProfilesModal
        isOpen={isAddProfilesModalOpen}
        setIsOpen={setIsAddProfilesModalOpen}
        groupID={groupID || 0}
        onSuccess={handleAddProfiles}
      />
      <RemoveProfilesModal
        isOpen={isRemoveProfilesModalOpen}
        setIsOpen={setIsRemoveProfilesModalOpen}
        groupID={groupID || 0}
        onSuccess={handleRemoveProfiles}
      />
      <Modal
        isOpen={isDeleteGroupModalOpen}
        setIsOpen={setIsDeleteGroupModalOpen}
        dialogTitleIcon={<ExclamationCircleIcon className='h-16 w-16 text-red-450 stroke-1'/>}
        dialogTitle="Are you sure you want to delete this group?"
        dialogDescription="Group cannot be retrieved once it has been deleted."
        successButtonText="Delete group"
        successButtonKind={BUTTON_KIND.WHITE_BG_LIGHT_RED_TEXT}
        onSuccess={() => {
          handleDeleteGroup();
        }}
      />
    </Layout>
  );
}
