import { IIcon } from '@/types/IIcon';

const DownloadIcon = ({
  color = '#D1D5DB',
  width = '27',
  height = '27',
}: IIcon): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 27 27"
      fill="none"
    >
      <path
        d="M1.83594 19.334V20.7923C1.83594 21.9526 2.29687 23.0654 3.11735 23.8859C3.93782 24.7064 5.05062 25.1673 6.21094 25.1673H20.7943C21.9546 25.1673 23.0674 24.7064 23.8879 23.8859C24.7083 23.0654 25.1693 21.9526 25.1693 20.7923V19.334M19.3359 13.5007L13.5026 19.334M13.5026 19.334L7.66927 13.5007M13.5026 19.334V1.83398"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownloadIcon;
