import { IIcon } from '@/types/IIcon';

const Analytics = ({ className }: IIcon): JSX.Element => {
  return (
    <svg className={className} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 4C19 3.44772 18.5523 3 18 3C17.4477 3 17 3.44772 17 4V20C17 20.5523 17.4477 21 18 21C18.5523 21 19 20.5523 19 20V4ZM12 9C12.5523 9 13 9.44772 13 10V20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20V10C11 9.44772 11.4477 9 12 9ZM6 15C6.55228 15 7 15.4477 7 16V20C7 20.5523 6.55228 21 6 21C5.44772 21 5 20.5523 5 20V16C5 15.4477 5.44772 15 6 15Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Analytics;
