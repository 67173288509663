const unitPrice = 84.95;

const TaptCustomDiscount = {
  getDiscountAmountPerItem: (totalProfiles: number) => {
    const pricingTable = [
      { min: 1000, discount: 35 },
      { min: 500, discount: 30 },
      { min: 250, discount: 25 },
      { min: 50, discount: 20 },
      { min: 30, discount: 15 },
      { min: 13, discount: 10 },
      { min: 2, discount: 5 },
    ]

    return pricingTable.find((item) => totalProfiles >= item.min)?.discount || 0
  },

  getPremiumDiscountAmountPerItem: () => {
    const premiumUnitPrice = 40;
    return unitPrice - premiumUnitPrice;
  }
}

export default TaptCustomDiscount