import { Dispatch, useEffect, useState } from 'react';
import ordersAPI from '@/api/ordersV2';
import TabList from '@/components/TabList';
import { STAGE } from '@/constants/stage';

interface ChangeStageArgs {
  order_id: number;
  stage: string;
}

interface IOrderTabList {
  handleOrderStatusChange: any;
  status: string;
  setOnDragTab: Dispatch<React.SetStateAction<string | undefined>>;
  onDragTab: string | undefined;
  initial: boolean;
}

interface IStageCount {
  newCount: number;
  awaitingClientCount: number;
  designEditsCount: number;
  readyForPrintCount: number;
  inPrintCount: number;
  fulfilledCount: number;
  onHoldCount: number;
  allOrdersCount: number;
}

const allowOrderNewFeature =
  process.env.REACT_APP_ALLOW_ORDER_NEW_FEATURE === 'true';

export const tabs = [
  {
    name: STAGE.order_stage_name.new,
    value: STAGE.order.new,
  },
  {
    name: STAGE.order_stage_name.awaiting_client,
    value: STAGE.order.awaiting_client,
  },
  {
    name: STAGE.order_stage_name.design_edits,
    value: STAGE.order.design_edits,
  },
  {
    name: STAGE.order_stage_name.ready_for_print,
    value: STAGE.order.ready_for_print,
  },
  ...(allowOrderNewFeature
    ? [
        {
          name: STAGE.order_stage_name.in_print,
          value: STAGE.order.in_print,
        },
      ]
    : []),
  {
    name: STAGE.order_stage_name.fulfilled,
    value: STAGE.order.fulfilled,
  },
  {
    name: STAGE.order_stage_name.all_orders,
    value: STAGE.order.all_orders,
  },
  {
    name: STAGE.order_stage_name.on_hold,
    value: STAGE.order.on_hold,
  },
];

const OrderTabList = (props: IOrderTabList): JSX.Element => {
  const { handleOrderStatusChange, status, setOnDragTab, onDragTab, initial } =
    props;

  const [stageCount, setStageCount] = useState<IStageCount>({
    newCount: 0,
    awaitingClientCount: 0,
    designEditsCount: 0,
    readyForPrintCount: 0,
    inPrintCount: 0,
    fulfilledCount: 0,
    onHoldCount: 0,
    allOrdersCount: 0,
  });

  const handleDrop = (
    e: React.DragEvent<HTMLButtonElement>,
    value: string,
    tab_value: string,
  ): void => {
    e.preventDefault();
    const orderId = Number(e.dataTransfer.getData('order_id'));
    const changeStageArgs: ChangeStageArgs = {
      order_id: orderId,
      stage: tab_value,
    };

    ordersAPI.updateOrder(changeStageArgs).then(() => {
      if (tab_value !== value || tab_value !== 'all_orders') {
        handleOrderStatusChange();
        setOnDragTab('');
      }
    });
  };

  const handleDragOver = (
    e: React.DragEvent<HTMLButtonElement>,
    tab_value: string,
  ): void => {
    e.preventDefault();

    if (tab_value !== onDragTab) {
      setOnDragTab(tab_value);
    }
  };

  function handleTabColor(status: string) {
    switch (status) {
      case STAGE.order.new:
        return 'red';
      case STAGE.order.awaiting_client:
        return 'orange';
      case STAGE.order.design_edits:
        return 'yellow';
      case STAGE.order.ready_for_print:
        return 'green';
      case STAGE.order.in_print:
        return 'in_print';
      case STAGE.order.fulfilled:
        return 'fullfiled';
      case STAGE.order.all_orders:
        return 'gray';
      case STAGE.order.on_hold:
        return 'gray';
      default:
        return '';
    }
  }

  useEffect(() => {
    ordersAPI.countOrders().then((res) => {
      setStageCount({
        newCount: res.data.new,
        awaitingClientCount: res.data.awaiting_client,
        designEditsCount: res.data.design_edits,
        readyForPrintCount: res.data.ready_for_print,
        inPrintCount: res.data.in_print,
        fulfilledCount: res.data.fulfilled,
        onHoldCount: res.data.on_hold,
        allOrdersCount:
          Number(res.data.new) +
          Number(res.data.awaiting_client) +
          Number(res.data.design_edits) +
          Number(res.data.ready_for_print) +
          Number(res.data.in_print) +
          Number(res.data.fulfilled),
      });
    });
  }, [initial]);

  return (
    <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full md:border-b border-gray-200 z-10">
      <div className="pt-4 w-full md:w-auto">
        <TabList
          value={status}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragTab={onDragTab}
          setOnDragTab={setOnDragTab}
          badgeTextColor="text-gray-800"
          tabs={tabs.map((item) => {
            let count;
            const color = handleTabColor(item.value);
            switch (item.value) {
              case STAGE.order.new:
                count = stageCount.newCount;
                break;
              case STAGE.order.awaiting_client:
                count = stageCount.awaitingClientCount;
                break;
              case STAGE.order.design_edits:
                count = stageCount.designEditsCount;
                break;
              case STAGE.order.ready_for_print:
                count = stageCount.readyForPrintCount;
                break;
              case STAGE.order.in_print:
                count = stageCount.inPrintCount;
                break;
              case STAGE.order.fulfilled:
                count = stageCount.fulfilledCount;
                break;
              case STAGE.order.all_orders:
                count = stageCount.allOrdersCount;
                break;
              case STAGE.order.on_hold:
                count = stageCount.onHoldCount;
                break;
              default:
                break;
            }

            return {
              ...item,
              count,
              color,
            };
          })}
        />
      </div>
    </div>
  );
};

export default OrderTabList;
