import { IIcon } from '@/types/IIcon';

const ShoppingBagIcon = ({ className }: IIcon): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
    >
      <path
        d="M23.3359 16.0417V10.2083C23.3359 8.66124 22.7214 7.17751 21.6274 6.08354C20.5334 4.98958 19.0497 4.375 17.5026 4.375C15.9555 4.375 14.4718 4.98958 13.3778 6.08354C12.2839 7.17751 11.6693 8.66124 11.6693 10.2083V16.0417M7.29427 13.125H27.7109L29.1693 30.625H5.83594L7.29427 13.125Z"
        stroke="#D1D5DB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShoppingBagIcon;
