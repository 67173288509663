import { useState } from 'react';
import Input from '@/components/Input';
import Radio from '@/components/Radio';
import filesAPI from '@/api/files';
import FileUploadButtonComponent from '@/components/FileUploadButton';
import IFile from '@/types/IFile';
import classNames from '@/helpers/classNames';
import MESSAGES from '@/constants/messages-en';

interface ICardDesign {
  orgID: number;
  primaryC: string;
  setPrimaryC: React.Dispatch<React.SetStateAction<string>>;
  primaryM: string;
  setPrimaryM: React.Dispatch<React.SetStateAction<string>>;
  primaryY: string;
  setPrimaryY: React.Dispatch<React.SetStateAction<string>>;
  primaryK: string;
  setPrimaryK: React.Dispatch<React.SetStateAction<string>>;
  secondaryC: string;
  setSecondaryC: React.Dispatch<React.SetStateAction<string>>;
  secondaryM: string;
  setSecondaryM: React.Dispatch<React.SetStateAction<string>>;
  secondaryY: string;
  setSecondaryY: React.Dispatch<React.SetStateAction<string>>;
  secondaryK: string;
  setSecondaryK: React.Dispatch<React.SetStateAction<string>>;
  designFile: IFile | undefined;
  setDesignFile: React.Dispatch<React.SetStateAction<IFile | undefined>>;
  logoFile: IFile | undefined;
  setLogoFile: React.Dispatch<React.SetStateAction<IFile | undefined>>;
  cardDesign: number;
  setCardDesign: React.Dispatch<React.SetStateAction<number>>;
  designNote: string;
  setDesignNote: React.Dispatch<React.SetStateAction<string>>;
  error?: boolean;
  disabled?: boolean;
}

export default function CardDesign({
  orgID,
  primaryC,
  setPrimaryC,
  primaryM,
  setPrimaryM,
  primaryY,
  setPrimaryY,
  primaryK,
  setPrimaryK,
  secondaryC,
  setSecondaryC,
  secondaryM,
  setSecondaryM,
  secondaryY,
  setSecondaryY,
  secondaryK,
  setSecondaryK,
  designFile,
  setDesignFile,
  logoFile,
  setLogoFile,
  cardDesign,
  setCardDesign,
  designNote,
  setDesignNote,
  error,
  disabled,
}: ICardDesign): JSX.Element {
  const [designSizeError, setDesignSizeError] = useState<boolean>(false);
  const [designFileError, setDesignFileError] = useState<boolean>(false);
  const [designLoading, setDesignLoading] = useState<boolean>(false);
  const [logoSizeError, setLogoSizeError] = useState<boolean>(false);
  const [logoFileError, setLogoFileError] = useState<boolean>(false);
  const [logoLoading, setLogoLoading] = useState<boolean>(false);
  const cardDesignOptions = [
    {
      name: 'Upload a design file',
      description: MESSAGES.design.upload,
      value: 0,
    },
    {
      name: 'Use an existing design file for my organisation',
      description: MESSAGES.design.existing,
      value: 1,
    },
    {
      name: 'I need some help designing my card',
      description: MESSAGES.design.help,
      value: 2,
    },
  ];

  return (
    <>
      <Radio
        settings={cardDesignOptions}
        value={cardDesign}
        setter={setCardDesign}
        error={error}
        disabled={disabled}
      />
      {cardDesign === 0 && (
        <div
          className={classNames(
            'mt-4 shadow overflow-hidden sm:rounded-md border border-gray-200',
            designSizeError || designFileError ? 'border-red-500' : '',
          )}
        >
          <div className="px-4 py-5 bg-white sm:p-6">
            <div className="mb-1">
              <div className="block text-sm font-medium text-gray-700 mb-4">
                Upload card design template
              </div>
              <div className="flex justify-between">
                <FileUploadButtonComponent
                  id="design-upload"
                  filename={designFile?.file?.file_name}
                  url={designFile?.original_url}
                  onFileSelected={(file) => {
                    setDesignSizeError(false);
                    setDesignFileError(false);
                    setDesignLoading(true);
                    filesAPI
                      .createAndUploadFile(orgID, new File([file], file.name))
                      .then((res) => {
                        if (res?.data?.data.id) {
                          setDesignFile(res.data.data);
                        }
                      })
                      .catch(() => setDesignFileError(true))
                      .finally(() => setDesignLoading(false));
                  }}
                  buttonTitle="Upload design"
                  fileFormat=".svg, .eps, .ai, .indd, .pdf"
                  fileFormatMessage="(.svg, .eps, .ai, .indd, or .pdf only)"
                  loading={designLoading}
                  sizeError={designSizeError}
                  fileError={designFileError}
                  disabled={disabled}
                />
                {!disabled && designFile?.file?.file_name && (
                  <button
                    type="button"
                    onClick={() => {
                      setDesignFile(undefined);
                    }}
                    className="appearance-none text-sm leading-5 text-brand-500"
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {cardDesign === 2 && (
        <div className="mt-4 shadow overflow-hidden sm:rounded-md">
          <div className="px-4 py-4 bg-white sm:p-6">
            <span className="block text-lg font-medium text-gray-700">
              Card design specifications
            </span>
            <p className="block text-sm font-small text-gray-500 mb-4">
              {MESSAGES.design.specifications}
            </p>
            <div className="block text-sm font-medium text-gray-700">
              Company logo
            </div>
            <div className="mb-4 flex justify-between">
              <FileUploadButtonComponent
                id="logo-upload"
                filename={logoFile?.file?.file_name}
                url={logoFile?.original_url}
                onFileSelected={(file) => {
                  setLogoSizeError(false);
                  setLogoFileError(false);
                  setLogoLoading(true);
                  filesAPI
                    .createAndUploadFile(orgID, new File([file], file.name))
                    .then((res) => {
                      if (res?.data?.data.id) {
                        setLogoFile(res.data.data);
                      }
                    })
                    .catch(() => setLogoFileError(true))
                    .finally(() => setLogoLoading(false));
                }}
                buttonTitle="Upload file"
                fileFormat=".svg, .eps, .ai, .indd, .pdf"
                fileFormatMessage="(.svg, .eps, .ai, .indd, or .pdf only)"
                loading={logoLoading}
                sizeError={logoSizeError}
                fileError={logoFileError}
                disabled={disabled}
              />
              {!disabled && logoFile?.file?.file_name && (
                <button
                  type="button"
                  onClick={() => {
                    setLogoFile(undefined);
                  }}
                  className="appearance-none text-sm leading-5 text-brand-500"
                >
                  Remove
                </button>
              )}
            </div>
            <div className="block text-sm font-medium text-gray-700">
              Primary color
            </div>
            <div className="grid grid-cols-6 gap-6 mt-1">
              <Input
                label="C"
                value={primaryC}
                type="number"
                onChange={setPrimaryC}
                disabled={disabled}
              />
              <Input
                label="M"
                value={primaryM}
                type="number"
                onChange={setPrimaryM}
                disabled={disabled}
              />
              <Input
                label="Y"
                value={primaryY}
                type="number"
                onChange={setPrimaryY}
                disabled={disabled}
              />
              <Input
                label="K"
                value={primaryK}
                type="number"
                onChange={setPrimaryK}
                disabled={disabled}
              />
            </div>
            <div className="block text-sm font-medium text-gray-700 mt-4">
              Secondary color
            </div>
            <div className="grid grid-cols-6 gap-6 mt-1">
              <Input
                label="C"
                value={secondaryC}
                type="number"
                onChange={setSecondaryC}
                disabled={disabled}
              />
              <Input
                label="M"
                value={secondaryM}
                type="number"
                onChange={setSecondaryM}
                disabled={disabled}
              />
              <Input
                label="Y"
                value={secondaryY}
                type="number"
                onChange={setSecondaryY}
                disabled={disabled}
              />
              <Input
                label="K"
                value={secondaryK}
                type="number"
                onChange={setSecondaryK}
                disabled={disabled}
              />
            </div>
            <label
              htmlFor="note"
              className="block text-sm font-medium text-gray-700 mt-4"
            >
              Design notes
              <div className="mt-1">
                <textarea
                  id="note"
                  name="note"
                  rows={3}
                  className="shadow-sm focus:ring-brand-500 focus:border-brand-500 mt-1 block w-full text-base leading-6 border border-gray-300 rounded-md disabled:bg-gray-200 disabled:cursor-not-allowed"
                  placeholder={disabled ? '' : MESSAGES.design.note}
                  value={designNote}
                  onChange={(e) => setDesignNote(e.target.value)}
                  disabled={disabled}
                />
              </div>
            </label>
          </div>
        </div>
      )}
    </>
  );
}
