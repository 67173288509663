import { useEffect, useState } from 'react';
import { ChargebeeInstance } from '@chargebee/chargebee-js-types';
import Layout from '@/components/Layout';
import {
  BillingInformation,
  OrderOverview,
  PlanOverview,
  Plans,
} from '@/components/PremiumSubscriptionPage';

const FORM_STEPS = [
  {
    label: 'Premium',
  },
  {
    label: 'Plan Overview',
  },
  {
    label: 'Billing information',
  },
  {
    label: 'Order Overview',
  },
];

export default function PlanSelectionPage(): JSX.Element {
  const [step, setStep] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState('monthly');
  const [cbInstance, setCbInstance] = useState<ChargebeeInstance>();

  const renderFormStep = (step: number) => {
    switch (step) {
      case 0:
        return <Plans cbInstance={cbInstance}  selectPlan={setSelectedPlan} />;
      case 1:
        return <PlanOverview onNext={() => setStep(2)} />;
      case 2:
        return <BillingInformation onNext={() => setStep(3)} />;
      case 3:
        return <OrderOverview plan={selectedPlan} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (window && document) {
      const script = document.createElement('script')
      const body = document.getElementsByTagName('body')[0]
      script.src = 'https://js.chargebee.com/v2/chargebee.js'
      body.appendChild(script)
      script.addEventListener('load', () => {
        // Initialize chargebee.js
        window.Chargebee?.init({
          site: process.env.REACT_APP_CHARGEBEE_SITE || '',
          publishableKey: process.env.REACT_APP_CHARGEBEE_KEY || '',
          isItemsModel: true,
        });

        setCbInstance(window.Chargebee.getInstance())
      })
    }
  }, [])

  return (
    <Layout pageName={FORM_STEPS[step].label}>
      <div className="mt-10">{renderFormStep(step)}</div>
    </Layout>
  );
}
