import { useCallback, useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { Tab } from '@headlessui/react';
import moment from 'moment-timezone';
import Layout from '@/components/Layout';
import Spinner from '@/components/Icons/Spinner';
import SuccessAlert from '@/components/SuccessAlert';
import ErrorAlert from '@/components/ErrorAlert';
import classNames from '@/helpers/classNames';
import MESSAGES from '@/constants/messages-en';
import IOrder from '@/types/IOrderV2';
import Search from '@/components/Search';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import Button, { BUTTON_KIND } from '@/components/Button';
import OrdersListItem from '@/components/OrdersPage/OrdersV2ListAdmin/Item';
import Pagination from '@/components/Pagination';
import ordersAPI from '@/api/ordersV2';
import Sort from '@/components/Sort';
import { IListPaging, IListNew } from '@/types/IList';
import InputCheckbox from '@/components/InputCheckbox';
import adminAPI from '@/api/admin';
import STAGE from '@/constants/stage';
import useDialog from '@/hooks/useDialog';
import orderTableHeader from '@/pages/OrderV2Page/OrdersV2ListAdmin/TableHeader';
import { CustomScroll } from '../../../styleds/CustomScroll';
import TableHeader from '@/components/OrdersPage/shared/TableHeader';
import OrderTabList, { tabs } from './Tablist';
import OrderConstrain from './constrains/constrain';
import OrderDetails from '@/components/OrdersPage/OrderDetails/OrderDetails';

export default function OrdersListPage({
  orderDetailUUID,
}: {
  orderDetailUUID: string | undefined;
}): JSX.Element {
  const { openDialog, loadingDialog, closeDialog } = useDialog();
  const [data, setData] = useState<IOrder[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');
  const [status, setStatus] = useState('new');
  const [sort, setSort] = useState(status === 'new' ? 'tag' : 'updated_at');
  const [order, setOrder] = useState('desc');
  const [selectAll, setSelectAll] = useState(false);
  const [initial, setInitial] = useState<boolean>(false);
  const [onDragTab, setOnDragTab] = useState<string>();
  const [orderDetailData, setOrderDetailData] = useState<IOrder>();
  const [isOpenOrderDetailModalByUUID, setIsOpenOrderDetailModalByUUID] =
    useState<boolean>(false);
  const [isMoveOrder, setIsMoveOrder] = useState<boolean>(false);

  useEffect(() => {
    orderDetailUUID &&
      ordersAPI.showOrder(orderDetailUUID).then((res) => {
        setOrderDetailData(res.data.data);
        setIsOpenOrderDetailModalByUUID(true);
      });
  }, [orderDetailUUID]);

  function handleCheckedItem(orderId: string) {
    let arr = checkedItems;
    if (checkedItems.includes(orderId)) {
      arr = arr.filter((e) => e !== orderId);
    } else {
      arr = [...checkedItems, orderId];
    }
    setCheckedItems(arr);
  }

  function handleTabColor(status: string) {
    switch (status) {
      case STAGE.order.new:
        return 'red';
      case STAGE.order.awaiting_client:
        return 'orange';
      case STAGE.order.design_edits:
        return 'yellow';
      case STAGE.order.ready_for_print:
        return 'green';
      case STAGE.order.in_print:
        return 'in_print';
      case STAGE.order.fulfilled:
        return 'fullfiled';
      case STAGE.order.all_orders:
        return 'gray';
      case STAGE.order.on_hold:
        return 'gray';
      default:
        return '';
    }
  }

  const listOrders = useCallback(
    async ({
      newPage = page,
      newPageSize = pageSize,
      newSort = sort,
      newOrder = order,
      newStatus = status,
      newSearch = search,
      initial = false,
    }: IListNew): Promise<{ data: IOrder[]; paging: IListPaging }> => {
      const res = await ordersAPI.listOrders({
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        order: newOrder,
        status: newStatus,
        search: newSearch,
      });

      setData(res.data?.data);
      setPage(newPage);
      setPageSize(newPageSize);
      setStatus(newStatus);
      setSearch(newSearch);
      setIsLoading(false);

      return res.data;
    },
    [page, pageSize, sort, order, status, search],
  );

  const handleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    orderId: number,
  ): void => {
    e.dataTransfer.setData('order_id', String(orderId));
  };

  const exportCSV = useCallback(
    async (arr?: string[]) => {
      if (arr || checkedItems) {
        const response = await adminAPI.exportProfilesViaOrderCSV(
          arr || checkedItems,
        );

        const blob = new Blob([response.data]);
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = 'admin_profiles.csv';
        a.click();
      }
    },
    [checkedItems],
  );
  const { isLoading: isDeletingOrders, mutate: deleteAllOrders } = useMutation(
    () => ordersAPI.deleteOrders(checkedItems),
    {
      onSuccess: (res) => {
        listOrders({ initial: true });
        setSuccess('Cart(s) were deleted successfully');
        closeDialog();
        setCheckedItems([]);
      },
      onError: (err) => {
        setError(true);
        closeDialog();
      },
    },
  );
  useEffect(() => {
    loadingDialog(isDeletingOrders);
    return () => {};
  }, [isDeletingOrders]);

  const handleDeleteOrdes = async () => {
    deleteAllOrders();
  };

  return (
    <Layout pageName="Shopify Orders" wrapClassName="flex flex-col">
      {success !== '' && <SuccessAlert message={success} />}
      {error && <ErrorAlert message={MESSAGES.error.generic} />}

      <Tab.Group
        onChange={(index: number) => {
          setPage(1);
          setError(false);
          setStatus(tabs[index].value);
          setSelectAll(false);
        }}
      >
        <OrderTabList
          handleOrderStatusChange={() => {
            listOrders({ initial: true });
            setInitial(!initial);
          }}
          status={status}
          setOnDragTab={setOnDragTab}
          onDragTab={onDragTab}
          initial={initial}
        />
        <Tab.Panels>
          {tabs.map((tab) => (
            <Tab.Panel key={tab.name} className="outline-none">
              <div className="py-8">
                <div className="pb-8 flex flex-col space-y-3 items-start sm:flex-row sm:space-y-0 sm:items-center sm:justify-end">
                  <div className="flex flex-shrink-0 space-x-3">
                    <div className="inline-flex">
                      <div className="relative mr-4">
                        <Search
                          id={`OrdersList-${page}-${pageSize}-${sort}-${order}-${status}-${search}`}
                          placeholder="Search"
                          search={search}
                          setSearch={setSearch}
                          fetchQuery={(newSearch) => listOrders({ newSearch })}
                        />
                      </div>
                      <Sort
                        id={`OrdersList-${page}-${pageSize}-${sort}-${order}-${status}-${search}`}
                        options={OrderConstrain.sortOptions}
                        sort={sort}
                        setSort={setSort}
                        order={order}
                        setOrder={setOrder}
                        fetchQuery={(newSort, newOrder) =>
                          listOrders({ newSort, newOrder })
                        }
                      />
                    </div>
                  </div>
                </div>
                {data && data.length > 0 ? (
                  <div className="flex flex-col">
                    <div className="relative -mx-4 sm:-mx-6 md:mx-0 lg:-mx-8">
                      <div className="block w-full lg:px-8">
                        <div className="relative shadow-sm border border-gray-200 sm:rounded-md min-h-8 ">
                          <ul
                            className={classNames(
                              isLoading ? 'opacity-40' : '',
                              'divide-y divide-gray-200',
                            )}
                          >
                            <li key="OrderListHeader">
                              <div className="bg-gray-50">
                                <table
                                  className="w-full text-left text-gray-500 dark:text-gray-400"
                                  style={{ tableLayout: 'fixed' }}
                                >
                                  <thead className="bg-gray-50">
                                    <tr className=" uppercase">
                                      <th scope="col " className="w-5.3%">
                                        <InputCheckbox
                                          id="select-all"
                                          label=""
                                          value={selectAll}
                                          onChange={(value) => {
                                            setSelectAll(value);
                                            if (value) {
                                              const arr = data.map(
                                                ({ order_number }) =>
                                                  order_number,
                                              );
                                              setCheckedItems(arr);
                                            } else {
                                              setCheckedItems([]);
                                            }
                                          }}
                                        />
                                      </th>
                                      {orderTableHeader(status).map(
                                        (item, index) => {
                                          return (
                                            <TableHeader
                                              key={index}
                                              style={item.style}
                                              className={item.className}
                                              scope={item.scope}
                                            >
                                              {item.children}
                                            </TableHeader>
                                          );
                                        },
                                      )}
                                    </tr>
                                  </thead>
                                </table>
                              </div>
                            </li>

                            <CustomScroll
                              style={{
                                height: 'calc(100vh - 408px)',
                                overflowY: 'scroll',
                              }}
                            >
                              {data.map((item: IOrder) => (
                                <OrdersListItem
                                  key={item.id}
                                  id={item.id}
                                  orderUrl={item.order_url}
                                  uuid={item.uuid}
                                  customer_name={item.customer_name}
                                  customer_email={item.customer_email}
                                  design_specs={item.design_specs}
                                  selected={checkedItems.includes(
                                    item.order_number,
                                  )}
                                  handleCheckedItem={() =>
                                    handleCheckedItem(item.order_number)
                                  }
                                  handleTabColor={() =>
                                    handleTabColor(item.order_stage)
                                  }
                                  orderType={item.order_origin}
                                  orderNumber={item.order_number}
                                  numberOfProfiles={item.order_quantity}
                                  orderStatus={status}
                                  url={item?.design_file?.original_url}
                                  orgId={item.organisation_id}
                                  notifications_sent={item.notifications_sent}
                                  designFile={item?.design_file}
                                  alternate_email={item.alternate_email}
                                  alternate_email_flag={
                                    item.alternate_email_flag
                                  }
                                  activationId={undefined}
                                  handleOrderStatusChange={() => {
                                    listOrders({ initial: true });
                                    setInitial(!initial);
                                  }}
                                  submission_date={moment(
                                    String(item.updated_at),
                                  ).format('DD/MM/YY')}
                                  is_priority={item.is_priority}
                                  setSuccess={setSuccess}
                                  setError={setError}
                                  onDragStart={handleDragStart}
                                  tag={item?.tag}
                                  paused_by={item.paused_by}
                                />
                              ))}
                            </CustomScroll>
                          </ul>
                          {isLoading && (
                            <div className="absolute left-1/2 top-1/2 text-gray-500">
                              <Spinner className="text-brand-500" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="py-32">
                    <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
                      {MESSAGES.order.empty.heading}
                    </h3>
                    <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
                      {MESSAGES.order.empty.description}
                    </p>
                  </div>
                )}
              </div>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
      <Pagination
        id={`OrdersList-${page}-${pageSize}-${sort}-${order}-${status}-${search}`}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        fetchQuery={(newPage, newPageSize) =>
          listOrders({ newPage, newPageSize })
        }
        setIsLoading={setIsLoading}
      />

      {/* Export selected profile */}

      <InfoPanelFooter className={checkedItems?.length > 0 ? '' : '-bottom-28'}>
        <p className="text-sm leading-5 text-gray-500 mb-1 sm:hidden">{`${checkedItems.length} selected`}</p>
        <div className="flex items-center flex-nowrap space-x-4">
          <Button
            kind={BUTTON_KIND.WHITE}
            buttonText="Export profiles from selected orders"
            onClick={() => {
              setIsLoading(true);
              setSuccess('');
              setError(false);
              exportCSV()
                .then(() => setSuccess(MESSAGES.profile.export.success))
                .catch(() => setError(true))
                .then(() => setIsLoading(false))
                .finally(() => setCheckedItems([]));
            }}
          />
          <Button
            kind={BUTTON_KIND.LIGHT_RED}
            buttonText="Delete Cards"
            onClick={() => {
              openDialog({
                isOpen: true,
                dialogTitle: MESSAGES.order.delete.heading,
                dialogDescription: MESSAGES.order.delete.description,
                successButtonText: 'Delete Cards',
                onSuccess: () => {
                  handleDeleteOrdes();
                },
              });
            }}
          />
          <p className="text-sm leading-5 text-gray-500 hidden sm:block">{`${checkedItems.length} selected`}</p>
        </div>
      </InfoPanelFooter>

      {orderDetailData && (
        <OrderDetails
          orderId={orderDetailData.id}
          orgId={orderDetailData.organisation_id}
          orderStage={orderDetailData.order_origin}
          isOpenOrderDetailModal={isOpenOrderDetailModalByUUID}
          setIsOpenOrderDetailModal={setIsOpenOrderDetailModalByUUID}
          setIsMoveOrder={setIsMoveOrder}
          orderNumber={orderDetailData.order_number}
          design_specs={false}
          isPriority={orderDetailData.is_priority}
          handleClickLinkedOrg={(orgId: number) => {
            if (!orgId) {
              setError(true);
              return;
            }
            window.open(`/?scope=user&uOrgID=${orgId}`);
          }}
          orderAlternateEmail={orderDetailData.alternate_email}
          orderAlternateEmailFlag={orderDetailData.alternate_email_flag}
          handleOrderStatusChange={() => {
            listOrders({ initial: true });
            setInitial(!initial);
          }}
          tag={orderDetailData.tag}
          customerName={orderDetailData.customer_name}
          customerEmail={orderDetailData.customer_email}
          orderStatusUrl={orderDetailData.order_status_url}
        />
      )}
    </Layout>
  );
}
