import { IIcon } from '@/types/IIcon';

const LocationMarkerIcon = ({ className }: IIcon): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="36"
      viewBox="0 0 35 36"
      fill="none"
    >
      <g filter="url(#filter0_d_6773_85179)">
        <path
          d="M25.7524 24.291L19.5647 30.4787C19.2941 30.7496 18.9728 30.9645 18.619 31.1111C18.2653 31.2577 17.8862 31.3332 17.5033 31.3332C17.1205 31.3332 16.7413 31.2577 16.3876 31.1111C16.0339 30.9645 15.7126 30.7496 15.442 30.4787L9.25282 24.291C7.62127 22.6594 6.51018 20.5806 6.06007 18.3175C5.60996 16.0544 5.84103 13.7087 6.72407 11.5769C7.60711 9.44512 9.10246 7.62307 11.021 6.34115C12.9396 5.05922 15.1952 4.375 17.5026 4.375C19.81 4.375 22.0656 5.05922 23.9842 6.34115C25.9028 7.62307 27.3981 9.44512 28.2811 11.5769C29.1642 13.7087 29.3953 16.0544 28.9451 18.3175C28.495 20.5806 27.384 22.6594 25.7524 24.291V24.291Z"
          stroke="#D1D5DB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.5936 19.1346C21.4141 18.3141 21.875 17.2013 21.875 16.041C21.875 14.8807 21.4141 13.7679 20.5936 12.9474C19.7731 12.127 18.6603 11.666 17.5 11.666C16.3397 11.666 15.2269 12.127 14.4064 12.9474C13.5859 13.7679 13.125 14.8807 13.125 16.041C13.125 17.2013 13.5859 18.3141 14.4064 19.1346C15.2269 19.9551 16.3397 20.416 17.5 20.416C18.6603 20.416 19.7731 19.9551 20.5936 19.1346Z"
          stroke="#D1D5DB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_6773_85179"
          x="-2"
          y="-1"
          width="39"
          height="39"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6773_85179"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6773_85179"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default LocationMarkerIcon;
