import { FC } from 'react';

import { RouteComponentProps } from 'react-router-dom';

interface IMatchParams {
  profile_hash_id: string;
}

type ViewProfilePageType = RouteComponentProps<IMatchParams>;

const DEFAULT_PROFILE_LINK = 'https://profile.tapt.io';

const ViewProfilePage: FC<ViewProfilePageType> = ({
  match: {
    params: { profile_hash_id: profileHashID },
  },
}) => {
  window.location.replace(
    `${
      process.env.REACT_APP_PROFILE_LINK || DEFAULT_PROFILE_LINK
    }/profile/${profileHashID}`,
  );

  return null;
};

export { ViewProfilePage as default };
