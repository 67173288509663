import { useState } from 'react';
import { ISubscription } from '@/types/IOrganisation';

import ToggleField from './ToggleField';
import adminAPI from '@/api/admin';
import Button, { BUTTON_KIND } from './Button';
import Modal from './Modal';

interface IPilotsListItem {
  id: number;
  name?: string;
  insertedAt: Date;
  saasEnabled: boolean;
  subscription: ISubscription | null;
}

export default function PilotsListItem({
  id,
  name,
  saasEnabled,
  insertedAt,
  subscription,
}: IPilotsListItem): JSX.Element {
  const [pilotSubscription, setPilotSubscription] =
    useState<ISubscription | null>(subscription);
  const [saasToggle, setSaasToggle] = useState<boolean>(saasEnabled);
  const [updatingToEnabled, setUpdatingToEnabled] = useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const hasTrialPlan = pilotSubscription?.plan_id === 'pilot';
  const canCreateTrialPlan = !pilotSubscription && !hasTrialPlan;

  async function createPilot(organisation_id: number) {
    const res = await adminAPI.createPilot({ organisation_id });

    return res.data.data;
  }

  async function deletePilot(subscriptionId: string) {
    const res = await adminAPI.deletePilot(subscriptionId);

    return res;
  }

  async function toggleSaaSFeatureFlag(
    organisation_id: number,
    isEnabled: boolean,
  ) {
    const res = await adminAPI.updateOrganisation(organisation_id, {
      organisation: {
        saas_enabled: isEnabled,
      },
    });
  }

  return (
    <li key={id}>
      <div className="block bg-white hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-start md:items-center">
            <div className="min-w-0 flex-1 md:grid md:grid-cols-4 md:gap-4 xl:grid-cols-5">
              <div className="col-span-4 md:col-span-1 flex items-center space-x-4 w-full">
                <p className="text-sm font-medium text-gray-900 truncate">
                  #{id}
                </p>
              </div>
              <div className="flex items-center">
                <div className="min-w-0">
                  <p className="text-sm text-gray-900 truncate">{name}</p>
                </div>
              </div>
              <div className="flex md:hidden xl:flex items-center">
                {insertedAt && (
                  <p className="text-sm text-gray-900">
                    {insertedAt.toLocaleString()}
                  </p>
                )}
              </div>
              <div className="mt-2 md:mt-0 flex flex-col lg:flex-row lg:space-x-4 space-y-2 lg:space-y-0 items-start md:items-center md:justify-start md:col-span-2">
                <ToggleField
                  enabled={saasToggle}
                  setter={(isEnabled) => {
                    setUpdatingToEnabled(isEnabled);
                    setIsConfirmModalOpen(true);
                  }}
                  label=""
                />

                <Button
                  kind={BUTTON_KIND.WHITE}
                  buttonText="Log in"
                  href={`/?scope=user&uOrgID=${id}
                  ${saasToggle ? '&uSaaSEnabled=1' : ''}
                  ${
                    pilotSubscription !== null
                      ? `&uSubscriptionType=premium&uSubscriptionId=${pilotSubscription.subscription_id}`
                      : ''
                  }`}
                  external
                />

                {hasTrialPlan && (
                  <Button
                    kind={BUTTON_KIND.RED}
                    buttonText="Cancel trial"
                    onClick={() => {
                      deletePilot(pilotSubscription.subscription_id).then(
                        () => {
                          setPilotSubscription(null);
                        },
                      );
                    }}
                  />
                )}

                {canCreateTrialPlan && (
                  <Button
                    kind={BUTTON_KIND.PRIMARY}
                    buttonText="Enable trial"
                    onClick={() => {
                      createPilot(id).then((data) => {
                        setPilotSubscription(data);
                        
                      });
                      
                      toggleSaaSFeatureFlag(id, true).then(() => {
                        setSaasToggle(true);
                      });
                    }}
                  />
                )}
              </div>
              <Modal
                isOpen={isConfirmModalOpen}
                setIsOpen={setIsConfirmModalOpen}
                dialogTitle={
                  updatingToEnabled
                    ? 'Are you sure you want to enable SaaS?'
                    : 'Are you sure you want to disable SaaS?'
                }
                dialogDescription={
                  updatingToEnabled
                    ? `${name} will be able to subscribe to the SaaS features`
                    : `${name} will no longer be able to access the SaaS features`
                }
                successButtonText={updatingToEnabled ? 'Enable' : 'Disable'}
                successButtonKind={
                  updatingToEnabled ? BUTTON_KIND.PRIMARY : BUTTON_KIND.RED
                }
                onSuccess={() => {
                  toggleSaaSFeatureFlag(id, updatingToEnabled).then(() => {
                    setSaasToggle(updatingToEnabled);
                    setIsConfirmModalOpen(false);
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
