import Button, { BUTTON_KIND } from '@/components/Button';

export default function PlanOverview(props: {
  onNext: () => void;
}): JSX.Element {
  const { onNext } = props;

  return (
    <div className="flex space-x-4 justify-end">
      <Button
        kind={BUTTON_KIND.WHITE}
        buttonText="Cancel"
        onClick={() => console.log('cancel')}
      />
      <Button buttonText="Continue to payment" onClick={onNext} />
    </div>
  );
}
