import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

export interface AppContextType {
  multipleCards: number[];
  multipleProfiles: number[];
  profileStatus: number;
  profileType: number;
  cardStatus: number;
  settingsTab: number;
  selectCards: (cardIDs: number[]) => void;
  selectProfiles: (profileIDs: number[]) => void;
  selectProfileStatus: (index: number) => void;
  selectProfileType: (index: number) => void;
  selectCardStatus: (index: number) => void;
  selectSettingsTab: (index: number) => void;
}

const StateContext = createContext<AppContextType>({} as AppContextType);

export function StateProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [multipleCards, setMultipleCards] = useState<number[]>([]);
  const [multipleProfiles, setMultipleProfiles] = useState<number[]>([]);
  const [profileStatus, setProfileStatus] = useState<number>(0);
  const [profileType, setProfileType] = useState<number>(0);
  const [cardStatus, setCardStatus] = useState<number>(0);
  const [settingsTab, setSettingsTab] = useState<number>(0);

  function selectCards(cardIDs: number[]) {
    setMultipleCards(cardIDs);
  }

  function selectProfiles(profileIDs: number[]) {
    setMultipleProfiles(profileIDs);
  }

  function selectProfileStatus(index: number) {
    setProfileStatus(index);
  }

  function selectProfileType(index: number) {
    setProfileType(index);
  }

  function selectCardStatus(index: number) {
    setCardStatus(index);
  }

  function selectSettingsTab(index: number) {
    setSettingsTab(index);
  }

  const memoedValue = useMemo(
    () => ({
      multipleCards,
      multipleProfiles,
      profileStatus,
      profileType,
      cardStatus,
      settingsTab,
      selectCards,
      selectProfiles,
      selectProfileStatus,
      selectProfileType,
      selectCardStatus,
      selectSettingsTab,
    }),
    [
      multipleCards,
      multipleProfiles,
      profileStatus,
      profileType,
      cardStatus,
      settingsTab,
    ],
  );

  return (
    <StateContext.Provider value={memoedValue}>
      {children}
    </StateContext.Provider>
  );
}

export default function useAppState(): AppContextType {
  return useContext(StateContext);
}
