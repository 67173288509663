import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from '@/hooks/useAuth';
import Router from '@/components/Router';
import { StateProvider } from '@/hooks/useAppState';
import { ShopifyProvider } from './hooks/useShopify';
import { DialogProvider } from './hooks/useDialog';

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <StateProvider>
          <QueryClientProvider client={queryClient}>
            <ShopifyProvider>
              <DialogProvider>
                <Router />
              </DialogProvider>
            </ShopifyProvider>
          </QueryClientProvider>
        </StateProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
