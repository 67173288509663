import { debounce } from '@material-ui/core';
import { ReactNode, useRef, useState } from 'react';

type Props = {
  setting: {
    title?: string | ReactNode;
    name?: string;
    value: string | number | boolean | { id: number | string; value: any };
  }[];
  type: 'radio' | 'checkbox';
  data: any;
  setData: React.Dispatch<any>;
  divideText?: string;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  itemClassName?: string;
  wrapperClassName?: string;
  htmlFor?: boolean;
  onChange?: (
    data: any,
    option: {
      success: (data?: (setData?: any) => void) => void;
      error: (error?: (setError?: any) => void) => void;
      complate: (complate?: () => void) => void;
      debounce?: typeof setTimeout;
    },
  ) => void;
};

const InputInline = ({
  setting,
  type,
  data,
  setData,
  divideText,
  className = 'flex flex-wrap gap-5',
  itemClassName = 'justify-start',
  wrapperClassName = '',
  inputClassName,
  labelClassName,
  htmlFor = true,
  onChange,
}: Props) => {
  const [error, setError] = useState<string>('');
  const changeRef = useRef<any>();
  const handleChange = async (isRadio: boolean, e: any, isExist: boolean) => {
    let currentData: any;
    if (isRadio) {
      currentData = e.value;
      setData(currentData);
    } else if (isExist) {
      currentData = [
        ...data?.filter((f: any) =>
          typeof f === 'object' ? f.id !== e.value.id : f !== e.value,
        ),
      ];
      setData(currentData);
    } else {
      currentData = [...data, ...[e.value]];
      setData(currentData);
    }
    if (onChange) {
      if (changeRef.current) clearTimeout(changeRef.current);
      changeRef.current = setTimeout(async () => {
        onChange(currentData, {
          success: (e) => {
            e && e(setData);
          },
          error: (e) => {
            e && e(setError);
          },
          complate: (e) => {
            e && e();
          },
        });
      }, 500);
    }
  };

  return (
    <div>
      <div className={`${className}`}>
        {setting?.map((e, i) => {
          const isRadio = type === 'radio';
          const isExist = !isRadio
            ? data?.find((el: any) =>
                typeof e.value === 'object'
                  ? el.id === e.value.id
                  : el === e.value,
              )
            : undefined;

          const id = `id_${(Math.random() * 99999999)
            .toString()
            .replaceAll('.', '_')}`;

          return (
            <div key={id} className={`${wrapperClassName}`}>
              <div className={`${itemClassName} flex items-center`}>
                <input
                  id={id}
                  type={type}
                  checked={isRadio ? e.value === data : isExist}
                  className={`${
                    isRadio ? 'rounded-full' : 'rounded'
                  } ${inputClassName} focus:ring-brand-500 h-4 w-4 text-brand-500 border-gray-300 block cursor-pointer`}
                  onChange={() => handleChange(isRadio, e, isExist)}
                />
                {e.title && (
                  <label
                    className={`${labelClassName} text-sm font-semibold pl-3 block ${
                      htmlFor && 'cursor-pointer'
                    }`}
                    htmlFor={htmlFor ? id : undefined}
                  >
                    {e.title}
                  </label>
                )}
              </div>
              {divideText && i !== setting.length - 1 && (
                <p className="inline-flex items-center text-gray-500 text-sm leading-5 font-normal font-poppins">
                  {divideText}
                </p>
              )}
            </div>
          );
        })}
      </div>
      {error && <p className="text-xs mt-1 text-red-400 ">{error}</p>}
    </div>
  );
};

export default InputInline;
