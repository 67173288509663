import { useCallback, useEffect, useState } from 'react';
import { UserIcon } from '@heroicons/react/solid';
import { EyeIcon, PencilIcon } from '@heroicons/react/outline';
import Spinner from '@/components/Icons/Spinner';
import { stringTruncate } from '@/helpers/strings';
import Button, { BUTTON_ICON_POSITION, BUTTON_KIND } from '@/components/Button';
import InputCheckbox from '@/components/InputCheckbox';
import Copy from '@/components/Icons/Copy';
import organisationsAPI from '@/api/organisations';

interface IProfileListItem {
  id: number;
  orgID: number;
  groupName: string;
  groupSubheading: string;
  firstName?: string;
  lastName?: string;
  title?: string;
  emailAddress?: string;
  photoUrl?: string;
  status: string;
  selected: boolean;
  profileHash: string;
  checkItem: () => void;
  viewProfile: string;
  editProfile: string;
  disableCardholderEditing: () => void;
  enableCardholderEditing: (arg0?: boolean) => void;
  premium: boolean;
}

export default function ProfileListItem({
  id,
  orgID,
  groupName,
  groupSubheading,
  firstName,
  lastName,
  title,
  emailAddress,
  photoUrl,
  status,
  selected,
  profileHash,
  checkItem,
  viewProfile,
  editProfile,
  disableCardholderEditing,
  enableCardholderEditing,
  premium,
}: IProfileListItem): JSX.Element {
  const [clipboardCopy, setClipboardCopy] = useState<boolean>(false);
  const [isEnableEditingOpen, setIsEnableEditingOpen] =
    useState<boolean>(false);
  const [editingToggle, setEditingToggle] = useState<boolean>(
    status === 'editing',
  );

  function handleStatusChange() {
    if (!editingToggle) {
      if (emailAddress && emailAddress !== '') {
        setIsEnableEditingOpen(true);
      } else {
        setEditingToggle(true);
        enableCardholderEditing();
      }
    } else {
      setEditingToggle(false);
      disableCardholderEditing();
    }
  }

  return (
    <li key={id}>
      <div className="hidden xl:block block bg-white hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="self-start md:self-center">
            <InputCheckbox
              id={`Checkbox-${status}-${id}`}
              label=""
              value={selected}
              onChange={checkItem}
            />
          </div>
          {/* ---------------------------------------FULL SCREEN ---------------------------------------*/}
          <div className="min-w-0 flex-1 flex items-start md:items-center">
            <div className="min-w-0 flex-1 lg:grid lg:grid-cols-6 xl:grid-cols-8">
              <div className="hidden lg:block lg:col-span-1 xl:col-span-2 relative">
                <div className="flex flex-col md:flex-row relative items-start md:items-center">
                  <div className="flex-shrink-0 ml-4">
                    {photoUrl ? (
                      <img
                        className="h-10 w-10 rounded-full"
                        src={photoUrl}
                        alt=""
                      />
                    ) : (
                      <div className="hidden lg:block h-10 w-10 rounded-full bg-gray-200 overflow-hidden text-center">
                        <UserIcon className="h-10 w-10 mt-1 rounded-full text-gray-600" />
                      </div>
                    )}
                  </div>
                  <div className="min-w-0 mt-0 ml-12 md:mt-0 md:ml-4">
                    <p className="text-sm font-medium text-gray-900 truncate">
                      {firstName} {lastName}
                    </p>
                    <p className="flex items-center text-sm font-medium text-gray-500">
                      ID:&nbsp;
                      <button
                        type="button"
                        className="appearance-none text-brand-600 text-sm font-medium flex items-center"
                        onClick={() => {
                          navigator.clipboard
                            .writeText(profileHash)
                            .then(() => setClipboardCopy(true))
                            .finally(() =>
                              setTimeout(() => {
                                setClipboardCopy(false);
                              }, 2000),
                            );
                        }}
                      >
                        {clipboardCopy
                          ? 'Copied!'
                          : stringTruncate(profileHash)}
                        <Copy className="h-4 w-4 ml-1" />
                      </button>
                    </p>
                  </div>
                </div>
              </div>
              {premium && (
                <div className="hidden xl:block md:mt-0 lg:ml-1 lg:col-span-2 xl:flex flex-col">
                  <p className="text-sm text-gray-900 truncate">{title}</p>
                  <p className="text-sm text-gray-500 truncate">
                    {emailAddress}
                  </p>
                </div>
              )}
              {!premium && (
                <div className="hidden xl:block md:mt-0 lg:ml-1 lg:col-span-2 xl:col-span-4 xl:flex flex-col">
                  <p className="text-sm text-gray-900 truncate">{title}</p>
                  <p className="text-sm text-gray-500 truncate">
                    {emailAddress}
                  </p>
                </div>
              )}
              {premium && (
                <div className="hidden lg:block lg:col-span-1 xl:col-span-2 relative">
                  <div className="flex flex-col md:flex-row relative items-start md:items-center">
                    <div className="min-w-0 ml-2 mt-0 md:mt-0">
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {groupName}
                      </p>
                      <p className="text-sm text-gray-500 truncate">
                        {groupSubheading}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="hidden xl:block md:mt-0 col-span-1 lg:flex flex-col">
                <div className="flex justify-center space-x-2 ml-4">
                  <Button
                    className="pl-5"
                    buttonText=""
                    kind={BUTTON_KIND.COLOR_OMITTED}
                    icon={<PencilIcon />}
                    href={editProfile}
                  />
                </div>
              </div>
              <div className="hidden xl:block md:mt-1 col-span-1 lg:flex flex-col">
                <div className="flex justify-center ml-4">
                  <Button
                    className="pl-5"
                    buttonText=""
                    kind={BUTTON_KIND.COLOR_OMITTED}
                    size="xsmall"
                    icon={<EyeIcon />}
                    href={viewProfile}
                    external
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ---------------------------------------MOBILE SCREEN ---------------------------------------*/}
      <div className="xl:hidden block bg-gray-50 hover:bg-white">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="block self-start md:self-center">
            <InputCheckbox
              id={`Checkbox-${status}-${id}`}
              label=""
              value={selected}
              onChange={checkItem}
            />
          </div>
          <div className="min-w-0 flex-1 flex items-start md:items-center">
            <div className="min-w-0 flex-1">
              <div className="items-start">
                <div className="grid grid-cols-6">
                  {!premium && (
                    <div className="col-span-4 mt-0 flex space-x-1 md:mt-0 md:ml-4">
                      <div className="flex-shrink-0">
                        {photoUrl ? (
                          <img
                            className="h-10 w-10 rounded-full"
                            src={photoUrl}
                            alt=""
                          />
                        ) : (
                          <div className="h-10 w-10 rounded-full bg-gray-200 overflow-hidden text-center">
                            <UserIcon className="h-10 w-10 mt-1 rounded-full text-gray-600" />
                          </div>
                        )}
                      </div>
                      <div className="min-w-0 mt-0 ml-12 md:mt-0 md:ml-4">
                        <p className="text-sm font-medium text-gray-900 ml-4 truncate">
                          {firstName} {lastName}
                        </p>
                        <p className="text-sm text-gray-900 truncate ml-4">
                          {emailAddress}
                        </p>
                      </div>
                    </div>
                  )}
                  {premium && (
                    <div className="col-span-4 lg:col-span-2 mt-0 flex space-x-1 md:mt-0 md:ml-4">
                      <div className="flex-shrink-0">
                        {photoUrl ? (
                          <img
                            className="h-10 w-10 rounded-full"
                            src={photoUrl}
                            alt=""
                          />
                        ) : (
                          <div className="h-10 w-10 rounded-full bg-gray-200 overflow-hidden text-center">
                            <UserIcon className="h-10 w-10 mt-1 rounded-full text-gray-600" />
                          </div>
                        )}
                      </div>
                      <div className="min-w-0 mt-0 ml-12 md:mt-0 md:ml-4">
                        <p className="text-sm font-medium text-gray-900 ml-4 truncate">
                          {firstName} {lastName}
                        </p>
                        <p className="text-sm text-gray-900 truncate ml-4">
                          {emailAddress}
                        </p>
                      </div>
                    </div>
                  )}
                  {premium && (
                    <div className="hidden lg:block col-span-2 relative">
                      <div className="flex flex-col md:flex-row relative items-start md:items-center">
                        <div className="min-w-0 mt-0 md:mt-0">
                          <p className="text-sm font-medium text-gray-900 truncate">
                            {groupName}
                          </p>
                          <p className="text-sm text-gray-500 truncate">
                            {groupSubheading}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-span-1 flex justify-end">
                    <Button
                      buttonText=""
                      kind={BUTTON_KIND.CIRCULAR_WHITE}
                      size="notext"
                      iconPos={BUTTON_ICON_POSITION.CENTER}
                      icon={<PencilIcon />}
                      href={editProfile}
                    />
                  </div>
                  <div className="col-span-1 flex justify-end">
                    <Button
                      buttonText=""
                      kind={BUTTON_KIND.CIRCULAR_WHITE}
                      size="notext"
                      iconPos={BUTTON_ICON_POSITION.CENTER}
                      icon={<EyeIcon />}
                      href={viewProfile}
                      external
                    />
                  </div>
                  {premium && groupName && (
                    <div className="lg:hidden block mt-4 col-span-4">
                      <div className="mt-0 flex space-x-1 md:mt-0">
                        <div className="ml-12">
                          <p className="text-sm ml-3 md:ml-7 font-medium text-gray-900 truncate">
                            {groupName}
                          </p>
                          <p className="text-sm ml-3 md:ml-7  text-gray-500 truncate">
                            {groupSubheading}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
