import { ChangeEvent, useEffect, useState } from 'react';
import { PlusIcon, MinusIcon } from '@heroicons/react/solid';
import { CSVLink } from 'react-csv';
import Button from '@/components/Button';
import Input from '../../Input';
import InfoPanelContainer from '../../InfoPanelContainer';
import validateEmail from '@/helpers/validateEmail';
import MESSAGES from '@/constants/messages-en';
import NumberHelper from '@/helpers/number';
import Modal from '../../Modal';
import {
  ICardVariablesImportedFromCSV,
  IStep2,
} from '@/types/ReOrderExperience/IStep2';
import FileUploadButtonComponent from '@/components/FileUploadButton';

export default function MoreOrderDetailsForm(props: IStep2): JSX.Element {
  const {
    nameToggle,
    mobileNumberToggle,
    emailToggle,
    jobTitleToggle,
    companyAddressToggle,
    companyWebsiteURLToggle,
    companyPhoneNumberToggle,
    companyNameToggle,
    units,
    setUnits,
    error,
    information,
    setInformation,
    importCSV,
    setImportCSV,
    openUploadCsvDialog,
    setOpenUploadCsvDialog,
  } = props;
  const [updatedUnits, setUpdatedUnits] = useState(Number(units) || 0);

  useEffect(() => {
    const blankCardFormNum =
      Number(units) < information.length
        ? 0
        : Number(units) - information.length;
    const newForms = Array.from(Array(Number(blankCardFormNum)).keys()).map(
      () => ({
        ...(nameToggle ? { name: '' } : {}),
        ...(mobileNumberToggle ? { mobileNumber: '' } : {}),
        ...(emailToggle ? { email: '' } : {}),
        ...(jobTitleToggle ? { jobTitle: '' } : {}),
        ...(companyWebsiteURLToggle ? { companyWebsiteURL: '' } : {}),
        ...(companyAddressToggle ? { companyAddress: '' } : {}),
        ...(companyPhoneNumberToggle ? { companyPhoneNumber: '' } : {}),
        ...(companyNameToggle ? { companyName: '' } : {}),
      }),
    );
    const updatedOldInformation = information.map((item) => ({
      ...(nameToggle ? { name: item.name || '' } : {}),
      ...(mobileNumberToggle ? { mobileNumber: item.mobileNumber } : {}),
      ...(emailToggle ? { email: item.email || '' } : {}),
      ...(jobTitleToggle ? { jobTitle: item.jobTitle || '' } : {}),
      ...(companyWebsiteURLToggle
        ? { companyWebsiteURL: item.companyWebsiteURL || '' }
        : {}),
      ...(companyAddressToggle
        ? { companyAddress: item.companyAddress || '' }
        : {}),
      ...(companyNameToggle ? { companyName: item.companyName || '' } : {}),
      ...(companyPhoneNumberToggle
        ? { companyPhoneNumber: item.companyPhoneNumber || '' }
        : {}),
    }));
    if (Number(units) < information.length) {
      updatedOldInformation.splice(Number(units) - information.length);
    }
    setInformation([...updatedOldInformation, ...newForms]);
  }, [
    companyAddressToggle,
    companyPhoneNumberToggle,
    companyNameToggle,
    companyWebsiteURLToggle,
    emailToggle,
    jobTitleToggle,
    mobileNumberToggle,
    nameToggle,
    units,
  ]);

  const csvFileToArray = (string: string): any => {
    const csvHeader = string.slice(0, string.indexOf('\n')).split(',');
    const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');

    const array = csvRows.map((i) => {
      const values = i.split(',');
      const obj = csvHeader.reduce(
        (object: Record<string, string>, header: string, index: number) => {
          // eslint-disable-next-line no-param-reassign
          object[header] = values[index];
          return object;
        },
        {},
      );
      return obj;
    });

    return array.slice(0, array.length - 1);
  };

  return (
    <>
      <InfoPanelContainer
        title={
          <div>
            <p className="text-2xl font-medium leading-6 text-gray-900 mb-2">
              Add your details
            </p>
          </div>
        }
        padding=""
        information={
          <>
            <p>These details will be printed on your individual cards.</p>
            <br />
            <p>
              We will send you a proof PDF ahead of printing but please ensure
              all information provided is correct.
            </p>
            <br />

            <p>You can customise your digital profile after checkout.</p>
          </>
        }
        border={false}
        additionalContents={
          <>
            <h4 className="text-sm font-medium mb-3 mt-10">Quantity</h4>
            <div className="flex flex-row justify-evenly w-3/4 gap-2">
              <div className="flex flex-row justify-evenly items-center w-3/5 border-2 border-gray-100 rounded">
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() =>{
                    if(updatedUnits !== 1)
                      setUpdatedUnits((prev) => prev - 1)
                  }}
                >
                  <MinusIcon
                    className="h-4 w-4 text-gray-700"
                    aria-hidden="true"
                  />
                </div>
                <div className="text-sm">
                  {NumberHelper.toLeadingZero(String(updatedUnits))}
                </div>
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => setUpdatedUnits((prev) => prev + 1)}
                >
                  <PlusIcon
                    className="h-4 w-4 text-gray-700"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <Button
                buttonText="Update"
                onClick={() => setUnits(String(updatedUnits))}
                className="w-2/5"
              />
            </div>
          </>
        }
      >
        <div className="grid grid-cols-6 gap-6">
          <div className="flex flex-col col-span-6 sm:col-span-3 md:col-span-6 p-6 border border-gray-100 rounded-lg">
            <div className="self-end"> </div>
            {information.map((item, index) => (
              <div key={index} className="mb-6 last:mb-0">
                <div className="p-6 shadow sm:rounded-md border border-gray-100">
                  {/* eslint-disable-next-line @typescript-eslint/restrict-plus-operands */}
                  <h3 className="mb-6">{`Card ${index + 1}`}</h3>
                  <div className="grid grid-cols-2 gap-6">
                    {nameToggle && item.name !== undefined && (
                      <div className="text-left">
                        <Input
                          id={`Name ${index}`}
                          type="text"
                          label="Name"
                          value={item.name}
                          onChange={(name) => {
                            setInformation((prevInformation) =>
                              prevInformation?.map((prevItem, prevIndex) => ({
                                ...prevItem,
                                ...(prevIndex === index
                                  ? {
                                      name,
                                    }
                                  : {}),
                              })),
                            );
                          }}
                          required
                        />
                        {error && !item.name && (
                          <p className="mt-1 text-red-450 text-sm">
                            Please fill out this field
                          </p>
                        )}
                      </div>
                    )}
                    {jobTitleToggle && item.jobTitle !== undefined && (
                      <div className="text-left">
                        <Input
                          id={`Job title / position ${index}`}
                          type="text"
                          label="Job title / position"
                          value={item.jobTitle}
                          onChange={(jobTitle) => {
                            setInformation((prevInformation) =>
                              prevInformation?.map((prevItem, prevIndex) => ({
                                ...prevItem,
                                ...(prevIndex === index
                                  ? {
                                      jobTitle,
                                    }
                                  : {}),
                              })),
                            );
                          }}
                          required
                        />
                        {error && !item.jobTitle && (
                          <p className="mt-1 text-red-450 text-sm">
                            Please fill out this field
                          </p>
                        )}
                      </div>
                    )}
                    {emailToggle && item.email !== undefined && (
                      <div className="text-left">
                        <Input
                          id={`Email address ${index}`}
                          type="email"
                          label="Email address"
                          value={item.email}
                          error={
                            item.email && !validateEmail(item.email)
                              ? true
                              : undefined
                          }
                          message={
                            item.email && !validateEmail(item.email)
                              ? MESSAGES.error.email
                              : undefined
                          }
                          onChange={(email) => {
                            setInformation((prevInformation) =>
                              prevInformation?.map((prevItem, prevIndex) => ({
                                ...prevItem,
                                ...(prevIndex === index
                                  ? {
                                      email,
                                    }
                                  : {}),
                              })),
                            );
                          }}
                          required
                        />
                        {error && !item.email && (
                          <p className="mt-1 text-red-450 text-sm">
                            Please fill out this field
                          </p>
                        )}
                      </div>
                    )}
                    {mobileNumberToggle && item.mobileNumber !== undefined && (
                      <div className="text-left">
                        <Input
                          id={`Mobile number ${index}`}
                          type="text"
                          label="Mobile number"
                          value={item.mobileNumber}
                          onChange={(mobileNumber) => {
                            setInformation((prevInformation) =>
                              prevInformation?.map((prevItem, prevIndex) => ({
                                ...prevItem,
                                ...(prevIndex === index
                                  ? {
                                      mobileNumber,
                                    }
                                  : {}),
                              })),
                            );
                          }}
                          required
                        />
                        {error && !item.mobileNumber && (
                          <p className="mt-1 text-red-450 text-sm">
                            Please fill out this field
                          </p>
                        )}
                      </div>
                    )}
                    {companyNameToggle && item.companyName !== undefined && (
                      <div className="text-left">
                        <Input
                          id={`Company name ${index}`}
                          type="text"
                          label="Company name"
                          value={item.companyName}
                          onChange={(companyName) => {
                            setInformation((prevInformation) =>
                              prevInformation?.map((prevItem, prevIndex) => ({
                                ...prevItem,
                                ...(prevIndex === index
                                  ? {
                                      companyName,
                                    }
                                  : {}),
                              })),
                            );
                          }}
                          required
                        />
                        {error && !item.companyName && (
                          <p className="mt-1 text-red-450 text-sm">
                            Please fill out this field
                          </p>
                        )}
                      </div>
                    )}
                    {companyWebsiteURLToggle &&
                      item.companyWebsiteURL !== undefined && (
                        <div className="text-left">
                          <Input
                            id={`Company website ${index}`}
                            type="text"
                            label="Company website"
                            value={item.companyWebsiteURL}
                            onChange={(companyWebsiteURL) => {
                              setInformation((prevInformation) =>
                                prevInformation?.map((prevItem, prevIndex) => ({
                                  ...prevItem,
                                  ...(prevIndex === index
                                    ? {
                                        companyWebsiteURL,
                                      }
                                    : {}),
                                })),
                              );
                            }}
                            required
                          />
                          {error && !item.companyWebsiteURL && (
                            <p className="mt-1 text-red-450 text-sm">
                              Please fill out this field
                            </p>
                          )}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </InfoPanelContainer>
      <Modal
        isOpen={openUploadCsvDialog}
        setIsOpen={setOpenUploadCsvDialog}
        dialogTitle="Import a CSV file to edit profiles"
        dialogDescription={
          <>
            Make bulk edits to cardholder profiles by importing a CSV file.
            Existing details will automatically be replaced by the details in
            your imported file.
            <br />
            <br />
            Don’t have a CSV file?{' '}
            <CSVLink
              data={[Object.keys(information[0] || {})]}
              filename="CardsCsvTemplate"
            >
              Export CSV Template
            </CSVLink>
          </>
        }
        successButtonText="Import"
        cancelButtonText="Cancel"
        onSuccess={() => {
          if (!importCSV) {
            setInformation([]);
            setUnits('0');
            setUpdatedUnits(0);
            setOpenUploadCsvDialog(false);
            return;
          }
          const reader = new FileReader();
          reader.onload = async (event) => {
            const text = event?.target?.result || '';
            if (typeof text === 'string') {
              const importedContents = csvFileToArray(
                text,
              ) as ICardVariablesImportedFromCSV[];

              const newImformation = importedContents.map(
                (item: ICardVariablesImportedFromCSV) => ({
                  ...(nameToggle ? { name: item?.name || '' } : {}),
                  ...(mobileNumberToggle
                    ? { mobileNumber: item?.mobilePhone || '' }
                    : {}),
                  ...(emailToggle ? { email: item?.email || '' } : {}),
                  ...(jobTitleToggle ? { jobTitle: item?.jobTitle || '' } : {}),
                  ...(companyWebsiteURLToggle
                    ? { companyWebsiteURL: item?.companyWebsite || '' }
                    : {}),
                  ...(companyAddressToggle
                    ? { companyAddress: item?.companyAddress || '' }
                    : {}),
                  ...(companyPhoneNumberToggle
                    ? { companyPhoneNumber: item?.companyPhoneNumber || '' }
                    : {}),
                  ...(companyNameToggle
                    ? { companyName: item?.companyName || '' }
                    : {}),
                }),
              );
              setInformation(newImformation);
              setUnits(String(importedContents.length));
              setUpdatedUnits(importedContents.length);
            }
          };
          reader.readAsText(importCSV);

          setOpenUploadCsvDialog(false);
        }}
        onCancel={() => {
          setOpenUploadCsvDialog(false);
        }}
      >
        <div className="mt-6 space-y-4">
          <div className="border rounded-md px-4 mt-6 flex flex-row items-center justify-between">
            <FileUploadButtonComponent
              filename={importCSV?.name}
              fileFormat=".csv"
              fileFormatMessage="(.csv only)"
              onFileSelected={(file) => setImportCSV(file)}
            />
            {importCSV && (
              <button
                type="button"
                onClick={() => setImportCSV(undefined)}
                className="appearance-none text-brand-500 text-sm font-medium"
              >
                Remove link
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}
