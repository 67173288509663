import React from 'react';
import { cx } from '@emotion/css';
import { ExclamationCircleIcon, PlusIcon } from '@heroicons/react/solid';
import { Popover, Transition } from '@headlessui/react';
import IInput from '@/types/IInput';
import Copy from './Icons/Copy';
import Check from './Icons/Check';

export default function Input({
  autocomplete,
  className,
  copy,
  description,
  disabled,
  error,
  icon,
  id,
  label,
  message,
  onChange,
  onFocus,
  placeholder,
  preLabel,
  required,
  type,
  value,
  min,
  max,
  maxLength,
  rightIcon,
  handleRightIconClick,
}: IInput): JSX.Element {
  return (
    <div>
      <label
        htmlFor={id || label}
        className={cx(
          className,
          'block text-sm leading-5 font-medium',
          'text-gray-900',
        )}
      >
        {label}
      </label>
      {description && (
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      )}
      <div
        className={cx('relative flex rounded-md', label === '' ? '' : 'mt-1')}
      >
        {preLabel && (
          <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
            {preLabel}
          </span>
        )}
        {icon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-400">
            {React.createElement(icon, { className: 'h-5 w-5' })}
          </div>
        )}
        <div className="w-full flex">
          <div className="flex-1">
            <input
              id={id || label}
              name={id || label}
              type={type}
              autoComplete={autocomplete || type}
              required={required}
              placeholder={placeholder}
              className={cx(
                'appearance-none transition-all flex-1 block w-full px-3 py-2 border shadow-sm placeholder-gray-500 text-gray-900 focus:outline-none disabled:bg-gray-200 disabled:cursor-not-allowed',
                preLabel ? 'rounded-r-md' : 'rounded-md',
                error
                  ? 'text-red-900 border-red-300 focus:ring-red-500 focus:border-red-500'
                  : 'border-gray-300 focus:ring-brand-500 focus:border-brand-500',
                icon ? 'text-sm leading-5 pl-10' : 'text-base leading-6',
                value === 'Click to edit or clear'
                  ? 'bg-gray-50 text-gray-700'
                  : '',
              )}
              min={min}
              max={max}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              onFocus={onFocus}
              disabled={disabled}
              maxLength={maxLength}
            />
          </div>
          {rightIcon && !disabled && (
            <div
              tabIndex={0}
              role="button"
              className="flex items-center ml-2 min-w-fit"
              onClick={() => {
                handleRightIconClick?.();
              }}
              onKeyDown={() => {
                handleRightIconClick?.();
              }}
            >
              {rightIcon}
            </div>
          )}
        </div>
        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
        {value && copy && (
          <Popover className="absolute inset-y-0 right-0 pr-3 flex items-center">
            <Popover.Button as="div" className="h-5 w-5">
              <button
                type="button"
                className="appearance-none"
                onClick={() => {
                  navigator.clipboard.writeText(value).finally(() => {});
                }}
              >
                <Copy className="h-5 w-5" aria-hidden="true" />
              </button>
            </Popover.Button>

            <Transition
              enter="transition-opacity duration-100 ease-out"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-75 ease-out"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Popover.Panel className="absolute z-10 -top-6 leading-3 right-0 text-xs text-green-700 bg-white w-20 text-center">
                {({ close }) => {
                  setTimeout(() => {
                    close();
                  }, 2000);
                  return (
                    <div className="flex items-center justify-end">
                      <Check className="h-5 w-5 mr-1" /> Copied
                    </div>
                  );
                }}
              </Popover.Panel>
            </Transition>
          </Popover>
        )}
      </div>
      {message && (
        <p className={cx(className, 'mt-2 text-sm leading-5', 'text-gray-500')}>
          {message}
        </p>
      )}
    </div>
  );
}
