import { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import Compress from 'react-image-file-resizer';
import Slider from '@material-ui/core/Slider';
import Button, { BUTTON_KIND } from '@/components/Button';
import { MAX_UPLOAD_FILE_SIZE } from '@/constants/files';

interface ImageCropperProps {
  aspectRatio?: number;
  filePath: string;
  loading: boolean;
  onClose(): void;
  onCropSubmit(file: Blob): void;
}

function dataURLtoBlob(dataurl: string) {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataurl.split(',')[1]);

  // separate out the mime component
  const mimeString = dataurl.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: mimeString });
  return blob;
}

const ImageCropper = ({
  aspectRatio = 1,
  filePath,
  loading,
  onClose,
  onCropSubmit,
}: ImageCropperProps): JSX.Element => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const onCropComplete = useCallback(
    (
      _: unknown,
      newCroppedAreaPixels: {
        x: number;
        y: number;
        width: number;
        height: number;
      },
    ) => {
      setCroppedAreaPixels(newCroppedAreaPixels);
    },
    [],
  );

  const onCropButtonClicked = useCallback(() => {
    const resizeCanvas = document.createElement('canvas');
    const image = new Image();
    image.src = filePath;

    if (
      image &&
      filePath &&
      resizeCanvas &&
      resizeCanvas.getContext('2d') &&
      croppedAreaPixels
    ) {
      resizeCanvas.width = croppedAreaPixels.width;
      resizeCanvas.height = croppedAreaPixels.height;

      resizeCanvas
        .getContext('2d')
        ?.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
        );

      const res = resizeCanvas.toDataURL('image/png');
      const head = 'data:image/png;base64,';
      const imgFileSize = Math.round(((res.length - head.length) * 3) / 4);
      const blob = dataURLtoBlob(res);
      if (imgFileSize <= MAX_UPLOAD_FILE_SIZE) {
        onCropSubmit(blob);
      } else {
        const ratio = MAX_UPLOAD_FILE_SIZE / imgFileSize;
        Compress.imageFileResizer(
          blob,
          croppedAreaPixels.width * ratio,
          croppedAreaPixels.height * ratio,
          'PNG',
          100,
          0,
          (file) => {
            if (file instanceof Blob) {
              onCropSubmit(file);
            }
          },
          'blob',
        );
      }
    }
  }, [filePath, croppedAreaPixels, onCropSubmit]);

  const onZoomChange = useCallback((_: unknown, newZoom: number | number[]) => {
    const res = newZoom as number;
    setZoom(res);
  }, []);

  return (
    <div className="flex flex-col items-center">
      <div className="relative w-80 h-80">
        <Cropper
          image={filePath}
          crop={crop}
          zoom={zoom}
          minZoom={0.2}
          aspect={aspectRatio}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          restrictPosition={false}
        />
      </div>
      <div className="w-full mt-6">
        <Slider
          value={zoom}
          min={0.2}
          max={2}
          step={0.01}
          aria-labelledby="Zoom"
          onChange={onZoomChange}
          classes={{ root: 'slider' }}
        />
      </div>
      <div className="flex flex-row items-center justify-end w-full">
        <Button
          buttonText="Cancel"
          kind={BUTTON_KIND.WHITE}
          onClick={onClose}
        />
        <div className="ml-2" />
        <Button
          loading={loading}
          buttonText="Set photo"
          kind={BUTTON_KIND.PRIMARY}
          onClick={onCropButtonClicked}
        />
      </div>
    </div>
  );
};

export default ImageCropper;
