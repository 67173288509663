import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import accountsAPI from '@/api/accounts';
import IUser from '@/types/IUser';
import organisationsAPI from '@/api/organisations';
import { ISubscription } from '@/types/IOrganisation';

interface ILogin {
  email: string;
  password: string;
  rememberMe?: boolean;
  activationKey?: string;
  activationKeyFromLogin?: string;
}
interface AuthContextType {
  user?: IUser;
  userScope?: string;
  userRole?: string;
  orgID?: number;
  isUserViewForAdmin?: boolean;
  loading: boolean;
  authError?: boolean;
  subscriptionType?: string;
  subscription?: ISubscription | null;
  features?: string[];
  getCurrentUser: () => void;
  refreshCurrentUser: () => Promise<void>;
  setScopeAndOrg: (
    scope?: string,
    ID?: number,
    userViewForAdmin?: boolean,
    subscriptionType?: string,
    subscription?: ISubscription | null,
    role?: string,
    features?: string[],
  ) => void;
  login: ({
    email,
    password,
    rememberMe,
    activationKeyFromLogin,
  }: ILogin) => void;
  signUp: (
    email: string,
    firstName: string,
    lastName: string,
    organisationName: string,
    password: string,
    passwordConfirmation: string,
    termsAccepted: boolean,
    activationKey?: string,
  ) => void;
  logout: () => void;
  hasFeature: (feature: string) => boolean;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [user, setUser] = useState<IUser>();
  const [userScope, setUserScope] = useState<string>();
  const [userRole, setUserRole] = useState<string>();
  const [subscription, setSubscription] = useState<ISubscription | null>();
  const [subscriptionType, setSubscriptionType] = useState<string>();
  const [features, setFeatures] = useState<string[]>([]);
  const [orgID, setOrgID] = useState<number>();
  const [isUserViewForAdmin, setIsUserViewForAdmin] = useState<boolean>();
  const [authError, setAuthError] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const history = useHistory();

  const setScopeAndOrg = useCallback(
    (
      scope?: string,
      ID?: number,
      userViewForAdmin?: boolean,
      subscriptionType?: string,
      subscription?: ISubscription | null,
      userRole?: string,
      features?: string[],
    ) => {
      setUserScope(scope);
      setOrgID(ID);
      setIsUserViewForAdmin(userViewForAdmin);
      setSubscriptionType(subscriptionType ?? 'basic');
      setSubscription(subscription);
      setUserRole(userRole);
      setFeatures(features ?? []);
    },
    [],
  );

  const refreshCurrentUser = () => {
    setLoading(true);
    return accountsAPI
      .getCurrentUser()
      .then((res) => res.data.data)
      .then((data) => {
        setUser(data);
        setScopeAndOrg(
          data.scope,
          data.organisation_id,
          false,
          data.subscription_type,
          data.subscription,
          data.organisation_role,
          data.features,
        );
      })
      .finally(() => setLoading(false));
  };

  const getCurrentUser = useCallback(() => {
    setLoading(true);
    return accountsAPI
      .getCurrentUser()
      .then((res) => res.data.data)
      .then((data) => {
        setUser(data);
        setScopeAndOrg(
          data.scope,
          data.organisation_id,
          false,
          data.subscription_type,
          data.subscription,
          data.organisation_role,
          data.features,
        );
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }, [setScopeAndOrg]);

  useEffect(() => {
    getCurrentUser().finally(() => setLoadingInitial(false));
  }, [getCurrentUser]);

  const login = useCallback(
    ({
      email,
      password,
      rememberMe,
      activationKey,
      activationKeyFromLogin,
    }: ILogin) => {
      setLoading(true);
      accountsAPI
        .login({ email, password })
        .then((res) => {
          localStorage.setItem('access_token', res.data.data.access_token);
          if (rememberMe) {
            localStorage.setItem('refresh_token', res.data.data.refresh_token);
          }
        })
        .then(() => getCurrentUser())
        .then(() =>
          history.push('/', {
            activationKey,
            activationKeyFromLogin,
          }),
        )
        .catch(() => setAuthError(true))
        .finally(() => setLoading(false));
    },
    [history, getCurrentUser],
  );

  const signUp = useCallback(
    (
      email: string,
      firstName: string,
      lastName: string,
      organisationName: string,
      password: string,
      passwordConfirmation: string,
      termsAccepted: boolean,
      activationKey?: string,
    ) => {
      setLoading(true);
      accountsAPI
        .signup({
          email,
          first_name: firstName,
          last_name: lastName,
          organisation_name: organisationName,
          password,
          password_confirmation: passwordConfirmation,
          terms_accepted: termsAccepted,
        })
        .then(() => {
          login({ email, password, activationKey });
        })
        .catch(() => setAuthError(true))
        .finally(() => setLoading(false));
    },
    [login],
  );

  function logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    setUser(undefined);
    setOrgID(undefined);
  }

  function hasFeature(feature: string): boolean {
    return features.includes(feature);
  }

  const memoedValue = useMemo(
    () => ({
      user,
      userScope,
      userRole,
      orgID,
      isUserViewForAdmin,
      loading,
      authError,
      subscriptionType,
      subscription,
      features,
      getCurrentUser,
      setScopeAndOrg,
      refreshCurrentUser,
      login,
      signUp,
      logout,
      hasFeature,
    }),
    [
      getCurrentUser,
      setScopeAndOrg,
      refreshCurrentUser,
      login,
      signUp,
      hasFeature,
      user,
      userScope,
      userRole,
      orgID,
      isUserViewForAdmin,
      loading,
      authError,
      subscriptionType,
      subscription,
      features,
    ],
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
}

export default function useAuth(): AuthContextType {
  return useContext(AuthContext);
}
