import { useCallback, useEffect, useState } from 'react';
import formatDistance from 'date-fns/formatDistance';
import { Link, useHistory } from 'react-router-dom';
import useAuth from '@/hooks/useAuth';
import Button, { BUTTON_KIND } from '../Button';
import InfoPanelContainer from '../InfoPanelContainer';
import InfoPanelDivider from '../InfoPanelDivider';
import Modal from '../Modal';
import saasAPI from '@/api/saas';
import profilesAPI from '@/api/profiles';

enum SectionType {
  SUBSCRIPTION_DETAILS = 'sub_details',
  SUBSCRIPTION_CANCELLATION = 'sub_cancel',
  EDIT_SUBSCRIPTION = 'edit_subscription',
  VIEW_SCHEDULED_CHANGES = 'scheduled_changes',
  ACCOUNT_DETAILS = 'account_details',
  EDIT_ACCOUNT_DETAILS = 'portal_edit_account',
  ADDRESS = 'portal_address',
  EDIT_BILLING_ADDRESS = 'portal_edit_billing_address',
  EDIT_SHIPPING_ADDRESS = 'portal_edit_shipping_address',
  EDIT_SUBSCRIPTION_CUSTOM_FIELDS = 'portal_edit_subscription_cf',
  PAYMENT_SOURCES = 'portal_payment_methods',
  ADD_PAYMENT_SOURCE = 'portal_add_payment_method',
  EDIT_PAYMENT_SOURCE = 'portal_edit_payment_method',
  VIEW_PAYMENT_SOURCE = 'portal_view_payment_method',
  CHOOSE_PAYMENT_METHOD_FOR_SUBSCRIPTION = 'portal_choose_payment_method',
  BILLING_HISTORY = 'portal_billing_history',
}

const PLANS = [
  {
    name: 'Trial plan',
    price: ' ',
    plan_id: 'pilot',
  },
  {
    name: 'Monthly',
    price: '$5.00/month',
    plan_id:
      process.env.REACT_APP_SAAS_PREMIUM_MONTHLY_PLAN_ID || 'v2-AUD-Monthly',
  },
  {
    name: 'Yearly',
    price: '$50.00/year',
    plan_id:
      process.env.REACT_APP_SAAS_PREMIUM_YEARLY_PLAN_ID || 'v2-AUD-Yearly',
  },
];

export default function PremiumPlanTab(props: { cbPortal: any }): JSX.Element {
  const { orgID, subscription, refreshCurrentUser } = useAuth();
  const history = useHistory();
  const { cbPortal } = props;
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [monthlyPrice, setMonthlyPrice] = useState<number>(5);
  const [yearlyPrice, setYearlyPrice] = useState<number>(50);
  const billingDate =
    subscription && subscription.next_billing_at
      ? new Date(subscription.next_billing_at)
      : null;
  
  const planId = subscription?.plan_id || 'pilot';
  const plan = PLANS.find(
    (plan) => plan.plan_id === planId,
  );

  const isTrialPlan = planId === 'pilot';

  const manageSubscription = () => {
    if (subscription) {
      cbPortal.openSection({
        sectionType: SectionType.SUBSCRIPTION_DETAILS,
        params: { subscriptionId: subscription.subscription_id },
      });
    }
  };

  const managePaymentMethods = () => {
    cbPortal.openSection({ sectionType: SectionType.PAYMENT_SOURCES });
  };

  const manageBillingHistory = () => {
    cbPortal.openSection({ sectionType: SectionType.BILLING_HISTORY });
  };

  const handleCancelSubscription = async () => {
    if (orgID && subscription) {
      saasAPI
        .cancelSubscription(orgID, subscription.subscription_id)
        .then(() => {
          refreshCurrentUser().then(() => {
            history.push('/');
          });
        });
    }
  };

  const getSubscriptionPrice = (totalProfiles: number) => {
    const VOLUME_PRICE = [
      { min: 1000, unit_price: 4 },
      { min: 0, unit_price: 5 },
    ];

    const tier = VOLUME_PRICE.filter((tier) => totalProfiles >= tier.min)[0];

    return tier.unit_price;
  };

  const listProfiles = useCallback(async () => {
    const res = await profilesAPI.listProfiles({
      orgID,
    });

    const totalProfiles = res.data?.paging.total_entries || 0;
    setMonthlyPrice(getSubscriptionPrice(totalProfiles));
    setYearlyPrice(getSubscriptionPrice(totalProfiles) * 10);

    return res.data;
  }, [orgID]);

  const renderPrice = (plan: any) => {
    switch (plan.plan_id) {
      case PLANS[1].plan_id:
        return `$${monthlyPrice.toFixed(2)}/month`;
      case PLANS[2].plan_id:
        return `$${yearlyPrice.toFixed(2)}/year`;
      default:
        return plan.price;
    }
  };

  useEffect(() => {
    if (orgID) {
      listProfiles();
    }
  }, [orgID, listProfiles]);

  return (
    <>
      <InfoPanelContainer
        title="Current plan"
        information="Select between monthly and yearly subscription packages."
      >
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between">
          <div className="min-w-0">
            <p className="text-lg font-bold text-brand-500">{plan?.name}</p>
            <p className="text-sm text-gray-500">{renderPrice(plan)}</p>
            {billingDate && (
              <p className="text-sm mt-5">
                Next billing date in {formatDistance(new Date(), billingDate)}
              </p>
            )}
          </div>
          {!isTrialPlan && (
            <div className="inline-flex flex-col lg:flex-row lg:space-x-6 items-start lg:items-center mt-5 sm:mt-0">
              <Button
                buttonText="Discover other plans"
                onClick={manageSubscription}
              />
            </div>
          )}
        </div>
      </InfoPanelContainer>
      {!isTrialPlan && (
        <>
          <InfoPanelDivider />
          <InfoPanelContainer title="Payment method">
            <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between">
              <div className="min-w-0 text-sm">
                View and change payment methods
              </div>
              <div className="inline-flex flex-col lg:flex-row lg:space-x-6 items-start lg:items-center mt-5 sm:mt-0">
                <Button
                  kind={BUTTON_KIND.WHITE}
                  buttonText="Manage payment method"
                  onClick={managePaymentMethods}
                />
              </div>
            </div>
          </InfoPanelContainer>
          <InfoPanelDivider />
          <InfoPanelContainer title="Billing History">
            <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between">
              <div className="min-w-0 text-sm">
                View and download billing history
              </div>
              <div className="inline-flex flex-col lg:flex-row lg:space-x-6 items-start lg:items-center mt-5 sm:mt-0">
                <Button
                  kind={BUTTON_KIND.WHITE}
                  buttonText="Manage billing history"
                  onClick={manageBillingHistory}
                />
              </div>
            </div>
          </InfoPanelContainer>
          <InfoPanelDivider />
          <InfoPanelContainer title="Unsubscribe">
            <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between">
              <div className="min-w-0 text-sm">Cancel subscription</div>
              <div className="inline-flex flex-col lg:flex-row lg:space-x-6 items-start lg:items-center mt-5 sm:mt-0">
                <Button
                  kind={BUTTON_KIND.WHITE}
                  buttonText="Cancel"
                  onClick={() => setShowCancelModal(true)}
                />
              </div>
            </div>
          </InfoPanelContainer>
        </>
      )}

      <Modal
        isOpen={showCancelModal}
        setIsOpen={setShowCancelModal}
        dialogTitle="Cancel subscription"
        dialogDescription={
          <>
            <p>Are you sure you want to cancel your Premium subscription?</p>
            All the premium features will be disabled immediately.
          </>
        }
        successButtonText="Cancel subscription"
        successButtonKind={BUTTON_KIND.RED}
        onSuccess={handleCancelSubscription}
      />
    </>
  );
}
