import ErrorAlert from '@/components/ErrorAlert';
import Input from '@/components/Input';
import UploadPhoto from '@/components/UploadPhoto';
import MESSAGES from '@/constants/messages-en';
import IFile from '@/types/IFile';

interface IGroupInfo {
  orgID: number;
  groupName: string;
  groupNameError?: boolean;
  subheading: string;
  disabled?: boolean;
  setGroupName: React.Dispatch<React.SetStateAction<string>>;
  setSubheading: React.Dispatch<React.SetStateAction<string>>;
}

export default function GroupInfoForm({
  orgID,
  groupName,
  groupNameError,
  subheading,
  disabled = false,
  setGroupName,
  setSubheading,
}: IGroupInfo): JSX.Element {
  return (
    <>
      {groupNameError && <ErrorAlert message={MESSAGES.error.firstName} />}
      <div className="grid grid-cols-2 gap-6">
        <div className="col-span-2 sm:col-span-1">
          <Input
            label="Group name"
            type="text"
            value={groupName}
            message="(e.g. Sales Team)"
            required
            onChange={setGroupName}
            error={groupNameError}
            disabled={disabled}
            copy={disabled}
            maxLength={50}
          />
        </div>

        <div className="col-span-2 sm:col-span-1">
          <Input
            label="Subheading"
            type="text"
            message="(e.g. Melbourne Office)"
            value={subheading}
            onChange={setSubheading}
            disabled={disabled}
            maxLength={70}
          />
        </div>
      </div>
    </>
  );
}
