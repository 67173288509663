import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import InputSelect from '@/components/InputSelect';
import Button, { BUTTON_KIND } from '@/components/Button';
import { IListPaging } from '@/types/IList';
import ICard from '@/types/ICard';
import IProfile from '@/types/IProfile';
import IActivationKey from '@/types/IActivationKey';
import IAnalytics, { IAnalyticsAdmin } from '@/types/IAnalytics';
import IContacts from '@/types/IContacts';
import IOrganisation, {
  IOrganisationGroup,
  IOrganisationUser,
  IPilot,
} from '@/types/IOrganisation';
import classNames from '@/helpers/classNames';
import IOrder from '@/types/IOrderV2';
import IDesign from '@/types/IDesign';

export interface IPaging {
  page_number: number;
  page_offset: number;
  page_size: number;
  total_entries: number;
  total_pages: number;
}

interface IPagination {
  id: string;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  fetchQuery: (
    page: number,
    pageSize: number,
  ) => Promise<{
    data: (
      | IActivationKey
      | IAnalytics
      | IAnalyticsAdmin
      | ICard
      | IContacts
      | IOrganisationGroup
      | IOrganisation
      | IOrganisationUser
      | IProfile
      | IOrder
      | IPilot
      | IDesign
    )[];
    paging: IListPaging;
  }>;
  showChoisePageSize?: boolean;
  className?: string;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Pagination({
  id,
  page = 1,
  setPage,
  pageSize,
  setPageSize,
  fetchQuery,
  setIsLoading,
  showChoisePageSize = true,
  className = 'bg-white flex items-center justify-between'
}: IPagination): JSX.Element {
  const { isLoading, data, isPreviousData } = useQuery(
    id,
    () => fetchQuery(page, pageSize),
    {
      refetchOnWindowFocus: false,
      staleTime: 0,
    },
  );

  useEffect(() => {
    setIsLoading(isLoading);
  }, [setIsLoading, isLoading]);

  const totalEntries = data?.paging?.total_entries;
  const totalPages = data?.paging?.total_pages;
  const currentPage = data?.paging?.page_number;

  if (!data || !data.data || !data.data.length) {
    return <div />;
  }

  return (
    <div className={className}>
      {
        totalPages && totalPages > 1 ? (
          <div className="flex-1 flex justify-between sm:hidden">
            <Button
              kind={BUTTON_KIND.WHITE}
              buttonText="Previous"
              disabled={page === 1}
              onClick={() => setPage((old) => Math.max(old - 1, 0))}
            />
            <Button
              kind={BUTTON_KIND.WHITE}
              buttonText="Next"
              disabled={isPreviousData || page === totalPages}
              onClick={() => {
                setPage((old) => old + 1);
              }}
            />
          </div>
        ) : (
          <div className="flex-1 flex justify-between sm:hidden">
            <p className="text-sm text-gray-700">
              Showing{' '}
              <span className="font-medium">{1 + (page - 1) * pageSize}</span> to{' '}
              <span className="font-medium">
                {totalEntries && totalEntries < pageSize * page
                  ? totalEntries
                  : pageSize * page}
              </span>{' '}
              of <span className="font-medium">{totalEntries}</span> results
            </p>
          </div>
        )
      }
      <div className={`hidden sm:flex-1 sm:flex sm:items-center ${showChoisePageSize ? 'sm:justify-between' : 'sm:justify-end'} `}>
        {showChoisePageSize && <div className="flex space-x-4 items-center justify-between">
          <InputSelect
            id="page-size"
            label=""
            value={String(pageSize)}
            onChange={(value) => {
              setPage(1);
              setPageSize(Number(value));
            }}
            options={[
              { label: '10 per page', value: '10' },
              { label: '20 per page', value: '20' },
              { label: '50 per page', value: '50' },
              { label: '100 per page', value: '100' },
              { label: '500 per page', value: '500' },
            ]}
          />
          <p className="hidden lg:block text-sm text-gray-700">
            Showing{' '}
            <span className="font-medium">{1 + (page - 1) * pageSize}</span> to{' '}
            <span className="font-medium">
              {totalEntries && totalEntries < pageSize * page
                ? totalEntries
                : pageSize * page}
            </span>{' '}
            of <span className="font-medium">{totalEntries}</span> results
          </p>
        </div>
        }
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <button
              type="button"
              disabled={page === 1}
              onClick={() => setPage((old) => Math.max(old - 1, 0))}
              className="relative transition transition-all inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {Array.from(Array(totalPages).keys()).map(
              (item: number, index: number) => {
                if (currentPage && totalPages && totalPages > 7) {
                  if (index < currentPage - 3) {
                    return null;
                  }
                  if (index === currentPage - 3) {
                    return (
                      <button
                        type="button"
                        key={item}
                        onClick={() => setPage(1)}
                        className="relative inline-flex items-center px-4 py-2 border text-sm font-medium bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                      >
                        {'<<'}
                      </button>
                    );
                  }
                  if (index === currentPage + 3) {
                    return (
                      <button
                        type="button"
                        key={item}
                        onClick={() => setPage(totalPages)}
                        className="relative inline-flex items-center px-4 py-2 border text-sm font-medium bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                      >
                        {'>>'}
                      </button>
                    );
                  }
                  if (index > currentPage + 3) {
                    return null;
                  }
                }
                return (
                  <button
                    type="button"
                    key={item}
                    onClick={() => setPage(index + 1)}
                    className={classNames(
                      page === index + 1
                        ? 'z-10 bg-brand-100 border-brand-500 text-brand-500'
                        : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50',
                      'relative transition transition-all inline-flex items-center px-4 py-2 border text-sm font-medium',
                    )}
                  >
                    {index + 1}
                  </button>
                );
              },
            )}
            <button
              type="button"
              disabled={!!totalPages && page === totalPages}
              onClick={() => {
                setPage((old) => old + 1);
              }}
              className="relative transition transition-all inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div >
  );
}
