import { useState, useEffect, useCallback, Fragment } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import ErrorAlert from '@/components/ErrorAlert';
import SuccessAlert from '@/components/SuccessAlert';
import InfoPanelContainer from '@/components/InfoPanelContainer';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import Input from '@/components/Input';
import InputSelect from '@/components/InputSelect';
import Modal from '@/components/Modal';
import accountsAPI from '@/api/accounts';
import adminAPI from '@/api/admin';
import organisationsAPI from '@/api/organisations';
import Button, { BUTTON_KIND } from '@/components/Button';
import Pagination from '@/components/Pagination';
import IOrganisation, {
  IOrganisationDeleteError,
  IOrganisationSettings,
  IOrganisationUser,
} from '@/types/IOrganisation';
import validateEmail from '@/helpers/validateEmail';
import MESSAGES from '@/constants/messages-en';
import Search from '@/components/Search';
import ToggleField from '@/components/ToggleField';
import InputInline from '@/components/InputInline';
import filesAPI from '@/api/files';
import ModalNoCancel from '@/components/ModalNoCancel';
import FileUploadButtonComponent from '@/components/FileUploadButton';
import IFile from '@/types/IFile';
import designsAPI from '@/api/designs';
import IDesign from '@/types/IDesign';
import PaymentTerms from '@/components/OrganisationSettingsPage/PaymentTerms';
import ColourSettings from '@/components/OrganisationSettingsPage/ColourSettings';
import CardDesigns from '@/components/OrganisationSettingsPage/CardDesigns';

interface IOrganisationAdminSettings {
  orgID: number;
  organisationName: string;
  setOrganisationName: React.Dispatch<React.SetStateAction<string>>;
  inviteUserEmail?: string;
  setInviteUserEmail?: React.Dispatch<React.SetStateAction<string>>;
  inviteUserRole?: string;
  setInviteUserRole?: React.Dispatch<React.SetStateAction<string>>;
  orgUsers: IOrganisationUser[];
  setOrgUsers: React.Dispatch<React.SetStateAction<IOrganisationUser[]>>;
  disabled?: boolean;
  designFiles: IDesign[];
  setDesignFiles: React.Dispatch<React.SetStateAction<IDesign[]>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  designsSelected: { id: number; value: IDesign }[];
  setDesignsSelected: React.Dispatch<
    React.SetStateAction<{ id: number; value: IDesign }[]>
  >;
}

const showOrganisationSettingsNewFeat =
  process.env.REACT_APP_ORG_PAYMENT_TERM_AND_COLOR_SETTING === 'true';

export default function OrganisationAdminSettings({
  orgID,
  organisationName,
  setOrganisationName,
  inviteUserEmail = '',
  setInviteUserEmail,
  inviteUserRole = 'editor',
  setInviteUserRole,
  orgUsers,
  setOrgUsers,
  disabled,
  page,
  setPage,
  designFiles,
  setDesignFiles,
  setDesignsSelected,
  designsSelected,
}: IOrganisationAdminSettings): JSX.Element {
  const [inviteUserIsOpen, setInviteUserIsOpen] = useState<boolean>(false);
  const [deleteAccountIsOpen, setDeleteAccountIsOpen] =
    useState<boolean>(false);
  const [resetPasswordIsOpen, setResetPasswordIsOpen] =
    useState<boolean>(false);
  const [deleteOrganisationIsOpen, setDeleteOrganisationIsOpen] =
    useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<IOrganisationUser>();
  const [nameLoading, setNameLoading] = useState<boolean>(false);
  const [nameSuccess, setNameSuccess] = useState<string>('');
  const [nameError, setNameError] = useState<boolean>(false);
  const [accountLoading, setAccountLoading] = useState<boolean>(false);
  const [accountSuccess, setAccountSuccess] = useState<string>('');
  const [accountError, setAccountError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emptyOrgError, setEmptyOrgError] = useState<string>('');
  const [deleteOrgError, setDeleteOrgError] = useState<boolean>(false);
  const [orgInviteError, setOrgInviteError] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(10);
  const [orgMoveIsOpen, setOrgMoveIsOpen] = useState<boolean>(false);
  const [orgSearch, setOrgSearch] = useState<string>('');
  const [moveOrgID, setMoveOrgID] = useState<number>(-1);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [orgData, setOrgData] = useState<IOrganisation[]>();

  const [isReorderTemplateModalOpen, setIsReorderTemplateModalOpen] =
    useState<boolean>(false);
  // const [designFiles, setDesignFiles] = useState<IDesign[]>();
  const [designFile, setDesignFile] = useState<IFile>();
  const [pageDesignFiles, setPageDesignFiles] = useState<number>(1);
  const [designSizeError, setDesignSizeError] = useState<boolean>(false);
  const [designFileError, setDesignFileError] = useState<boolean>(false);
  const [designLoading, setDesignLoading] = useState<boolean>(false);
  const [variableInformation, setVariableInformation] = useState<string[]>([]);
  const [organisationSetting, setOrganisationSetting] =
    useState<IOrganisationSettings>();
  const [organisationSettingID, setOrganisationSettingID] = useState<number>();

  const variables = [
    {
      value: 'name',
      title: 'Name',
    },
    {
      value: 'job_title',
      title: 'Job Title',
    },
    {
      value: 'email',
      title: 'Email',
    },
    {
      value: 'company_website',
      title: 'Company website',
    },
    {
      value: 'mobile_number',
      title: 'Mobile Number',
    },
    {
      value: 'street_address',
      title: 'Company Address',
    },
    {
      value: 'company_phone_number',
      title: 'Company Phone Number',
    },
  ];

  const listOrganisations = useCallback(async (newOrgSearch: string) => {
    const res = await adminAPI.listOrganisations({
      pageSize: 5,
      search: newOrgSearch,
    });

    setOrgData(res.data?.data?.filter((item: IOrganisation) => item.name));

    return res.data;
  }, []);

  const showOrganisationSettings = useCallback(async () => {
    const res = await organisationsAPI.showAdminOrganisationSettings(orgID);
    if (res?.data?.data) {
      const returnedOrgSettings = res.data.data;
      setOrganisationSetting(returnedOrgSettings);
      setOrganisationSettingID(res.data.data.id);
    }

    return res.data.data;
  }, [orgID]);

  const handleOrganisationNameSave = () => {
    setNameSuccess('');
    setNameError(false);
    setNameLoading(true);
    organisationsAPI
      .updateOrganisation(orgID, {
        organisation: {
          name: organisationName,
        },
      })
      .then(() => setNameSuccess(MESSAGES.organisation.settings))
      .catch(() => setNameError(true))
      .finally(() => setNameLoading(false));
  };

  const handleInviteUser = () => {
    setAccountSuccess('');
    setEmailError(false);
    setAccountLoading(true);
    if (inviteUserEmail && validateEmail(inviteUserEmail)) {
      setEmailError(false);
      organisationsAPI
        .inviteUser(orgID, {
          invite: {
            organisation_id: orgID,
            email: inviteUserEmail,
            role: inviteUserRole,
          },
        })
        .then(() => setAccountSuccess(MESSAGES.organisation.user))
        .catch((e: AxiosError) => {
          if (e.response?.status === 422) {
            setOrgInviteError(MESSAGES.organisation.invite.error);
          } else {
            setAccountError(true);
          }
        })
        .then(() => setAccountLoading(false))
        .finally(() => setInviteUserIsOpen(false));
    } else {
      setEmailError(true);
      setAccountLoading(false);
    }
  };

  const getDesignFiles = useCallback(
    async (newPage: number = page) => {
      const res = await designsAPI.listDesigns({
        orgID,
        page: newPage,
        pageSize: 6,
      });

      setDesignFiles(res.data?.data?.filter((item: IDesign) => item.id));

      setDesignsSelected([]);

      return res.data;
    },
    [pageDesignFiles],
  );

  const showOrganisation = useCallback(() => {
    organisationsAPI
      .showOrganisation(orgID)
      .then((res) => {
        setOrganisationName(res?.data?.data?.name);
      })
      .finally(() => setNameLoading(false));
  }, [orgID, setOrganisationName]);

  async function listOrganisationRoles(
    newPage: number = page,
    newPageSize: number = pageSize,
  ) {
    const res = await organisationsAPI.listOrganisationRoles({
      orgID,
      page: newPage,
      pageSize: newPageSize,
    });

    setOrgUsers(res.data.data);
    setPage(newPage);
    setPageSize(newPageSize);

    return res.data;
  }

  useEffect(() => {
    showOrganisation();
    showOrganisationSettings();
  }, [showOrganisation, orgID]);

  const handleDeleteAccount = (userID: number) => {
    setAccountSuccess('');
    setAccountError(false);
    setOrgInviteError('');
    setEmptyOrgError('');
    setAccountLoading(true);
    adminAPI
      .deleteUser(userID)
      .then(() => setAccountSuccess(MESSAGES.account.delete.success))
      .catch(() => setAccountError(true))
      .then(() => setDeleteAccountIsOpen(false))
      .then(() => listOrganisationRoles())
      .finally(() => setAccountLoading(false));
  };

  const handleResetPassword = (email: string) => {
    setAccountSuccess('');
    setAccountError(false);
    setOrgInviteError('');
    setEmptyOrgError('');
    setAccountLoading(true);
    accountsAPI
      .forgotPassword({ forgot: { email } })
      .then(() => setAccountSuccess(MESSAGES.account.reset.success))
      .catch(() => setAccountError(true))
      .then(() => setAccountLoading(false))
      .finally(() => setResetPasswordIsOpen(false));
  };

  const handleMoveAccount = (roleID: number, organisation_id: number) => {
    setAccountSuccess('');
    setAccountError(false);
    setOrgInviteError('');
    setEmptyOrgError('');
    setAccountLoading(true);
    adminAPI
      .updateOrganisationRole(roleID, {
        organisation_role: {
          organisation_id,
        },
      })
      .then(() => setAccountSuccess(MESSAGES.account.organisation.success))
      .catch(() => setAccountError(true))
      .then(() => setAccountLoading(false))
      .finally(() => setOrgMoveIsOpen(false));
  };

  const handleDeleteOrganisation = () => {
    setAccountSuccess('');
    setAccountError(false);
    setOrgInviteError('');
    setEmptyOrgError('');
    setAccountLoading(true);
    adminAPI
      .deleteOrganisation(orgID)
      .then(() => setAccountSuccess(MESSAGES.organisation.delete.success))
      .catch((e: IOrganisationDeleteError) => {
        if (e.response?.status === 422) {
          setEmptyOrgError(e.response.data.errors[0]);
        } else {
          setDeleteOrgError(true);
        }
      })
      .then(() => setDeleteOrganisationIsOpen(false))
      .then(() => listOrganisationRoles())
      .finally(() => setAccountLoading(false));
  };

  return (
    <>
      {setInviteUserEmail && setInviteUserRole && (
        <Modal
          isOpen={inviteUserIsOpen}
          setIsOpen={setInviteUserIsOpen}
          dialogTitle="Invite user"
          successButtonText="Send email invitation"
          onSuccess={() => handleInviteUser()}
          isLoading={accountLoading}
        >
          <div className="mt-6">
            {emailError && <ErrorAlert message={MESSAGES.error.email} />}
            <Input
              label="Email"
              value={inviteUserEmail}
              onChange={setInviteUserEmail}
              type="text"
            />
            <div className="mt-4">
              <InputSelect
                id="role"
                label="Role"
                value={inviteUserRole}
                onChange={setInviteUserRole}
                options={[
                  {
                    label: 'Editor',
                    value: 'editor',
                  },
                  {
                    label: 'Account owner',
                    value: 'account_owner',
                  },
                ]}
              />
            </div>
          </div>
        </Modal>
      )}
      <Modal
        isOpen={deleteAccountIsOpen}
        setIsOpen={setDeleteAccountIsOpen}
        dialogTitle={MESSAGES.account.delete.heading}
        dialogDescription={MESSAGES.account.delete.description(
          selectedAccount?.user.email || '',
        )}
        successButtonText="Delete account"
        successButtonKind={BUTTON_KIND.RED}
        onSuccess={() => {
          if (selectedAccount) handleDeleteAccount(selectedAccount?.user.id);
        }}
        onCancel={() => setSelectedAccount(undefined)}
        isLoading={accountLoading}
      />
      <Modal
        isOpen={resetPasswordIsOpen}
        setIsOpen={setResetPasswordIsOpen}
        dialogTitle={MESSAGES.account.reset.heading}
        dialogDescription={MESSAGES.account.reset.description}
        successButtonText="Reset password"
        onSuccess={() => {
          if (selectedAccount) handleResetPassword(selectedAccount?.user.email);
        }}
        onCancel={() => setSelectedAccount(undefined)}
        isLoading={accountLoading}
      />
      <Modal
        isOpen={orgMoveIsOpen}
        setIsOpen={setOrgMoveIsOpen}
        dialogTitle={MESSAGES.account.organisation.heading}
        dialogDescription={MESSAGES.account.organisation.description}
        successButtonText="Move account"
        onSuccess={() => {
          if (selectedAccount) {
            handleMoveAccount(selectedAccount?.id, moveOrgID);
          }
        }}
        onCancel={() => setSelectedAccount(undefined)}
        isDisabled={moveOrgID === -1}
        isLoading={accountLoading}
      >
        <div className="pt-4 relative">
          <Search
            id={`OrganisationList-1-20-date-desc--${orgSearch}`}
            placeholder="Search for organisations"
            search={orgSearch}
            setSearch={(value) => {
              if (value !== orgSearch) {
                setMoveOrgID(-1);
                setShowOptions(true);
              }
              setOrgSearch(value);
            }}
            fetchQuery={(newOrgSearch) => listOrganisations(newOrgSearch)}
          />
          {orgSearch && showOptions && (
            <div className="origin-top-left absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
              <div className="py-1">
                {orgData && orgData?.length ? (
                  orgData?.map((item: IOrganisation) => (
                    <button
                      type="button"
                      key={item.id}
                      className="appearance-none px-3 py-2 cursor-pointer hover:bg-gray-200 w-full text-left"
                      onClick={() => {
                        setMoveOrgID(item.id);
                        setShowOptions(false);
                        setOrgSearch(`#${item.id} ${item.name}`);
                      }}
                    >
                      #{item.id}{' '}
                      <span className="text-gray-500">{item.name}</span>
                    </button>
                  ))
                ) : (
                  <li className="px-3 py-2 text-center">
                    No matching items found
                  </li>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
      <Modal
        isOpen={deleteOrganisationIsOpen}
        setIsOpen={setDeleteOrganisationIsOpen}
        dialogTitle={MESSAGES.organisation.delete.heading}
        dialogDescription={MESSAGES.organisation.delete.description}
        successButtonText="Delete organisation"
        successButtonKind={BUTTON_KIND.RED}
        onSuccess={() => {
          if (orgID) handleDeleteOrganisation();
        }}
        isLoading={accountLoading}
      />

      <div className="mt-10">
        <InfoPanelContainer
          title="Organisation settings"
          footerContent={() =>
            !disabled && (
              <Button
                buttonText="Save"
                loading={nameLoading}
                onClick={handleOrganisationNameSave}
              />
            )
          }
        >
          {nameSuccess !== '' && <SuccessAlert message={nameSuccess} />}
          {nameError && (
            <ErrorAlert message="Something went wrong. Please try again later" />
          )}
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <Input
                label="Organisation name"
                type="text"
                value={organisationName}
                required
                onChange={setOrganisationName}
                disabled={disabled}
              />
            </div>
          </div>
        </InfoPanelContainer>

        <InfoPanelDivider />

        <InfoPanelContainer title="Organisation accounts">
          {accountSuccess !== '' && <SuccessAlert message={accountSuccess} />}
          {accountError && <ErrorAlert message={MESSAGES.error.generic} />}
          {orgInviteError !== '' && <ErrorAlert message={orgInviteError} />}
          {emptyOrgError !== '' && <ErrorAlert message={emptyOrgError} />}
          <div className="mb-6">
            <ul className="divide-y divide-gray-200">
              {orgUsers &&
                orgUsers.map((item) => (
                  <li key={item.id}>
                    <div className="block bg-white">
                      <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between py-4">
                        <div className="min-w-0">
                          <p className="text-sm font-medium text-gray-900 truncate">
                            {item.user?.first_name} {item.user?.last_name}
                          </p>
                          <p className="flex items-center text-sm text-gray-500 truncate">
                            {item.user?.email}
                          </p>
                        </div>
                        <div className="inline-flex flex-col lg:flex-row lg:space-x-6 items-start lg:items-center">
                          <p className="flex items-center text-sm text-gray-500">
                            {item.role === 'org_admin'
                              ? 'Administrator'
                              : 'Editor'}
                          </p>
                          <Menu
                            as="div"
                            className="relative inline-block text-left mt-2 lg:mt-0"
                          >
                            <div>
                              <Menu.Button className="inline-flex justify-center w-max rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:ring-brand-500 focus:border-brand-500">
                                Manage user
                                <ChevronDownIcon
                                  className="-mr-1 ml-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                                <div className="py-1">
                                  <Menu.Item>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setDeleteAccountIsOpen(true);
                                        setSelectedAccount(item);
                                      }}
                                      className="appearance-none text-red-500 w-full text-left block px-4 py-2 text-sm"
                                    >
                                      Delete account
                                    </button>
                                  </Menu.Item>
                                  <Menu.Item>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setResetPasswordIsOpen(true);
                                        setSelectedAccount(item);
                                      }}
                                      className="appearance-none text-gray-700 w-full text-left block px-4 py-2 text-sm"
                                    >
                                      Reset password
                                    </button>
                                  </Menu.Item>
                                  <Menu.Item>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setOrgMoveIsOpen(true);
                                        setSelectedAccount(item);
                                      }}
                                      className="appearance-none text-gray-700 w-full text-left block px-4 py-2 text-sm"
                                    >
                                      Assign to another organisation
                                    </button>
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>

          <div className="space-y-4">
            {setInviteUserEmail && setInviteUserRole && (
              <Button
                kind={BUTTON_KIND.WHITE}
                buttonText="Invite user"
                onClick={() => setInviteUserIsOpen(true)}
              />
            )}
            <Pagination
              id={`OrganisationsList-${page}-${pageSize}`}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              fetchQuery={(pageQuery, pageSizeQuery) =>
                listOrganisationRoles(pageQuery, pageSizeQuery)
              }
              setIsLoading={setAccountLoading}
            />
          </div>
        </InfoPanelContainer>

        {showOrganisationSettingsNewFeat && (
          <>
            <InfoPanelDivider />
            <PaymentTerms
              organisationSettingID={organisationSettingID}
              organisationPaymentTerms={
                organisationSetting?.payment_terms &&
                organisationSetting.payment_terms
              }
            />
            <ColourSettings
              organisationSettingID={organisationSettingID}
              organisationColorSetting={organisationSetting?.colour_settings}
            />
            <CardDesigns orgID={orgID} />
          </>
        )}

        {disabled && (
          <>
            <InfoPanelDivider />
            <InfoPanelContainer
              title="Login"
              information="Click this button to log into this organisation account."
            >
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <Button
                    kind={BUTTON_KIND.WHITE}
                    buttonText="Switch to Organisation view"
                    href={`/?scope=user&uOrgID=${orgID}`}
                    external
                  />
                </div>
              </div>
            </InfoPanelContainer>
          </>
        )}
        {disabled && (
          <>
            <InfoPanelDivider />
            <InfoPanelContainer
              title="Delete organisation"
              information="Click this button to delete this organisation permanently."
            >
              {deleteOrgError && (
                <ErrorAlert message={MESSAGES.error.generic} />
              )}
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <Button
                    kind={BUTTON_KIND.RED}
                    buttonText="Delete Organisation"
                    onClick={() => setDeleteOrganisationIsOpen(true)}
                  />
                </div>
              </div>
            </InfoPanelContainer>
          </>
        )}
      </div>

      {/* Modal upload file */}
      <ModalNoCancel
        isOpen={isReorderTemplateModalOpen}
        setIsOpen={setIsReorderTemplateModalOpen}
        dialogTitle="Reorder Template Set-Up (Required)"
        dialogDescription={
          <>
            Upload a previous proof or a new card design. If uploading a new
            card design, please ensure you have followed the design
            specifications mentioned in our design template.
            <br />
          </>
        }
        large
        successButtonText="Save"
        onSuccess={() => {
          designsAPI
            .createDesign({
              orgID,
              body: {
                design: {
                  design_file_id: designFile?.id,
                  flags: {
                    company_name: false,
                    company_phone_number: variableInformation.includes(
                      'company_phone_number',
                    ),
                    company_website:
                      variableInformation.includes('company_website'),
                    email: variableInformation.includes('email'),
                    name: variableInformation.includes('name'),
                    job_title: variableInformation.includes('job_title'),
                    mobile_number:
                      variableInformation.includes('mobile_number'),
                    street_address:
                      variableInformation.includes('street_address'),
                  },
                },
              },
            })
            .then((res) => {})
            .catch((err) => {
              console.log('err', err);
            })
            .finally(() => {
              setIsReorderTemplateModalOpen(false);
              setDesignFile(undefined);
              setVariableInformation([]);
            });
        }}
      >
        <div className="px-4 mt-6 flex flex-row items-center justify-star">
          <FileUploadButtonComponent
            buttonTitle="Choose file"
            id="design-upload"
            filename={designFile?.file?.file_name}
            url={designFile?.original_url}
            fileFormat=".svg, .eps, .ai, .indd, .pdf"
            fileFormatMessage="(.svg, .eps, .ai, .indd, or .pdf only)"
            onFileSelected={(file) => {
              if (orgID) {
                setDesignSizeError(false);
                setDesignFileError(false);
                setDesignLoading(true);
                filesAPI
                  .createAndUploadFile(orgID, new File([file], file.name))
                  .then((res) => {
                    if (res?.data?.data.id) {
                      setDesignFile(res.data.data);
                      getDesignFiles();
                    }
                  })
                  .catch(() => setDesignFileError(true))
                  .finally(() => {
                    setDesignLoading(false);
                  });
              }
            }}
            loading={designLoading}
          />
          {designFile && (
            <button
              type="button"
              onClick={() => setDesignFile(undefined)}
              className="ml-4 appearance-none text-brand-500 text-sm font-medium"
            >
              Remove file
            </button>
          )}
        </div>
        <br />
        <Dialog.Title
          as="h3"
          className="text-2xl leading-8 font-medium text-gray-900"
        >
          Variable Information
        </Dialog.Title>
        <Dialog.Description className="text-sm leading-5 text-gray-500">
          Select which details will be different on each card
        </Dialog.Description>
        <br />
        <div className="">
          <InputInline
            type="checkbox"
            data={variableInformation}
            setData={setVariableInformation}
            setting={variables}
            className="grid grid-cols-2 gap-3"
            itemClassName="block bg-white border border-gray-100 rounded py-1 px-2"
          />
        </div>
      </ModalNoCancel>
    </>
  );
}
