import { ITableHeader } from '@/components/OrdersPage/shared/TableHeader';
import { STAGE } from '@/constants/stage';
import classNames from '@/helpers/classNames';
import { orderHandler } from '@/helpers/order_handler';

const orderTableHeader = (status: string): ITableHeader[] => [
  {
    style: { width: '10%' },
    className: 'text-xs font-semibold text-gray-900 text-center',
    scope: 'col',
    children: 'ORDER NO.',
  },
  {
    style: { width: '9.6%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'TAG'),
      'text-xs font-semibold text-gray-900 text-center',
    ),
    scope: 'col',
    children: 'TAG',
  },
  {
    className:
      'text-xs font-semibold text-gray-900 text-left pl-6 lg:table-cell hidden',
    scope: 'col',
    children: <span className="truncate">NAME &amp; EMAIL</span>,
  },
  {
    style: { width: '9.2%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'ORDER TYPE'),
      'text-xs font-semibold text-gray-900 text-center',
    ),
    scope: 'col',
    children: 'ORDER TYPE',
  },
  {
    style: { width: '11%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'NOTIFICATIONS'),
      'text-xs font-semibold text-gray-900 text-center',
    ),
    scope: 'col',
    children: 'NOTIFICATIONS',
  },
  {
    style: { width: '9.2%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'WARNING'),
      'text-xs text-gray-900 text-center font-semibold lg:table-cell hidden',
    ),
    scope: 'col',
    children: 'WARNING',
  },
  {
    style: { width: '9.2%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'CHANGES'),
      'text-gray-900 text-center text-xs font-semibold',
    ),
    scope: 'col',
    children: 'CHANGES',
  },
  {
    style: { width: '11%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'EXPORT PROFILES'),
      'text-gray-900 text-center text-xs font-semibold lg:table-cell hidden',
    ),
    scope: 'col',
    children: ' EXPORT PROFILES',
  },
  {
    style: { width: '11%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'SUBMISSION DATE'),
      'text-gray-900 text-center text-xs font-semibold',
    ),
    scope: 'col',
    children: 'Submission Date',
  },
  {
    style: { width: '11%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'PAUSED BY'),
      'text-gray-900 text-center text-xs font-semibold',
    ),
    scope: 'col',
    children: 'Paused by',
  },
  {
    style: { width: '9.2%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'SHOPIFY LINK'),
      'text-gray-900 text-center text-xs font-semibold lg:table-cell hidden',
    ),
    scope: 'col',
    children: 'SHOPIFY LINK',
  },
  {
    style: { width: '9.2%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'SHIPPING'),
      'text-xs font-semibold text-gray-900 text-center lg:table-cell hidden',
    ),
    scope: 'col',
    children: 'SHIPPING',
  },
  {
    className: classNames(
      orderHandler.stageTabHandler(status, 'Define Guidelines'),
      'text-xs font-semibold text-gray-900 text-center w-1/6',
    ),
    scope: 'col',
    children: 'Define Guidelines',
  },
  {
    style: { width: '9.2%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'DESIGN FILE'),
      'text-xs font-semibold text-gray-900 text-center lg:table-cell hidden',
    ),
    scope: 'col',
    children: 'DESIGN FILE',
  },
  {
    className: classNames(
      orderHandler.stageTabHandler(status, 'ORDER DETAILS'),
      'text-gray-900 text-center text-xs font-semibold w-1/6',
    ),
    scope: 'col',
    children: 'ORDER DETAILS',
  },
  {
    className: classNames(
      orderHandler.stageTabHandler(status, 'UPLOAD DESIGN'),
      'text-gray-900 text-center text-xs font-semibold w-1/6',
    ),
    scope: 'col',
    children:
      status === STAGE.order.design_edits ||
      status === STAGE.order.ready_for_print
        ? 'REUPLOAD DESIGN'
        : 'UPLOAD DESIGN',
  },
  {
    style: { width: '11%' },
    className: classNames(
      orderHandler.stageTabHandler(
        status,
        'EXPORT PROFILES FOR AWAITING CLIENT',
      ),
      'text-gray-900 text-center text-xs font-semibold lg:table-cell hidden',
    ),
    scope: 'col',
    children: 'EXPORT PROFILES',
  },
  {
    className: 'text-xs font-semibold text-gray-900 text-center py-2 w-1/12',
    scope: 'col',
    children: 'OTHER',
  },
];
export default orderTableHeader;
