const OrderConstrain = {
    sortOptions: [
                    {
                    sort: 'inserted_at',
                    order: 'desc',
                    label: 'Date created (Newest first)',
                    },
                    {
                    sort: 'inserted_at',
                    order: 'asc',
                    label: 'Date created (Oldest first)',
                    },
                    {
                    sort: 'quantity',
                    order: 'asc',
                    label: 'No of Profile (Lowest first)',
                    },
                    {
                    sort: 'quantity',
                    order: 'desc',
                    label: 'No of Profile (Highest first)',
                    },
                    {
                    sort: 'type',
                    order: 'shop',
                    label: 'Order Type (Shop)',
                    },
                    {
                    sort: 'type',
                    order: 'draft',
                    label: 'Order Type (Draft)',
                    },
                    {
                    sort: 'tag',
                    order: 'asc',
                    label: 'Tag (Oldest first)',
                    },
                    {
                    sort: 'tag',
                    order: 'desc',
                    label: 'Tag (Newest first)',
                    },
                ],
}

export default OrderConstrain