import { useState } from 'react';
import { DuplicateIcon, ExclamationCircleIcon } from '@heroicons/react/solid';
import Button from '../Button';
import useAuth from '@/hooks/useAuth';
import scimApi from '@/api/scim';

export default function UserProvisioningTab(): JSX.Element {
  const { orgID } = useAuth();
  const [baseUrl, setBaseUrl] = useState(
    process.env.REACT_APP_SCIM_BASE_URL || '',
  );
  const [isBaseUrlCopied, setBaseUrlCopied] = useState(false);
  const [secret, setSecret] = useState('');
  const [isSecretCopied, setSecretCopied] = useState(false);

  const copyScimBaseUrl = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(baseUrl).then(() => {
        setBaseUrlCopied(true);
        setTimeout(() => setBaseUrlCopied(false), 2000);
      });
    } else {
      document.execCommand('copy', true, baseUrl);
      setBaseUrlCopied(true);
      setTimeout(() => setBaseUrlCopied(false), 2000);
    }
  };

  const copyScimSecret = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(secret).then(() => {
        setSecretCopied(true);
        setTimeout(() => setSecretCopied(false), 2000);
      });
    } else {
      document.execCommand('copy', true, secret);
      setSecretCopied(true);
      setTimeout(() => setSecretCopied(false), 2000);
    }
  };

  const generateToken = () => {
    if (orgID === undefined) return;

    scimApi.createToken(orgID).then((res) => {
      setSecret(res.data.data.access_token);
    });
  };

  return (
    <>
      <div className="space-y-1 mt-10">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          SCIM Provisioning
        </h3>
        <p className="text-sm text-gray-500">
          System for Cross-Domain Identity Management (SCIM) allows you to
          automatically update, provision, and deprovision users.
          <br />
          <br />
          Connect your Active Directory to Tapt to automatically provision users
          and create profiles for them.
        </p>
      </div>
      <div className="max-w-xl mt-6">
        <label
          htmlFor="scim-base-url"
          className="block text-sm font-medium text-gray-700"
        >
          SCIM Base URL
        </label>
        <div className="mt-1 flex rounded-md shadow-sm">
          <div className="relative flex flex-grow items-stretch focus-within:z-10">
            <input
              type="text"
              disabled
              name="scim-base-url"
              id="scim-base-url"
              className="block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={baseUrl}
            />
          </div>
          <button
            type="button"
            className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 p-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            onClick={copyScimBaseUrl}
          >
            {isBaseUrlCopied ? (
              'Copied!'
            ) : (
              <DuplicateIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            )}
          </button>
        </div>
      </div>
      <div className="mt-6">
        <label
          htmlFor="scim-token"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          SCIM Secret Token
        </label>
        {secret ? (
          <>
            <div className="max-w-xl flex rounded-md shadow-sm">
              <div className="relative flex flex-grow items-stretch focus-within:z-10">
                <input
                  type="text"
                  disabled
                  name="scim-token"
                  id="scim-token"
                  className="block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={secret}
                />
              </div>
              <button
                type="button"
                className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 p-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                onClick={copyScimSecret}
              >
                {isSecretCopied ? (
                  'Copied!'
                ) : (
                  <DuplicateIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                )}
              </button>
            </div>
            <p className="mt-1 text-sm text-gray-500">
              <ExclamationCircleIcon
                className="h-5 w-5 text-yellow-400 inline-block"
                aria-hidden="true"
              />{' '}
              Store this key somewhere safe. It will not be visible again once
              you leave this page.
            </p>
          </>
        ) : (
          <Button buttonText="Generate Token" onClick={generateToken} />
        )}
      </div>
    </>
  );
}
