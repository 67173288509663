import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { SearchIcon } from '@heroicons/react/solid';
import Input from '@/components/Input';
import { IListPaging } from '@/types/IList';
import ICard from '@/types/ICard';
import IProfile from '@/types/IProfile';
import IActivationKey from '@/types/IActivationKey';
import IAnalytics, { IAnalyticsAdmin } from '@/types/IAnalytics';
import IContacts from '@/types/IContacts';
import IOrganisation, {
  IOrganisationGroup,
  IPilot,
} from '@/types/IOrganisation';
import IOrder from '@/types/IOrderV2';

interface ISearch {
  id: string;
  label?: string;
  description?: string;
  placeholder?: string;
  search: string;
  disabled?: boolean;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  fetchQuery: (search: string) => Promise<{
    data: (
      | IActivationKey
      | IAnalytics
      | IAnalyticsAdmin
      | ICard
      | IContacts
      | IOrganisationGroup
      | IOrganisation
      | IProfile
      | IOrder
      | IPilot
    )[];
    paging: IListPaging;
  }>;
}

export default function Search({
  id,
  label = '',
  description,
  placeholder = 'Search',
  search,
  disabled = false,
  setSearch,
  fetchQuery,
}: ISearch): JSX.Element {
  const [debouncedValue, setDebouncedValue] = useState(search);
  useEffect(() => {
    const handler: NodeJS.Timeout = setTimeout(() => {
      setSearch(debouncedValue);
    }, 500);

    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => {
      clearTimeout(handler);
    };
  }, [debouncedValue, setSearch]);

  useEffect(() => {
    setDebouncedValue(search);
  }, [search]);

  useQuery(id, () => fetchQuery(search), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 0,
  });

  return (
    <Input
      id="search"
      label={label}
      description={description}
      placeholder={placeholder}
      type="text"
      autocomplete="off"
      value={debouncedValue}
      onChange={(e) => setDebouncedValue(e)}
      icon={SearchIcon}
      disabled={disabled}
    />
  );
}
