import { useEffect, useState } from "react";
import { AuthenticatedConnectUser, ConnectSDK, ConnectUser } from "../paragon";
import accountsAPI from "@/api/accounts";
import useAuth from "./useAuth";

async function getParagonUserToken(orgID: number): Promise<string> {
  const response = await accountsAPI.getIntegrationToken(orgID);
  return response.data.token;
}

export default function useParagonAuth(paragon?: ConnectSDK, userId?: string): {
  user?: AuthenticatedConnectUser;
  error?: Error;
} {
  const { orgID } = useAuth();
  const [token, setToken] = useState<string | null>(null);
  const [user, setUser] = useState<AuthenticatedConnectUser | undefined>();
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    if (orgID) {
      getParagonUserToken(orgID).then(setToken).catch(setError);
    }
  }, [orgID]);

  // Listen for account state changes
  useEffect(() => {
    const listener = () => {
      if (paragon) {
        const authedUser = paragon.getUser();
        if (authedUser.authenticated) {
          setUser({ ...authedUser });
        }
      }
    };
    paragon?.subscribe("onIntegrationInstall", listener);
    paragon?.subscribe("onIntegrationUninstall", listener);
    return () => {
      paragon?.unsubscribe("onIntegrationInstall", listener);
      paragon?.unsubscribe("onIntegrationUninstall", listener);
    };
  }, [paragon]);

  useEffect(() => {
    if (paragon && token && !error) {
      paragon
        .authenticate(process.env.REACT_APP_PARAGON_PROJECT_ID || '', token)
        .then(() => {
          const authedUser = paragon.getUser();
          if (authedUser.authenticated) {
            setUser(authedUser);
          }
        })
        .catch(setError);
    }
  }, [paragon, token, error]);

  return { user, error };
}