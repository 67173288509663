import { useContext, createContext, ReactNode, useState, useEffect, useMemo } from 'react';
import { useMutation } from 'react-query';
import Modal, { IModal } from '@/components/Modal';


export interface DialogContextType {
    openDialog: (prams: IModal) => void,
    loadingDialog: (isLoading: boolean) => void,
    closeDialog: () => void
}

const DialogContext = createContext<DialogContextType>({} as DialogContextType);

export function DialogProvider({
    children,
}: {
    children: ReactNode;
}): JSX.Element {

    const [dialog, setDialog] = useState<IModal>({
        isOpen: false,
        successButtonText: 'OK',
        onSuccess: () => new Promise(() => { })
    })
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false)

    function openDialog(prams: IModal) {
        setDialog({ ...prams })
    }

    function loadingDialog(isLoading: boolean) {
        setIsLoading(isLoading)
    }

    function closeDialog() {
        setIsOpen(false)
    }

    useEffect(() => {
        setIsOpen(dialog?.isOpen || false)
    }, [dialog]);


    const memoedValue = useMemo(
        () => ({
            dialog,
            openDialog,
            loadingDialog,
            closeDialog
        }),
        [
        ],
    );

    // useEffect(() => {
    //     loadingDialog(true);
    // }, [isLoading]);

    return (
        <DialogContext.Provider value={memoedValue}>
            {children}
            {dialog &&
                <Modal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    dialogTitle={dialog.dialogTitle}
                    dialogDescription={dialog.dialogDescription}
                    successButtonText={dialog.successButtonText}
                    onSuccess={async () => {
                        dialog.onSuccess()
                    }}
                    buttonKind={dialog.buttonKind}
                    buttonSize={dialog.buttonSize}
                    buttonClassName={dialog.buttonClassName}
                    buttonTitle={dialog.buttonTitle}
                    dialogHeroVideo={dialog.dialogHeroVideo}
                    checkboxDescription={dialog.checkboxDescription}
                    icon={dialog.icon}
                    successButtonKind={dialog.successButtonKind}
                    cancelButtonText={dialog.cancelButtonText || 'Cancel'}
                    isLoading={isLoading}
                    isDisabled={dialog.isDisabled}
                    large={dialog.large}
                    checkbox={dialog.checkbox}
                    setCheckbox={dialog.setCheckbox}
                    onCancel={dialog.onCancel}
                />}
        </DialogContext.Provider >
    );
}

export default function useDialog(): DialogContextType {
    return useContext(DialogContext);
}