import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { searchParamsQuery } from '@/helpers/urlHandler';
import validateComplexPassword from '@/helpers/validateComplexPassword';
import SuccessAlert from '@/components/SuccessAlert';
import ErrorAlert from '@/components/ErrorAlert';
import accountsAPI from '@/api/accounts';
import useAuth from '@/hooks/useAuth';
import Input from '@/components/Input';
import Button from '@/components/Button';
import MESSAGES from '@/constants/messages-en';
import LayoutStandard from '@/components/LayoutStandard';

export default function AccountRecoveryPage({
  location,
}: {
  location: {
    search: string;
  };
}): JSX.Element {
  const { code, email } = searchParamsQuery(location.search);

  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [passwordComplexError, setPasswordComplexError] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleClick = () => {
    // set errors back to false
    setPasswordMatchError(false);
    setPasswordComplexError(false);
    setValidationError(false);

    // calls api three times, one to verify code, next to reset password, and last to login
    if (!validateComplexPassword(password)) {
      setPasswordComplexError(true);
    } else if (password !== confirmPassword) {
      setPasswordMatchError(true);
    } else {
      setLoading(true);
      accountsAPI
        .verifyForgotPassword({
          forgot: {
            email,
            code,
          },
        })
        .then((res) => {
          localStorage.setItem('access_token', res.data.data.access_token);
          localStorage.setItem('refresh_token', res.data.data.refresh_token);
        })
        .then(() =>
          accountsAPI.updatePassword({
            update_password: {
              password,
              password_confirmation: confirmPassword,
            },
          }),
        )
        .then(() => setSuccess(true))
        .then(() => login({ email, password }))
        .catch(() => setValidationError(true))
        .finally(() => setLoading(false));
    }
  };

  if (!code || !email) {
    return <Redirect to="/reset-password" />;
  }

  return (
    <LayoutStandard
      heading="Reset your Tapt password"
      description="Choose a new password. Make sure it's strong!"
    >
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 border border-gray-100 shadow sm:rounded-lg sm:px-10">
          {success && <SuccessAlert message={MESSAGES.password.success} />}
          {validationError && (
            <ErrorAlert message={MESSAGES.password.error.validation} />
          )}
          {passwordComplexError && (
            <ErrorAlert message={MESSAGES.password.error.complex} />
          )}
          {passwordMatchError && (
            <ErrorAlert message={MESSAGES.password.error.match} />
          )}
          <div className="space-y-6">
            <Input
              label="New password"
              type="password"
              value={password}
              required
              message="Min 8 chars. Min 1 uppercase letter. Min 1 number."
              onChange={setPassword}
            />

            <Input
              label="Confirm password"
              type="password"
              value={confirmPassword}
              required
              onChange={setConfirmPassword}
            />

            <div>
              <Button
                buttonText="Reset password"
                onClick={() => {
                  if (!loading) handleClick();
                }}
                fullWidth
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutStandard>
  );
}
