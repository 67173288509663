import React, { ReactNode } from 'react';
import { CustomScroll } from '../styleds/CustomScroll';

interface ITextAreaMessage {
  id: string;
  placeholder?: string;
  message: string;
  setMessage?: React.Dispatch<React.SetStateAction<string>>;
  reSize?: boolean,
  className?: string,
  readOnly?: boolean
}

export default function TextAreaMessage({
  id,
  placeholder = 'Text Area Message',
  message,
  setMessage,
  reSize = false,
  className = '',
  readOnly = false
}: ITextAreaMessage): JSX.Element {
  return (
    <CustomScroll>
      <div className={`rounded-lg overflow-hidden border border-gray-300 dark:border-gray-600 dark:border-gray-600 ${className} `}>
        <textarea
          id="id"
          rows={4}
          readOnly={readOnly}
          className={`${!reSize && 'resize-none'} text-base block w-full h-48 text-gray-900 bg-gray-50 rounded-lg border-none focus:ring-transparent focus:border-gray-300 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder:text-base `}
          placeholder={placeholder}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            setMessage && setMessage(e.target.value);
          }}
          value={message}
        />
      </div>
    </CustomScroll>
  );
}
