import { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CheckIcon,
  DownloadIcon,
  XIcon,
  ExternalLinkIcon,
  ClipboardCheckIcon,
} from '@heroicons/react/outline';
import Moment from 'react-moment';
import moment from 'moment-timezone';
import Modal from '@/components/Modal';
import Search from '../../Search';
import IOrganisation from '@/types/IOrganisation';
import adminAPI from '@/api/admin';
import Spinner from '@/components/Icons/Spinner';
import STAGE from '@/constants/stage';
import StageHelper from '@/helpers/stage';
import fulfillmentStageHelper from '@/helpers/fulfillment_stage';
import Button, { BUTTON_KIND, BUTTON_SIZE } from '../../Button';

interface IOrdersListItem {
  id: number;
  uuid: string;
  order_status_url: string;
  orderNumber: string;
  numberOfProfiles: number;
  orderStatus: string;
  inserted_at?: any;
}

export default function OrdersListItem({
  id,
  uuid,
  orderNumber,
  order_status_url,
  numberOfProfiles,
  orderStatus,
  inserted_at,
}: IOrdersListItem): JSX.Element {
  const history = useHistory();

  const [isMoveOrder, setIsMoveOrder] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [orgDataModal, setOrgDataModal] = useState<IOrganisation[]>();
  const [orgModalSearch, setOrgModalSearch] = useState<string>('');
  const [showOptionsModal, setShowOptionsModal] = useState<boolean>(false);
  const [orgIDModal, setOrgIDModal] = useState<number>(-1);
  const Day = new Date(Date.parse(inserted_at)).getDate();
  const Month = new Date(Date.parse(inserted_at)).getMonth() + 1;
  const Year = new Date(Date.parse(inserted_at))
    .getFullYear()
    .toString()
    .slice(-2);
  const [destopWidth, setDestopWidth] = useState<boolean>(
    window.innerWidth >= 768,
  );
  const [orderInfoWidth, setOrderInfoWidth] = useState<boolean>(
    window.innerWidth >= 768 && window.innerWidth <= 796,
  );

  const listOrganisationsModal = useCallback(
    async (newOrgModalSearch: string) => {
      const res = await adminAPI.listOrganisations({
        pageSize: 5,
        search: newOrgModalSearch,
      });
      setOrgDataModal(
        res.data?.data?.filter((item: IOrganisation) => item.name),
      );
      return res.data;
    },
    [],
  );

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 768) {
        setDestopWidth(true);
      } else {
        setDestopWidth(false);
      }

      window.innerWidth >= 768 && window.innerWidth <= 796
        ? setOrderInfoWidth(true)
        : setOrderInfoWidth(false);
    }

    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <li className="z-50">
      <div className="block bg-white hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 md:flex md:items-start md:items-center">
            {destopWidth ? (
              <div className="md:grid md:grid-cols-4 md:gap-4 xl:grid-cols-6 min-w-0 flex-1">
                <div className="flex flex-row justify-center">
                  <div className="flex items-end flex-col">
                    <p className="text-sm text-gray-900 font-medium">
                      #{orderNumber}
                    </p>
                    <p className="text-sm text-gray-500 font-medium">
                      {numberOfProfiles === 1
                        ? `${numberOfProfiles} card`
                        : `${numberOfProfiles} cards`}
                    </p>
                  </div>
                </div>

                {/* DATE */}
                <div className="flex items-center justify-self-center">
                  <p className="text-sm text-gray-900 font-medium">
                    <Moment utc={inserted_at} local format="DD/MM/YY" />
                  </p>
                </div>

                {/* Order Fulfillment */}
                <div className="md:hidden xl:flex items-center justify-self-center ">
                  {orderStatus === STAGE.order.fulfilled ? (
                    <button
                      className="flex justify-center items-center bg-emerald-100 rounded-full"
                      style={{ width: '30px', height: '30px' }}
                    >
                      <CheckIcon className="text-emerald-800 w-5 h-5" />
                    </button>
                  ) : (
                    <button
                      className="flex justify-center items-center bg-red-100 rounded-full"
                      style={{ width: '30px', height: '30px' }}
                    >
                      <XIcon className="text-red-800 w-5 h-5" />
                    </button>
                  )}
                </div>

                {/* Tracking */}
                <div className="flex items-center justify-center ">
                  <Button
                    buttonText="View order"
                    kind={BUTTON_KIND.WHITE}
                    className="md:w-24 lg:w-32 lg:text-sm md:text-xs md:py-1.5 md:px-0"
                    external
                    href={order_status_url}
                  />
                </div>
                {/* <div className="md:hidden xl:flex items-center justify-self-center ">
                  <DownloadIcon className="h-6 w-6 text-gray-300" />
                </div> */}
                <div className="md:hidden xl:flex items-center justify-center">
                  <div
                    className={`rounded-full w-32 h-5 bg-${
                      StageHelper.getOrderStatus(orderStatus)?.color
                    }-100`}
                  >
                    <p
                      className={`text-xs text-center font-medium w-full h-full flex items-center justify-center text-${
                        StageHelper.getOrderStatus(orderStatus)?.color
                      }-800`}
                    >
                      {StageHelper.getOrderStatus(orderStatus)?.label}
                    </p>
                  </div>
                </div>
                {/* Orther */}
                {(orderStatus === STAGE.order.awaiting_client && (
                  <div className="flex items-center justify-center ">
                    <Button
                      buttonText="Review"
                      kind={BUTTON_KIND.PRIMARY}
                      href={`./preview/${uuid}`}
                      className=" w-32 md:w-24 lg:w-32 lg:text-sm md:text-xs md:py-1.5 md:px-0 "
                    />
                  </div>
                )) ||
                  ((orderStatus === STAGE.order.fulfilled ||
                    orderStatus === STAGE.order.ready_for_print) && (
                    <div className="flex items-center justify-center ">
                      <Button
                        buttonText="View design"
                        kind={BUTTON_KIND.WHITE}
                        href={`./preview/${uuid}`}
                        className=" w-32 md:w-24 lg:w-32 lg:text-sm md:text-xs md:py-1.5 md:px-0"
                      />
                    </div>
                  )) ||
                  ((orderStatus === STAGE.order.new ||
                    orderStatus === STAGE.order.design_edits) && (
                    <div className="flex items-center justify-center ">
                      <Button
                        buttonText="Not available"
                        kind={BUTTON_KIND.WHITE}
                        disabled
                        className=" w-32 md:w-24 lg:w-32 lg:text-sm md:text-xs md:py-1.5 md:px-0"
                      />
                    </div>
                  ))}
              </div>
            ) : (
              <>
                <div className="flex w-full">
                  <div className="flex items-start flex-col w-1/2 text-sm font-normal">
                    <div className="flex items-center">
                      <p className="text-sm text-gray-900 font-medium">
                        Order #{orderNumber}{' '}
                      </p>
                      <ExternalLinkIcon className="h-3 w-3 ml-1 stroke-1" />
                    </div>
                    <p className=" text-gray-600 ">{numberOfProfiles} cards</p>
                  </div>

                  <div className="flex justify-end w-1/2">
                    <p
                      className=" text-gray-400 font-medium leading-5"
                      style={{ fontSize: '9px' }}
                    >
                      Order placed {`${Day}/${Month}/${Year}  `}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-start text-xs leading-4 mt-5">
                  <button
                    className={`flex justify-center items-center font-medium bg-${
                      fulfillmentStageHelper.getOrderStatus(orderStatus)?.color
                    }-100 text-${
                      fulfillmentStageHelper.getOrderStatus(orderStatus)?.color
                    }-800 rounded-full px-2 py-1`}
                  >
                    {fulfillmentStageHelper.getOrderStatus(orderStatus)?.label}
                  </button>

                  <div className="flex items-center justify-self-center ml-2">
                    <div
                      className={`rounded-full w-36 px-2 py-1 bg-${
                        StageHelper.getOrderStatus(orderStatus)?.color
                      }-100`}
                    >
                      <p
                        className={`text-xs leading-4 text-center font-medium w-full h-full text-${
                          StageHelper.getOrderStatus(orderStatus)?.color
                        }-800`}
                      >
                        {StageHelper.getOrderStatus(orderStatus)?.label}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-3 flex justify-end items-center ">
                  {/* <Button
                    kind={BUTTON_KIND.WHITE}
                    buttonText="Invoice"
                    icon={
                      <DownloadIcon
                        className="w-3 h-3 text-gray-700 "
                        style={{ strokeWidth: '1px' }}
                      />
                    }
                    className="px-10 h-9"
                  /> */}
                  <div className="ml-3">
                    {(orderStatus === STAGE.order.awaiting_client && (
                      <div className="flex items-center justify-center ">
                        <a
                          href={`./preview/${uuid}`}
                          className="text-xs text-white font-medium rounded-md bg-brand-500 w-40 h-9 flex items-center justify-center"
                        >
                          <ClipboardCheckIcon
                            className="w-5 h-4  mr-2 text-white"
                            style={{ strokeWidth: '1px' }}
                          />
                          Review design
                        </a>
                      </div>
                    )) ||
                      ((orderStatus === STAGE.order.fulfilled ||
                        orderStatus === STAGE.order.ready_for_print) && (
                        <div className="flex items-center justify-center ">
                          {/* <button className="text-xs text-gray-700  font-medium border border-gray-300 rounded-md bg-white w-40 h-9 ">
                      View design
                    </button> */}
                          <a
                            href={`./preview/${uuid}`}
                            className="text-white font-medium border border-gray-300 rounded-md bg-brand-500 w-40 h-9 text-xs flex items-center justify-center"
                          >
                            <ClipboardCheckIcon
                              className="w-5 h-4  mr-2 text-white"
                              style={{ strokeWidth: '1px' }}
                            />
                            View design
                          </a>
                        </div>
                      )) ||
                      ((orderStatus === STAGE.order.new ||
                        orderStatus === STAGE.order.design_edits) && (
                        <div className="flex items-center justify-center ">
                          <button className="text-xs text-gray-700 font-medium rounded-md bg-gray-300 w-40 h-9 flex items-center justify-center">
                            <ClipboardCheckIcon
                              className="w-5 h-4  mr-2 text-gray-700"
                              style={{ strokeWidth: '1px' }}
                            />
                            Not available
                          </button>
                        </div>
                      ))}
                  </div>
                </div>
              </>
            )}
            {isLoading && (
              <div className="absolute left-1/2 top-1/2 text-gray-500">
                <Spinner className="text-brand-500" />
              </div>
            )}
          </div>
          {/* Search Modal */}
          <Modal
            isOpen={isMoveOrder}
            setIsOpen={setIsMoveOrder}
            dialogTitle="Move Order and Profiles"
            dialogDescription="This will move the order and profiles associated with the order to the selected organisation"
            successButtonText="Move profiles"
            onSuccess={() => {
              console.log('moveOrder');
            }}
            isLoading={isLoading}
          >
            <div className="mt-6">
              <div className="mt-6 relative">
                <Search
                  id={`OrgList-1-20-date-desc--${orgModalSearch}`}
                  placeholder="Search for Organisation ID or Name"
                  search={orgModalSearch}
                  setSearch={(value) => {
                    if (value !== orgModalSearch) {
                      setOrgIDModal(-1);
                      console.log('setOrgID(-1);');
                      setShowOptionsModal(true);
                    }
                    setOrgModalSearch(value);
                  }}
                  fetchQuery={(newOrgModalSearch) =>
                    listOrganisationsModal(newOrgModalSearch)
                  }
                />

                {orgModalSearch && showOptionsModal && (
                  <div className="origin-top-left absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                    <div className="py-1">
                      {orgDataModal && orgDataModal?.length ? (
                        orgDataModal?.map((item: IOrganisation) => (
                          <button
                            type="button"
                            key={item.id}
                            className="appearance-none px-3 py-2 cursor-pointer hover:bg-gray-200 w-full text-left"
                            onClick={() => {
                              setOrgIDModal(item.id);
                              setShowOptionsModal(false);
                              setOrgModalSearch(`#${item.id} ${item.name}`);
                            }}
                          >
                            #{item.id}{' '}
                            <span className="text-gray-500">{item.name}</span>
                          </button>
                        ))
                      ) : (
                        <span className="text-center appearance-none px-3 py-2 cursor-pointer hover:bg-gray-200 w-full text-grey-500">
                          No matching items found
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </li>
  );
}
