import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ShopifyBuy, { Cart, CustomAttribute, Product } from 'shopify-buy';
import useAuth from './useAuth';

const client = ShopifyBuy.buildClient({
  domain: process.env.REACT_APP_SHOPIFY_API_ENDPOINT || '',
  storefrontAccessToken:
    process.env.REACT_APP_SHOPIFY_STOREFRONT_API_TOKEN || '',
});

interface ShopifyContextType {
  isShopifyLoading: boolean;
  checkout?: Cart;
  products: Product[];
  taptCustom?: Product;
  taptReplacement?: Product;
  proceedToCheckout: (
    id: string | number,
    quantity: number,
    customAttributes?: CustomAttribute[],
  ) => void;
  fetchCheckout: (id: string) => Promise<Cart>;
}

const ShopifyContext = createContext<ShopifyContextType>(
  {} as ShopifyContextType,
);

export function ShopifyProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const { user } = useAuth();
  const [isShopifyLoading, setIsShopifyLoading] = useState<boolean>(false);
  const [checkout, setCheckout] = useState<Cart>();
  const [products, setProducts] = useState<Product[]>([]);
  const [taptCustom, setTaptCustom] = useState<Product>();
  const [taptReplacement, setTaptReplacement] = useState<Product>();

  const proceedToCheckout = useCallback(
    async (
      variantId: string | number,
      quantity: number,
      customAttributes: CustomAttribute[] = [],
    ) => {
      if (checkout) {
        setIsShopifyLoading(true);

        await client.checkout
          .removeLineItems(
            checkout.id,
            checkout.lineItems.map((item) => String(item.id)),
          )
          .then((emptyCheckout) => {
            setCheckout(emptyCheckout);

            client.checkout
              .addLineItems(checkout.id, [
                {
                  variantId,
                  quantity,
                  customAttributes,
                },
              ])
              .then((res) => {
                setCheckout(res);
              })
              .finally(() => {
                setIsShopifyLoading(false);
                if (typeof window !== 'undefined' && window.location) {
                  window.location.href = checkout.webUrl;
                }
              });
          });
      }
    },
    [checkout],
  );

  const createCheckout = useCallback(() => {
    setIsShopifyLoading(true);
    client.checkout
      .create()
      .then((res) => {
        setCheckout(res);
        localStorage.setItem('checkoutId', String(res.id));
        return res;
      })
      .finally(() => {
        setIsShopifyLoading(true);
      });
  }, []);

  const fetchPendingCheckout = useCallback(
    (id: string) => {
      setIsShopifyLoading(true);
      client.checkout
        .fetch(id)
        .then((res) => {
          if (!res.completedAt) {
            setCheckout(res);
            localStorage.setItem('checkoutId', String(res.id));
          } else {
            createCheckout();
          }
        })
        .catch(() => {
          createCheckout();
        })
        .finally(() => {
          setIsShopifyLoading(true);
        });
    },
    [createCheckout],
  );

  const fetchCheckout = async (id: string) => {
    const checkout = await client.checkout.fetch(id);

    return checkout;
  };

  useEffect(() => {
    if (user) {
      const existingCheckoutId = localStorage.getItem('checkoutId');

      if (existingCheckoutId) {
        fetchPendingCheckout(existingCheckoutId);
      } else {
        createCheckout();
      }
    }
  }, [user, createCheckout, fetchPendingCheckout]);

  useEffect(() => {
    if (user) {
      setIsShopifyLoading(true);

      client.product
        .fetchAll()
        .then((fetchedProducts) => {
          setProducts(fetchedProducts);
          setTaptCustom(
            fetchedProducts.find(({ handle }) => handle === 'tapt-custom'),
          );
          setTaptReplacement(
            fetchedProducts.find(({ handle }) => handle === 'replacement-card'),
          );
        })
        .finally(() => {
          setIsShopifyLoading(false);
        });
    }
  }, [user]);

  const memoedValue = useMemo(
    () => ({
      isShopifyLoading,
      products,
      taptCustom,
      taptReplacement,
      checkout,
      setCheckout,
      proceedToCheckout,
      fetchCheckout,
    }),
    [
      isShopifyLoading,
      products,
      taptCustom,
      taptReplacement,
      checkout,
      setCheckout,
      proceedToCheckout,
      fetchCheckout
    ],
  );

  return (
    <ShopifyContext.Provider value={memoedValue}>
      {children}
    </ShopifyContext.Provider>
  );
}

export default function useShopify(): ShopifyContextType {
  return useContext(ShopifyContext);
}
