import { Box } from '@material-ui/core';
import { Dispatch, SetStateAction, useState } from 'react';
import CustomInfiniteScroll from '../../../styleds/CustomInfiniteScroll';
import ClientComment from './ClientComment';
// eslint-disable-next-line import/no-cycle
import { IOrderFeedbackItem } from './OrderDetails';
import TaptComment from './TaptComment';
import TaptNewComment from './TaptNewComment';

interface ICommentBox {
  orderComment: IOrderFeedbackItem[];
  newComment: string;
  newCommentFile: File[];
  orderDesignFile: File | undefined;
  orderId: number;
  isRerender: boolean;
  setIsReRender: Dispatch<SetStateAction<boolean>>;
  updateCommentValue: string | undefined;
  setUpdateCommentValue: Dispatch<SetStateAction<string | undefined>>;
  setUpdateCommentId: Dispatch<SetStateAction<number | undefined>>;
}
export default function CommentBox({
  orderComment,
  newComment,
  newCommentFile,
  orderDesignFile,
  orderId,
  isRerender,
  setIsReRender,
  updateCommentValue,
  setUpdateCommentValue,
  setUpdateCommentId,
}: ICommentBox): JSX.Element {
  return (
    <>
      <Box className="border rounded-t-lg grid gap-6">
        <CustomInfiniteScroll
          fetchMore={() => {}}
          customRerenderArray={[newComment, newCommentFile]}
          className="overflow-y-scroll h-96 pl-5 pr-4 py-4 flex flex-col"
        >
          {orderComment
            .slice()
            .reverse()
            .map((item) => {
              return item.change_request?.type === 'feedback_provider' ? (
                <TaptComment
                  key={item.change_request.id}
                  comment={item.change_request.note}
                  comment_time={item.change_request.inserted_at}
                  participant_name={item.change_request.participant_name}
                  comment_file={item.change_request_file}
                  updateCommentValue={updateCommentValue}
                  setUpdateCommentValue={setUpdateCommentValue}
                  changeRequestId={item.change_request.id}
                  orderId={orderId}
                  isRerender={isRerender}
                  setIsReRender={setIsReRender}
                  setUpdateCommentId={setUpdateCommentId}
                />
              ) : (
                <ClientComment
                  key={item.change_request.id}
                  comment={item.change_request.note}
                  comment_time={item.change_request.inserted_at}
                  participant_name={item.change_request.participant_name}
                  comment_file={item.change_request_file}
                />
              );
            })}
          {(newComment?.length > 0 ||
            orderDesignFile ||
            newCommentFile.length > 0) && (
            <TaptNewComment
              comment={newComment}
              commentFiles={newCommentFile}
              orderDesignFile={orderDesignFile}
            />
          )}
        </CustomInfiniteScroll>
      </Box>
    </>
  );
}
