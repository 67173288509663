import STAGE from '@/constants/stage';

const orderHandler = {
  stageTabHandler: (stage: string, colValue: string | undefined) => {
    const handleStage = (stage: string, currentStage: string[]) => {
      return currentStage.includes(stage) ? '' : 'lg:hidden hidden';
    };

    const stageMapping: { [key: string]: string[] } = {
      'ORDER TYPE': [
        STAGE.order.new,
        STAGE.order.design_edits,
        STAGE.order.ready_for_print,
        STAGE.order.all_orders,
      ],
      TAG: [STAGE.order.new],
      NOTIFICATIONS: [STAGE.order.awaiting_client],
      WARNING: [STAGE.order.awaiting_client],
      CHANGES: [STAGE.order.design_edits],
      'EXPORT PROFILES': [STAGE.order.ready_for_print],
      'PAUSED BY': [STAGE.order.on_hold],
      'SHOPIFY LINK': [
        STAGE.order.fulfilled,
        STAGE.order.in_print,
        STAGE.order.on_hold,
      ],
      SHIPPING: [STAGE.order.fulfilled],
      'Define Guidelines': [],
      'EXPORT PROFILES FOR AWAITING CLIENT': [
        STAGE.order.awaiting_client,
        STAGE.order.new,
      ],
      'ORDER DETAILS': [STAGE.order.in_print, STAGE.order.on_hold],
      'SUBMISSION DATE': [STAGE.order.in_print],
      'UPLOAD DESIGN': [
        STAGE.order.new,
        STAGE.order.awaiting_client,
        STAGE.order.design_edits,
        STAGE.order.ready_for_print,
        STAGE.order.fulfilled,
        STAGE.order.all_orders,
      ],
      'DESIGN FILE': [
        STAGE.order.awaiting_client,
        STAGE.order.design_edits,
        STAGE.order.ready_for_print,
        STAGE.order.fulfilled,
        STAGE.order.all_orders,
        STAGE.order.on_hold,
      ],
    };

    const currentStage = stageMapping[colValue ?? ''] ?? [];

    return handleStage(stage, currentStage);
  },
  orderRequiredBadge: {
    getOrderRequiredBadge: (
      stage: string,
      order_design_spec_setup: string,
      design_spec_setup_id: string,
    ) => {
      const isDisplayed =
        stage === STAGE.order.awaiting_client ||
        stage === STAGE.order.new ||
        stage === STAGE.order.ready_for_print;

      const isMatched = order_design_spec_setup === design_spec_setup_id;

      const className = isDisplayed && isMatched ? '' : 'lg:hidden hidden';
      const kind = isDisplayed && !isMatched ? 'white' : 'red';
      const text = isDisplayed && isMatched ? 'Setup' : '';

      return { className, kind, text };
    },
  },
  orderBadge: {
    getOrderBadge: (
      stage: string,
      order_design_spec_setup: string,
      design_spec_setup_id: string,
      color: string,
    ) => {
      const isDisplayed =
        stage === STAGE.order.awaiting_client ||
        stage === STAGE.order.new ||
        stage === STAGE.order.ready_for_print;

      const isMatched = order_design_spec_setup === design_spec_setup_id;

      const className = isDisplayed ? 'lg:hidden hidden' : '';
      const kind = isDisplayed && !isMatched ? 'white' : color;
      const text = isDisplayed && isMatched ? 'Setup' : '';

      return { className, kind, text };
    },
    handleTagBadge: {
      tagText: (tagValue: string | undefined) => {
        switch (tagValue) {
          case 'setup':
            return 'Setup';
          case 'ready':
            return 'Ready';
          case 'awaiting':
            return 'Awaiting';
          case 'on_hold':
            return 'On hold';
          default:
            return '';
        }
      },
      tagKindColor: (tagValue: string | undefined) => {
        switch (tagValue) {
          case 'setup':
            return 'red';
          case 'ready':
            return 'green';
          case 'awaiting':
            return 'yellow';
          case 'on_hold':
            return 'gray';
          default:
            return '';
        }
      },
    },
  },
  orderDetailUpdateStatus: () => [
    {
      name: STAGE.order_stage_name.new,
      order_status: STAGE.order.new,
      kind: 'red',
    },
    {
      name: STAGE.order_stage_name.awaiting_client,
      order_status: STAGE.order.awaiting_client,
      kind: 'orange',
    },
    {
      name: STAGE.order_stage_name.design_edits,
      order_status: STAGE.order.design_edits,
      kind: 'yellow',
    },
    {
      name: STAGE.order_stage_name.ready_for_print,
      order_status: STAGE.order.ready_for_print,
      kind: 'green',
    },
    {
      name: STAGE.order_stage_name.in_print,
      order_status: STAGE.order.in_print,
      kind: 'in_print',
    },
    {
      name: STAGE.order_stage_name.fulfilled,
      order_status: STAGE.order.fulfilled,
      kind: 'fullfiled',
    },
    {
      name: STAGE.order_stage_name.on_hold,
      order_status: STAGE.order.on_hold,
      kind: 'on_hold',
    },
  ],
};

export { orderHandler as default, orderHandler };
