import { AxiosResponse } from 'axios';
import { BASE_URL } from '../config';
import axiosApiService from '@/services/axiosApiService';
import IUser from '@/types/IUser';

const accountsAPI = {
  signup: (body: {
    email: string;
    first_name: string;
    last_name: string;
    organisation_name: string;
    password: string;
    password_confirmation: string;
    terms_accepted: boolean;
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.post(`${BASE_URL}/api/users`, body);
  },
  login: (body: {
    email: string;
    password: string;
  }): Promise<
    AxiosResponse<{ data: { access_token: string; refresh_token: string } }>
  > => {
    return axiosApiService.post(`${BASE_URL}/api/session`, body);
  },
  refreshToken: (body: {
    refresh_token: string;
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.put(`${BASE_URL}/api/session`, body);
  },
  getCurrentUser: (): Promise<AxiosResponse<{ data: IUser }>> => {
    return axiosApiService.get(`${BASE_URL}/api/current_user`);
  },
  updatePassword: (body: {
    update_password: {
      password: string;
      password_confirmation: string;
    };
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.put(`${BASE_URL}/api/current_user`, body);
  },
  updateCurrentUser: (body: {
    email?: string;
    first_name?: string;
    last_name?: string;
    first_log_in_at?: Date;
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.put(`${BASE_URL}/api/current_user`, body);
  },
  forgotPassword: (body: {
    forgot: {
      email: string;
    };
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.post(`${BASE_URL}/api/forgot`, body);
  },
  verifyForgotPassword: (body: {
    forgot: {
      email: string;
      code: string;
    };
  }): Promise<
    AxiosResponse<{ data: { access_token: string; refresh_token: string } }>
  > => {
    return axiosApiService.put(`${BASE_URL}/api/forgot`, body);
  },
  getIntegrationToken: (orgID: number): Promise<AxiosResponse<{ token: string }>> => {
    return axiosApiService.get(`${BASE_URL}/api/current_user/integration_token/${orgID}`);
  },
};
export default accountsAPI;
