import { Tab } from '@headlessui/react';
import Badge, { BADGE_KIND } from '@/components/Badge';
import InputSelect from '@/components/InputSelect';
import classNames from '@/helpers/classNames';

interface ITab {
  name: string;
  value: string;
  href?: string;
  description?: string | JSX.Element;
  count?: number;
  color?: string;
  selectedName?: string;
}
interface IDropDownTabList {
  tabs: ITab[];
  onDrop?: (
    e: React.DragEvent<HTMLButtonElement>,
    value: string,
    tab_value: string,
  ) => void;
  onDragOver?: (
    e: React.DragEvent<HTMLButtonElement>,
    tab_value: string,
  ) => void;
  onDragTab?: string;
  setOnDragTab?: React.Dispatch<React.SetStateAction<string | undefined>>;
  badgeClassName?: string;
  badgeTextColor?: string;
  badgeBackgroundColor?: string;
  value: string;
  disabled?: boolean;
}

export default function TabList({
  tabs,
  value,
  onDrop,
  onDragOver,
  onDragTab,
  setOnDragTab,
  badgeClassName,
  badgeTextColor,
  badgeBackgroundColor,
  disabled = false,
}: IDropDownTabList): JSX.Element {
  return (
    <div>
      <div className="w-full lg:hidden">
        <InputSelect
          label=""
          id="SelectTab"
          onChange={(val) => {
            const tab: HTMLButtonElement | null = document.querySelector(
              `[name="Tab-${val}"]`,
            );
            tab?.click();
          }}
          options={tabs.map((tab) => ({
            label: tab.count ? `${tab.name} (${tab.count})` : tab.name,
            value: tab.value,
          }))}
          value={value}
        />
      </div>
      <div className="hidden lg:flex">
        <Tab.List className="-mb-px flex justify-between">
          {tabs.map((tab: ITab) => (
            <Tab
              key={tab.name}
              name={`Tab-${tab.value}`}
              className="first:pl-0  px-4"
              onDragOver={(e: React.DragEvent<HTMLButtonElement>) => {
                if (onDragOver) {
                  onDragOver(e, tab.value);
                }
              }}
              onDragLeave={() => {
                if (setOnDragTab) {
                  setOnDragTab('');
                }
              }}
              onDrop={(e: React.DragEvent<HTMLButtonElement>) => {
                if (onDrop) {
                  onDrop(e, value, tab.value);
                }
              }}
              disabled={disabled}
            >
              {({ selected }) => (
                <div
                  className={classNames(
                    selected || onDragTab === tab.value
                      ? 'text-brand-600 border-brand-500'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'md:flex-row whitespace-nowrap pb-4 px-1 border-b-2 transition transition-all font-normal text-lg leading-6 flex items-center space-x-2',
                  )}
                >
                  <span id={tab.value}>
                    {!!tab.selectedName && !!tab.name && selected
                      ? tab.selectedName
                      : tab.name}
                  </span>
                  {tab.count !== undefined && (
                    <Badge
                      text={tab.count ? String(tab.count) : '0'}
                      kind={tab.color ? tab.color : BADGE_KIND.GRAY}
                      className={badgeClassName}
                      textColor={badgeTextColor}
                      backgroundColor={badgeBackgroundColor}
                    />
                  )}
                </div>
              )}
            </Tab>
          ))}
        </Tab.List>
      </div>
    </div>
  );
}
