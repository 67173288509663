import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { BASE_URL } from '../config';

interface AxiosRequestConfigWithAuth extends AxiosRequestConfig {
  headers?: {
    Authorization?: string;
  };
}

const axiosApiService = axios.create();

// request interceptor to add the auth token header to requests
axiosApiService.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    let newConfig: AxiosRequestConfigWithAuth = config;
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      newConfig = {
        ...newConfig,
        headers: {
          ...newConfig.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      };
    }
    return newConfig;
  },
  (error) => Promise.reject(error),
);

// response interceptor to refresh token on receiving token expired error
axiosApiService.interceptors.response.use(
  (response) => response,
  (error: { config: AxiosRequestConfig; response: AxiosResponse }) => {
    const originalRequest = error.config;
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    if (error.response.status === 401) {
      if (refreshToken) {
        return axios
          .put(`${BASE_URL}/api/session`, { refresh_token: refreshToken })
          .then(
            (
              res: AxiosResponse<{
                data: {
                  access_token: string;
                  refresh_token: string;
                };
              }>,
            ) => {
              if (res.status === 200) {
                localStorage.setItem(
                  'access_token',
                  res.data.data.access_token,
                );
              }
              return axios(originalRequest);
            },
          );
      }

      if (accessToken) {
        localStorage.removeItem('access_token');
        window.location.pathname = '/login';
      }
    }
    return Promise.reject(error);
  },
);

export default axiosApiService;
