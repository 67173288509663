import { IOrganisationUser } from '@/types/IOrganisation';
import InputCheckbox from '../InputCheckbox';

interface IEditorItem {
  organisationRole: IOrganisationUser;
  selected: boolean;
  checkItem: (value: boolean) => void;
}

export default function EditorItem({
  organisationRole,
  selected,
  checkItem,
}: IEditorItem): JSX.Element {
  return (
    <li key={organisationRole.id}>
      <div className="block bg-white hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="hidden lg:block self-start md:self-center">
            <InputCheckbox
              id={`Checkbox-${organisationRole.id}`}
              label=""
              value={selected}
              onChange={checkItem}
            />
          </div>
          <div className="lg:hidden self-start md:self-center">
            <InputCheckbox
              id={`Checkbox-${organisationRole.id}`}
              label=""
              value={selected}
              onChange={checkItem}
            />
          </div>

          <div className="min-w-0 flex-1 flex items-start md:items-center">
            <div className="min-w-0 flex-1 lg:grid lg:grid-cols-4 xl:grid-cols-6">
              <div className="hidden lg:block md:mt-0 lg:ml-1 lg:col-span-2 xl:col-span-3 xl:flex flex-col">
                <p className="text-sm text-gray-900 truncate">
                  {organisationRole.user.first_name}{' '}
                  {organisationRole.user.last_name}
                </p>
                <p className="text-sm text-gray-500 truncate">
                  {organisationRole.user.email}
                </p>
              </div>
              <div className="hidden lg:block lg:col-span-2 xl:col-span-3 relative">
                <p className="text-sm font-medium text-gray-900 truncate">
                  {organisationRole.groups.map((group) => group.name).join(' | ')}
                </p>
              </div>

              {/* ---------------------------------------MOBILE SCREEN ---------------------------------------*/}
              <div className="lg:hidden items-start">
                <div className="grid grid-cols-6">
                  <div className="col-span-4 mt-0 flex space-x-1 md:mt-0">
                    <div className="min-w-0 mt-0 md:mt-0">
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {organisationRole.user.first_name}{' '}
                        {organisationRole.user.last_name}
                      </p>
                      <p className="text-sm text-gray-900 truncate">
                        {organisationRole.user.email}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
