import STAGE from "@/constants/stage";

const fulfillmentStageHelper = {
    getOrderStatus: (id: string) => {
        const orderStatus = STAGE.fulfillmentStatus.filter(item => {
            return item.id === id
        })

        if (orderStatus?.length) {
            return orderStatus[0]
        }
        return {
            id: 0,
            label: "Unfulfilled",
            color: 'red'
        };
    }
}

export { fulfillmentStageHelper as default, fulfillmentStageHelper };