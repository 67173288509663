import formatDistance from 'date-fns/formatDistance';
import useAuth from '@/hooks/useAuth';

export const TRIAL_BADGE_KIND = {
  LONG: 'long',
  SHORT: 'short',
} as const;

export default function TrialBadge({ kind }: { kind: string }): JSX.Element {
  const { subscription } = useAuth();
  const trialEndDate =
    subscription && subscription.status === 'in_trial' && subscription.trial_end
      ? new Date(subscription.trial_end)
      : null;

  return trialEndDate ? (
    <>
      {kind === TRIAL_BADGE_KIND.SHORT ? (
        <span className="sm:hidden inline-flex items-center rounded-md bg-gray-100 leading-5 py-2 px-4 text-sm text-brand-500">
          Trial ends in {formatDistance(new Date(), trialEndDate)}
        </span>
      ) : (
        ''
      )}

      {kind === TRIAL_BADGE_KIND.LONG ? (
        <span className="hidden md:block inline-flex items-center rounded-md bg-gray-100 leading-5 py-2 px-4 text-sm text-brand-500">
          {formatDistance(new Date(), trialEndDate)} left on trial mode
        </span>
      ) : (
        ''
      )}
    </>
  ) : (
    <></>
  );
}
