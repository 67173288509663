import { useCallback, useState } from 'react';
import { Tab } from '@headlessui/react';
import Layout from '@/components/Layout';
import TabList from '@/components/TabList';
import Spinner from '@/components/Icons/Spinner';
import SuccessAlert from '@/components/SuccessAlert';
import ErrorAlert from '@/components/ErrorAlert';
import classNames from '@/helpers/classNames';
import MESSAGES from '@/constants/messages-en';
import IOrder from '@/types/IOrder';
import ordersAPI from '@/api/orders';
import CursorPagination from '@/components/CursorPagination';
import Search from '@/components/Search';
import adminAPI from '@/api/admin';
import IOrganisation from '@/types/IOrganisation';
import InputCheckbox from '@/components/InputCheckbox';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import Button, { BUTTON_KIND } from '@/components/Button';
import OrdersListItem from '@/components/OrdersListItem';

export default function OrdersListPage(): JSX.Element {
  const [data, setData] = useState<IOrder[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [page, setPage] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(20);
  const [selectAll, setSelectAll] = useState(false);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [fulfillmentStatus, setFulfillmentStatus] =
    useState<string>('unfulfilled');
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [orgSearch, setOrgSearch] = useState<string>('');
  const [orgID, setOrgID] = useState<number>(-1);
  const [orgData, setOrgData] = useState<IOrganisation[]>();
  const tabs = [
    {
      name: 'Unfulfilled',
      value: 'unfulfilled',
    },
    {
      name: 'In progress',
      value: 'partial',
    },
    {
      name: 'Fulfilled',
      value: 'shipped',
    },
  ];

  function handleCheckItem(orderNo: string) {
    let arr = checkedItems;
    if (checkedItems.includes(orderNo)) {
      arr = arr.filter((e) => e !== orderNo);
    } else {
      arr = [...checkedItems, orderNo];
    }
    setCheckedItems(arr);
  }
  const listOrders = useCallback(
    async (
      newPage: string = page,
      newpageSize: number = pageSize,
      newfulfillmentStatus: string = fulfillmentStatus,
    ) => {
      const res = await ordersAPI.listOrders({
        page: newPage,
        pageSize: newpageSize,
        fulfillmentStatus: newfulfillmentStatus,
        orgID,
      });

      setData(res.data?.data);
      setPage(newPage);
      setPageSize(newpageSize);
      setFulfillmentStatus(newfulfillmentStatus);

      return res.data;
    },
    [page, pageSize, fulfillmentStatus, orgID],
  );

  const exportCSV = useCallback(
    async (arr?: string[]) => {
      if (arr || checkedItems) {
        const response = await adminAPI.exportProfilesViaOrderCSV(
          arr || checkedItems,
        );
        const blob = new Blob([response.data]);
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = 'admin_profiles.csv';
        a.click();
      }
    },
    [checkedItems],
  );

  const listOrganisations = useCallback(async (newOrgSearch: string) => {
    const res = await adminAPI.listOrganisations({
      pageSize: 5,
      search: newOrgSearch,
    });

    setOrgData(res.data?.data?.filter((item: IOrganisation) => item.name));

    return res.data;
  }, []);

  return (
    <Layout pageName="Orders">
      {success !== '' && <SuccessAlert message={success} />}
      {error && <ErrorAlert message={MESSAGES.error.generic} />}
      <Tab.Group
        onChange={(index: number) => {
          setPage('');
          setError(false);
          setFulfillmentStatus(tabs[index].value);
        }}
      >
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full md:border-b border-gray-200">
          <div className="pt-4 w-full md:w-auto">
            <TabList tabs={tabs} value={fulfillmentStatus} />
          </div>
        </div>
        <Tab.Panels>
          {tabs.map((tab) => (
            <Tab.Panel key={tab.name} className="outline-none">
              <div className="py-8">
                <div className="pb-8 flex flex-col space-y-3 items-start sm:flex-row sm:space-y-0 sm:items-center sm:justify-end">
                  <div className="flex flex-shrink-0 space-x-3">
                    <div className="relative">
                      <Search
                        id={`OrganisationList-1-20-date-desc--${orgSearch}`}
                        placeholder="Search for organisations"
                        search={orgSearch}
                        setSearch={(value) => {
                          if (value !== orgSearch) {
                            setOrgID(-1);
                            console.log('setOrgID(-1);');
                            setShowOptions(true);
                          }
                          setOrgSearch(value);
                        }}
                        fetchQuery={(newOrgSearch) =>
                          listOrganisations(newOrgSearch)
                        }
                      />
                      {orgSearch && showOptions && (
                        <div className="origin-top-left absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                          <div className="py-1">
                            {orgData && orgData?.length ? (
                              orgData?.map((item: IOrganisation) => (
                                <button
                                  type="button"
                                  key={item.id}
                                  className="appearance-none px-3 py-2 cursor-pointer hover:bg-gray-200 w-full text-left"
                                  onClick={() => {
                                    setOrgID(item.id);
                                    setShowOptions(false);
                                    setOrgSearch(`#${item.id} ${item.name}`);
                                  }}
                                >
                                  #{item.id}{' '}
                                  <span className="text-gray-500">
                                    {item.name}
                                  </span>
                                </button>
                              ))
                            ) : (
                              <li className="px-3 py-2 text-center">
                                No matching items found
                              </li>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {data && data.length > 0 ? (
                  <div className="flex flex-col">
                    <div className="relative -mx-4 sm:-mx-6 md:mx-0 lg:-mx-8">
                      <div className="block w-full lg:px-8">
                        <div className="relative shadow-sm border border-gray-200 sm:rounded-md min-h-8 overflow-hidden">
                          <ul
                            className={classNames(
                              isLoading ? 'opacity-40' : '',
                              'divide-y divide-gray-200',
                            )}
                          >
                            <li
                              key="OrderListHeader"
                              className="hidden md:block"
                            >
                              <div className="bg-gray-50">
                                <div className="flex items-center px-4 py-2 sm:px-6">
                                  <div className="min-w-0 flex-1 flex items-start md:items-center">
                                    <div className="w-7">
                                      <InputCheckbox
                                        id="select-all"
                                        label=""
                                        value={selectAll}
                                        onChange={(value: boolean) => {
                                          setSelectAll(value);
                                          if (value) {
                                            const arr = data.map(
                                              ({ order_number }) =>
                                                order_number,
                                            );
                                            setCheckedItems(arr);
                                          } else {
                                            setCheckedItems([]);
                                          }
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={classNames(
                                        'min-w-0 flex-1 md:grid md:grid-cols-5 md:gap-4 xl:grid-cols-6',
                                      )}
                                    >
                                      <p className="col-span-1 text-sm font-medium text-gray-900">
                                        ORDER NO.
                                      </p>
                                      <p className="mt-2 md:mt-0 hidden xl:block text-sm font-medium text-gray-900">
                                        NAME &amp; EMAIL
                                      </p>
                                      <p className="xl:col-span-1 hidden md:block text-sm font-medium text-gray-900">
                                        ACTIVATION KEY
                                      </p>
                                      <p className="xl:col-span-1 hidden md:block text-sm font-medium text-gray-900">
                                        LINKED ORG ID
                                      </p>
                                      <p className="xl:col-span-1 hidden md:block text-sm font-medium text-gray-900">
                                        DESIGN LINK
                                      </p>
                                      <p className="mt-2 md:mt-0 hidden md:block xl:col-span-1 text-sm font-medium text-gray-900">
                                        STATUS
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            {data?.map((item: IOrder) => (
                              <OrdersListItem
                                key={`${item.order_number}`}
                                id={item.order_number}
                                name={item.customer_name}
                                email={item.customer_email}
                                activationId={item.card_activation?.id}
                                activationKey={item.card_activation?.key}
                                activationStatus={item.card_activation?.status}
                                activationOrg={
                                  item.card_activation?.organisation_id
                                }
                                selected={checkedItems.includes(
                                  item.order_number,
                                )}
                                url={item.order_url}
                                designUrl={item.design_file_urls}
                                status={item.fulfillment_status}
                                checkItem={() =>
                                  handleCheckItem(item.order_number)
                                }
                                listOrders={() => listOrders()}
                                markSuccess={() => setSuccess('Order Moved')}
                              />
                            ))}
                          </ul>
                          {isLoading && (
                            <div className="absolute left-1/2 top-1/2 text-gray-500">
                              <Spinner className="text-brand-500" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="py-32">
                    <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
                      {MESSAGES.activationKey.list.empty.heading}
                    </h3>
                    <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
                      {MESSAGES.activationKey.list.empty.description}
                    </p>
                  </div>
                )}
              </div>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
      <CursorPagination
        id={`OrderList-${page}-${pageSize}-${fulfillmentStatus}-${orgID}`}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        fetchQuery={(pageQuery, pageSizeQuery) => {
          return listOrders(pageQuery, pageSizeQuery, fulfillmentStatus);
        }}
        setIsLoading={setIsLoading}
      />
      <div className="block" aria-hidden="true">
        <div className="py-8" />
      </div>

      <InfoPanelFooter className={checkedItems?.length > 0 ? '' : '-bottom-28'}>
        <p className="text-sm leading-5 text-gray-500 mb-1 sm:hidden">{`${checkedItems.length} selected`}</p>
        <div className="flex items-center flex-nowrap space-x-4">
          <Button
            kind={BUTTON_KIND.WHITE}
            buttonText="Export profiles from selected orders"
            onClick={() => {
              setIsLoading(true);
              setSuccess('');
              setError(false);
              exportCSV()
                .then(() => setSuccess(MESSAGES.profile.export.success))
                .catch(() => setError(true))
                .then(() => setIsLoading(false))
                .finally(() => setCheckedItems([]));
            }}
          />
          <p className="text-sm leading-5 text-gray-500 hidden sm:block">{`${checkedItems.length} selected`}</p>
        </div>
      </InfoPanelFooter>
    </Layout>
  );
}
