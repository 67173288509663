import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { DownloadIcon } from '@/components/Icons';
import InputCheckbox from '@/components/InputCheckbox';
import Modal from '@/components/Modal';
import Search from '../../Search';
import IOrganisation from '@/types/IOrganisation';
import adminAPI from '@/api/admin';
import FileUploadComponent from '@/components/FileUploadComponent';
import filesAPI from '@/api/files';
import ordersAPI from '@/api/ordersV2';
import Spinner from '@/components/Icons/Spinner';
import ModalNoSuccess from '../../ModalNoSuccess';
import TextAreaMessage from '../../TextAreaMessage';
import IFile from '@/types/IFile';
import useAuth from '@/hooks/useAuth';
import SuccessAlert from '../../SuccessAlert';
import MESSAGES from '@/constants/messages-en';
import ErrorAlert from '../../ErrorAlert';
import FileUploadMuntiButtonComponent from '../../FileUploadMultiButton';
import OrderDetails from '../OrderDetails/OrderDetails';
import OrderTableData from './TableData';
import TableData from '../shared/TableData';
import IOrder from '@/types/IOrderV2';

export interface IOrdersListItem {
  id: number;
  url?: string;
  uuid: string;
  orderUrl: string;
  customer_name: string;
  customer_email: string;
  designFile?: any;
  selected: boolean;
  activationId: number | undefined;
  orderType: string | undefined;
  orderNumber: string;
  numberOfProfiles: number;
  orderStatus: string;
  orgId: number;
  notifications_sent?: string;
  design_specs?: IDesignSpec;
  submission_date: string;
  is_priority?: boolean;
  alternate_email?: string;
  alternate_email_flag: boolean;
  handleCheckedItem: () => void;
  handleOrderStatusChange: () => void;
  onDragStart?: (e: React.DragEvent<HTMLDivElement>, order_id: number) => void;
  tag?: string;
  paused_by?: string;
  handleTabColor: () => void;
  setSuccess: React.Dispatch<React.SetStateAction<string>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IDesignSpec {
  id?: number;
  order_id: number;
  content?: string;
  setup?: string;
  user_id?: number;
  design_spec_files?: IFile[];
}
export interface IDesignSpecUploadRQ {
  design_spec?: IDesignSpec;
  design_spec_file_id?: number[];
  send_email_flag: boolean;
}
const DesignSpecSetup = {
  complete: {
    id: 'complete',
    title: 'Set up complete',
  },
  required: {
    id: 'required',
    title: 'Set up required',
  },
  norequired: {
    id: 'norequired',
    title: 'No Set Up Required',
  },
};

export default function OrdersListItem({
  id,
  url,
  uuid,
  orderUrl,
  customer_name,
  customer_email,
  designFile,
  selected,
  activationId,
  orderType,
  orderNumber,
  numberOfProfiles,
  orderStatus,
  orgId,
  notifications_sent,
  design_specs,
  submission_date,
  is_priority,
  alternate_email,
  alternate_email_flag,
  handleCheckedItem,
  handleTabColor,
  handleOrderStatusChange,
  setSuccess,
  setError,
  onDragStart,
  tag,
  paused_by
}: IOrdersListItem): JSX.Element {
  const [isMoveOrder, setIsMoveOrder] = useState<boolean>(false);
  const [isDropFile, setIsDropFile] = useState<boolean>(false);
  const [isChangeRequest, setIsChangeRequest] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [orgDataModal, setOrgDataModal] = useState<IOrganisation[]>();
  const [orgModalSearch, setOrgModalSearch] = useState<string>('');
  const [showOptionsModal, setShowOptionsModal] = useState<boolean>(false);

  const [orgIDModal, setOrgIDModal] = useState<number>(-1);
  const [file, setFile] = useState();
  const [designSizeError, setDesignSizeError] = useState(false);
  const [designFileError, setDesignFileError] = useState(false);
  const [designLoading, setDesignLoading] = useState(false);
  const [FileID, setFileID] = useState<number>(-1);
  const [checkbox, setCheckbox] = useState<boolean>(false);
  const [isOpenOrderDetailModal, setIsOpenOrderDetailModal] =
    useState<boolean>(false);

  const [changeRequest, setChangeRequest] = useState<string>('');
  const [changeRequestFile, setChangeRequestFile] = useState<any>();
  const [downloading, setDownloading] = useState<boolean>(false);
  const [isOpenMessageDesignSpecs, setIsOpenMessageDesignSpecs] =
    useState<boolean>(false);
  const [designSpects, setDesignSpects] = useState<IDesignSpec>({
    order_id: 0,
    setup: DesignSpecSetup.required.id,
  });
  const [arrFile, setArrFile] = useState<any>([]);

  const [isPriority, setIsPriority] = useState<boolean>(false);

  const { user } = useAuth();

  const [
    successDownloadChangeRequestFile,
    setSuccessDownloadChangeRequestFile,
  ] = useState<string>('');
  const [errorDownloadChangeRequestFile, setErrorDownloadChangeRequestFile] =
    useState<boolean>(false);

  const listOrganisationsModal = useCallback(
    async (newOrgModalSearch: string) => {
      if (newOrgModalSearch) {
        const res = await adminAPI.listOrganisations({
          pageSize: 5,
          search: newOrgModalSearch,
        });
        setOrgDataModal(
          res.data?.data?.filter((item: IOrganisation) => item.name),
        );

        return res.data;
      }
      return {
        data: [],
        paging: {
          page_number: 1,
          page_offset: 5,
          page_size: 5,
          total_entries: 0,
          total_pages: 1,
        },
      };
    },
    [],
  );

  const handleMoveOrg = useCallback(
    async (order_id: number, organisation_id: number) => {
      if (organisation_id <= 0) {
        return;
      }
      setIsLoading(true);
      try {
        const rep = await ordersAPI.assignToDifferentOrg({
          order_id,
          organisation_id,
        });

        if (rep) {
          setSuccess('Order Moved');
          handleOrderStatusChange();
        } else {
          setError(true);
        }
      } catch (error) {
        setError(true);
      } finally {
        setIsMoveOrder(false);
        setIsLoading(false);
      }
    },
    [handleOrderStatusChange, setError, setSuccess],
  );

  const handleClickLinkedOrg = (orgId: number) => {
    if (!orgId) {
      setError(true);
      return;
    }
    window.open(`/?scope=user&uOrgID=${orgId}`);
  };

  return (
    <div
      onDragStart={(e) => {
        if (onDragStart) {
          onDragStart(e, id);
        }
      }}
      draggable
    >
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 border-b ">
        <tbody>
          <tr className="bg-white dark:bg-gray-800">
            <>
              <th scope="col" className="py-7 w-5.3%">
                <InputCheckbox
                  id={`Checkbox-${id}}`}
                  label=""
                  value={selected}
                  onChange={handleCheckedItem}
                />
              </th>
              {OrderTableData({
                orderStatus,
                is_priority,
                setIsOpenOrderDetailModal,
                orderNumber,
                numberOfProfiles,
                tag,
                customer_name,
                customer_email,
                orderType,
                setChangeRequestFile,
                setChangeRequest,
                setIsChangeRequest,
                notifications_sent,
                submission_date,
                paused_by,
                orderUrl,
                setArrFile,
                setIsOpenMessageDesignSpecs,
                setDesignSpects,
                designSpects,
                setError,
                setSuccess,
                setIsLoading,
                handleOrderStatusChange,
                id,
                designFile,
                setDownloading,
                downloading,
                setIsDropFile,
                orgId,
                setIsMoveOrder,
                uuid,
                setIsPriority,
                isPriority,
              }).map((item, index) => {
                return (
                  <TableData
                    key={index}
                    style={item.style}
                    className={item.className}
                  >
                    {item.children}
                  </TableData>
                );
              })}
            </>
          </tr>
        </tbody>
      </table>
      {isLoading && (
        <div className="absolute left-1/2 top-1/2 text-gray-500">
          <Spinner className="text-brand-500" />
        </div>
      )}
      {/* Search Modal */}
      <Modal
        isOpen={isMoveOrder}
        setIsOpen={setIsMoveOrder}
        dialogTitle="Move Order and Profiles"
        dialogDescription="This will move the order and profiles associated with the order to the selected organisation"
        successButtonText="Move profiles"
        onSuccess={() => {
          handleMoveOrg(id, orgIDModal);
        }}
        onCancel={() => {
          setOrgModalSearch('');
          setOrgIDModal(-1);
        }}
        isLoading={isLoading}
      >
        <div className="mt-6">
          <div className="mt-6 relative">
            <Search
              id={`OrgList-1-20-date-desc--${orgModalSearch}`}
              placeholder="Search for Organisation ID or Name"
              search={orgModalSearch}
              setSearch={(value) => {
                if (value !== orgModalSearch) {
                  setOrgIDModal(-1);
                }

                if (value && orgIDModal < 0) {
                  setShowOptionsModal(true);
                  setOrgModalSearch(value);
                } else {
                  setShowOptionsModal(false);
                }
              }}
              fetchQuery={(newOrgModalSearch) =>
                listOrganisationsModal(newOrgModalSearch)
              }
            />

            {orgModalSearch && showOptionsModal && (
              <div className="origin-top-left absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                <div className="py-1">
                  {orgDataModal && orgDataModal?.length ? (
                    orgDataModal?.map((item: IOrganisation) => (
                      <button
                        type="button"
                        key={item.id}
                        className="appearance-none px-3 py-2 cursor-pointer hover:bg-gray-200 w-full text-left"
                        onClick={() => {
                          setOrgIDModal(item.id);
                          setShowOptionsModal(false);
                          setOrgModalSearch(`#${item.id} ${item.name}`);
                        }}
                      >
                        #{item.id}{' '}
                        <span className="text-gray-500">{item.name}</span>
                      </button>
                    ))
                  ) : (
                    <span className="text-center appearance-none px-3 py-2 cursor-pointer hover:bg-gray-200 w-full text-grey-500">
                      No matching items found
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
      {/* LinkOrg */}
      <Modal
        key={`Upload-Modal-${id}}`}
        isOpen={isDropFile}
        setIsOpen={setIsDropFile}
        dialogTitle="Drag And Drop File"
        dialogDescription="Upload desired files"
        successButtonText="Upload"
        checkboxDescription="I want this sent to the client"
        onSuccess={() => {
          setDesignLoading(true);
          ordersAPI.uploadFile(id, FileID, checkbox).then(() => {
            setIsDropFile(false);
            handleOrderStatusChange();
          });
          ordersAPI.AdminOrderChangeRequest(
            id,
            checkbox ? 'Design sent to client' : undefined,
            [FileID],
          );
        }}
        checkbox={checkbox}
        setCheckbox={setCheckbox}
      >
        <FileUploadComponent
          onFileSelected={(file) => {
            setDesignSizeError(false);
            setDesignFileError(false);
            setIsLoading(true);
            filesAPI
              .createAndUploadOrderFile(orgId, new File([file], file.name))
              .then((res) => {
                setFileID(res?.data?.data.id);
              })
              .catch(() => setDesignFileError(true))
              .finally(() => setIsLoading(false));
          }}
          fileFormat=".svg, .eps, .ai, .indd, .pdf"
          fileFormatMessage="(.svg, .eps, .ai, .indd, or .pdf only)"
          loading={isLoading}
          sizeError={designSizeError}
          fileError={designFileError}
          file={file}
          setFile={setFile}
          setSizeError={setDesignSizeError}
        />
      </Modal>
      {/* Change Request */}
      <ModalNoSuccess
        isOpen={isChangeRequest}
        setIsOpen={setIsChangeRequest}
        dialogTitle="Change Request"
        dialogDescription="View comments and change request from clients"
        cancelButtonText="Close"
      >
        <div className="mt-4">
          {successDownloadChangeRequestFile !== '' && (
            <SuccessAlert message={successDownloadChangeRequestFile} />
          )}
          {errorDownloadChangeRequestFile && (
            <ErrorAlert message={MESSAGES.error.generic} />
          )}
        </div>
        <TextAreaMessage
          placeholder=""
          readOnly
          id="TextArea-changeRequest"
          message={changeRequest}
        />
        <div>
          {!!changeRequestFile?.length && (
            <p className=" text-sm font-medium text-gray-900 my-4">Files</p>
          )}

          {changeRequestFile?.map((item: any, index: number) => (
            <button
              className="text-brand-500 w-full"
              key={index}
              onClick={(e) => {
                filesAPI
                  .downloadFile(item.original_url)
                  .then((res: AxiosResponse) => {
                    const a = document.createElement('a');
                    a.href = window.URL.createObjectURL(new Blob([res.data]));
                    a.download = item.file.file_name;
                    a.click();

                    setSuccessDownloadChangeRequestFile(
                      `File ${item.file.file_name} downloaded successfully!`,
                    );
                  })
                  .catch((error: AxiosError) =>
                    setErrorDownloadChangeRequestFile(true),
                  );
              }}
            >
              <div className=" my-2 lg:flex hidden">
                {downloading ? (
                  <Spinner className="h-7 w-7" />
                ) : (
                  <DownloadIcon className="h-6 w-6" />
                )}
                <p className="ml-2 ">{item.name}</p>
              </div>
            </button>
          ))}
        </div>
      </ModalNoSuccess>

      {/* Provide brand guidelines and specs */}
      <Modal
        key={`Design-Specs-Modal-${id}}`}
        isOpen={isOpenMessageDesignSpecs}
        setIsOpen={setIsOpenMessageDesignSpecs}
        dialogTitle="Provide brand guidelines and specs"
        successButtonText="Submit design specifications"
        isLoading={designLoading}
        onSuccess={() => {
          setDesignLoading(true);
          ordersAPI
            .uploadDesignSpec({
              design_spec: {
                ...designSpects,
                order_id: id,
                user_id: user?.id,
              },
              design_spec_file_id: arrFile.map((el: any) => el.file?.id),
              send_email_flag: true,
            })
            .then((result) => {
              setSuccess('Sent provide brand guidelines and specs success');
              setIsOpenMessageDesignSpecs(false);
              handleOrderStatusChange();
            })
            .catch(() => setError(true))
            .finally(() => setDesignLoading(false));
        }}
        checkbox={checkbox}
        setCheckbox={setCheckbox}
        wider
      >
        <TextAreaMessage
          id={`TextArea${designSpects.content}`}
          placeholder="What are the brand guidelines and design specifications?"
          message={designSpects.content || ''}
          setMessage={(e) =>
            setDesignSpects({ ...designSpects, content: e.toString() || '' })
          }
          reSize
          className="mt-4.5"
        />

        <div className="flex mt-3">
          <div className="w-full flex justify-start space-x-2 items-center cursor-pointer">
            <input
              name="set_up"
              id="set_up_required_chek"
              type="radio"
              className="focus:ring-brand-500 h-4 w-4 text-brand-500 border-gray-300 rounded-full"
              onChange={() => {
                setDesignSpects({
                  ...designSpects,
                  setup: DesignSpecSetup.required.id,
                });
              }}
              checked={
                designSpects.setup === DesignSpecSetup.required.id ||
                !designSpects.setup
              }
            />
            <label
              className="text-sm font-medium"
              htmlFor="set_up_required_chek"
            >
              {DesignSpecSetup.required.title}
            </label>
          </div>

          <div className="w-full flex justify-start space-x-2 items-center cursor-pointer">
            <input
              name="set_up"
              id="set_up_complete_chek"
              type="radio"
              className="focus:ring-brand-500 h-4 w-4 text-brand-500 border-gray-300 rounded-full"
              onChange={() => {
                setDesignSpects({
                  ...designSpects,
                  setup: DesignSpecSetup.complete.id,
                });
              }}
              checked={designSpects.setup === DesignSpecSetup.complete.id}
            />
            <label
              className="text-sm font-medium"
              htmlFor="set_up_complete_chek"
            >
              {DesignSpecSetup.complete.title}
            </label>
          </div>

          <div className="w-full flex justify-start space-x-2 items-center cursor-pointer">
            <input
              name="set_up"
              id="set_up_norequired_chek"
              type="radio"
              className="focus:ring-brand-500 h-4 w-4 text-brand-500 border-gray-300 rounded-full"
              onChange={() => {
                setDesignSpects({
                  ...designSpects,
                  setup: DesignSpecSetup.norequired.id,
                });
              }}
              checked={designSpects.setup === DesignSpecSetup.norequired.id}
            />
            <label
              className="text-sm font-medium"
              htmlFor="set_up_norequired_chek"
            >
              {DesignSpecSetup.norequired.title}
            </label>
          </div>
        </div>

        <div>
          <div className="mt-5 font-medium ">Upload any relevant files</div>

          <div className="flex items-center">
            <FileUploadMuntiButtonComponent
              buttonTitle="Choose file"
              id="design-upload-preview"
              fileFormat=".svg, .csv, .xls, .xlsx, .eps, .ai, .indd, .pdf"
              message="Maximum file size 1GB (.svg, .csv, .xls, .xlsx, .eps, .ai, .indd, .pdf only)"
              multiple
              values={arrFile}
              setArrFile={setArrFile}
              onFileSelected={async (file) => {
                const res = await filesAPI.createAndUploadOrderFile(
                  orgId,
                  new File([file.file], file.file.name),
                );
                arrFile.push({
                  id: Math.random(),
                  file: res.data.data,
                });
                setArrFile([...arrFile]);
                return res;
              }}
            />
          </div>
        </div>
      </Modal>

      <OrderDetails
        orderId={id}
        orgId={orgId}
        orderStage={orderStatus}
        isOpenOrderDetailModal={isOpenOrderDetailModal}
        setIsOpenOrderDetailModal={setIsOpenOrderDetailModal}
        setIsMoveOrder={setIsMoveOrder}
        orderNumber={orderNumber}
        design_specs={design_specs?.setup === 'required'}
        isPriority={is_priority}
        handleClickLinkedOrg={handleClickLinkedOrg}
        orderAlternateEmail={alternate_email}
        orderAlternateEmailFlag={alternate_email_flag}
        handleOrderStatusChange={handleOrderStatusChange}
        tag={tag}
        customerName={customer_name}
        customerEmail={customer_email}
        orderStatusUrl={orderUrl}
      />
    </div>
  );
}
