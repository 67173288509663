import React, { useCallback, useEffect, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { searchParamsQuery } from '@/helpers/urlHandler';
import Button, { BUTTON_KIND } from '@/components/Button';
import NewLayoutStandard from '@/components/NewLayoutStandard';
import cardsAPI from '@/api/cards';
import ICard from '@/types/ICard';
import Spinner from '@/components/Icons/Spinner';
import usePageHeight from '@/hooks/usePageHeight';

interface IActivationPage {
  location: {
    search: string;
  };
  match: {
    params: {
      card_hash_id: string;
    };
  };
}

const ActivationPage: React.FC<IActivationPage> = ({ location, match }) => {
  const history = useHistory();
  const cardHashID = match.params.card_hash_id;
  const { key } = searchParamsQuery(location.search);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [cardData, setCardData] = useState<ICard>();
  const pageHeight = usePageHeight();

  function isIOS() {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  const getCardData = useCallback(() => {
    cardsAPI
      .showCardWithHashID(cardHashID)
      .then((response) => {
        const cardDataResponse = response?.data?.data;
        if (cardDataResponse) {
          setCardData(cardDataResponse);
        }
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [cardHashID]);

  useEffect(() => {
    getCardData();
  }, [getCardData]);

  if (cardData) {
    const { status, profile } = cardData;

    if (status === 'connected' && profile && profile.profile_hash) {
      return <Redirect to={`/view-profile/${profile.profile_hash}`} />;
    }
  }

  if (!isLoading && !key) {
    return <Redirect to="/" />;
  }

  if (isLoading) {
    return (
      <div className="min-h-screen bg-white flex flex-col justify-center py-6 sm:px-6 lg:px-8">
        <div className="py-32">
          <Spinner className="h-16 w-16 text-brand-500 mx-auto" />
        </div>
      </div>
    );
  }

  return (
    <NewLayoutStandard heading="Activate your card" description="">
      <div
        className="overflow-hidden sm:mx-auto sm:w-full sm:max-w-md"
        style={{ maxHeight: pageHeight }}
      >
        {/* ---------------------------------------MOBILE ---------------------------------------*/}
        <div className="sm:hidden bg-white py-2 px-4 sm:rounded-lg sm:px-10">
          <div className="space-y-6">
            <div className="rounded-tr-4xl mb-52 rounded-bl-4xl bg-brand-100 py-8 px-4 flex flex-col space-y-2 w-full items-center justify-center">
              <h3 className="text-2xl text-gray-900 font-medium mt-3 mb-3">
                {key}
              </h3>
            </div>
          </div>
        </div>
        <div className="sm:hidden mt-auto bg-white sm:rounded-lg sm:px-10 justify-center">
          <div className="space-y-3 mb-4 fixed px-4 w-full bottom-0">
            <ol className="text-sm text-center leading-5 text-gray-500 pl-4">
              <li>
                Create an account or Login to an existing account to activate
                your card.
              </li>
            </ol>
            <Button
              buttonText="Login"
              fullWidth
              kind={BUTTON_KIND.WHITE}
              onClick={() =>
                history.push('/login', {
                  activationKey: key,
                })
              }
            />
            <Button
              buttonText="Create an account"
              fullWidth
              onClick={() =>
                history.push('/sign-up', {
                  activationKey: key,
                })
              }
            />
          </div>
        </div>

        {/* ---------------------------------------FULL SCREEN ---------------------------------------*/}
        <div className="hidden sm:block bg-white py-8 px-4 border border-gray-100 shadow sm:rounded-lg sm:px-10">
          <div className="space-y-6">
            <div className="rounded-tr-4xl rounded-bl-4xl bg-brand-100 py-8 px-4 flex flex-col space-y-2 w-full items-center justify-center">
              <h3 className="text-2xl text-gray-900 font-medium mt-3 mb-3">
                {key}
              </h3>
            </div>
            <ol className="text-sm leading-5 text-gray-500 pl-4 ">
              <li>
                Create a Tapt account or sign in to an existing account to
                activate your card.
              </li>
            </ol>
            <Button
              buttonText="Create an account"
              fullWidth
              onClick={() =>
                history.push('/sign-up', {
                  activationKey: key,
                })
              }
            />
            <div className="flex items-center justify-between">
              <Link
                to={{
                  pathname: '/login',
                  state: {
                    activationKey: key,
                  },
                }}
              >
                Already have an account?
              </Link>
              <a href="https://help.tapt.io/knowledge">I need help</a>
            </div>
          </div>
        </div>
      </div>
    </NewLayoutStandard>
  );
};

export default ActivationPage;
