import { AxiosResponse } from 'axios';
import { BASE_URL } from '../config';
import axiosApiService from '@/services/axiosApiService';
import IList, { IListPaging } from '@/types/IList';
import ICard from '@/types/ICard';

export interface IListCards {
  orgID: number;
  page: number;
  pageSize: number;
  sort: string;
  order: string;
  status: string;
  search: string;
}

const cardsAPI = {
  listCards: ({
    orgID = -1,
    page = 1,
    pageSize = 20,
    sort = 'date',
    order = 'desc',
    status = '',
    search = '',
  }: Partial<IList>): Promise<
    AxiosResponse<{ data: ICard[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/cards?page_size=${pageSize}&page=${page}&sort[${sort}]=${order}
      ${
        status !== '' ? `&status=${status}` : ''
      }
      ${
        search !== '' ? `&search=${search}` : ''
      }`,
    );
  },
  createCard: (
    orgID: number,
    body: {
      card: {
        design_id?: number;
        profile_id?: number;
        type: string;
      };
    },
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.post(
      `${BASE_URL}/api/organiations/${orgID}/cards`,
      body,
    );
  },
  updateCard: (
    orgID: number,
    cardID: number,
    body: {
      card: {
        status?: string;
        profile_id?: number | null;
        design_id?: number;
        admin_note?: string;
      };
    },
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.put(
      `${BASE_URL}/api/organisations/${orgID}/cards/${cardID}`,
      body,
    );
  },
  updateCardsShared: (
    orgID: number,
    body: {
      ids: number[];
      card: {
        status?: string;
        profile_id?: number | null;
        design_id?: number;
      };
    },
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.put(
      `${BASE_URL}/api/organisations/${orgID}/cards/shared`,
      body,
    );
  },
  showCard: (
    orgID: number,
    cardID: number,
  ): Promise<AxiosResponse<{ data: ICard }>> => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/cards/${cardID}`,
    );
  },
  showCardWithHashID: (
    hashID: string,
  ): Promise<AxiosResponse<{ data: ICard }>> => {
    // Set header to disable response caching
    const config = {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    };

    return axiosApiService.get(`${BASE_URL}/api/cards/${hashID}`, config);
  },
  importCardsCSV: (
    orgID: number,
    formData: FormData,
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.post(
      `${BASE_URL}/api/organisations/${orgID}/csv_import`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  exportCardsCSV: (orgID: number): Promise<AxiosResponse<BlobPart>> => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/cards/export_csv`,
    );
  },
  activateCards: (
    activationKey: string,
    orgID: number,
    profileID?: number,
  ): Promise<
    AxiosResponse<{ data: { type: string; profile?: { id: number } } }>
  > => {
    return axiosApiService.post(
      `${BASE_URL}/api/organisations/${orgID}/card_activations/activate/${activationKey}`,
      {
        profile_id: profileID,
      },
    );
  },
  showActivatationKeyType: (
    activationKey: string,
  ): Promise<AxiosResponse<{ data: { type: string } }>> => {
    return axiosApiService.get(
      `${BASE_URL}/api/card_activations/${activationKey}`,
    );
  },
  activateCardsWithProfileHashID: (
    activationKey: string,
    profileHashID: string,
  ): Promise<
    AxiosResponse<{ data: { type: string; profile?: { id: number } } }>
  > => {
    return axiosApiService.post(
      `${BASE_URL}/api/card_activations/activate/${activationKey}`,
      {
        profile_hash_id: profileHashID,
      },
    );
  },
};

export default cardsAPI;
