import { IIcon } from '@/types/IIcon';

const SocialTiktok = ({ className }: IIcon): JSX.Element => {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0909 2H3.90909C2.85432 2 2 2.85432 2 3.90909V21.0909C2 22.1457 2.85432 23 3.90909 23H21.0909C22.1457 23 23 22.1457 23 21.0909V3.90909C23 2.85432 22.1447 2 21.0909 2ZM19.8395 11.0434C19.7154 11.0548 19.5904 11.0625 19.4644 11.0625C18.0373 11.0625 16.783 10.3294 16.0528 9.21923C16.0528 12.1296 16.0528 15.4419 16.0528 15.4973C16.0528 18.0602 13.9748 20.1373 11.4128 20.1373C8.85077 20.1364 6.77273 18.0583 6.77273 15.4963C6.77273 12.9334 8.85077 10.8563 11.4128 10.8563C11.5101 10.8563 11.6046 10.8649 11.6991 10.8706V13.1577C11.6037 13.1462 11.5111 13.129 11.4128 13.129C10.105 13.129 9.04455 14.1895 9.04455 15.4973C9.04455 16.805 10.1041 17.8655 11.4128 17.8655C12.7215 17.8655 13.8765 16.8346 13.8765 15.5269C13.8765 15.4744 13.8994 4.86459 13.8994 4.86459H16.0843C16.2895 6.81855 17.8674 8.36109 19.8395 8.50236V11.0434Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SocialTiktok;
