import { ReactNode, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button, { BUTTON_KIND } from '@/components/Button';
import classNames from '@/helpers/classNames';

interface IModalFullWidth {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  buttonKind?: string;
  buttonSize?: string;
  buttonClassName?: string;
  dialogHeroVideo?: string;
  buttonTitle?: string;
  dialogTitle?: string;
  dialogDescription?: string | JSX.Element;
  children?: ReactNode;
  successButtonText: string;
  successButtonKind?: string;
  removeButtonText?: string;
  removeButtonKind?: string;
  cancelButtonText?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  large?: boolean;
  onCancel?: () => void;
  onSuccess: () => void;
  onRemove?: () => void;
}

export default function ModalFullWidth({
  isOpen,
  setIsOpen,
  buttonKind = BUTTON_KIND.WHITE,
  buttonSize,
  buttonClassName,
  buttonTitle,
  dialogHeroVideo,
  dialogTitle,
  dialogDescription,
  children,
  successButtonText,
  successButtonKind = BUTTON_KIND.PRIMARY,
  removeButtonKind = BUTTON_KIND.RED,
  removeButtonText = 'Remove profiles',
  cancelButtonText = 'Cancel',
  isLoading,
  isDisabled = false,
  large = false,
  onCancel,
  onSuccess,
  onRemove,
}: IModalFullWidth): JSX.Element {
  return (
    <>
      {buttonTitle && (
        <Button
          kind={buttonKind}
          size={buttonSize}
          className={buttonClassName}
          onClick={() => setIsOpen(true)}
          buttonText={buttonTitle}
          fullWidth
        />
      )}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setIsOpen(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-300 bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={classNames(
                  'inline-block p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl',
                  large ? 'w-full md:w-3/4 xl:w-1/2' : 'w-full max-w-5xl',
                )}
              >
                {dialogHeroVideo && (
                  <video
                    src={dialogHeroVideo}
                    muted
                    autoPlay
                    loop
                    playsInline
                    className="w-full mb-6 bg-white"
                  />
                )}
                {dialogTitle && (
                  <Dialog.Title
                    as="h3"
                    className="text-2xl leading-8 font-medium text-gray-900"
                  >
                    {dialogTitle}
                  </Dialog.Title>
                )}

                {dialogDescription && (
                  <div className={classNames(dialogTitle ? 'mt-2' : '')}>
                    <Dialog.Description className="text-sm leading-5 text-gray-500">
                      {dialogDescription}
                    </Dialog.Description>
                  </div>
                )}

                {children}
                {onRemove && (
                  <div className="mt-6 w-full">
                    <div className="flex justify-between">
                      <div className="justify-start">
                        <Button
                          kind={BUTTON_KIND.WHITE}
                          onClick={() => {
                            if (onCancel) onCancel();
                            setIsOpen(false);
                          }}
                          buttonText={cancelButtonText}
                        />
                      </div>
                      <div className="justify-end flex space-x-4">
                        <div className="hidden sm:block">
                          <Button
                            kind={removeButtonKind}
                            onClick={onRemove}
                            buttonText={removeButtonText}
                            loading={isLoading}
                            disabled={isDisabled}
                          />
                        </div>
                        <Button
                          kind={successButtonKind}
                          onClick={onSuccess}
                          buttonText={successButtonText}
                          loading={isLoading}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {!onRemove && (
                  <div className="mt-6 justify-end w-full flex space-x-4">
                    <Button
                      kind={BUTTON_KIND.WHITE}
                      onClick={() => {
                        if (onCancel) onCancel();
                        setIsOpen(false);
                      }}
                      buttonText={cancelButtonText}
                    />
                    <Button
                      kind={successButtonKind}
                      onClick={onSuccess}
                      buttonText={successButtonText}
                      loading={isLoading}
                      disabled={isDisabled}
                    />
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
