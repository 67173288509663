const STAGE = {
  order: {
    new: 'new',
    awaiting_client: 'awaiting_client',
    design_edits: 'design_edits',
    ready_for_print: 'ready_for_print',
    fulfilled: 'fulfilled',
    in_print: 'in_print',
    on_hold: 'on_hold',
    all_orders: 'all_orders'
  },
  order_stage_name: {
    new: 'New',
    awaiting_client: 'With Client',
    design_edits: 'Edit',
    ready_for_print: 'Ready for Print',
    fulfilled: 'Fullfilled',
    in_print: 'In Print',
    on_hold: 'On Hold',
    all_orders: 'All Orders'
  },
  designStatus: [
    {
      id: 'awaiting_client',
      label: 'Awaiting approval',
      color: 'amber'
    },
    {
      id: 'fulfilled',
      label: 'Design approved',
      color: 'emerald'
    },
    {
      id: 'new',
      label: 'In process',
      color: 'red'
    },
    {
      id: 'ready_for_print',
      label: 'Design approved',
      color: 'emerald'
    },
    {
      id: 'design_edits',
      label: 'Proof pending',
      color: 'red'
    },
  ],
  fulfillmentStatus: [
    {
      id: 'new',
      label: 'Unfulfilled',
      color: 'red'
    },
    {
      id: 'design_edits',
      label: 'Partially fulfilled',
      color: 'amber'
    },
    {
      id: 'fulfilled',
      label: 'Fulfilled',
      color: 'emerald'
    },
    {
      id: 'awaiting_client',
      label: 'Unfulfilled',
      color: 'red'
    },
    {
      id: 'ready_for_print',
      label: 'Partially fulfilled',
      color: 'amber'
    },
  ],
  order_tag: [
    {
      name: 'setup',
      value: 'setup'
    },
    {
      name: 'ready',
      value: 'ready'
    },
    {
      name: 'awaiting',
      value: 'awaiting'
    },
    {
      name: 'on_hold',
      value: 'on_hold'
    },

  ]
};

export { STAGE as default, STAGE };