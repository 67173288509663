import STAGE from "@/constants/stage";

const StageHelper = {
    getOrderStatus: (id: string) => {
        const orderStatus = STAGE.designStatus.filter(item => {
            return item.id === id
        })

        if (orderStatus?.length) {
            return orderStatus[0]
        }
        return {
            id: 0,
            label: id,
            color: 'gray'
        };
    }
}

export { StageHelper as default, StageHelper };