import { AxiosResponse } from 'axios';
import { BASE_URL } from '../config';
import axiosApiService from '@/services/axiosApiService';
import IAnalytics, { AnalyticsResponse } from '@/types/IAnalytics';
import IList, { IListPaging } from '@/types/IList';

const analyticsAPI = {
  oldListAnalytics: ({
    orgID = -1,
    page = 1,
    pageSize = 20,
    sort = 'date',
    order = 'desc',
    search = '',
  }: Partial<IList>): Promise<
    AxiosResponse<{ data: IAnalytics[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/analytics?page_size=${pageSize}&page=${page}&sort[${sort}]=${order}${
        search !== '' ? `&search=${search}` : ''
      }`,
    );
  },
  listAnalytics: (orgID: number) => {
    return axiosApiService.get<AnalyticsResponse>(
      `${BASE_URL}/api/organisations/${orgID}/analytics2`,
    );
  },
};

export default analyticsAPI;
